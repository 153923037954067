import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { login } from '../../_actions/auth.actions';

import { Login } from './login.component';
import { SignIn } from './sign-in.component';

export class LoginContainer extends Component {

    login = (event) => {
        event.preventDefault();
        let form = event.target;
        this.props.login(form.username.value, form.password.value);
    }
    render() {
        return (
            <Login loading={this.props.loading} >
                <SignIn  fromPath={this.props.location?.state?.from?.pathname} errorMsg={this.props.errorMsg} onSubmit={this.login} />
            </Login>
        )
    }
}

LoginContainer.propTypes = {
    errorMsg: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    login: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    errorMsg: state.auth.errorMsg,
    loading: state.auth.loading
})

const mapDispatchToProps = dispatch => bindActionCreators({
    login: login
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer)