import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';

import Keyword from './chosen.keyword';
import { colorTheme, ChosenWrapper, grayedText } from '../../../sharedStyledComponents/generalStyles';

export function Keywords(props) {
    // Reduce and count all keyword appearances
    const countKeywords = (keywords) => {
        const keywordCount = keywords.reduce((countedKeywords, keyword) => {
            if (keyword.keyword in countedKeywords) {
                countedKeywords[keyword.keyword]++;
            }
            else {
                countedKeywords[keyword.keyword] = 1;
            }
            return countedKeywords;
        }, {})
        // Get all object keys (these are the keywords)
        const keys = Object.keys(keywordCount);
        //Return an array of Keyword components
        return keys.map((k, i) => {
            // Get the count of this specifik keyword (k)
            const count = keywordCount[k];
            return (<Keyword key={k + i} keyword={k} count={count} searchKeyword={props.searchKeyword} />)
        });
    }
    return (
        <Wrapper>
            <Header>
                <HeaderMaintext>Nøgleord</HeaderMaintext>
                <HeaderSubtext>- AI Medlyt</HeaderSubtext>
            </Header>
            <ContentWrapper >
                {props.keywords.length === 0 && <HeaderSubtext>Ingen ord registreret</HeaderSubtext>}
                {/* // Unique values only (no duplicates) */}
                {props.keywords && countKeywords(props.keywords)}
            </ContentWrapper>
        </Wrapper>
    )
}

Keywords.defaultProps = {
    keywords: []
}

Keywords.propTypes = {
    keywords: PropTypes.arrayOf(PropTypes.object),
}

const Wrapper = styled(ChosenWrapper)`
    height: 100%;
`
const Header = styled.div`
    display: flex;
`;

const HeaderMaintext = styled.div`
    font-weight: 550;
    color: ${colorTheme};
    margin-right: .2rem;
    user-select: none;
`;

const HeaderSubtext = styled.div`
    color: ${grayedText};
    user-select: none;
`;

const ContentWrapper = styled.div`
    /* flex-wrap: wrap; */
    /* overflow-y: scroll; */
    /* max-height: 12vh; */

`;