import { Input, Row, Col, Button, Form } from 'antd';
import styled from 'styled-components';
import {
  colorTheme
} from '../../sharedStyledComponents/generalStyles';
import { useState, useEffect } from 'react';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import { useSelector, useDispatch } from 'react-redux';
import { getGreetingMessage, updateGreetingMessage } from '../../../_actions/additionalSettings.actions';
import { useForm } from 'antd/lib/form/Form';

const GeneralContainer = () => {
  const [form] = useForm();
  const greetingMessage = useSelector(state => state.additionalSettings.greetingMessage);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGreetingMessage());
  }, [dispatch]);

  const FormItem = ({ name, label, input, toggleEditable, isEditable, onSave }) => {
    return (
      <FormItemWrapper>
        <Form.Item
          style={{ width: '100%' }}
          name={name}
          label={label}>
          {input}

        </Form.Item>
        {
          !isEditable
            ?
            <EditButton icon={<CreateOutlinedIcon style={{ fontSize: '1.7rem' }} />} type='text' onClick={() => toggleEditable()} />
            :
            <EditBottons>
              <Button size='small' onClick={onSave}>
                Gem
                </Button>
              <Button size='small' type='primary' danger onClick={() => toggleEditable()}>
                Anullér
                </Button>
            </EditBottons>
        }
      </FormItemWrapper>
    )
  }

  //greeting message
  useEffect(() => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      greetingMessage: greetingMessage
    })
  }, [greetingMessage, form])
  const [isGreetingsMessageEditable, setIsGreetingsMessageEditable] = useState(false);
  const toggleEditable = (setMethod) => () => {
    setMethod(prevState => !prevState);
    form.resetFields();
  }
  const saveGreetingMessage = async () => {
    await dispatch(updateGreetingMessage(form.getFieldValue('greetingMessage')));
    setIsGreetingsMessageEditable(false);
  }

  return (
    <PageWrapper>

      <Row>
        <Col span={24}>
          <TitleWrapper>
            <PrimaryTitle>Generelt</PrimaryTitle>
            <SecondaryTitle $level={3}>Dashboard</SecondaryTitle>
          </TitleWrapper>
        </Col>
      </Row>

      <Form
        form={form}
        initialValues={{
          'greetingMessage': greetingMessage
        }}>
        <FormItem
          name='greetingMessage'
          label="Velkomsthilsen"
          input={
            <Input.TextArea
              disabled={!isGreetingsMessageEditable}
              autoSize={{ minRows: 2 }} />
          }
          toggleEditable={toggleEditable(setIsGreetingsMessageEditable)}
          isEditable={isGreetingsMessageEditable}
          onSave={saveGreetingMessage}
        />
      </Form>

    </PageWrapper >
  )
}

export default GeneralContainer;

const PageWrapper = styled.div`
  padding: 1rem 0;
`;

const TitleWrapper = styled.div`
  font-size: 1.5rem;
  color: ${colorTheme};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0 0.5rem 0.1rem;
`;

const PrimaryTitle = styled.div`
  font-weight: 700;
  margin-right: 0.6rem;
`;

const FormItemWrapper = styled.div`
  display: flex;
  margin-right: 0.6rem;
`;

const EditButton = styled(Button)`
  padding-left: 1rem;
  background: none;
  color: ${colorTheme};
  &:hover {
    background: none !important;
    color: ${colorTheme};
    &:hover {
      background: none;
      border-color: none;
    }
    &:focus {
      background: none;
      border-color: none;
    }
  }
`;

const EditBottons = styled.div`
  width: max-content;
  display: flex;
  gap: 5px;
  flex-direction: column;
  padding-left: 1rem;
`;


const SecondaryTitle = styled.div``;