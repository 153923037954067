import { CURRENT_INSTRUCTION, SET_GREETING_MESSAGE } from '../_actions/additionalSettings.actions';

const initialState = {
  loading: false,
  instruction: undefined,
  greetingMessage: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CURRENT_INSTRUCTION: {
      return {
        ...state,
        instruction: action.payload,
      };
    }
    case SET_GREETING_MESSAGE: {
      return {
        ...state,
        greetingMessage: action.payload,
      }
    }
    default:
      return state;
  }
};

export default reducer;
