import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components';

import { addActiveDocument } from '../../../_actions/multiDocument.actions';

import { Content, colorTheme, borderColor, textTheme, TextUnderlineAnimated, textOnTheme } from '../../sharedStyledComponents/generalStyles';
import { dotIcon } from '../../sharedStyledComponents/icons';

export class Transactions extends PureComponent {

    render() {
        return (
            <Container>
                <Tab>Kørsler</Tab>
                <ListWrapper>
                    {this.props.transactions.sort((a, b) => a.name.localeCompare(b.name)).map(wI => (
                        <ListItemLink key={wI.id} onClick={() => this.props.addActiveDocument(wI.id)}>
                            <Icon>{dotIcon('5px', colorTheme)}</Icon>
                            <TextUnderlineAnimated>
                                {wI.name.charAt(0).toUpperCase() + wI.name.slice(1)}
                            </TextUnderlineAnimated>
                        </ListItemLink>
                    ))}
                </ListWrapper>
            </Container>
        )
    }
}

Transactions.propTypes = {
    loading: PropTypes.bool,
    transactions: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        category: PropTypes.string
    })).isRequired,
    addActiveDocument: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    transactions: state.dashboard.transactions,
    loading: state.dashboard.loading,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    addActiveDocument,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Transactions)


const Container = styled(Content)`
    ::-webkit-scrollbar {
    display: none;
    }
`;

const ListWrapper = styled.div`
    flex-grow: 1;
    max-height: 15rem;
    overflow-y: auto;
    ::-webkit-scrollbar {
    display: none;
    }
`;

const Icon = styled.div`
  margin-right: .3rem;  
`;

const ListItemLink = styled.li`
    margin: 0;
    padding: .35rem 1rem;
    border-bottom: .1rem solid ${borderColor};
    color : #666;
    cursor: pointer;
    list-style: none;
    display: inline-flex;
    width: 100%;
    &:hover {
        ${TextUnderlineAnimated}:before {
            visibility: visible;
            width: 100%;
            background-color: ${textTheme};
            transform: width;
        }
    }
`;

const Tab = styled.div`
    background-color: ${colorTheme};
    color: ${textOnTheme};
    user-select: none;
    border-radius: .2rem .2rem 0 0;
    cursor: pointer;
    padding: .5rem 1rem .5rem 1rem;
    font-weight: 550;
    user-select: none;
    flex: 1;
    box-shadow: -1px -1px 1px #888;
`;