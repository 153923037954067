import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Keywords } from './components/chosen.keywords.component';
import RelevantSections from './components/chosen.relevant-sections';
import {
  Content,
  colorTheme,
  textOnTheme,
  textTheme,
  ChosenWrapper,
  TextUnderlineAnimated,
} from '../../sharedStyledComponents/generalStyles';
import { chevronLeftIcon, numberedListIcon } from '../../sharedStyledComponents/icons';
import { Loading } from '../../GlobalComponents/loading.component';
import { ComplianceList } from './components/chosen.compliance-list';

import { navigateTo } from '../../../_actions/chosen.actions';

import { removeActiveDocument } from '../../../_actions/multiDocument.actions';

export class Chosen extends Component {

  /**
   * Simple function to detect quickguides in documents
   */
  quickGuideCheck = () => {
    const activeDocument = this.props.activeDocument
    if (activeDocument.outline) {
      let found = activeDocument.outline.find(item => item.title.toLowerCase().includes('quick'));
      if (found) {
        return (
          <QuickGuideWrapper>
            <LinkWrapper onClick={() => this.props.navigateTo(found.title, activeDocument.id)}>
              <IconWrapper>{numberedListIcon('18px', colorTheme)}</IconWrapper>
              <LinkText>Quickguide</LinkText>
            </LinkWrapper>
          </QuickGuideWrapper>
        );
      }
    }
    return null;
  };

  render() {
    const {
      removeActiveDocument,
      outline,
      offline,
      keywords,
      searchKeyword,
      navigateTo,
      relevantHeaders,
      activeDocument,
      callStatus,
    } = this.props;

    if (!activeDocument) return <Loading />;

    return (
      <>
        <Container>
          <ContentWrapper>
            <Category>
              <BackButton title='Tilbage' onClick={() => removeActiveDocument(activeDocument.id, true)}>
                {chevronLeftIcon('15px', textOnTheme)}
              </BackButton>
              {activeDocument.name}
            </Category>
            {outline && this.quickGuideCheck()}
            <RelevantSections navigateTo={navigateTo} relevantHeaders={relevantHeaders} activeDocument={activeDocument} />

            <Keywords offline={offline} keywords={keywords} searchKeyword={searchKeyword} />
          </ContentWrapper>

          <ContentWrapper>
            {callStatus && <ComplianceList /> }
          </ContentWrapper>
        </Container>
      </>
    );
  }
}

Chosen.propTypes = {
  activeDocument: PropTypes.object,
  removeActiveDocument: PropTypes.func.isRequired,
  keywords: PropTypes.array.isRequired,
  outline: PropTypes.array,
  navigateTo: PropTypes.func.isRequired,
  relevantHeaders: PropTypes.array.isRequired,
  offline: PropTypes.bool.isRequired,
  callStatus: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  callStatus: state.dashboard.callStatus,
  keywords: state.dashboard.keywords,
  offline: state.dashboard.offline,
  navigateTitle: state.chosen.navigateTitle,
  relevantHeaders: state.chosen.relevantHeaders,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      navigateTo,
      removeActiveDocument,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Chosen);

const Container = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;

const ContentWrapper = styled(Content)``;

const Category = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: ${colorTheme};
  color: ${textOnTheme};
  font-weight: 550;
  font-size: 1.1rem;
  border-radius: 0.2rem 0.2rem 0 0;
  user-select: none;
`;

const BackButton = styled.a`
  margin-left: -0.5rem;
  margin-right: 0.5rem;
  height: 5vh;
  max-height: 3rem;
  color: ${colorTheme};
  &:hover {
    cursor: pointer;
  }
`;

// const LinkContainer = styled.div`
//             display: flex;
//             margin-bottom: 1rem;
//             max-height: 3rem;
//         `;

const LinkText = styled(TextUnderlineAnimated)`
  margin-left: 0.4rem;
  color: ${colorTheme};
  user-select: none;
`;

const IconWrapper = styled.span`
  height: 1.5rem;
`;

const LinkWrapper = styled.a`
  display: flex;
  align-items: center;
  padding: 0.2rem 0rem;
  cursor: pointer;
  margin-left: 0.5rem;
  &:hover {
    ${LinkText}:before {
      visibility: visible;
      width: 100%;
      background-color: ${textTheme};
      -webkit-transform: width;
      transform: width;
    }
  }
`;

const QuickGuideWrapper = styled(ChosenWrapper)`
  flex-direction: column;
`;
