import axios from 'axios';
import { Config } from '../config/config';

export const GET_AGENTS = 'agents/GET_AGENTS';
export const AGENTS_LOADING = 'agents/AGENTS_LOADING';
export const ERROR = 'agents/ERROR';

export const getAgents = () => {
  return async (dispatch) => {
    dispatch({ type: AGENTS_LOADING, payload: { loading: true } });
    try {
      const res = await axios.get(`${Config.conf.backendUrl}/api/v1/agentconfigs`);

      dispatch({ type: GET_AGENTS, payload: { agents: res.data } });
    } catch (e) {
      dispatch({ type: ERROR, payload: { error: e } });
    }
  };
};
