import jwtDecode from 'jwt-decode';

const utils = {
  roundToNearest(numToRound, numToRoundTo) {
    return Math.round(numToRound / numToRoundTo) * numToRoundTo;
  },
  isString(item) {
    return typeof item === 'string' || item instanceof String;
  },

  /**
   * Check if user has an admin role in token
   * this allows access to the protected route.
   */
  isAdmin() {
    try {
      const decodedToken = jwtDecode(sessionStorage.getItem('token'));
      return decodedToken.role === 'admin' || this.isSuperAdmin();
    } catch (error) {
      console.error('TOKEN ERROR: ', error);
      sessionStorage.removeItem('token');
    }
  },

  /**
   * Check if user has an admin role in token
   * this allows access to the protected route.
   */
  isSuperAdmin() {
    try {
      const decodedToken = jwtDecode(sessionStorage.getItem('token'));
      return decodedToken.role === 'superadmin';
    } catch (error) {
      console.error('TOKEN ERROR: ', error);
      sessionStorage.removeItem('token');
    }
  },
};

export default utils;
