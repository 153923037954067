const prodConf = {
  backendUrl: "https://api.ipsb.dk",
  searchApi: "https://ml.ipsb.dk/get_autocomplete",
  suggestionsApi: "https://ml.ipsb.dk/get_documents_from_keywords"
}

const devConf = {
  backendUrl: "https://api-dev.testipsb.dk",
  searchApi: "https://ipsb-ml-dev.lokmebaehxr.eu-de.codeengine.appdomain.cloud/get_autocomplete",
  suggestionsApi: "https://ipsb-ml-dev.lokmebaehxr.eu-de.codeengine.appdomain.cloud/get_documents_from_keywords"
  // CF conf:
  //backendUrl: "https://api-dev.ipsb.dk",
  //searchApi: "https://ipsb-ml-london-dev.eu-gb.mybluemix.net/get_autocomplete",
  //suggestionsApi: "https://ipsb-ml-london-dev.eu-gb.mybluemix.net/get_documents_from_keywords"
}

const localConf = {
  backendUrl: "http://localhost:3000",
  searchApi: "https://ipsb-ml-dev.lokmebaehxr.eu-de.codeengine.appdomain.cloud/get_autocomplete",
  suggestionsApi: "https://ipsb-ml-dev.lokmebaehxr.eu-de.codeengine.appdomain.cloud/get_documents_from_keywords"
  // CF conf:
  //searchApi: "https://ipsb-ml-london-dev.eu-gb.mybluemix.net/get_autocomplete",
  //suggestionsApi: "https://ipsb-ml-london-dev.eu-gb.mybluemix.net/get_documents_from_keywords"
}
export class Config {
  static envTest() {
    switch (process.env.REACT_APP_STAGE) {
      case "prod": return prodConf;
      case "dev": return devConf;
      default: return localConf;
    }
  }
  static conf = Config.envTest();
}
