import { Config } from '../config/config';
import { push } from 'connected-react-router';
import jwtDecode from 'jwt-decode';

export const LOGIN_REQUEST = 'auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'auth/LOGIN_FAILURE';
export const SET_USERNAME = 'auth/SET_USERNAME';
export const CLEAR_TOKEN = 'auth/CLEAR_TOKEN';
export const LOGOUT_SHOW = 'auth/LOGOUT_SHOW';

export const login = (username, password) => {
  return (dispatch, getState) => {
    dispatch(loginRequest(username));
    fetch(Config.conf.backendUrl + '/api/v1/login', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({ username: username, password: password }),
    }) /*end fetch */
      .then((results) => results.json())
      .then(async (data) => {
        if (data.token) {
          await dispatch(loginSuccess(data.token));
          const token = await jwtDecode(getState().auth.token);
          if (token.role === 'admin' || token.role === 'superadmin') {
            dispatch(push('/admin'));
            return;
          }
          dispatch(push('/dashboard'));
          return;
        }
        if (!data.errorMsg) {
          dispatch(loginFailure('Der skete en uventet server fejl. Prøv igen'));
        } else {
          console.error(data.errorMsg);
          dispatch(loginFailure('Forkert indtastet brugernavn og/eller adgangskode. Prøv igen.'));
        }
      })
      .catch((e) => {
        console.error('Error: ', e);
        dispatch(loginFailure('Der opstod en uventet netværks fejl. Prøv igen'));
      }); // this should be logged as well
  };
};

export const loginRequest = (username) => {
  return {
    type: LOGIN_REQUEST,
    username,
  };
};

export const loginSuccess = (token) => {
  sessionStorage.setItem('token', token);
  return {
    type: LOGIN_SUCCESS,
    token,
  };
};

export const loginFailure = (errorMsg) => {
  return {
    type: LOGIN_FAILURE,
    errorMsg,
  };
};

export const clearToken = () => {
  sessionStorage.removeItem('token');
  return (dispatch) => {
    dispatch({ type: CLEAR_TOKEN });
  };
};

export const setLogoutState = (showState) => {
  return (dispatch) => {
    dispatch(setCurrentState(showState));
  };
  function setCurrentState(showState) {
    return { type: LOGOUT_SHOW, showState };
  }
};

export const redirectDashboard = () => {
  return (dispatch) => dispatch(push('/dashboard'));
};
