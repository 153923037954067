import { useSelector, useDispatch } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import { generalBackground, colorTheme, textTheme, CloseIcon } from '../../sharedStyledComponents/generalStyles';
import { toggleDisplayChangesToInstructions } from '../../../_actions/dashboard.actions';
import { useEffect, useState } from 'react';
import { getDocumentChanges } from '../../../_actions/documentChanges.actions';
import dayjs from 'dayjs';
import 'dayjs/locale/da';
import { addActiveDocument } from '../../../_actions/multiDocument.actions';
import { navigateTo } from '../../../_actions/chosen.actions';
import { closeIcon } from '../../sharedStyledComponents/icons';
import ReactResizeDetector from 'react-resize-detector';
import InstructionChangesTable from '../../AdminDashboard/InstructionChanges/InstructionChangesTable.component';
import { AGENT_STATUS_TYPE } from '../../AdminDashboard/InstructionChanges/_constants/instructionChanges.contstants';
dayjs.locale('da');

const ChangesToInstructionsModal = () => {
  const agentConfig = useSelector(store => store.agentConfig);
  const displayModal = useSelector(store => store.dashboard.displayChangesToInstructionsModal);
  const { documentChanges, loading } = useSelector(store => store.documentChanges);
  const [days, setDays] = useState(30);
  const [displayedChanges, setDisplayedChanges] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const mappedChanges = documentChanges
      .filter(change => change.timestamp > (agentConfig.createdTimestamp || 0))
      .map(change => {
        const mappedStatus = [
          {
            agent: agentConfig._id,
            status: change.agentStatus[agentConfig._id] ? AGENT_STATUS_TYPE.seen : AGENT_STATUS_TYPE.seen,
            timestamp: change.agentStatus[agentConfig._id]?.timestamp || 0,
          },
        ];

        const lastSeen = mappedStatus.reduce((lastSeen, agentStatus) => {
          return Math.max(lastSeen, agentStatus.timestamp);
        }, 0);

        return { ...change, lastSeen: lastSeen, agentStatus: mappedStatus };
      });

    setDisplayedChanges(mappedChanges);
  }, [documentChanges, agentConfig]);

  const onChangeClick = change => {
    dispatch(addActiveDocument(change.docId));
    dispatch(navigateTo(change.sectionName, change.docId));
    closeModal();
  };

  const closeModal = () => {
    dispatch(toggleDisplayChangesToInstructions());
  };

  const onKeyDown = logKey => {
    if (displayModal && logKey.key === 'Escape') {
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  });

  useEffect(() => {
    document.body.style.overflow = displayModal ? 'hidden' : 'scroll';
    if (!displayModal) return;
    const timestamp = dayjs().subtract(days, 'days').toDate().getTime();
    dispatch(getDocumentChanges(timestamp));
  }, [displayModal, dispatch, days]);

  return (
    displayModal && (
      <Wrapper>
        <ModalBackground onClick={closeModal} />
        <Modal>
          <CloseIcon onClick={() => closeModal()}>{closeIcon(14, colorTheme)}</CloseIcon>
          <TitleWrapper>
            <PrimaryTitle>Oversigt</PrimaryTitle>
            <SecondaryTitle level={3}>Ændringer til arbejdsinstrukser</SecondaryTitle>
          </TitleWrapper>
          <Description>
            I oversigten vises de arbejdsinstrukser, som er blevet ændret indenfor det definerede tidsinterval nedenfor.
          </Description>
          <ChangeDays>
            Vis ændringer fra de sidste&nbsp;
            <Select name='days' id='daysSelect' value={days} onChange={event => setDays(event.target.value)}>
              <option id={1} value={1}>
                1
              </option>
              <option id={10} value={10}>
                10
              </option>
              <option id={30} value={30}>
                30
              </option>
              <option id={60} value={60}>
                60
              </option>
              <option id={90} value={90}>
                90
              </option>
            </Select>
            &nbsp;dage.
          </ChangeDays>
          <ReactResizeDetector handleHeight handleWidth>
            {({ height = 0 }) => (
              <TableWrapper>
                <InstructionChangesTable
                  documentChanges={displayedChanges}
                  loading={loading}
                  onChangeClicked={onChangeClick}
                />
              </TableWrapper>
            )}
          </ReactResizeDetector>
        </Modal>
      </Wrapper>
    )
  );
};

export default ChangesToInstructionsModal;

const Wrapper = styled.div`
  position: relative;
`;

const ModalBackground = styled.div`
  position: relative;
  content: '';
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgb(40, 50, 75, 0.6);
`;

const fade = keyframes`
  100%{
    opacity: 1;
    top: 5rem;
  } 
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 50%;
  top: 10rem;
  transform: translateX(-50%);
  width: 70vw;
  max-height: 80vh;
  border-radius: 0.6rem;
  padding: 2rem;
  background-color: ${generalBackground};
  overflow: hidden;
  animation: ${fade} 250ms ease-in-out forwards;
  opacity: 0;
  .ant-table-column-sorters {
    justify-content: unset;
  }
  .ant-table-column-title {
    flex: unset;
    margin-right: 0.3rem;
  }
`;

const TitleWrapper = styled.div`
  font-size: 1.5rem;
  color: ${colorTheme};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 0.5rem;
`;

const PrimaryTitle = styled.div`
  font-weight: 700;
  margin-right: 0.6rem;
`;

const TableWrapper = styled.div`
  height: 80vh;
  overflow: hidden;
`;

const SecondaryTitle = styled.div``;

const Description = styled.div`
  color: ${textTheme};
  margin-bottom: 0.5rem;
`;

const ChangeDays = styled(Description)`
  margin-bottom: 1rem;
`;

const Select = styled.select``;

// const ListItem = styled.div``;

// const AgentStatus = styled.div`
//   position: relative;
//   padding-left: 1.2rem;

//   :before {
//     position: absolute;
//     content: '';
//     width: 0.8rem;
//     height: 0.8rem;
//     border-radius: 50%;
//     background-color: ${props => props.color};
//     top: 50%;
//     left: 0;
//     transform: translateY(-50%);
//   }
// `;
