import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import CallStatus from './components/callstatus.component';

import { logoutIcon } from '../../sharedStyledComponents/icons';
import { colorTheme, StyledLink, TitleText, itemBackground, borderColor } from '../../sharedStyledComponents/generalStyles';

import { bindActionCreators } from 'redux';
import { Popconfirm } from 'antd';

import { clearToken, setLogoutState } from '../../../_actions/auth.actions';
import { timerStart, timerStop, reopenDocuments } from '../../../_actions/dashboard.actions';

export class ContentHeader extends Component {

  render() {
    return (
      <Container>
        <TopContent>
          <HeaderText color={colorTheme} fontSize={'1.5rem'}>
            Samtale Support Bot
          </HeaderText>
          <HeaderButtons>
            {/*
              This should be added instead of next line if there is added more options to the menu than logout 
              <SettingsButton border={this.props.logoutState} onClick={() => this.props.setLogoutState(!this.props.logoutState)}> 
            */}
            <Popconfirm placement="topLeft" title={'Er du sikker på at du vil logge ud?'} onConfirm={this.props.logout} okText="Log ud" cancelText="Fortryd">
              <SettingsButton title='Log ud' $border={this.props.logoutState}>
                {logoutIcon('20px', colorTheme)}
              </SettingsButton>
            </Popconfirm>

          </HeaderButtons>
        </TopContent>
        <CallStatus
          callStatusIndicator={this.props.callStatusIndicator}
          callStatusText={this.props.callStatusText}
          time={this.props.time}
          timerStart={this.props.timerStart}
          timerStop={this.props.timerStop}
          reopenDocuments={this.props.reopenDocuments}
          offline={this.props.offline}
          n1Click={this.props.n1Click}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  callStatusIndicator: state.dashboard.callStatus,
  callStatusText: state.dashboard.callStatusText,
  time: state.dashboard.time,
  logoutState: state.auth.showLogout,
  offline: state.dashboard.offline,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout: clearToken,
      timerStart,
      timerStop,
      reopenDocuments,
      setLogoutState,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ContentHeader);

ContentHeader.propTypes = {
  logoutState: PropTypes.bool,
  logout: PropTypes.func.isRequired,
  callStatusIndicator: PropTypes.bool.isRequired,
  callStatusText: PropTypes.string,
  offline: PropTypes.bool.isRequired,
  time: PropTypes.number,
};

const Container = styled.div`
  background-color: ${itemBackground};
  border-bottom: 0.1rem solid ${borderColor};
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
`;

const TopContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.6rem 2rem 0.3rem 1.4rem;
`;

const HeaderText = styled(TitleText)`
  &.ant-typography {
    font-weight: 600;
    user-select: none;
    flex-grow: 1;
  }
`;

const HeaderButtons = styled.div``;

const SettingsButton = styled(StyledLink)`
  padding-right: 0rem;
  font-size: 1.2rem;
  background-color: transparent !important;
  border-bottom: ${(props) => (props.$border ? `3px solid ${colorTheme};` : 'none;')} !important;
  max-height: 6vh;
  transition: all 0.2s ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: transparent !important;
    transform: scale(1.1);
    border-bottom: ${colorTheme} 0.1rem solid;
  }
`;
