import { n2Click, complianceItemToggle } from '../_actions/socket-io.actions';

export const NAVIGATE_TO = 'chosen/NAVIGATE_TO';
export const CLEAR_NAVIGATION = 'chosen/CLEAR_NAVIGATION';
export const ADD_OUTLINE = 'chosen/ADD_OUTLINE';
export const CLEAR_N2 = 'chosen/CLEAR_N2';
export const TOGGLE_COMPLIANCE_ITEM_CHECK = 'chosen/TOGGLE_COMPLIANCE_ITEM_CHECK';
export const SET_COMPLIANCE_LIST = 'chosen/SET_COMPLIANCE_LIST';

export const navigateTo = (title, id) => {
  return async (dispatch, getState) => {
    dispatch(navigate(title));
    const callStatus = await getState().dashboard.callStatus;
    dispatch(n2Click(title, callStatus, id));
  };
  function navigate() {
    return { type: NAVIGATE_TO, payload: { title } };
  }
};

export const clearNavigation = () => {
  return dispatch => {
    dispatch(clearNavigation());
  };
  function clearNavigation() {
    return { type: CLEAR_NAVIGATION };
  }
};

export const addOutline = outline => {
  return dispatch => {
    dispatch(addOutline(outline));
  };
  function addOutline() {
    return { type: ADD_OUTLINE, payload: { outline } };
  }
};

export const clearN2 = () => {
  return { type: CLEAR_N2 };
};

export const setComplianceList = workInstructionId => {
  return async (dispatch, getState) => {
    const complianceList = getState().dashboard.complianceItems.filter(
      item => item.workInstructionId === workInstructionId
    );
    dispatch({
      type: SET_COMPLIANCE_LIST,
      payload: {
        complianceList,
      },
    });
  };
};

export function toggleComplianceItemCheck(id, value) {
  return dispatch => {
    dispatch({
      type: TOGGLE_COMPLIANCE_ITEM_CHECK,
      payload: {
        id: id,
        value,
      },
    });

    //socket event
    dispatch(complianceItemToggle(id, value));
  };
}
