import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types'

import { warningIcon } from '../sharedStyledComponents/icons';
import { textOnTheme, colorTheme } from '../sharedStyledComponents/generalStyles';

export default function Error(props) {
    const text = props.text;
    return (
        <Wrapper>
            <ErrorBox>
                <ErrorText>
                    <ErrorIcon>{warningIcon('36px', 'yellow')}</ErrorIcon>
                    {text}
                </ErrorText>
            </ErrorBox>
            {props.redirect && <BackButton onClick={() => props.redirect()}>Tilbage</BackButton>}
        </Wrapper>
    )
}

Error.propTypes = {
    text: PropTypes.string.isRequired,
    redirect: PropTypes.func,
}

Error.defaultProps = {
    text: 'Manglende fejlbesked'
}

const Wrapper = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ErrorBox = styled.div`
    position: absolute;
    border-radius: .5rem;
    top: 50vh;
    max-height: 10rem;
    color: red;
    border: 1px solid red;
    padding: .5rem 2rem;
    background-color: rgba(231, 76, 60,0.2);
`;

const ErrorText = styled.span`
    padding: .6rem .4rem;
    color: ${textOnTheme};
    font-size: 1.1rem;
    height: 2.5rem;
    max-height: 2.5rem;
`;

const ErrorIcon = styled.span`
    padding: .3rem 1rem;
    height: 2rem;
    max-height: 2rem;
`;

const BackButton = styled.button`
    position: absolute;
    top: 60vh;
    border-radius: .3rem;
    background: ${colorTheme};
    color: ${textOnTheme};
    font-size: 1.2rem;
    &:hover {
        background: ${textOnTheme};
        color: ${colorTheme};
    }
`;