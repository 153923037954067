import { useSelector } from 'react-redux';
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area } from 'recharts';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { last } from 'lodash';
import { isEmpty } from '../../../utils/helpers';
import dayjs from 'dayjs';

const YAxisProps = {
  padding: { bottom: 20 },
  orientation: 'right',
  axisLine: false,
  tickLine: false,
  unit: ' timer',
  tickMargin: 30,
  width: 75,
};

const XAxisProps = {
  dataKey: 'date',
  tickFormatter: (t) => {
    const d = new Date(t);
    return d.getDate();
  },

  label: { value: 'Okt', position: 'right', offset: 20 },
  tickMargin: 10,
  padding: { right: 70 },
  tick: { fill: '#333' },
  stroke: '#ff0000',
  axisLine: false,
  tickLine: false,
};

const CustomToolTip = () => null;

const CustomDot = (props) => {
  return (
    <>
      <circle className='c1' cx={props.cx} cy={props.cy} r='16' stroke='#DDE2FF' strokeWidth='3' fill='transparent' />
      <circle className='c2' cx={props.cx} cy={props.cy} r='6' stroke='#333' strokeWidth='6' fill='white' />
      <path
        d={`
          m ${props.cx - 15} ${props.cy - 60}
          l 40 0
          a 5 5 4 0 1 5 5
          l 0 15
          a 5 5 4 0 1 -5 5
          l -20 0 
          l -5 5 
          l -5 -5 
          l -20 0 
          a 5 5 0 0 1 -5 -5
          l 0 -15
          a 5 5 0 0 1 5 -5`}
        x={props.cx - 20}
        y={props.cy - 40}
        rx='3'
        ry='3'
        fill='#333'
      ></path>
      <text width={50} x={props.cx} y={props.cy - 42.5} fill='#FFF' textAnchor='middle'>
        {props.payload.value}
      </text>
    </>
  );
};

/**
 * Renders the agent assist module graph. The graph is built on the Recharts foundation
 * @returns {JSX} a graph showing a period for this year and the same period last year
 */
const AgentAssistModule = () => {
  const { statistics } = useSelector((store) => store);
  const { agentActivityTable } = statistics;

  // Initialize the variables so they can render in the table. These should not be shown before the data is populated
  let periodStart = 'N/A';
  let periodEnd = 'N/A';
  let comparePeriodStart = 'N/A';
  let comparePeriodEnd = 'N/A';

  if (!isEmpty(agentActivityTable)) {
    // Lets extract the dates as dayjs entities
    periodStart = dayjs(agentActivityTable[0].date);
    periodEnd = dayjs(last(agentActivityTable).date);

    // Create seperate copies of the date object so it does not interfere
    // const tempStart = dayjs(agentActivityTable[0].date).subtract(1, 'y');
    // const tempEnd = dayjs(last(agentActivityTable).date).subtract(1, 'y');

    const tempStart = dayjs(agentActivityTable[0].compareDate);
    const tempEnd = dayjs(last(agentActivityTable).compareDate);
    // Calculate for the same period last year as well
    comparePeriodStart = tempStart.format('MMM YYYY');
    comparePeriodEnd = tempEnd.format('MMM YYYY');

    XAxisProps.label.value = `${periodStart.format('MMM')} - ${periodEnd.format('MMM')}`;

    periodStart = periodStart.format('MMM YYYY');
    periodEnd = periodEnd.format('MMM YYYY');
  }

  // Initialize legend props with the date periods we just created
  const legendProps = {
    verticalAlign: 'top',
    align: 'right',
    height: 50,

    payload: [
      { value: `${comparePeriodStart} - ${comparePeriodEnd}`, type: 'line', id: 'compareValue', color: '#9FA2B4' },
      { value: `${periodStart} - ${periodEnd}`, type: 'line', id: 'value', color: '#34495E' },
    ],
  };

  return (
    <Row>
      <Col flex={1}>
        <ChartWrapper width='100%' height='80%' minHeight={500}>
          <AreaChart width={500} height={300} data={agentActivityTable}>
            <defs>
              <linearGradient id='colorUv' x1='1' y1='0' x2='0' y2='0'>
                <stop offset='5%' stopColor='#3751FF' stopOpacity={0} />
                <stop offset='95%' stopColor='#3751FF' stopOpacity={0.15} />
              </linearGradient>
            </defs>
            <CartesianGrid vertical={false} />
            <XAxis {...XAxisProps} />
            <YAxis {...YAxisProps} />
            <Area type='monotone' dataKey='compareValue' stroke='#DFE0EB' strokeWidth={3} fill='transparent' activeDot={false} />
            <Tooltip content={<CustomToolTip />} allowEscapeViewBox={{ x: true, y: true }} />
            <Area type='monotone' dataKey='value' stroke='#34495E' strokeWidth={2} fillOpacity={1} fill='url(#colorUv)' activeDot={<CustomDot />} />
            <Legend {...legendProps} />
          </AreaChart>
        </ChartWrapper>
      </Col>
    </Row>
  );
};

const ChartWrapper = styled(ResponsiveContainer)`
  .recharts-tooltip-wrapper {
    pointer-events: none;
    top: -20% !important;
    left: -35px !important;
  }

  .recharts-surface {
    overflow: visible;
  }

  .recharts-cartesian-grid-horizontal {
    line {
      stroke: #ebedf0;
      &:last-child {
        display: none;
      }
    }
  }

  .recharts-text.recharts-label {
    transform: translateY(5px);
  }
`;

export default AgentAssistModule;
