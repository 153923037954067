/**
 * @name isEmpty
 * @description evaluates whether an array, object or string is empty
 * @param {any} value the input value
 * @returns {boolean} is the input value empty?
 */
export const isEmpty = (value) => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
}; // isEmpty()

/**
 * @name wait
 * @description
 * @param {number} ms the amount of time to wait
 * @returns {Promise} the promise is returned
 */
export async function wait(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
} // wait()

export const uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

/**
 * Converts seconds to minute format
 * @param {integer} seconds the amount of seconds to format
 * @returns {String} formatted minutes string
 * @source https://stackoverflow.com/questions/3733227/javascript-seconds-to-minutes-and-seconds/3733257
 */
export const secondsToMinutes = (seconds) => {
  var m = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, '0'),
    s = Math.floor(seconds % 60)
      .toString()
      .padStart(2, '0');

  return m + ':' + s;
}; // secondsToMinutes()
