import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  colorTheme,
  textOnTheme,
  textTheme,
  Content,
} from '../../../sharedStyledComponents/generalStyles';
import { Bar } from '../../../sharedStyledComponents/Bar';
import { toggleComplianceItemCheck } from '../../../../_actions/chosen.actions';
import { InfoCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { IMPLEMENTATION_STATUS } from '../../../AdminDashboard/Compliance/_constants/compliance.constants';
import { sortComplianceItems } from '../../../../utils/sort-compliance';

export const ComplianceList = () => {
  const activeComplianceItems = useSelector(state => state.chosen.activeComplianceItems);

  const dispatch = useDispatch();

  const check = async item => {
    await dispatch(toggleComplianceItemCheck(item._id, !item.checked));
  };

  const groupItems = items => {
    const groups = new Map();
    items.forEach(item => {
      const key = item.group;

      if (groups.has(key)) {
        groups.get(key).push(item);
      } else {
        groups.set(key, [item]);
      }
    });
    return [...groups];
  };

  const sortedItems = sortComplianceItems(activeComplianceItems);
  const grouped = groupItems(sortedItems);

  return (
    grouped.length > 0 && (
      <Container>
        <Title>
          Husk <strong>altid</strong>....
        </Title>
        <GroupList>
          {grouped.map(([group, items]) => (
            <GroupWrapper key={group}>
              {group && <GroupTitle>{group}</GroupTitle>}
              <ComplianceItemsList>
                {items.map(item => {
                  return (
                    <ComplianceItemWrapper key={item._id} status={item.implementation.status}>
                      <ComplianceConfidenceWrapper>
                        <ComplianceItemConfidenceBar>
                          <ComplianceBar
                            width={Math.max(
                              item.checked && !item.agentOverride ? 100 : Math.round(item.score * 100),
                              10
                            )}
                          />
                        </ComplianceItemConfidenceBar>

                        {item.agentOverride ? (
                          <CheckboxContainer key={item._id + '_checkbox_manual'} onClick={() => check(item)}>
                            <HiddenCheckbox checked={item.checked} readOnly />
                            <StyledCheckbox checked={item.checked}>
                              <Icon viewBox='0 0 24 24'>
                                <polyline points='20 6 9 17 4 12' />
                              </Icon>
                            </StyledCheckbox>
                          </CheckboxContainer>
                        ) : (
                            <CheckboxContainer key={item._id + '_checkbox_ai'} onClick={() => check(item)}>
                              <HiddenCheckbox checked={item.checked} readOnly />
                              <StyledCheckbox $ai={true} checked={item.checked}>
                                <Icon $ai={true} viewBox='0 0 24 24'>
                                  <polyline points='20 6 9 17 4 12' />
                                </Icon>
                              </StyledCheckbox>
                            </CheckboxContainer>
                          )}
                      </ComplianceConfidenceWrapper>

                      <ComplianceItemText>{item.resume}</ComplianceItemText>
                      {item.implementation.status === IMPLEMENTATION_STATUS.active && (
                        <ImplementationLabel>
                          Indkøring
                          <Tooltip
                            width={100}
                            placement='bottomRight'
                            title={
                              <>
                                Udvalgte rådgivere er sat til at træne algoritmen. Når du har håndteret telefonpinden,
                                så check af i boksen med det samme, så informeres algoritmen om, at telefonpinden er
                                håndteret indenfor <strong>de sidste 30 sekunder.</strong>
                              </>
                            }
                          >
                            <InfoCircleFilled
                              style={{ fontSize: '0.6rem', color: 'white', transform: 'rotate(90deg)' }}
                            />
                          </Tooltip>
                        </ImplementationLabel>
                      )}
                    </ComplianceItemWrapper>
                  );
                })}
              </ComplianceItemsList>
            </GroupWrapper>
          ))}
        </GroupList>
      </Container>
    )
  );
};

const Container = styled(Content)`
  margin: 0;
  padding: 0;
`;

const Title = styled.div`
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  background-color: ${colorTheme};
  color: ${textOnTheme};
  font-weight: 550;
  font-size: 1.1rem;
  border-radius: 0.2rem 0.2rem 0 0;
  user-select: none;
`;

const GroupList = styled.ul`
  list-style: none;
  padding: 0;
`;
const GroupTitle = styled.div`
  padding-left: 80px;
  padding-right: 1.75rem;
  margin-bottom: 0.3rem;
`;
const GroupWrapper = styled.li`
  list-style: none;
  color: ${textTheme};
  margin-bottom: 0.2rem;
  font-weight: 600;
`;

const ComplianceItemsList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
`;

const ComplianceItemWrapper = styled.li`
  list-style: none;
  font-weight: 400;
  display: flex;
  margin: 0 0 0.7rem 0;
  padding: 0;
  align-items: top;
  background: ${props => (props.status === IMPLEMENTATION_STATUS.active ? '#E3EBED' : '')};
`;

const ComplianceConfidenceWrapper = styled.div`
  display: flex;
  max-width: 75px;
  min-width: 75px;
  align-self: top,
  align-items: center;
  padding-top: 0.5rem;
`;

const ComplianceItemText = styled.div`
  padding: 0.5rem 1.75rem 0.5rem 5px;
`;

const ComplianceItemConfidenceBar = styled.div`
  margin-top: 0.35rem;
  min-width: 50px;
  transform: scale(-1, 1);
`;

const ComplianceBar = styled(Bar)`
  height: 0.8rem;
`;

const Icon = styled.svg`
  fill: none;
  stroke: ${props => (!props.$ai ? '#ffffff' : colorTheme)};
  stroke-width: 2px;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.4));
  transform: scale(1.7) translate(0.1rem, -0.25rem);
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const CheckboxContainer = styled.div`
  margin: 0.3rem;
`;

const StyledCheckbox = styled.div`
  cursor: pointer;
  display: inline-block;
  width: 0.9rem;
  height: 0.9rem;
  background: ${props => (!props.$ai ? colorTheme : '#ffffff')};
  border: 1px solid ${colorTheme};
  border-radius: 4px;
  transition: all 150ms;
  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;

const ImplementationLabel = styled.div`
  display: flex;
  width: 25px;
  min-width: 25px;
  margin-left: auto;
  padding: 0.5rem 0rem;
  gap: 0.4rem;
  align-items: center;
  justify-content: center;
  background: ${colorTheme};

  color: ${textOnTheme};
  font-size: 0.75rem;
  font-weight: 550;
  writing-mode: vertical-rl;
  text-orientation: mixed;
`;
