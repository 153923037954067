import { Config } from '../config/config';
import axios from 'axios';
import { message } from 'antd';

export const GET_DOCUMENT_CHANGES = 'revison/GET_DOCUMENT_CHANGES';
export const DOCUMENT_CHANGES_LOADING = 'revison/DOCUMENT_CHANGES_LOADING';
export const ERROR = 'revison/ERROR';

export const getSuperAdminDocumentChanges = (timestamp) => {
  return async (dispatch) => {
    dispatch({ type: DOCUMENT_CHANGES_LOADING, payload: { loading: true } });
    try {
      const res = await axios.get(`${Config.conf.backendUrl}/api/v1/superadmin/revision?since=${timestamp}`);

      dispatch({ type: GET_DOCUMENT_CHANGES, payload: { documentChangesRevisions: res.data } });
    } catch (e) {
      dispatch({ type: ERROR, payload: { error: e } });
    }
  };
};

export const publishDocumentChanges = (changeList) => {
  return async (dispatch) => {
    dispatch({ type: DOCUMENT_CHANGES_LOADING, payload: { loading: true } });
    try {
      message.loading({
        content: 'Igang med at Publicérer...',
        duration: 1,
      });
      await axios.put(`${Config.conf.backendUrl}/api/v1/superadmin/revision/publish`, { changeList });
      message.success('Publicéret');
      dispatch(getSuperAdminDocumentChanges(0));
    } catch (e) {
      dispatch({ type: ERROR, payload: { error: e } });
    }
  };
};

export const rejectDocumentChanges = (changeList) => {
  return async (dispatch) => {
    dispatch({ type: DOCUMENT_CHANGES_LOADING, payload: { loading: true } });
    try {
      message.loading({
        content: 'Igang med at fjerne fra revision...',
        duration: 1,
      });
      await axios.put(`${Config.conf.backendUrl}/api/v1/superadmin/revision/reject`, { changeList });
      message.success('Fjernet fra revision');
      dispatch(getSuperAdminDocumentChanges(0));
    } catch (e) {
      dispatch({ type: ERROR, payload: { error: e } });
    }
  };
};

export const publishFullDocument = (documentId) => {
  return async (dispatch) => {
    dispatch({ type: DOCUMENT_CHANGES_LOADING, payload: { loading: true } });
    try {
      message.loading({
        content: 'Igang med at Publicére dokumentet...',
        duration: 1,
      });
      await axios.put(`${Config.conf.backendUrl}/api/v1/superadmin/revision/publish/document/${documentId}`);
      message.success('Dokumentet er Publicéret');
      dispatch(getSuperAdminDocumentChanges(0));
    } catch (e) {
      dispatch({ type: ERROR, payload: { error: e } });
    }
  };
};
