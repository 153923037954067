import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import { Popconfirm, Form, Button, message, Space, Typography } from 'antd';
import styled from 'styled-components';
import { MenuOutlined } from '@ant-design/icons';

import { EditableCell } from './EditableCell';
import {
  StyledAddButton,
  Fade,
  SettingsButton,
  StyledTable,
  TableSize,
  MinimizeButton,
  TableWrapper,
} from '../../../sharedStyledComponents/dataGridStyles';
import { colorTheme } from '../../../sharedStyledComponents/generalStyles';

// Drag & Drop
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import { orderBy } from 'lodash';

const { Title } = Typography;

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableWrapper = SortableContainer((props) => <tbody style={{ width: '100%', overflow: 'auto' }} {...props} />);

const DragHandle = sortableHandle(() => <MenuOutlined style={{ color: '#999', cursor: 'grab' }} />);

export const EditableTable = (props) => {
  let { data } = props;
  const {
    columns,
    category,
    update,
    add,
    submitted,
    submit,
    deleteInstr,
    loading,
    editableGroup,
    updateGroup,
    clickableRow,
    dragAndDroppable,
  } = props;
  const history = useHistory();
  const table = useRef(null);
  const cancelInputButton = useRef(null);
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [groupEditing, setGroupEditing] = useState(false);

  useEffect(() => {
    document.addEventListener('click', onEditClickAway, false);
    document.addEventListener('keyup', onEditEscapeKey, false);
    return () => {
      document.removeEventListener('click', onEditClickAway, false);
      document.removeEventListener('keyup', onEditEscapeKey, false);
    };
  });

  /**
   * Check if it is the currently currently edited row
   * @param {row} record
   */
  const isEditing = (record) => record.key === editingKey;

  /**
   * Column with actions (edit/save/delete) functions
   */
  const action = {
    title: 'Action',
    dataIndex: 'operation',
    render: (_, record) => {
      const editable = isEditing(record);
      return editable ? (
        <Space direction="vertical">
          <Button size="small" onClick={() => save(record.key)}>
            Gem
          </Button>

          <Popconfirm
            title={
              <>
                Er du sikker på at du vil slette denne instruks?
                <br /> (du kan ikke fortryde sletning)
              </>
            }
            onConfirm={onDelete}
          >
            <Button danger size="small">
              Slet
            </Button>
          </Popconfirm>

          {/* Hidden button for saving edit or regretting save */}
          <Popconfirm
            placement="leftBottom"
            title="Vil du gemme ændringerne?"
            cancelText="Nej"
            okText="Ja"
            onConfirm={() => save(record.key)}
            onCancel={cancel}
          >
            <Button
              ref={cancelInputButton}
              style={{ visibility: 'hidden', padding: 0, margin: 0, height: 0, width: 0 }}
              size="small"
            >
              Fortryd
            </Button>
          </Popconfirm>
        </Space>
      ) : (
        <EditButton
          icon={<CreateOutlinedIcon style={{ fontSize: '1.7rem' }} />}
          type="text"
          onClick={() => edit(record)}
        />
      );
    },
  };

  /**
   * Cancel edit
   * if it is a new entry with empty fields, remove it from redux
   */
  const cancel = () => {
    const formValues = form.getFieldValue(true);
    if (!formValues || Object.keys(formValues).length === 0) {
      deleteInstr('new');
    }
    message.warning('Ændringer ikke gemt');
    setEditingKey('');
  };

  /**
   * Set selected row to edit state changing internal component to <Input/>
   * @param {row} record
   */
  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
  };

  /**
   * Get the row key from form, and update the object in the main list
   * if it is a new key(does not have a key value) a submit fuction will be envoked instead
   * @param key Row key id
   */
  const save = async (key) => {
    // dont do a request if fields are untouched
    if (!form.isFieldsTouched()) {
      cancel();
      return;
    }

    try {
      const row = await form.validateFields();
      const index = data.findIndex((item) => key === item.key);

      if (index > -1 && key !== 'new') {
        // UPDATE EXISTING KEYS
        const item = JSON.parse(JSON.stringify(data[index]));
        update({ ...item, doc: { ...item.doc, ...row.doc } });
        setEditingKey('');
      } else {
        //ADD NEW KEY
        setEditingKey('');
        const item = JSON.parse(JSON.stringify(data[index]));
        submit({ ...item, doc: { ...item.doc, ...row.doc } });
      }
      form.resetFields();
    } catch (errInfo) {
      message.error('Validering fejlede, ændringerne blev ikke gemt.');
    }
    message.success('Ændringer er gemt');
  };

  /**
   * Handles delete of instruction
   */
  const onDelete = () => {
    deleteInstr(editingKey);
    setEditingKey('');
  };

  /**
   * Minimizing the table will scroll up to top of the table
   */
  const onMinimize = () => {
    setExpanded(!expanded);
    table.current.scrollIntoView(true, { behavior: 'smooth' });
  };

  /**
   * Set extra variables for column object, to be used in editing component
   */
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.type,
        dataIndex: col.dataIndex,
        placeholder: col.placeholder,
        required: col.required,
        editing: isEditing(record),
      }),
    };
  });

  if (dragAndDroppable) {
    data = orderBy(data, (item) => item.doc.order);
    mergedColumns.unshift({
      title: 'Sorter',
      dataIndex: 'sort',
      width: 75,
      render: () => <DragHandle />,
    });
  }

  // Add action column
  mergedColumns.push(action);

  /**
   * On adding new instruction, an object with all column items
   * is created.
   */
  const onNewEntry = () => {
    if (!submitted) {
      message.warning(`Du skal først udfylde manglende felter i ny oprettet instruks.`);
      return;
    }
    // recreate the object with empty values
    const keys = columns.map((col) => col.dataIndex);
    // Bare minimum object
    const cleanEntry = { key: 'new', doc: {} };
    keys.forEach((keyArray) => {
      if (keyArray) cleanEntry.doc[keyArray[1]] = '';
    });
    // Add category name from current table
    cleanEntry.doc.category = category.value;
    add(cleanEntry);
    form.resetFields();
    setEditingKey('new');
  };

  /**
   * If user is editing and clicks on anything else but the input promt
   * if they want to cancel their input
   * @param {Event} e
   */
  const onEditClickAway = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // for click outside component (this triggers clicks in all components, thus check if 'current' exists)
    if (
      editingKey &&
      e.target.tagName !== 'INPUT' &&
      e.target.tagName !== 'SPAN' &&
      e.target.tagName !== 'TEXTAREA' &&
      e.target.tagName !== 'BUTTON' &&
      e.target.tagName !== 'PATH' &&
      e.target.tagName !== 'SVG' &&
      e.target.className !== 'ant-switch-handle' &&
      e.target.className !== 'ant-form-item-control-input'
    ) {
      if (!form.isFieldsTouched()) {
        cancel();
        return;
      }
      if (cancelInputButton.current) cancelInputButton.current.click();
    }
  };

  const onSortStart = () => {
    document.body.style.cursor = 'grabbing';
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    document.body.style.cursor = 'default';
    let itemOrder
    switch (newIndex) {
      case 0:
        //if first index, make new index smaller than previous
        itemOrder = data[newIndex].doc.order / 2;
        break;
      case data.length - 1:
        //if last index, make new index larger than previous
        itemOrder = data[newIndex].doc.order + 1;
        break;
      default:
        //if item is moved down
        if (newIndex > oldIndex) {
          itemOrder = data[newIndex].doc.order + Math.abs(data[newIndex].doc.order - data[newIndex + 1].doc.order) / 2;
          //if item is moved up
        } else if (newIndex < oldIndex) {
          itemOrder = data[newIndex].doc.order - Math.abs(data[newIndex - 1].doc.order - data[newIndex].doc.order) / 2;
        }
        break;
    }

    update({ ...data[oldIndex], doc: { ...data[oldIndex].doc, order: itemOrder } });
  };

  const DraggableContainer = (givenProps) => (
    <SortableWrapper
      lockAxis="y"
      useDragHandle={true}
      helperClass="row-dragging"
      onSortStart={onSortStart}
      onSortEnd={onSortEnd}
      {...givenProps}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = data.findIndex((x) => x.key === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  /**
   * Handles escape button press
   * @param {Event} e
   */
  const onEditEscapeKey = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (editingKey && e.key === 'Escape' && cancelInputButton) {
      if (!form.isFieldsTouched()) {
        cancel();
        return;
      }
      cancelInputButton.current.click();
    }
  };

  const onGroupInput = (groupName) => {
    if (groupName.length === 0) message.error('Du skal mindst skrive et tegn');
    if (groupName === category.value) {
      setGroupEditing(false);
      return;
    }
    updateGroup(category.id, groupName);
    message.success(`Gruppenavn opdateret til "${groupName}"`);
    setGroupEditing(false);
  };

  const onRowClick = (event, record) => {
    event.preventDefault();
    event.stopPropagation();
    if (
      editingKey === '' &&
      event.target.tagName !== 'SPAN' &&
      event.target.tagName !== 'svg' &&
      event.target.tagName !== 'path' &&
      event.target.className !== 'ant-select-selection-overflow' &&
      event.target.className !== 'ant-select-selection-item' &&
      event.target.className !== 'ant-select-selection-item-remove' &&
      event.target.className !== 'ant-tooltip-inner' &&
      record.doc.level1kw
    ) {
      history.push(`/admin/instructions/additionalconfig?id=${record.id}`);
    }
  };
  return (
    <Container>
      <CategoryWrapper>
        <CategoryType>Arbejdsinstrukser</CategoryType>
        <EditableTitle
          level={3}
          editable={
            editableGroup && {
              icon: <CreateOutlinedIcon style={{ color: colorTheme, fontSize: '1.75rem' }} />,
              onChange: onGroupInput,
              onStart: () => setGroupEditing(true),
              editing: groupEditing,
            }
          }
        >
          {category?.value}
        </EditableTitle>
      </CategoryWrapper>
      <TableSize $expanded={expanded} $count={data.length}>
        <TableWrapper ref={table}>
          <Form form={form} component={false}>
            <StyledTable
              components={{
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRow,
                  cell: EditableCell,
                },
              }}
              sortDirections={['ascend', 'descend', 'ascend']}
              pagination={false}
              dataSource={data}
              columns={mergedColumns}
              loading={loading}
              clickableRow={clickableRow}
              rowClassName="editable-row"
              onRow={(record) => {
                return {
                  onClick: (e) => onRowClick(e, record),
                };
              }}
              footer={() => {
                return (
                  data && (
                    <SettingsButton
                      icon={<PlusCircleOutlined style={{ fontSize: '1.4rem' }} />}
                      shape="round"
                      onClick={onNewEntry}
                    >
                      Tilføj ny arbejdsinstruks
                    </SettingsButton>
                  )
                );
              }}
            />
          </Form>
          <Fade $visible={data.length > 8 && !expanded} />
        </TableWrapper>
      </TableSize>
      {data.length > 8 && (
        <StyledAddButton
          icon={<PlusCircleOutlined style={{ fontSize: '1.4rem' }} />}
          displaybutton={(data.length > 8 && !expanded).toString()}
          onClick={() => setExpanded(!expanded)}
        >
          Se alle arbejdsinstrukser
        </StyledAddButton>
      )}

      {data.length > 8 && expanded && (
        <MinimizeButton
          icon={<MinusCircleOutlined style={{ lineHeight: 0, fontSize: '1.2rem' }} />}
          onClick={onMinimize}
        >
          Vis færre
        </MinimizeButton>
      )}
    </Container>
  );
};

const EditableTitle = styled(Title)`
  &.ant-typography {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 400;
    color: ${colorTheme};
    user-select: none;
    display: flex;
    align-items: center;

    .ant-typography-edit {
      display: flex !important;
      align-items: center;
    }
  }
`;

const Container = styled.div`
  flex-wrap: wrap;
  flex-direction: column;
  margin: 1rem 0rem;
  padding-bottom: 2rem;
`;

const CategoryWrapper = styled.div`
  font-size: 1.5rem;
  color: ${colorTheme};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0 0.5rem 0.1rem;
`;

const CategoryType = styled.div`
  font-weight: 700;
  margin-right: 0.6rem;
  user-select: none;
`;

const EditButton = styled(Button)`
  background: none;
  color: ${colorTheme};
  &:hover {
    background: none !important;
    color: ${colorTheme};
    &:hover {
      background: none;
      border-color: none;
    }
    &:focus {
      background: none;
      border-color: none;
    }
  }
`;
