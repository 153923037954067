const size = {
  XS: 480,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
  XXL: 1600,
};

export const screen = {
  XS: `(min-width: ${size.XS}px)`,
  SM: `(min-width: ${size.SM}px)`,
  MD: `(min-width: ${size.MD}px)`,
  LG: `(min-width: ${size.LG}px)`,
  XL: `(min-width: ${size.XL}px)`,
  XXL: `(min-width: ${size.XXL}px)`,
};
