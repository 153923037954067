import { Select } from 'antd';
import styled from 'styled-components';

const AgentPicker = ({
  agentIds,
  onSelectionChanged,
  allAgentsOption = true,
  loading,
  selectedAgents,
  setSelectedAgents,
}) => {
  const onChange = (newAgents) => {
    if (newAgents[newAgents.length - 1] === '') {
      setSelectedAgents(['']);
      onSelectionChanged([]);
    } else {
      const agentIds = newAgents.filter((agent) => agent);
      setSelectedAgents(agentIds);
      onSelectionChanged(agentIds);
    }
  };

  return (
    <>
      <AgentSelect
        maxTagCount="responsive"
        mode="multiple"
        style={{ width: 250 }}
        defaultValue={['']}
        value={selectedAgents}
        placeholder="Vælg rådgiver"
        loading={loading}
        disabled={loading}
        onChange={onChange}
      >
        {allAgentsOption && <Select.Option value="">Alle rådgivere</Select.Option>}
        {agentIds.map((agent, i) => (
          <Select.Option key={agent} value={agent}>
            {agent}
          </Select.Option>
        ))}
      </AgentSelect>
    </>
  );
};

const AgentSelect = styled(Select)`
  max-width: 300px;
`;

export default AgentPicker;
