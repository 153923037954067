import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import styled, { keyframes } from 'styled-components';
import { isEmpty } from '../../../utils/helpers';
import NotificationCircle from '../../sharedStyledComponents/NotificationCircle.component';
import { caretRightIcon, closeIcon } from '../../sharedStyledComponents/icons';
import {
  popupBackground,
  popupBorder,
  colorTheme,
  textTheme,
  CloseIcon,
} from '../../sharedStyledComponents/generalStyles';
import { useState } from 'react';
import { useRef } from 'react';
import { addActiveDocument } from '../../../_actions/multiDocument.actions';
import { navigateTo } from '../../../_actions/chosen.actions';
import { toggleDisplayChangesToInstructions } from '../../../_actions/dashboard.actions';

const InstructionsChangesNotification = () => {
  const dispatch = useDispatch();
  // const previousLoginTimestamp = useSelector((state) => state.agentConfig.previousLoginTimestamp);
  const unseenDocumentChanges = useSelector((state) => state.agentConfig.unseenDocumentChanges);
  const workInstructions = useSelector((state) => state.dashboard.workInstructions);
  const fullInstructions = useSelector((state) => state.agentConfig.unseenDocumentTotalRevisions);
  const [displayNotification, setDisplayNotifications] = useState(true);

  let changesToDisplayGroupedByInstruction = [];
  let changesToDisplayFullInstructions = [];
  // const minTimeStampToShow = previousLoginTimestamp; //for testing //dayjs().subtract(20, 'days').toDate().getTime();
  unseenDocumentChanges
    // ?.filter((change) => change.timestamp > minTimeStampToShow) // for testing
    ?.filter((change) => !fullInstructions.includes(change.docId)) // hide all section revisions if there is a full document revision
    ?.forEach((change) => {
      const existingInstructionEntry = changesToDisplayGroupedByInstruction.find(
        (instruction) => instruction.docId === change.docId
      );
      if (isEmpty(existingInstructionEntry)) {
        changesToDisplayGroupedByInstruction.push({
          docId: change.docId,
          docName: workInstructions.find((instruction) => instruction.id === change.docId)?.name,
          sectionChanges: [change],
        });
      } else {
        existingInstructionEntry.sectionChanges.push(change);
      }
    });

  fullInstructions?.forEach((docRevision) => {
    changesToDisplayFullInstructions.push({
      docId: docRevision.documentId,
      docName: workInstructions.find((instruction) => instruction.id === docRevision.documentId)?.name,
    });
  });

  const onPressShowAllChanges = () => {
    dispatch(toggleDisplayChangesToInstructions());
  };

  const InstructionItem = ({ index, instruction }) => {
    const [collapsed, setCollapsed] = useState(true);
    const sectionsRef = useRef(null);
    const oldestDate = instruction.sectionChanges.sort((a, b) => a.timestamp - b.timestamp)?.[0]?.timestamp;

    const toggleCollapsed = () => {
      setCollapsed((prevState) => !prevState);
    };

    const onSectionClick = (section) => () => {
      dispatch(addActiveDocument(section.docId));
      dispatch(navigateTo(section.sectionName, section.docId));
    };

    return (
      <div key={index}>
        <Instruction onClick={toggleCollapsed}>
          <PanelIcon $collapsed={collapsed}>{caretRightIcon('15px', colorTheme)}</PanelIcon>
          <InstructionTitle>{instruction.docName}</InstructionTitle>
          <NotificationCircle number={instruction.sectionChanges?.length} shouldPositionInlineRight={true} />
          <Date>
            {`den ${dayjs(oldestDate).date()}/${dayjs(oldestDate).month() + 1}-${dayjs(oldestDate).year()}`}
          </Date>
        </Instruction>
        <Sections ref={sectionsRef} $collapsed={collapsed} $contentHeight={sectionsRef?.current?.scrollHeight}>
          {instruction.sectionChanges
            ?.sort((a, b) =>
              a.sectionName.localeCompare(b.sectionName, undefined, {
                numeric: true,
                sensitivity: 'base',
              })
            )
            .map((section, index) => (
              <Section key={index} onClick={onSectionClick(section)}>
                <SectionTitle>
                  {`- ${section.sectionName}`}
                  <Date>
                    &nbsp;
                    {`den ${dayjs(section.timestamp).date()}/${dayjs(section.timestamp).month() + 1}-${dayjs(
                      section.timestamp
                    ).year()}`}
                  </Date>
                </SectionTitle>
              </Section>
            ))}
        </Sections>
      </div>
    );
  };

  const FullInstructionItem = ({ index, instruction }) => {
    const onDocumentClick = (docId) => () => {
      dispatch(addActiveDocument(docId));
    };

    return (
      <div key={index}>
        <Instruction onClick={onDocumentClick(instruction.docId)}>
          <InstructionTitle>{instruction.docName}</InstructionTitle>
          <NotificationCircle number={1} shouldPositionInlineRight={true} />
          <Date>
            &nbsp;
            {`den ${dayjs(instruction.timestamp).date()}/${dayjs(instruction.timestamp).month() + 1}-${dayjs(
              instruction.timestamp
            ).year()} - Du skal læse hele instruksen`}
          </Date>
        </Instruction>
      </div>
    );
  };
  const ref = useRef(null);

  return (
    <>
      {(!isEmpty(changesToDisplayGroupedByInstruction) || !isEmpty(fullInstructions)) && displayNotification && (
        <Wrapper ref={ref} $backgroundColor={popupBackground} $borderColor={popupBorder}>
          <StyledCloseIcon
            onClick={() => {
              setDisplayNotifications(false);
            }}
          >
            {closeIcon(14, colorTheme)}
          </StyledCloseIcon>
          <Title>Siden sidst er der nyt i følgende instrukser:</Title>
          {changesToDisplayFullInstructions.map((instruction, index) => (
            <FullInstructionItem key={index} instruction={instruction} />
          ))}
          {changesToDisplayGroupedByInstruction?.map((instruction, index) => (
            <InstructionItem key={index} instruction={instruction} />
          ))}
          <LinkToAllChanges onClick={onPressShowAllChanges}>{`> Se oversigt over instruksændringer`}</LinkToAllChanges>
        </Wrapper>
      )}
    </>
  );
};

export default InstructionsChangesNotification;

const fade = keyframes`
  100%{
    opacity: 1;
    margin-top: 2rem;
  } 
`;

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 75%;
  margin-inline: auto;
  margin-top: 4rem;
  border: 1px ${(props) => props.$borderColor} solid;
  background-color: ${(props) => props.$backgroundColor};
  border-radius: 0.6rem;
  padding: 1rem;
  animation: ${fade} 250ms ease-in-out forwards;
  opacity: 0;
`;

const StyledCloseIcon = styled(CloseIcon)`
  top: 1rem;
  right: 1rem;
`;

const Title = styled.p`
  display: inline;
  margin-bottom: 0.5rem;
`;

const Date = styled.span`
  padding-left: 0.4rem;
  font-weight: normal;
  opacity: 0.6;
`;

const Instruction = styled.div`
  display: flex;
  &:hover {
    cursor: pointer;
  }
`;

const PanelIcon = styled.div`
  padding-right: 0.2rem;
  transform: rotate(${(props) => (props.$collapsed ? 0 : 90)}deg);
  transition: transform 0.2s ease;
  height: min-content;
`;

const InstructionTitle = styled.div`
  font-weight: bold;
`;

const Sections = styled.div`
  height: ${(props) => (props.$collapsed ? 0 : props.$contentHeight)}px;
  transition: height 0.2s ease;
  overflow: hidden;
`;

const SectionTitle = styled.div`
  position: relative;
`;

const Section = styled.div`
  padding-left: 1.5rem;
  display: inline-flex;
  width: 100%;

  ${SectionTitle}:before {
    position: absolute;
    content: '';
    background-color: ${textTheme};
    width: 0;
    height: 1px;
    bottom: 0;
    transition: width 0.5s ease;
  }

  &:hover {
    cursor: pointer;
    ${SectionTitle}:before {
      width: 100%;
    }
  }
`;

const LinkToAllChanges = styled.div`
  position: absolute;
  right: 0;
  bottom: -2rem;
  font-style: italic;
  color: ${textTheme};
  &:hover {
    cursor: pointer;
  }
`;
