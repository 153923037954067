import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';
import NotificationCircle from '../../../sharedStyledComponents/NotificationCircle.component';
import { isEmpty } from '../../../../utils/helpers';

const TableOfContent = props => {

    // const regex = new RegExp(/(\d{1}[.]?)?[ÆØÅæøåA-Za-z]+/gm); // this detects "1.1.3 <some text>"" types of titles (could be used to sort away "1.2." titles randomly generated)

    const filterOutline = arr => {
        let matches = [];
        if (!Array.isArray(arr)) return matches;

        arr.forEach(item => {
            if (item.title.length > 6) {
                // if (regex.test(item.title)) {
                matches.push(item);
            } else {
                let childRes = filterOutline(item.items);
                if (childRes.length) matches.push(Object.assign({}, item, { items: childRes }))
            }
        })
        return matches;
    }


    const listButton = (i, cb, mainTitle, depth) => {
        const isSectionChangeUnseen = !isEmpty(props.unseenSectionChanges?.find(section => section === i.title));
        return (<ListBtn $mainTitle={mainTitle} onClick={cb(i.title, props.docId)}>
            <Title depth={depth}>{i.title}</Title>
            {isSectionChangeUnseen && <NotificationCircle number={1} shouldPositionInlineRight={true} />}
        </ListBtn>)
    }

    const createList = (arr, firstLayer = true, depth = 0) => {
        let result = [];
        if (!arr.length) return result;

        result = arr.map((item, index) => {
            return (
                <ListItem
                    title={item.title}
                    $secondLayer={!firstLayer}
                    $mainTitle={firstLayer}
                    key={index} >
                    {listButton(item, props.handleClick, firstLayer, depth)
                    }{
                        item.items && createList(item.items, false, depth + 1)
                    }
                </ListItem>)
        })

        return (<List key={arr.length + firstLayer.toString()}>{result}</List>);
    }
    const filteredOutline = filterOutline(Object.assign([], props.outline));
    return (
        <Wrapper top={props.top}>
            {createList(filteredOutline)}
        </Wrapper>
    )
}

TableOfContent.propTypes = {
    top: PropTypes.number.isRequired,
    handleClick: PropTypes.func.isRequired,
    outline: PropTypes.array.isRequired,
}

export default TableOfContent

const ListItem = styled.li`
    position: relative;
    display:flex;
    flex-direction: column;
    padding: 0;
    border-bottom: ${props => props.$mainTitle && '1px solid #6B6B6B'};
    overflow:hidden;
    text-overflow: ellipsis;
`;

const Title = styled.div`
    min-width: calc(5.5rem - ${props => props.depth}rem);
    margin-left: calc(${props => props.depth} * 1rem);
`;

const ListBtn = styled.button`
    position: static;
    display:flex;
    padding: 0;
    color: #666;
    background:none;
    border:none;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    font-weight: ${props => props.$mainTitle && 600};
    font-style: ${props => !props.$mainTitle && 'italic'};
    &:hover {
        cursor: pointer;
        ${Title} {
            text-decoration: underline;
        }
    }
`;

const Wrapper = styled.div`
    box-sizing: content-box;
    position: fixed;
    z-index: 999;
    background: #4c4c4c;
    -webkit-box-shadow: inset 0px -0.5px 2px 1px rgba(0,0,0,0.75);
    -moz-box-shadow: inset 0px -0.5px 2px 1px rgba(0,0,0,0.75);
    box-shadow: inset 0px -0.5px 2px 1px rgba(0,0,0,0.75);
    border-radius: .3rem;
    top: ${props => props.top}px;
    left: 10px;
    padding: .2rem .5rem .2rem .5rem;
    width: 8rem;
    max-height: 75vh;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    overflow-x: hidden;
    transition: width 0.5s;
    opacity: .8;
    &:hover {
        opacity: .95;
        width: 30rem;
        ${ListBtn} {
            width: 30rem;
            color: #e7e9ec;
            white-space: normal;
        }
    }
`;

const List = styled.ul`
    list-style-type: none;
    padding-left: 0;
`;