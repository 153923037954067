import axios from 'axios';

const authInterceptor = () => {
  return axios.interceptors.request.use((config) => {
    config.headers['Authorization'] = `Bearer ${sessionStorage.token}`;
    return config;
  });
};

export default authInterceptor;
