import styled from "styled-components";
import { warning, recommendationRGB } from "./generalStyles";

const NotificationCircle = ({ number, shouldPositionInTopRight, $shouldPositionLeft, shouldPositionInlineRight, onClick, height, isNew }) => {
  const onPressNotification = () => {
    onClick?.();
  }

  const positionLeft = {
    right: 'unset',
    top: '50%',
    transform: 'translateY(-50%)'
  }

  const $positionInlineRightStyle = {
    position: 'unset',
    marginLeft: '0.5rem',
  }

  return (isNew || number > 0) && (
    <Circle style={
      $shouldPositionLeft ? positionLeft
        : shouldPositionInlineRight ? $positionInlineRightStyle
          : {}}
      onClick={onPressNotification}
      $positionInlineRightStyle
      $shouldPositionLeft
      $isNew={isNew}>
      {isNew ? 'NY' : number}
    </Circle>)

}

export default NotificationCircle;

const height = '1rem';
const Circle = styled.div`
  content: '';
  background: ${props => props.$isNew ? `rgb(${recommendationRGB})` : warning};
  position: absolute;
  border-radius: 50%;
  border: unset;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block: auto;
  min-height: ${height};
  max-height: ${height};
  min-width: ${height};
  max-width: ${height};
  top: calc(-${height} *0.2);
  right: calc(-${height} *0.2);
  font-size: ${props => props.$isNew ? '9' : '12'}px;
  &:hover{
    cursor: pointer;
  }
  font-style: normal;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  line-height: 1rem;
  `;