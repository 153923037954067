import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Popconfirm, Form, Button, Space, Row, Col } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';

import {
  StyledAddButton,
  Fade,
  SettingsButton,
  StyledTable,
  TableSize,
  MinimizeButton,
  TableWrapper,
} from '../../sharedStyledComponents/dataGridStyles';
import AgentPicker from '../../GlobalComponents/agent-picker.component';
import { updateTeam } from '../../../_actions/teams.actions';
import CustomModal from '../../GlobalComponents/custom-modal/custom-modal.component';
import { colorTheme, textTheme } from '../../sharedStyledComponents/generalStyles';
import { EditableCell } from './components/EditableCell';
// import { second } from '../../sharedStyledComponents/';

const AdminTeamTable = (props) => {
  const { data, columns } = props;

  const dispatch = useDispatch();
  const { loading, agents } = useSelector((stores) => stores.agents);
  const table = useRef(null);
  const cancelInputButton = useRef(null);
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [displayAddMemberModal, setDisplayAddMemberModal] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);

  useEffect(() => {
    document.addEventListener('click', onEditClickAway, false);
    document.addEventListener('keyup', onEditEscapeKey, false);
    return () => {
      document.removeEventListener('click', onEditClickAway, false);
      document.removeEventListener('keyup', onEditEscapeKey, false);
    };
  });

  useEffect(() => {
    setTeamMembers(
      data?.members?.map((m) => {
        return { ...m, key: m.name };
      })
    );
    setSelectedAgents(data?.members?.map((member) => member.name));
  }, [data]);
  /**
   * Check if it is the currently currently edited row
   * @param {row} record
   */
  const isEditing = (record) => record.key === editingKey;

  /**
   * Column with actions (edit/save/delete) functions
   */
  const action = {
    title: 'Rediger',
    dataIndex: 'operation',
    render: (_, record) => {
      const editable = isEditing(record);
      return editable ? (
        <Space direction="vertical">
          <Popconfirm
            title={
              <>
                Er du sikker på at du vil slette dette medlem?
                <br /> (du kan ikke fortryde sletning)
              </>
            }
            onConfirm={onDelete}
          >
            <Button danger size="medium">
              Slet
            </Button>
          </Popconfirm>
        </Space>
      ) : (
        <EditButton
          icon={<CreateOutlinedIcon style={{ fontSize: '1.7rem' }} />}
          type="text"
          onClick={() => edit(record)}
        />
      );
    },
  };

  /**
   * Cancel edit
   * if it is a new entry with empty fields, remove it from redux
   */
  const cancel = () => {
    setEditingKey('');
  };

  /**
   * Set selected row to edit state changing internal component to <Input/>
   * @param {row} record
   */
  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
  };

  /**
   * If user is editing and clicks on anything else but the input promt
   * if they want to cancel their input
   * @param {Event} e
   */
  const onEditClickAway = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // for click outside component (this triggers clicks in all components, thus check if 'current' exists)
    if (
      editingKey &&
      e.target.tagName !== 'INPUT' &&
      e.target.tagName !== 'SPAN' &&
      e.target.tagName !== 'TEXTAREA' &&
      e.target.tagName !== 'BUTTON' &&
      e.target.tagName !== 'path' &&
      e.target.tagName !== 'svg' &&
      e.target.className !== 'ant-switch-handle' &&
      e.target.className !== 'ant-form-item-control-input'
    ) {
      if (!form.isFieldsTouched()) {
        cancel();
        return;
      }
      if (cancelInputButton.current) cancelInputButton.current.click();
    }
  };

  /**
   * Handles escape button press
   * @param {Event} e
   */
  const onEditEscapeKey = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (editingKey && e.key === 'Escape' && cancelInputButton) {
      if (!form.isFieldsTouched()) {
        cancel();
        return;
      }
      cancelInputButton.current.click();
    }
  };

  const deleteTeamMember = (name) => {
    const filteredTeamMembers = teamMembers.filter((m) => m.name !== name);
    const updatedTeam = { ...data, members: filteredTeamMembers };
    dispatch(updateTeam(updatedTeam));
    setSelectedAgents(filteredTeamMembers.map((m) => m.name));
    setTeamMembers(filteredTeamMembers);
  };

  /**
   * Handles delete of instruction
   */
  const onDelete = () => {
    deleteTeamMember(editingKey);
    setEditingKey('');
  };

  /**
   * Minimizing the table will scroll up to top of the table
   */
  const onMinimize = () => {
    setExpanded(!expanded);
    table.current.scrollIntoView(true, { behavior: 'smooth' });
  };

  /**
   * Set extra variables for column object, to be used in editing component
   */
  const mergedColumns = columns?.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.type,
        dataIndex: col.dataIndex,
        placeholder: col.placeholder,
        required: col.required,
        editing: isEditing(record),
      }),
    };
  });

  // Add action column
  mergedColumns?.push(action);

  const onNewEntry = () => {
    setDisplayAddMemberModal(true);
  };

  const handleSelectionChange = (agentIds) => {
    setSelectedAgents(agentIds);
  };

  const handleSubmit = () => {
    setSelectedAgents(selectedAgents);
    const previousAgents =
      data.members?.filter((previousAgent) =>
        selectedAgents.find((selectedAgentName) => selectedAgentName === previousAgent.name)
      ) || [];
    const addedTimestamp = new Date().valueOf();
    const newAgents = selectedAgents
      .filter((name) => !data.members?.some((previousAgent) => previousAgent.name === name))
      .map((name) => {
        return { name, addedTimestamp };
      });
    const updatedTeam = { ...data, members: [...(previousAgents || []), ...newAgents] };
    dispatch(updateTeam(updatedTeam));
    setTeamMembers(
      updatedTeam.members.map((m, i) => {
        return { ...m, key: m.key || i };
      })
    );
    setDisplayAddMemberModal(false);
  };

  const handleCancel = () => {
    setSelectedAgents(data?.members?.map((member) => member.name));
    setDisplayAddMemberModal(false);
  };

  return (
    <>
      <CustomModal
        visible={displayAddMemberModal}
        title={<>Team</>}
        width={'32vw'}
        subtitle={<>Tilføj ny rådgiver</>}
        summary={'Tilføj en rådgiver til et team. Vær opmærksom på at de godt kan høre til flere teams.'}
        close={() => setDisplayAddMemberModal(false)}
      >
        <>
          <Row>
            <Col>
              <>
                {'Vælg Rådgiver(e): '}
                <AgentPicker
                  allAgentsOption={false}
                  selectedAgents={selectedAgents}
                  setSelectedAgents={setSelectedAgents}
                  agentIds={agents?.map((a) => a._id)}
                  onSelectionChanged={handleSelectionChange}
                />
              </>
            </Col>
          </Row>
          <Row>
            <Col>
              <br />
              <Space>
                <ConfirmButton onClick={handleSubmit}>Bekræft</ConfirmButton>
                <RejectButton onClick={handleCancel}>Fortryd</RejectButton>
              </Space>
            </Col>
          </Row>
        </>
      </CustomModal>
      <Container>
        <TableTitle>
          <TableType>Fritagede</TableType>
          Rådgivere
        </TableTitle>
        <TitleSubtext>
          I oversigten vises de rådgivere som er fritaget for at få opdaterineger på ændringer til arbejdsinstrukser.
          <br />
          Nedenstående rådgivere vil heller ikke indgå i data og statistik for dashboardet
        </TitleSubtext>
        <TableSize $expanded={expanded} $count={data?.length || 0}>
          <TableWrapper ref={table}>
            <Form form={form} component={false}>
              <StyledTable
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                sortDirections={['ascend', 'descend', 'ascend']}
                pagination={false}
                dataSource={teamMembers}
                columns={mergedColumns}
                loading={loading}
                clickableRow={false}
                rowClassName="editable-row"
                footer={() => {
                  return (
                    data && (
                      <SettingsButton
                        icon={<PlusCircleOutlined style={{ fontSize: '1.4rem' }} />}
                        shape="round"
                        onClick={onNewEntry}
                      >
                        Tilføj ny rådgiver
                      </SettingsButton>
                    )
                  );
                }}
              />
            </Form>
            <Fade $visible={data?.length > 8 && !expanded} />
          </TableWrapper>
        </TableSize>
        {data.length > 8 && (
          <StyledAddButton
            icon={<PlusCircleOutlined style={{ fontSize: '1.4rem' }} />}
            displaybutton={(data.length > 8 && !expanded).toString()}
            onClick={() => setExpanded(!expanded)}
          >
            Se alle teams
          </StyledAddButton>
        )}

        {data.length > 8 && expanded && (
          <MinimizeButton
            icon={<MinusCircleOutlined style={{ lineHeight: 0, fontSize: '1.2rem' }} />}
            onClick={onMinimize}
          >
            Vis færre
          </MinimizeButton>
        )}
      </Container>
    </>
  );
};

export default AdminTeamTable;

const Container = styled.div`
  flex-wrap: wrap;
  flex-direction: column;
  margin: 1rem 0rem;
  padding-bottom: 2rem;
`;

const EditButton = styled(Button)`
  background: none;
  color: ${colorTheme};
  &:hover {
    background: none !important;
    color: ${colorTheme};
    &:hover {
      background: none;
      border-color: none;
    }
    &:focus {
      background: none;
      border-color: none;
    }
  }
`;

const ConfirmButton = styled(Button)`
  background-color: #34495e;
  border-color: #34495e;
  border-radius: 0.5rem;
  color: white;
  &.ant-btn-round {
    border-radius: 10px;
  }
  &:hover,
  &:focus {
    background-color: #34495e;
    border-color: #34495e;
    span {
      text-decoration: underline;
    }
  }
`;

const RejectButton = styled(Button)`
  border-radius: 0.5rem;
  background-color: #ffffff;
  margin-right: 1rem;
  &.ant-btn-round {
    border-radius: 10px;
  }
  &:hover,
  &:focus {
    span {
      text-decoration: underline;
    }
  }
`;

const TableTitle = styled.div`
  font-size: 1.5rem;
  color: ${colorTheme};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0 0.5rem 0.1rem;
`;

const TableType = styled.div`
  font-weight: 700;
  margin-right: 0.6rem;
  user-select: none;
`;

const TitleSubtext = styled.div`
  color: ${textTheme};
  margin-bottom: 1rem;
`;
