import { io } from 'socket.io-client';
import axios from 'axios';

import { Config } from '../config/config';

export default function socketMiddleware() {
  let socket = null;
  return ({ dispatch }) => next => action => {
    if (typeof action === 'function') {
      return next(action);
    }

    const { event, leave, handle, emit, ...rest } = action;

    if (!event) {
      return next(action);
    }

    const token = sessionStorage.getItem('token');
    const sessionId = sessionStorage.getItem('sessionId');
    let query = 'token=' + token;

    if (sessionId) {
      query = query + "&sessionId=" + sessionId;
    }

    if (token && socket === null) {
      try {
        socket = io(Config.conf.backendUrl, {
          query: query,
          transports: ['websocket'],
          pingInterval: 65000, 
          pingTimeout: 25000   
        });
        socket.on('connect', c => {

          socket.emit('debug', `SOCKET MIDDLEWARE. Socket connected.`);

          window.addEventListener('beforeunload', async () => {
            socket.emit('debug', `SOCKET MIDDLEWARE. CLOSING SOCKET..`);
            socket.emit('app_closed');
            await socket.close();
            socket = null;
            return;
          });

          window.addEventListener('onbeforeunload', async () => {
            socket.emit('debug', `SOCKET MIDDLEWARE. Closing socket..`);
            socket.emit('app_closed');
            await socket.close();
            socket = null;
            return;
          });
        });

        socket.on('error', error => {
          sendLogToServer(`SOCKET MIDDLEWARE. Error on socket`, JSON.stringify(error, Object.getOwnPropertyNames(error)))
        });

        socket.on('connect_error', error => {
          sendLogToServer(`SOCKET MIDDLEWARE. Socket 'connect_error'`, JSON.stringify(error, Object.getOwnPropertyNames(error)))
        });

      } catch (error) {
        sendLogToServer(`SOCKET MIDDLEWARE. Error when creating socket`, JSON.stringify(error, Object.getOwnPropertyNames(error)));
      }
    }

    if (leave) {
      return socket.removeListener(event);
    }

    if (emit) {
      socket.emit(
        'debug',
        `SOCKET MIDDLEWARE. Received action. Call status: ${action.callStatus}. Emit: ${action.emit}. Event: ${action.event}. Payload: ${action.payload}`
      );
      return socket.emit(action.event, action.payload);
    }

    let handleEvent = handle;
    if (typeof handleEvent === 'string') {
      handleEvent = result => {
        socket.emit('debug', `SOCKET MIDDLEWARE. Received result. Type: ${handle}`);
        dispatch({ type: handle, result, ...rest });
      };
    }

    // We dont want to listen twice atm
    if (socket.listeners(event).length <= 0) {
      return socket.on(event, handleEvent);
    }
  };
}

function sendLogToServer(logMessage, logObject) {
  axios.post(`${Config.conf.backendUrl}/api/v1/debugLogs`, { logMessage: logMessage, logObject: logObject }, {
    headers: {
      Authorization: `Bearer ${sessionStorage.token}`
    }
  });
}
