import axios from 'axios';
import { Config } from '../config/config';

export const GET_COMPLIANCE_DATA = 'compliance/GET_COMPLIANCE_DATA';
export const GET_COMPLIANCE_DATA_SUCCESS = 'compliance/GET_COMPLIANCE_DATA_SUCCESS';
export const GET_COMPLIANCE_DATA_FAILED = 'compliance/GET_COMPLIANCE_DATA_FAILED';

export const UPDATE_COMPLIANCE_IMPLEMENTATION = 'compliance/UPDATE_COMPLIANCE_IMPLEMENTATION';
export const UPDATE_COMPLIANCE_IMPLEMENTATION_SUCCESS = 'compliance/UPDATE_COMPLIANCE_IMPLEMENTATION_SUCCESS';
export const UPDATE_COMPLIANCE_IMPLEMENTATION_FAILED = 'compliance/UPDATE_COMPLIANCE_IMPLEMENTATION_FAILED';

export const SET_SELECTED_COMPLIANCE_ITEM = 'compliance/SET_SELECTED_COMPLIANCE_ITEM';
export const SET_DISPLAY_SETUP_MODAL = 'compliance/SET_DISPLAY_SETUP_MODAL';
export const SET_DISPLAY_STATUS_MODAL = 'compliance/SET_DISPLAY_STATUS_MODAL';
export const SET_DISPLAY_STOP_MODAL = 'compliance/SET_DISPLAY_STOP_MODAL';

export const getComplianceData = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: GET_COMPLIANCE_DATA });

      const [complianceResponse, agentsResponse] = await Promise.all([
        axios.get(`${Config.conf.backendUrl}/api/v1/compliance`),
        axios.get(`${Config.conf.backendUrl}/api/v1/admin/agents`),
      ]);

      dispatch({
        type: GET_COMPLIANCE_DATA_SUCCESS,
        payload: { complianceItems: complianceResponse.data, agentIds: agentsResponse.data },
      });
    } catch (error) {
      return await dispatch({ type: GET_COMPLIANCE_DATA_FAILED, payload: { error: error } });
    }
  };
};

export const updateComplianceImplementation = (complianceId, implementation) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_COMPLIANCE_IMPLEMENTATION });

      const res = await axios.put(
        `${Config.conf.backendUrl}/api/v1/admin/compliance/${complianceId}/implementation`, {
          implementation
        }
        
      );
      const complianceItem = res.data;

      dispatch({
        type: UPDATE_COMPLIANCE_IMPLEMENTATION_SUCCESS,
        payload: {
          complianceItem: complianceItem,
        },
      });
    } catch (error) {
      return await dispatch({ type: UPDATE_COMPLIANCE_IMPLEMENTATION_FAILED, payload: { error: error } });
    }
  };
};

export const setSelectedComplianceItem = id => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_SELECTED_COMPLIANCE_ITEM,
      payload: {
        complianceId: id,
      },
    });
  };
};

export const setDisplaySetupModal = displayValue => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_DISPLAY_SETUP_MODAL,
      payload: {
        displayValue: displayValue,
      },
    });
  };
};

export const setDisplayStatusModal = displayValue => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_DISPLAY_STATUS_MODAL,
      payload: {
        displayValue: displayValue,
      },
    });
  };
};

export const setDisplayStopModal = displayValue => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_DISPLAY_STOP_MODAL,
      payload: {
        displayValue: displayValue,
      },
    });
  };
};
