import { GET_DOCUMENT_CHANGES, DOCUMENT_CHANGES_LOADING, ERROR } from '../_actions/revision.actions';

const initialState = {
  documentChangesRevisions: [],
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DOCUMENT_CHANGES: {
      return {
        ...state,
        loading: false,
        documentChangesRevisions: action.payload.documentChangesRevisions,
      };
    }

    case DOCUMENT_CHANGES_LOADING: {
      return {
        ...state,
        loading: action.payload.loading,
      };
    }

    case ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    }

    default:
      return state;
  }
};

export default reducer;
