import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';

import { colorTheme } from '../../../sharedStyledComponents/generalStyles';

const Keyword = memo(function Keyword(props) {
    return (
        // <Wrapper onClick={() => props.searchKeyword(props.keyword)}>
        <Wrapper onClick={(e) => {
            const input = document.getElementById('searchField');
            setNativeValue(input, props.keyword);
            input.dispatchEvent(new Event('input', { bubbles: true }))
        }}>
            {props.keyword}
            {props.count > 1 && <CountWrapper>{props.count}</CountWrapper>}
        </Wrapper>
    )
})

function setNativeValue(element, value) {
    const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
    const prototype = Object.getPrototypeOf(element);
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;

    if (valueSetter && valueSetter !== prototypeValueSetter) {
        prototypeValueSetter.call(element, value);
    } else {
        valueSetter.call(element, value);
    }
}

Keyword.propTypes = {
    keyword: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
}

const Wrapper = styled.div`
    display: inline-flex;
    align-items: center;
    padding: 0 .5rem;
    border-radius: 1rem;
    background-color: #e4eaed;
    margin: 0.5rem 0.5rem 0 0;
    cursor: pointer;
`;

const CountWrapper = styled.span`
    display: flex;
    margin-left: .2rem;
    margin-right: -.4rem;
    border-radius: 50%;
    border: 1px solid ${colorTheme};
    padding: 0 .3rem;
    font-size: .7rem;
`;

export default Keyword
