import {
  GET_INSTRUCTIONS_CONFIG_REQUEST,
  GET_INSTRUCTIONS_CONFIG_SUCCESS,
  SINGLE_DOCUMENT_UPDATING,
  SINGLE_DOCUMENT_UPDATED,
  TIMER_START,
  TIMER_TICK,
  TIMER_STOP,
  OFFLINE_CONFIG,
  SET_CURRENT_CATEGORY,
  TOGGLE_DISPLAY_CHANGES_TO_INSTRUCTIONS_MODAL,
} from '../_actions/dashboard.actions';

import { N1_RECEIVED, CALL_STATUS, RECONNECT, TAB_COUNT } from '../_actions/socket-io.actions';

import { CLEAR_TOKEN } from '../_actions/auth.actions';

const initialState = {
  offline: false,
  loadingConfig: true,
  loadingConfigError: false,
  loadingConfigErrorMsg: '',
  categories: [],
  currentCategory: undefined,
  workInstructions: [],
  topFiles: [],
  transactions: [],
  complianceItems: [],
  keywords: [],
  fullText: [],
  callStatus: false,
  callStatusText: undefined,
  time: 0,
  topic: null,
  displayChangesToInstructionsModal: false,
  tabCount: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECONNECT: {
      let elapsed = 0;
      if (action.result.startTime) {
        const millis = Date.now() - Number(action.result.startTime);
        elapsed = Math.floor(millis / 1000);
      }
      if (action.result.sessionId) {
        sessionStorage.setItem('sessionId', action.result.sessionId)
      }
      return {
        ...state,
        callStatus: action.result.convOngoing,
        topic: action.result.topic,
        time: elapsed,
        callStatusText: action.result.convOngoing ? '(AI medlyt)' : undefined,
        offline: false,
      };
    }

    case N1_RECEIVED: {
      const currentKeywords = state.keywords;
      const newKeywords = action.result.keywords;
      const currentFullText = state.fullText;
      const fullText = action.result.fulltext;
      // add new keywords to the state.
      newKeywords.forEach((keyword) => {
        if (currentKeywords.indexOf(keyword) >= 0) currentKeywords.push(keyword);
      });
      // deconstruct predictions and add previous confidence and new confidence (used in bar animations)
      const newWorkInstructions = state.workInstructions.map((instruction) => {
        const prediction = action.result.predictions.workInstructionsPredictions.find(
          (prediction) => prediction.id.toLowerCase() === instruction.id.toLowerCase()
        );
        if (prediction) {
          instruction.prevConfidence = instruction.confidence || 0;
          instruction.confidence = prediction.score;
        }
        return instruction;
      });

      // deconstruct perdicitions and add confideces to topfiles
      const newTopFiles = state.topFiles.map((instruction) => {
        const prediction = action.result.predictions.topFilesPredictions.find(
          (prediction) => prediction.id.toLowerCase() === instruction.id.toLowerCase()
        );
        if (prediction) {
          instruction.prevConfidence = instruction.confidence || 0;
          instruction.confidence = prediction.score * 100;
        }
        return instruction;
      });

      //handle full text
      let newFullText = [];
      if (process.env.NODE_ENV !== 'production') {
        newFullText = _sortFullText(currentFullText, fullText);
      }

      return {
        ...state,
        keywords: newKeywords,
        workInstructions: newWorkInstructions,
        topFiles: newTopFiles,
        fullText: newFullText,
      };
    }

    // case N2_RECEIVED: {
    //   const currentKeywords = state.keywords;
    //   const newKeywords = action.result.keywords;
    //   const currentFullText = state.fullText;
    //   const fullText = action.result.fulltext;

    //   // add new keywords to the state.
    //   newKeywords.forEach((keyword) => {
    //     if (currentKeywords.indexOf(keyword) >= 0) currentKeywords.push(keyword);
    //   });

    //   //handle full text
    //   let newFullText = [];
    //   if (process.env.NODE_ENV !== 'production') {
    //     newFullText = _sortFullText(currentFullText, fullText);
    //   }

    //   return {
    //     ...state,
    //     keywords: newKeywords,
    //     fullText: newFullText,
    //     // workInstructions: processedInstructions,
    //   };
    // }

    case GET_INSTRUCTIONS_CONFIG_REQUEST: {
      return {
        ...state,
        loadingConfig: true,
        loadingConfigError: false,
        loadingconfigErrorMsg: '',
        categories: [],
        workInstructions: [],
        topFiles: [],
        transactions: [],
        complianceItems: [],
      };
    }

    case GET_INSTRUCTIONS_CONFIG_SUCCESS: {
      return {
        ...state,
        offline: false,
        loadingConfig: false,
        categories: action.payload.categories,
        workInstructions: action.payload.workInstructions,
        topFiles: action.payload.topFiles,
        transactions: action.payload.transactions,
        complianceItems: action.payload.complianceItems,
      };
    }

    case OFFLINE_CONFIG: {
      return {
        ...state,
        offline: true,
        loadingConfig: false,
        categories: action.payload.categories,
        workInstructions: action.payload.workInstructions,
        topFiles: action.payload.topFiles,
        transactions: action.payload.transactions,
        complianceItems: action.payload.complianceItems,
      };
    }
    case CALL_STATUS: {
      return {
        ...state,
        callStatus: action.result,
      };
    }

    case TIMER_START:
      const workInstr = JSON.parse(localStorage.getItem('workInstructions'));
      const topFiles = JSON.parse(localStorage.getItem('topFiles'));

      return {
        ...state,
        time: state.time || 0,
        callStatusText: action.callStatusText,
        keywords: [],
        fullText: [],
        topic: null,
        workInstructions: workInstr,
        topFiles: topFiles,
      };

    case TIMER_STOP:
      return {
        ...state,
        time: 0,
        callStatusText: undefined,
      };

    case TIMER_TICK:
      return {
        ...state,
        time: state.time + 1,
      };

    case SINGLE_DOCUMENT_UPDATING: {
      const workInstrCopy = JSON.parse(JSON.stringify(state.workInstructions));
      const newInstructions = workInstrCopy.map((instruction) => {
        if (instruction.id === action.payload.id) instruction.updating = true;
        return instruction;
      });
      const topFilesCopy = JSON.parse(JSON.stringify(state.topFiles));
      const newTopFiles = topFilesCopy.map((doc) => {
        if (doc.id === action.payload.id) doc.updating = true;
        return doc;
      });
      return {
        ...state,
        workInstructions: newInstructions,
        topFiles: newTopFiles,
      };
    }

    case SINGLE_DOCUMENT_UPDATED: {
      const workInstrCopy = JSON.parse(JSON.stringify(state.workInstructions));
      const newInstructions = workInstrCopy.map((instruction) => {
        if (instruction.id === action.payload.id) instruction.updating = false;
        return instruction;
      });
      const topFilesCopy = JSON.parse(JSON.stringify(state.topFiles));
      const newTopFiles = topFilesCopy.map((doc) => {
        if (doc.id === action.payload.id) doc.updating = false;
        return doc;
      });
      return {
        ...state,
        workInstructions: newInstructions,
        topFiles: newTopFiles,
      };
    }

    case CLEAR_TOKEN: {
      return {
        ...state,
        offline: false,
        loadingConfig: true,
        loadingConfigError: false,
        loadingConfigErrorMsg: '',
        categories: [],
        currentCategory: undefined,
        workInstructions: [],
        topFiles: [],
        complianceItems: [],
        transactions: [],
        keywords: [],
        fullText: [],
        callStatus: false,
        callStatusText: undefined,
        time: 0,
        topic: null,
      };
    }

    case SET_CURRENT_CATEGORY: {
      return {
        ...state,
        currentCategory: action.payload,
      };
    }

    case TAB_COUNT: {
      return {
        ...state,
        tabCount: action.result,
      };
    }

    case TOGGLE_DISPLAY_CHANGES_TO_INSTRUCTIONS_MODAL: {
      return {
        ...state,
        displayChangesToInstructionsModal: !state.displayChangesToInstructionsModal,
      }
    }

    default:
      return state;
  }
};

function _sortFullText(currentFullText, fullText) {
  // JSON method deep clones all arrays as array of arrays can not be copied easily otherwise
  const newFullText = JSON.parse(JSON.stringify(currentFullText));

  // split the array of fulltext into multiple arrays to create lines in display
  if (fullText.length > 0 && newFullText.length > 0) {
    const diff = fullText.length - currentFullText.flat().length; // get the diffrence
    const fullTextEnd = fullText.slice(fullText.length - diff);
    // Get the newly added words as seperate array
    newFullText.unshift(fullTextEnd);
  } else {
    newFullText.unshift(fullText);
  }
  return newFullText;
}

export default reducer;
