import { useState, useEffect, createRef, Fragment } from 'react';
import * as SA from '../../../_actions/statistics.actions';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Dropdown, Menu, Input, Button, Space, Table } from 'antd';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPage from '@material-ui/icons/LastPage';
import FirstPage from '@material-ui/icons/FirstPage';
import styled from 'styled-components';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { isEmpty } from '../../../utils/helpers';
import { get } from 'lodash';
import Highlighter from 'react-highlight-words';
import { exportExcel } from '../../../utils/export-data';

/**
 * A table rendering a
 * @param {*} props
 * @returns
 */
const WorkInstructionsTable = props => {

  const calculatePaginationTotal = (data, limit) => {
    return Math.max(Math.ceil(data.length / limit), 1);
  }

  const { statistics } = useSelector(store => store);
  const { filters, workInstruction, fetching, workInstructions } = statistics;
  const [paginationLimit, setPaginationLimit] = useState(10);
  const [paginationNo, setPaginationNo] = useState(1);
  
  const [displayedData, setDisplayedData] = useState(workInstruction.data);
  const [paginationTotal, setPaginationTotal] = useState(calculatePaginationTotal(workInstruction.data, paginationLimit));
  const [searchText, setSearchText] = useState('');

  const dispatch = useDispatch();
  const { workInstructionMeta } = props;

  useEffect(() => {
    dispatch(SA.getWorkInstruction(filters, workInstructionMeta));
  }, [filters, workInstructionMeta, dispatch]);

  useEffect(() => {
    //reset data
    setSearchText('');
    setDisplayedData(workInstruction.data);
    setPaginationNo(1);
    setPaginationTotal(
      calculatePaginationTotal(workInstruction.data, paginationLimit)
    );
  }, [workInstruction.data, paginationLimit]);


  const filterDataByUser = (search) => {
      return isEmpty(search)
        ? workInstruction.data
        : workInstruction.data.filter(x => x.user.toLowerCase().includes(search.toLowerCase()));
  }

  const onSetPaginationLimit = event => {
    setPaginationLimit(event.item.props.limit);
    setPaginationNo(1);
  };

  const menu = (
    <Menu onClick={onSetPaginationLimit} style={{ minWidth: 75 }}>
      <Menu.Item key='1' limit='10'>
        10
      </Menu.Item>
      <Menu.Item key='2' limit='20'>
        20
      </Menu.Item>
      <Menu.Item key='3' limit='30'>
        30
      </Menu.Item>
    </Menu>
  );

  const onFirstPage = () => {
    setPaginationNo(1);
  };

  const onLastPage = () => {
    setPaginationNo(paginationTotal);
  };

  const onPrevPage = () => {
    setPaginationNo(paginationNo > 1 ? paginationNo - 1 : paginationNo);
  };

  const onNextPage = () => {
    setPaginationNo(
      paginationNo < paginationTotal ? paginationNo + 1 : paginationNo
    );
  };


  const searchInputRef = createRef();

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputRef}
          placeholder={`Søg efter bruger`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Søg
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size='small'
            style={{ width: 90 }}
          >
            Nulstil
          </Button>
        </Space>
      </div>
    ),
    filterIcon: () => <SearchOutlined />,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputRef && searchInputRef.current && searchInputRef.current.select(), 100);
      }
    },

    render: text =>
      dataIndex === 'user' ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const searchText = selectedKeys[0];
    const filtered = filterDataByUser(searchText);
    const totalPages = calculatePaginationTotal(filtered, paginationLimit);
    setSearchText(searchText);
    setDisplayedData(filtered);
    setPaginationTotal(totalPages);
    setPaginationNo(1);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
    setDisplayedData(workInstruction.data);
    setPaginationTotal(calculatePaginationTotal(workInstruction.data, paginationLimit));
    setPaginationNo(1);
  };
  

  const columns = [
    {
      title: <Typography.Text strong>Arbejdsinstruks</Typography.Text>,
      dataIndex: 'instructionName',
      key: 'instructionName',
    },
    {
      title: (
        <>
          Åbnet -<br />{' '}
          <Typography.Text strong>Data og tidspunkt</Typography.Text>
        </>
      ),
      dataIndex: 'formattedStartDate',
      key: 'formattedStartDate',
      sorter: (a, b) => new Date(a.startTimestamp) - new Date(b.startTimestamp),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: (
        <>
          Samlet
          <br /> <Typography.Text strong>åbningstid</Typography.Text>
        </>
      ),
      dataIndex: 'durationMin',
      key: 'durationMin',
    },
    {
      title: <Typography.Text strong>Bruger</Typography.Text>,
      dataIndex: 'user',
      key: 'user',
      sorter: (a, b) =>
        a.user.toLowerCase().localeCompare(b.user.toLowerCase(), 'da'),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('user'),
    },
  ];


  const onDownloadExcel = () => {
    exportExcel(displayedData, `${props.period}-${get(
      workInstruction,
      'name',
      'instruction'
    )}`);
  }
  return (
    <>
      <WorkInstructionTable
        $blur={isEmpty(workInstructions)}
        pagination={{ pageSize: paginationLimit, current: paginationNo }}
        columns={columns}
        dataSource={displayedData}
        loading={fetching.workInstruction}
      />
      <PaginationWrapper>
        <PaginationLimit>
          <Typography.Text>Vis</Typography.Text>
          <PaginationDropDown
            trigger={['click']}
            overlay={menu}
            icon={<KeyboardArrowDown />}
          >
            {paginationLimit}
          </PaginationDropDown>
          <Typography.Text>pr. side</Typography.Text>
        </PaginationLimit>
        <PaginationSkipper>
          {paginationTotal > 5 && (
            <PaginationButton
              type='primary'
              icon={<FirstPage />}
              onClick={onFirstPage}
              disabled={paginationNo <= 1 || isEmpty(workInstructions)}
            />
          )}
          <PaginationButton
            type='primary'
            icon={<KeyboardArrowLeft />}
            onClick={onPrevPage}
            disabled={paginationNo <= 1 || isEmpty(workInstructions)}
          />
          <Typography.Text>
            Side {paginationNo}/{paginationTotal}
          </Typography.Text>
          <PaginationButton
            disabled={
              paginationNo >= paginationTotal || isEmpty(workInstructions)
            }
            type='primary'
            icon={<KeyboardArrowRight />}
            onClick={onNextPage}
          />

          {paginationTotal > 5 && (
            <PaginationButton
              disabled={
                paginationNo >= paginationTotal || isEmpty(workInstructions)
              }
              type='primary'
              icon={<LastPage />}
              onClick={onLastPage}
            />
          )}
        </PaginationSkipper>
        <PaginationButton
            shape='round'
            type='primary'
            icon={<DownloadOutlined />}
            disabled={
              isEmpty(displayedData) || isEmpty(workInstructions)
            }
            onClick={onDownloadExcel}
          >
            Download excel
          </PaginationButton>
      </PaginationWrapper>
    </>
  );
};

export default WorkInstructionsTable;

const WorkInstructionTable = styled(Table)`
  filter: ${props => (props.$blur ? 'blur(.25rem)' : 'blur(0)')};

  thead.ant-table-thead {
    th.ant-table-column-has-sorters:hover {
      background: #34495e;

      .ant-table-filter-trigger-container {
        background: transparent;
      }
    }

    .ant-table-filter-trigger-container {
      margin-right: 1rem;
      svg {
        font-size: 18px;
      } 
    }

    .ant-table-filter-trigger-container-open {
      background: #34495e;
    }

    tr {
      &:first-child th:first-child {
        border-top-left-radius: 10px;
      }
      &:last-child th:last-child {
        border-top-right-radius: 10px;
      }

      th {
        background-color: #34495e;
        color: #fff;

        * {
          color: #fff;
        }
      }
    }

    tr:nth-of-type(even) {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  .ant-pagination {
    display: none;
  }

  .ant-table-cell {
    .anticon {
      // font-size: 24px;
      &.anticon-check-circle {
        color: #006104;
      }
      &.anticon-close-square {
        color: #6d0000;
      }
    }
  }
`;

const PaginationLimit = styled.div``;
const PaginationSkipper = styled.div`
  > * {
    margin: 0 0.25rem;
  }
`;

const PaginationButton = styled(Button)`
  background-color: #34495e;
  border-color: #34495e;

  &.ant-btn-round {
    border-radius: 10px;
  }
  &:hover,
  &:focus {
    background-color: #34495e;
    border-color: #34495e;
    span {
      text-decoration: underline;
    }
  }
`;
const PaginationDropDown = styled(Dropdown.Button)`
  margin: 0 1rem;
  .ant-dropdown-menu-item {
    background: blue;
    min-width: 75px;
  }

  > .ant-btn {
    &:hover,
    &:focus {
      border-color: #d9d9d9;
      color: initial;
    }
    &:first-child:not(:last-child) {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &:last-child:not(:first-child) {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-color: #34495e;
    }
  }

  .ant-dropdown-trigger {
    background-color: #34495e;
    svg  {
      fill: #fff;
    }
  }
`;
const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 1rem 0;

  > * {
    margin: 0 1rem;
    display: flex;
    align-items: center;
  }
`;
