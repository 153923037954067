import { useEffect } from 'react';
import styled from 'styled-components';
import DashboardMenu from './DashboardMenu';
import { itemBackground } from '../../sharedStyledComponents/generalStyles';
import { screen } from '../../sharedStyledComponents/Responsive.styles';
import CardModule from './Card.module';
import AgentAssistModule from './AgentAssist.module';
import BarChartComponent from './BarChart.module';
import { useDispatch, useSelector } from 'react-redux';
import {
  getKPIs,
  getWorkInstructions,
  getAgentActivities,
  getAgentFrequency,
} from '../../../_actions/statistics.actions';
import dayjs from 'dayjs';
import { Button, Typography } from 'antd';
import axios from 'axios';
import { Config } from '../../../config/config';
import { exportExcel, exportJson } from '../../../utils/export-data';
import { DownloadOutlined } from '@ant-design/icons';

const StatisticsDashboard = () => {
  const { statistics } = useSelector(store => store);
  const dispatch = useDispatch();
  const { kpis, filters, workInstructions, fetching, agentActivityCards, agentFrequency } = statistics;

  useEffect(() => {
    dispatch(getKPIs(filters));
    dispatch(getWorkInstructions(filters));
    dispatch(getAgentActivities(filters));
    dispatch(getAgentFrequency(filters));
  }, [filters, dispatch]);

  const periodStart = dayjs(filters.startTime).format('DD-MM-YYYY');
  const periodEnd = dayjs(filters.endTime).format('DD-MM-YYYY');

  const exportToExcel = async () => {
    try {
      const res = await axios.post(`${Config.conf.backendUrl}/api/v1/admin/statistics/exportdata`, { ...filters });
      const data = res.data.map(x => ({ ...x, clicks: x.clicks.length }));
      exportExcel(data, `${periodStart}-${periodEnd}-data-eksport`);
    } catch (error) {
      console.log(error);
    }
  };

  const exportToJson = async () => {
    try {
      const res = await axios.post(`${Config.conf.backendUrl}/api/v1/admin/statistics/exportdata`, { ...filters });
      exportJson(res.data, `${periodStart}-${periodEnd}-data-eksport`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <StickyHeader>
        <DashboardMenu />
      </StickyHeader>
      {kpis.length > 0 && (
        <CardModule data={kpis} isLoading={fetching.kpis} moduleTitle='Agent Assist' moduleSubtitle="KPI'er" />
      )}
      <Divider />
      {
        <BarChartComponent
          chartData={workInstructions}
          fetching={fetching.workInstructions}
          moduleTitle={'Arbejdsinstrukser'}
          moduleSubtitle={'Mest benyttede'}
          period={`${periodStart}-${periodEnd}`}
        />
      }
      <Divider />
      {
        <BarChartComponent
          chartData={agentFrequency}
          fetching={fetching.agentFrequency}
          moduleTitle={'Rådgivere'}
          moduleSubtitle={'Antal sessioner med åbnet instruks'}
          period={`${periodStart}-${periodEnd}`}
        />
      }
      <Divider />
      {agentActivityCards.length > 0 && (
        <CardModule
          data={agentActivityCards}
          isLoading={fetching.agentActivities}
          moduleTitle='Kunderådgiveraktivitet'
          moduleSubtitle='Samtaler og sagsbehandling med Agent Assist understøttelse'
        />
      )}
      <Divider />
      <AgentAssistModule />
      <Divider />
      <ModuleTitle level={2}>
        <span>
          <strong>Eksport</strong> Download data for perioden
        </span>
      </ModuleTitle>
      <ExportButtonsContainer>
        <ExportButton type='primary' icon={<DownloadOutlined />} onClick={exportToExcel}>
          Eksporter til Excel
        </ExportButton>
        <ExportButton type='primary' icon={<DownloadOutlined />} onClick={exportToJson}>
          Exporter til JSON
        </ExportButton>
      </ExportButtonsContainer>
      <Divider />
      <Divider />
    </>
  );
};

const StickyHeader = styled.nav`
  box-shadow: 1px 3px 3px #aeaeae;
  padding: 1rem calc(1rem + 50px);
  background: ${itemBackground};
  position: sticky;
  top: 0;
  z-index: 3;
  margin: 0;

  @media ${screen.MD} {
    margin: 0 calc(-1rem - 50px) 20px;
  }
  @media ${screen.XL} {
    padding: 1rem calc(1rem + 50px);
  }
`;

const Divider = styled.div`
  padding: 1rem 0;
`;

const ModuleTitle = styled(Typography.Title)`
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
  &.ant-typography {
    font-weight: 400 !important;
    margin: 2rem 0;
  }
  strong {
    font-weight: 600;
  }
`;

const ExportButtonsContainer = styled.div`
  display:flex;
  gap: 1rem;
  justify-content: start;
`;

const ExportButton = styled(Button)`
  background-color: #34495e;
  border-color: #34495e;
  border-radius: 0.5rem;

  &.ant-btn-round {
    border-radius: 10px;
  }
  &:hover,
  &:focus {
    background-color: #34495e !important;
    border-color: #34495e !important;
    span {
      text-decoration: underline;
    }
  }
`;

export default StatisticsDashboard;
