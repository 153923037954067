import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { Alert, Col, FloatButton, Input, Layout, Modal, Row, Space } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

import { EditableTable } from './components/instructionTable.component';
import { instructionColumns, topfilesColumns, transactionsColumns } from './tableConfiguration/cellSettings';
import {
  getData,
  updateInstruction,
  updateTransaction,
  updateTopfile,
  updateGroup,
  addNewInstruction,
  addNewTransaction,
  addNewTopfile,
  submitNewInstruction,
  submitNewTransaction,
  submitNewTopfile,
  deleteInstruction,
  deleteTransaction,
  deleteTopfile,
  addNewGroup,
} from '../../../_actions/instructionSettings.actions';
import { colorTheme, itemBackground, textOnTheme, TitleText } from '../../sharedStyledComponents/generalStyles';
import { SettingsButton } from '../../sharedStyledComponents/dataGridStyles';

export class InstructionsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newGroupModalVisible: false,
      groupName: '',
    };
  }

  componentDidMount() {
    this.props.getData();
  }

  componentDidUpdate(prevProps) {
    //Close modal after submitting new group
    if (prevProps.groupsLoading && !this.props.groupsLoading) {
      this.setState({ newGroupModalVisible: false });
    }
  }

  openModal = () => {
    this.setState({ newGroupModalVisible: true });
  };

  closeModal = () => {
    this.setState({ newGroupModalVisible: false, groupName: '' });
  };

  submitNewGroup = (e) => {
    e.preventDefault();
    this.props.addNewGroup({ name: this.state.groupName });
  };

  render() {
    const {
      loading,
      error,
      groups,
      instructions,
      transactions,
      topfiles,
      groupsLoading,
      instructionsLoading,
      transactionsLoading,
      topfilesLoading,
      updateInstruction,
      updateTransaction,
      updateTopfile,
      updateGroup,
      submitted,
      addNewInstruction,
      addNewTransaction,
      addNewTopfile,
      submitNewInstruction,
      submitNewTransaction,
      submitNewTopfile,
      deleteInstruction,
      deleteTransaction,
      deleteTopfile,
    } = this.props;

    const { newGroupModalVisible } = this.state;

    return (
      (<Layout style={{ background: itemBackground, marginTop: '1rem' }}>
        <FloatButton.BackTop visibilityHeight={700} />
        {error && <Alert message='Der skete desværre en fejl' description={error} type='error' showIcon closable />}
        {instructions.length > 0 && (
          <AddGroupButton icon={<PlusCircleOutlined style={{ fontSize: '1.4rem' }} />} onClick={this.openModal}>
            Tilføj ny gruppe
          </AddGroupButton>
        )}
        <Modal
          title={<TitleText fontSize='1.3rem'>Tilføj ny gruppe</TitleText>}
          cancelText='Fortryd'
          okText='Bekræft'
          open={newGroupModalVisible}
          onOk={this.submitNewGroup}
          confirmLoading={groupsLoading}
          onCancel={this.closeModal}
        >
          <Space direction='vertical'>
            Navngiv den nye gruppe nedenfor.
            <Input
              type='text'
              placeholder='Indtast gruppenavn'
              value={this.state.groupName}
              onChange={(e) => this.setState({ groupName: e.target.value })}
            />
          </Space>
        </Modal>
        {instructions.length === 0 ? (
          <EditableTable data={[]} columns={instructionColumns} categoryName={'-'} loading={loading} />
        ) : (
          // for each category get the relevant instructions and create a table.
          (groups.map((category) => {
            const filteredList = instructions.filter((instr) => instr.doc && instr.doc.category === category.value);
            return (
              <Row key={'row-' + category.value}>
                <Col span={24} key={category.value}>
                  <EditableTable
                    data={filteredList}
                    columns={instructionColumns}
                    add={addNewInstruction}
                    submit={submitNewInstruction}
                    submitted={submitted}
                    deleteInstr={deleteInstruction}
                    update={updateInstruction}
                    editableGroup={true}
                    clickableRow={true}
                    updateGroup={updateGroup}
                    category={category}
                    loading={loading || instructionsLoading || groupsLoading}
                  />
                </Col>
              </Row>
            );
          }))
        )}
        <Row>
          <Col span={24}>
            <EditableTable
              data={transactions || []}
              columns={transactionsColumns}
              add={addNewTransaction}
              submit={submitNewTransaction}
              update={updateTransaction}
              deleteInstr={deleteTransaction}
              submitted={submitted}
              category={{ value: 'Kørsel' }}
              loading={loading || transactionsLoading || groupsLoading}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <EditableTable
              data={topfiles || []}
              columns={topfilesColumns}
              add={addNewTopfile}
              submit={submitNewTopfile}
              update={updateTopfile}
              deleteInstr={deleteTopfile}
              submitted={submitted}
              clickableRow={true}
              category={{ value: 'Faste' }}
              loading={loading || topfilesLoading || groupsLoading}
              dragAndDroppable={true}
            />
          </Col>
        </Row>
      </Layout>)
    );
  }
}

InstructionsContainer.propTypes = {
  instructions: PropTypes.arrayOf(PropTypes.object).isRequired,
  transactions: PropTypes.arrayOf(PropTypes.object).isRequired,
  topfiles: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => ({
  instructions: state.instructionSettings.instructions,
  transactions: state.instructionSettings.transactions,
  topfiles: state.instructionSettings.topfiles,
  loading: state.instructionSettings.loading,
  instructionsLoading: state.instructionSettings.instructionsLoading,
  transactionsLoading: state.instructionSettings.transactionsLoading,
  topfilesLoading: state.instructionSettings.topfilesLoading,
  groupsLoading: state.instructionSettings.groupsLoading,
  error: state.instructionSettings.error,
  groups: state.instructionSettings.groups,
  submitted: state.instructionSettings.submitted,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getData,
      updateInstruction,
      updateTransaction,
      updateTopfile,
      addNewInstruction,
      addNewTransaction,
      addNewTopfile,
      submitNewInstruction,
      submitNewTransaction,
      submitNewTopfile,
      deleteInstruction,
      deleteTransaction,
      deleteTopfile,
      addNewGroup,
      updateGroup,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(InstructionsContainer);

const AddGroupButton = styled(SettingsButton)`
  background-color: ${colorTheme};
  color: ${textOnTheme};
  font-weight: 600;
  width: fit-content;
`;
