import React from 'react';
import styled from 'styled-components';

export const Offline = ({ children }) => {
    return (
        <LoadingBox>
            <FlexCenter>
                <Text>
                    Offline tilstand
                </Text>
            </FlexCenter>
        </LoadingBox>
    )
}

const Text = styled.div`
    font-weight: 700;  
`;

const FlexCenter = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingBox = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  left:0%;
  background: #fff;
  opacity: 0.8;
  z-index: 9999;
`;
