import {
  SEARCH_AUTOCOMPLETE_FAILED,
  SEARCH_AUTOCOMPLETE_SUCCESS,
  SEARCH_AUTOCOMPLETE_REQUEST,
  CLEAR_SEARCH,
  SUGGESTION_REQUEST,
  SUGGESTION_FAILED,
  SUGGESTION_SUCCESS,
  ADD_FILTER_CATEGORY,
  REMOVE_FILTER_CATEGORY,
  SEARCH_WORD_CHANGES,
} from '../_actions/search.actions';

const initialState = {
  autoCompleteLoading: false,
  autoCompleteSuggestions: [],
  autoCompleteError: '',
  suggestionLoading: false,
  activeCategories: [],
  suggestions: [],
  suggestionError: '',
  searchWord: '',
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SEARCH_WORD_CHANGES:
      return {
        ...state,
        searchWord: payload,
      };
    case SEARCH_AUTOCOMPLETE_REQUEST:
      return {
        ...state,
        autoCompleteLoading: true,
        autoCompleteError: '',
      };
    case SEARCH_AUTOCOMPLETE_FAILED:
      return {
        ...state,
        autoCompleteLoading: false,
        autoCompleteSuggestions: [],
        autoCompleteError: payload,
      };
    case SEARCH_AUTOCOMPLETE_SUCCESS:
      return {
        ...state,
        autoCompleteLoading: false,
        autoCompleteError: '',
        autoCompleteSuggestions: payload.map((w) => w),
      };
    case CLEAR_SEARCH:
      return {
        ...state,
        autoCompleteSuggestions: [],
        suggestions: [],
        autoCompleteError: '',
        suggestionError: '',
        searchWord: '',
      };
    case SUGGESTION_REQUEST:
      return {
        ...state,
        suggestions: [],
        suggestionLoading: true,
        suggestionError: '',
      };
    case SUGGESTION_SUCCESS:
      return {
        ...state,
        suggestions: payload,
        autoCompleteSuggestions: [],
        suggestionLoading: false,
        suggestionError: '',
      };
    case SUGGESTION_FAILED:
      return {
        ...state,
        suggestionLoading: false,
        suggestionError: 'Ingen resultater matcher',
      };
    case ADD_FILTER_CATEGORY:
      const newCategories = { ...state.activeCategories };
      newCategories[payload.name] = { name: payload.name, active: true };
      return {
        ...state,
        activeCategories: newCategories,
      };
    case REMOVE_FILTER_CATEGORY:
      const newCategoriesRemove = { ...state.activeCategories };
      newCategoriesRemove[payload.name] = { name: payload.name, active: false };
      return {
        ...state,
        activeCategories: newCategoriesRemove,
      };

    default:
      return state;
  }
};

export default reducer;
