import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import ReduxThunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import rootReducer from './_reducers';
// import { createLogger } from 'redux-logger';
import socketMiddleware from './middleware/socket-middleware';
// import { TIMER_TICK } from './_actions/dashboard.actions';

const isProd = process.env.NODE_ENV === 'production';

export const history = createBrowserHistory();

const enhancers = [];
const middleware = [ReduxThunk, socketMiddleware(), routerMiddleware(history)];

if (!isProd) {
  // Ignore timer ticks as these fill up the console and makes debugging a pain
  /**
    middleware.push(createLogger({
        predicate: (getState, action) => action.type !== TIMER_TICK
    }));
     */
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const configureStore = (preloadedState) => {
  const store = createStore(rootReducer(history), preloadedState, composedEnhancers);

  if (!isProd && module.hot) {
    module.hot.accept('./_reducers', () => {
      store.replaceReducer(rootReducer(history));
    });
  }
  return store;
};

export default configureStore;
//export default createStore(rootReducer(history), initialState, composedEnhancers);
