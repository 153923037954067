import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { TitleText, borderColor, linkItemHover, grayedText } from '../../../sharedStyledComponents/generalStyles';

export default class Suggestions extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expand: false,
    };
  }

  /**
   * build list of suggestions 3 items long if not expanded and 6 if it is expanded
   */
  _createList = (suggestions) => {
    if (this.state.expand) return suggestions.map((s, index) => this._listItem(s.title, s.id, s.documentName, s.category, index));

    return suggestions.slice(0, 3).map((s, index) => this._listItem(s.title, s.id, s.documentName, s.category, index));
  };

  _listItem = (title, id, documentName, category, index) => {
    // remove the numbers from headers for prettyfying
    const regex = /[a-zA-Z\u00c0-\u017e]+/g;
    const displayTitle = title.match(regex);
    return (
      <UnderlineWrapper key={title + index} onClick={() => this.props.openAndNavigate(id, title)}>
        <ListItemWrapper>
          {/* If the title is only numbers we want to use them else use the prettyfied one */}
          <ListItemCategoryWrapper>
            <ListItemDoc>{documentName}</ListItemDoc>
            <ListItemCategory>{category}</ListItemCategory>
          </ListItemCategoryWrapper>
          <ListItemTitleWrapper>
            <ListItemTitle>{displayTitle ? displayTitle.join(' ') : title}</ListItemTitle>
          </ListItemTitleWrapper>
        </ListItemWrapper>
      </UnderlineWrapper>
    );
  };

  handleExpand = (e) => {
    e.preventDefault();
  };
  render() {
    const { suggestions, suggestionError } = this.props;
    if (suggestions.length === 0 && !suggestionError) return <></>;
    return (
      <Container>
        <UnderlineWrapper>
          <Title>Søgeresultater</Title>
        </UnderlineWrapper>
        {suggestionError ? (
          <UnderlineWrapper>
            <Error>{suggestionError}</Error>
          </UnderlineWrapper>
        ) : (
            <>
              {this._createList(suggestions)}
              {suggestions.length > 3 && (
                <ExpandButton onClick={() => this.setState({ expand: !this.state.expand })}>
                  {this.state.expand ? 'Vis mindre' : `Vis ${suggestions.slice(3).length} mere`}
                </ExpandButton>
              )}
            </>
          )}
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Title = styled(TitleText)`
  display: flex;
  width: 100%;
  padding: 0 0 0.2rem 0.6rem;
  user-select: none;
`;

const ListItemWrapper = styled.div`
  margin: 0 0.6rem 0 0;
  display: flex;
  flex-wrap: wrap;
  padding: 0.4rem 0 0.2rem 1rem;
  cursor: pointer;
`;

const ListItemTitleWrapper = styled.div`
  width: 100%;
  padding-top: 0.1rem;
`;

const ListItemTitle = styled.div`
  user-select: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  position: relative;
  text-decoration: none;
  display: inline-block;
  color: ${grayedText};
  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #000;
    visibility: hidden;
    transform: width;
    transition: all 0.3s ease-out 0s;
  }
`;

const UnderlineWrapper = styled.div`
  width: 100%;
  border-bottom: 0.1rem solid ${borderColor};
  &:hover {
    ${ListItemTitle}:before {
      visibility: visible;
      width: 100%;
      background-color: ${grayedText};
      -webkit-transform: width;
      transform: width;
    }
  }
`;

const ListItemCategoryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

const ListItemDoc = styled.div`
  user-select: none;
  padding-right: 0.5rem;
  flex: 1;
`;

const ListItemCategory = styled.div`
  user-select: none;
  color: ${grayedText};
`;

const ExpandButton = styled.a`
  user-select: none;
  cursor: pointer;
  margin-left: auto;
  padding: 0.2rem 0.5rem 0.2rem 0;
  order: 2;
  text-decoration: underline !important;
`;

const Error = styled.div`
  display: flex;
  user-select: none;
  justify-content: center;
  padding: 0.4rem;
  color: ${linkItemHover};
`;
