import { message } from 'antd';
import axios from 'axios';
import * as SC from '../_constants/statistics.constants';
import { wait } from '../utils/helpers';
import { Config } from '../config/config';

/**
 * Fetches work instructions for displaying data in the given module
 * @param {object} filters the object with filters to apply the query
 * @returns {void}
 */
export const getWorkInstructions = (filters) => async (dispatch) => {
  dispatch({ type: SC.GET_WORK_INSTRUCTIONS_START });
  try {
    // await wait(2000);
    const res = await axios.post(`${Config.conf.backendUrl}/api/v1/admin/statistics/instructions`, { ...filters });
    dispatch({ type: SC.GET_WORK_INSTRUCTIONS_SUCCESS, payload: res.data });
  } catch (Error) {
    return await dispatch({ type: SC.GET_WORK_INSTRUCTIONS_FAILURE, payload: Error.message });
  }
}; // getWorkInstructions()

/**
 * Fetches work instructions for displaying data in the given module
 * @param {object} filters the object with filters to apply the query
 * @returns {void}
 */
export const getWorkInstruction = (filters, workInstruction) => async (dispatch) => {
  dispatch({ type: SC.GET_WORK_INSTRUCTION_START });

  try {
    // await wait(2000);
    const res = await axios.post(
      `${Config.conf.backendUrl}/api/v1/admin/statistics/instructions/${workInstruction.id}`,
      {
        ...filters,
        instruction: {
          id: workInstruction.id,
          name: workInstruction.name,
        },
      }
    );
    dispatch({ type: SC.GET_WORK_INSTRUCTION_SUCCESS, payload: { ...res.data, name: workInstruction.name } });
  } catch (Error) {
    return await dispatch({ type: SC.GET_WORK_INSTRUCTION_FAILURE, payload: Error.message });
  }
}; // getWorkInstructions()

/**
 * Fetches agent activities for displaying data in the given module
 * @param {object} filters the object with filters to apply the query
 * @returns {void}
 */
export const getAgentActivities = (filters) => async (dispatch) => {
  dispatch({ type: SC.GET_AGENT_ACTIVITIES_START });

  try {
    // await wait(2000);
    const res = await axios.post(`${Config.conf.backendUrl}/api/v1/admin/statistics/agentactivity`, { ...filters });
    dispatch({ type: SC.GET_AGENT_ACTIVITIES_SUCCESS, payload: res.data });
  } catch (Error) {
    return await dispatch({ type: SC.GET_AGENT_ACTIVITIES_FAILURE, payload: Error.message });
  }
}; // getWorkInstructions()

/**
 * Fetches agent activities for displaying data in the given module
 * @param {object} filters the object with filters to apply the query
 * @returns {void}
 */
export const getAgentFrequency = (filters) => async (dispatch) => {
  dispatch({ type: SC.GET_AGENT_FREQUENCY_START });
  try {
    // await wait(2000);
    const res = await axios.post(`${Config.conf.backendUrl}/api/v1/admin/statistics/agentFrequency`, { ...filters });
    dispatch({ type: SC.GET_AGENT_FREQUENCY_SUCCESS, payload: res.data });
  } catch (Error) {
    return await dispatch({ type: SC.GET_AGENT_FREQUENCY_FAILURE, payload: Error.message });
  }
}; // getWorkInstructions()

/**
 * Retrieve KPI data from endpoint and dispatch the action
 * @action
 * @param {object} filters the given filters to apply to the fetch
 * @returns {void}
 */
export const getKPIs = (filters) => async (dispatch) => {
  dispatch({ type: SC.GET_KPIS_START });

  try {
    const res = await axios.post(`${Config.conf.backendUrl}/api/v1/admin/statistics/kpis`, { ...filters });
    dispatch({ type: SC.GET_KPIS_SUCCESS, payload: res.data });
  } catch (Error) {
    return await dispatch({ type: SC.GET_KPIS_FAILURE, payload: Error.message });
  }
}; // getKPIs()

/**
 * Sets the filters on the reducer for usage in other actions
 * @param {Object} filters the filter object
 * @returns {void}
 */
export const setFilters = (filters) => async (dispatch) => {
  await dispatch({ type: SC.SET_FILTERS_START });
  await wait(200);
  await dispatch({ type: SC.SET_FILTERS_SUCCESS, payload: filters });
}; // setFilters()

/**
 * Gets the users and minFromDate vars from server
 * @returns {void}
 */
export const getUsers = () => async (dispatch) => {
  try {
    await dispatch({ type: SC.GET_USERS_START });
    // Fetch users data
    const res = await axios.get(`${Config.conf.backendUrl}/api/v1/admin/statistics/searchoptions`);
    await dispatch({ type: SC.GET_USERS_SUCCESS, payload: res.data });
  } catch (Error) {
    message.error('Der gik noget galt i hentning af brugere');
    return await dispatch({ type: SC.GET_USERS_FAILURE, payload: Error.message });
  }
}; // getUsers()
