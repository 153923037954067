import {
  NAVIGATE_TO,
  CLEAR_NAVIGATION,
  ADD_OUTLINE,
  CLEAR_N2,
  SET_COMPLIANCE_LIST,
  TOGGLE_COMPLIANCE_ITEM_CHECK,
} from '../_actions/chosen.actions';
import { TIMER_STOP } from '../_actions/dashboard.actions';
import { N2_RECEIVED, COMPLIANCE_SCORES_RECEIVED } from '../_actions/socket-io.actions';

const initialState = {
  navigateTitle: '',
  outline: [],
  relevantHeaders: [],
  complianceList: [],
  activeComplianceItems: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case NAVIGATE_TO:
      return {
        ...state,
        navigateTitle: action.payload.title,
      };

    case CLEAR_NAVIGATION:
      return {
        ...state,
        navigateTitle: '',
      };

    case ADD_OUTLINE:
      return {
        ...state,
        outline: action.payload.outline,
      };

    case SET_COMPLIANCE_LIST:
      return {
        ...state,
        displayCompliance: false,
        complianceList: action.payload.complianceList,
      };

    case N2_RECEIVED:
      return {
        ...state,
        relevantHeaders: action.result.headerScores,
      };

    case COMPLIANCE_SCORES_RECEIVED:
      return {
        ...state,
        activeComplianceItems: state.complianceList.reduce((map, item) => {
          const score = action.result.complianceScores[item._id];
          if (score) {
            const activeItem = state.activeComplianceItems[item._id];
            const updatedItem = activeItem && activeItem.agentOverride ? activeItem : { ...item, ...score };
            map[item._id] = updatedItem;
          }
          return map;
        }, {}),
      };

    case CLEAR_N2:
      return {
        ...state,
        relevantHeaders: [],
      };

    case TIMER_STOP:
      return {
        ...state,
        relevantHeaders: [],
        activeComplianceItems: {},
      };

    case TOGGLE_COMPLIANCE_ITEM_CHECK:
      const itemId = action.payload.id;
      const value = action.payload.value;
      return {
        ...state,
        activeComplianceItems: {
          ...state.activeComplianceItems,
          [itemId]: { ...state.activeComplianceItems[itemId], checked: value, agentOverride: true },
        },
      };

    default:
      return state;
  }
};

export default reducer;
