import styled from 'styled-components';
import { Button, Table } from 'antd';

import { colorTheme, itemBackground, textOnTheme } from './generalStyles';

export const StyledTable = styled(Table)`
  min-width: 100%;
  border-radius: 0.6rem;
  overflow: hidden;
  .ant-table-thead .ant-table-cell {
    background-color: ${colorTheme} !important;
    color: ${textOnTheme};
    &:hover  {
      background-color: ${colorTheme} !important;
    }
  }

  .ant-table-cell::before {
    width: 0 !important;
  }

  .ant-table-column-sorters {
    padding: 0;
  }

  .editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
  }

  .ant-table-tbody > tr:hover > td {
    cursor: ${props => (props.clickableRow ? 'pointer' : 'default')};
    // background-color: #f1f1f1;
  }

  tr:nth-child(2n + 1) td {
    background-color: #bebebe33;
  }

  .ant-table-thead > tr > th:last-child {
    width: 75px;
    font-weight: 500;
  }
  .ant-table-thead > tr > th,
  .ant-table tfoot > tr > th {
    padding: 16px;
  }
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > td {
    padding: 12px 16px;
    overflow-wrap: initial;
  }

  td.ant-table-column-sort {
    background: transparent;
  }

  tr > td:first-child,
  tr > th:first-child {
    padding-left: 36px;
  }
`;

export const TableWrapper = styled.div`
  position: relative;
  border-radius: 0.4rem;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

export const TableSize = styled.div`
  display: flex;
  min-height: 9rem;
  height: ${props => {
    // Show all if 8 lines or less
    if (props.$count <= 8) return 'auto';
    // show first ~8 lines
    if (!props.$expanded) {
      return `${(props.$count <= 8 ? props.$count : 8) * 4.2}rem`;
    }
    // if expanded show all
    return 'auto';
  }};
`;

const InlineHeader = styled.div`
  display: inline-flex;
  user-select: none;
`;

const MultiLineHeader = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
`;

const Subtitle = styled.span`
  color: ${textOnTheme};
  white-space: nowrap;
`;

// Exports

export const Title = styled.div`
  color: ${textOnTheme};
  background-color: ${colorTheme};
  font-weight: 700;
  margin-right: 0.2rem;
  user-select: none;
  white-space: nowrap;
`;

export const inlineHeader = (title, subtitle) => (
  <InlineHeader>
    <Title>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
  </InlineHeader>
);

export const multiLineHeader = (title, subtitle) => (
  <MultiLineHeader>
    <Title>{title}</Title>
    <NoWrapTitle>{subtitle}</NoWrapTitle>
  </MultiLineHeader>
);

const NoWrapTitle = styled.div`
  white-space: nowrap;
  font-weight: 500;
`;

export const SettingsButton = styled(Button)`
  background-color: ${itemBackground};
  padding: 0.2rem 0.9rem 0.2rem 0.4rem;
  font-style: italic;
  text-transform: none;
  border-radius: 1.1rem;
  border: 0.1rem solid ${colorTheme};
  display: inline-flex;
  align-items: center;
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.4);
  .anticon {
    line-height: 0;
  }
`;
export const StyledAddButton = styled(SettingsButton)`
  display: ${props => (props.displaybutton === 'true' ? 'inline-flex' : 'none')};
  position: relative;
  bottom: 4rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${itemBackground};
  padding: 0.2rem 0.9rem 0.2rem 0.4rem;
  font-style: italic;
  text-transform: none;
  border-radius: 1.1rem;
  border: 0.1rem solid ${colorTheme};
`;

export const MinimizeButton = styled(SettingsButton)`
  float: right;
  font-size: 0.8rem;
  padding: 0.1rem 0.5rem 0.1rem 0.1rem;
  height: auto;
`;

export const Fade = styled.div`
  position: absolute;
  pointer-events: none;
  bottom: 0px;
  display: ${props => (props.$visible ? 'block' : 'none')};
  width: 100%;
  height: 11rem;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 100%);
`;
