import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import authReducer from './auth.reducer';
import dashboardReducer from './dashboard.reducer';
import chosenReducer from './chosen.reducer';
import multiDocumentReducer from './multiDocument.reducer';
import searchReducer from './search.reducer';
import instructionSettingsReducer from './instructionSettings.reducer';
import additionalSettingsReducer from './additionalSettings.reducer';
import statisticsReducer from './statistics.reducer';
import complianceReducer from './compliance.reducer';
import agentConfigReducer from './agentConfig.reducer';
import agentsReducer from './agents.reducer';
import documentChangesReducer from './documentChanges.reducer';
import revisionReducer from './revision.reducer';
import teamsReducer from './teams.reducer';

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    dashboard: dashboardReducer,
    chosen: chosenReducer,
    documents: multiDocumentReducer,
    search: searchReducer,
    instructionSettings: instructionSettingsReducer,
    additionalSettings: additionalSettingsReducer,
    statistics: statisticsReducer,
    compliance: complianceReducer,
    agentConfig: agentConfigReducer,
    agents: agentsReducer,
    documentChanges: documentChangesReducer,
    revision: revisionReducer,
    teams: teamsReducer,
  });
export default rootReducer;
