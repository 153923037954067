import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, DatePicker, Select, Typography } from 'antd';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { getUsers, setFilters } from '../../../_actions/statistics.actions';
import { debounce } from 'lodash';
// import { isEmpty } from '../../../utils/helpers';
import { BarChartOutlined, AccountCircleOutlined, InsertInvitation } from '@material-ui/icons';
import AgentPickerTeams from '../../GlobalComponents/agentPickerTeams.component';

const { RangePicker } = DatePicker;

const DashboardMenu = () => {
  const { statistics } = useSelector((store) => store);
  const { fetching, minFromDate, filters } = statistics;
  const dispatch = useDispatch();

  const [selectedDates, setSelectedDates] = useState([filters.startTime, filters.endTime]);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  // useEffect(() => {
  //   dispatch(getUsers());
  // }, [dispatch]);

  useEffect(() => {
    if (
      !isDatePickerOpen &&
      selectedDates &&
      selectedDates[0] &&
      selectedDates[1] &&
      (!filters.startTime.isSame(selectedDates[0]) || !filters.endTime.isSame(selectedDates[1]))
    ) {
      dispatch(setFilters({ ...filters, startTime: dayjs(selectedDates[0]), endTime: dayjs(selectedDates[1]) }));
    }
  }, [isDatePickerOpen, dispatch, filters, selectedDates]);

  const dispatchFilters = (users, filters) => {
    dispatch(setFilters({ ...filters, users: users }));
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setFilterDebounce = useCallback(debounce(dispatchFilters, 2500), []);

  const onChangeSelectedUsers = (newUsers) => {
    setFilterDebounce(newUsers, filters);
  };

  const onChangeType = (type) => {
    dispatch(setFilters({ ...filters, type: type }));
  };

  /**
   * A simple event that evaluates whether the date rendered is available or not available
   * @event
   * @param {dayjs} dayjsDate a date formatted in a dayjs obj
   * @returns {Boolean} whether or not the date should be disabled
   */
  const onDisabledDate = (dayjsDate) => {
    // Align the date formats. One is UTC, other is dayjs obj
    const givenDate = new Date(dayjsDate);
    // Make the prev date
    const prevDate = new Date();
    prevDate.setDate(prevDate.getDate() - 1);
    // Get the min date available
    const minDate = new Date(minFromDate);
    // Validate that the currentDate is after the minimum date and return

    return givenDate < minDate || givenDate > prevDate;
  }; // onDisabledNextDate()

  return (
    <>
      <Row justify="space-between" gutter={8}>
        <Col span={8} align="left">
          <Row>
            <Label>
              <BlockIconWrapper>
                <AccountCircleOutlined />
              </BlockIconWrapper>
              <Typography.Text>Vælg rådgivere</Typography.Text>
            </Label>
          </Row>
          <Row>
            <AgentPickerTeams loading={fetching.users} onChangeHandler={onChangeSelectedUsers} />
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Label>
              <BlockIconWrapper>
                <BarChartOutlined />
              </BlockIconWrapper>
              <Typography.Text>Vis data for</Typography.Text>
            </Label>
          </Row>
          <Row>
            <UserSelect
              maxTagCount="responsive"
              style={{ width: 250 }}
              defaultValue={''}
              placeholder="Vælg anvendelse"
              onChange={onChangeType}
            >
              <Select.Option value="">Telefonsamtaler</Select.Option>
              <Select.Option value="manuel">Indtale via keywords</Select.Option>
              <Select.Option value="offline">Manuel brug</Select.Option>
            </UserSelect>
          </Row>
        </Col>
        <Col span={8} align="">
          <Row>
            <Label>
              <BlockIconWrapper>
                <InsertInvitation />
              </BlockIconWrapper>
              <Typography.Text>Vælg datointerval</Typography.Text>
            </Label>
          </Row>
          <Row>
            <RangePicker
              value={selectedDates}
              // style={{ minWidth: 230}}
              disabled={fetching.filters || fetching.users}
              disabledDate={onDisabledDate}
              onOpenChange={(open) => setIsDatePickerOpen(open)}
              onChange={(dates) => setSelectedDates(dates)}
              getPopupContainer={(trigger) => trigger.parentNode} //apparently this locks datepicker to parent (to prevent it scrolling out of sight)
            />
            {/** <DatePicker onChange={onChange} disabledDate={onDisabledNextDate} />  */}
          </Row>
        </Col>
      </Row>
      <Row></Row>
    </>
  );
};

const BlockIconWrapper = styled.span`
  svg {
    display: block !important;
  }
`;

const UserSelect = styled(Select)`
  max-width: 300px;
`;

const Label = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.25rem;
`;
export default DashboardMenu;
