import { message } from 'antd';
import axios from 'axios';
import { Config } from '../config/config';
import jwtDecode from 'jwt-decode';

export const SET_AGENT_CONFIG = 'agentConfig/SET';
export const SET_FAV_INSTRUCTIONS = 'agentConfig/SET_FAV_INSTRUCTION';
export const SET_SECION_CHANGE_VIEWED = 'agentConfig/SET_CHANGE_VIEWED';
export const SET_TOPFILE_AS_VIEWED = 'agentConfig/SET_TOPFILE_AS_VIEWED';
export const SET_TOTAL_REVIEW_VIEWED = 'agentConfig/SET_TOTAL_REVIEW_VIEWED';

const getUsername = () => {
  return sessionStorage.token ? jwtDecode(sessionStorage.token)?.username : '';
};

export const getAgentConfig = () => async (dispatch) => {
  try {
    const res = await axios.get(`${Config.conf.backendUrl}/api/v1/agentconfigs/${getUsername()}`);
    dispatch({ type: SET_AGENT_CONFIG, payload: res?.data });
  } catch (error) {
    console.log(error?.message);
  }
};

export const toggleFavInstruction = (currentFavInstructions, workInstructionToToggle) => async (dispatch) => {
  const updatedFavWorkInstructions = [...currentFavInstructions];
  const index = updatedFavWorkInstructions?.indexOf(workInstructionToToggle);
  const isNew = index === -1;
  if (!isNew) {
    updatedFavWorkInstructions.splice(index, 1); // 2nd parameter means remove one item only
  } else {
    updatedFavWorkInstructions.push(workInstructionToToggle);
  }

  const instructionsCouldNotBeUpdated = () => {
    message.error('Favoritter kunne ikke opdateres');
  };

  dispatch({ type: SET_FAV_INSTRUCTIONS, payload: { favInstructions: updatedFavWorkInstructions } });

  try {
    await axios.post(
      `${Config.conf.backendUrl}/api/v1/agentconfigs/${getUsername()}/favorite-instructions${!isNew ? '/remove' : ''}`,
      {
        id: workInstructionToToggle,
      }
    );
  } catch (Error) {
    instructionsCouldNotBeUpdated();
    dispatch({ type: SET_FAV_INSTRUCTIONS, payload: { favInstructions: currentFavInstructions } });
  }
};

export const setSectionAsViewed =
  ({ instructionId, sectionName, section }) =>
  async (dispatch) => {
    dispatch({
      type: SET_SECION_CHANGE_VIEWED,
      payload: {
        instructionId,
        sectionName,
      },
    });

    try {
      await axios.post(
        `${Config.conf.backendUrl}/api/v1/agentconfigs/${getUsername()}/unseen-document-changes/remove`,
        {
          ...section,
        }
      );
    } catch (error) {
      console.log(error.message);
    }
  };

export const setDocumentTotalRevisionAsViewed =
  ({ instructionId }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SET_TOTAL_REVIEW_VIEWED,
        payload: {
          instructionId,
        },
      });
      await axios.delete(
        `${Config.conf.backendUrl}/api/v1/agentconfigs/${getUsername()}/unseen-full-document/${instructionId}`
      );
    } catch (error) {
      console.log(error.message);
    }
  };

export const setTopfileAsViewed =
  ({ topfileId }) =>
  async (dispatch) => {
    dispatch({
      type: SET_TOPFILE_AS_VIEWED,
      payload: {
        topfileId,
      },
    });

    try {
      await axios.post(`${Config.conf.backendUrl}/api/v1/agentconfigs/${getUsername()}/unseen-topfiles/remove`, {
        id: topfileId,
      });
    } catch (error) {
      console.log(error.message);
    }
  };
