import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import 'dayjs/locale/da';
import styled from 'styled-components';
import { Empty, Button, Popconfirm, message } from 'antd';
import { RightOutlined, DownOutlined } from '@ant-design/icons';

import { StyledTable } from '../../sharedStyledComponents/dataGridStyles';
import { inlineHeader } from '../../sharedStyledComponents/dataGridStyles';
import { publishDocumentChanges, publishFullDocument, rejectDocumentChanges } from '../../../_actions/revision.actions';

dayjs.locale('da');

const RevisionTable = ({ documentChanges, loading }) => {
  const dispatch = useDispatch();

  const [displayedRows, setDisplayedRows] = useState([]);
  const [childRowChecked, setChildRowChecked] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    const instructionGroups = documentChanges.reduce((groups, change) => {
      if (groups[change.docId]) {
        groups[change.docId].changes.push(change);
      } else {
        groups[change.docId] = {
          docId: change.docId,
          docName: change.docName,
          changes: [change],
        };
      }

      return groups;
    }, []);

    const tableRows = Object.values(instructionGroups).reduce((rows, group) => {
      if (group.changes.length === 1) {
        rows.push({
          ...group.changes[0],
          groupRow: false,
        });
      } else {
        rows.push({
          _id: group.docId,
          docId: group.docId,
          docName: group.docName,
          sectionName: `${group.changes.length} sektioner`,
          timestamp: group.changes.reduce((max, change) => {
            return Math.max(max, change.timestamp);
          }, 0),
          groupRow: true,
          expanded: false,
          children: group.changes.map((change) => ({
            ...change,
            groupRow: false,
          })),
        });
      }

      return rows;
    }, []);

    setDisplayedRows(tableRows);
  }, [documentChanges]);

  const publishChange = (e, record) => {
    e.stopPropagation();

    //Publish full document
    if (record.fullPublish) {
      // publish full document with document of grouprow
      dispatch(publishFullDocument(record.docId));
    } else {
      // publish one or all document sections based on if it is a groupRow
      if (record.groupRow) {
        if (childRowChecked) {
          const recordRows = selectedRows.filter((row) => row.docId === record.docId);
          if (recordRows.length === 0) {
            message.error('Du har ikke valgt nogen sektioner i dette dokument');
            return;
          } else {
            dispatch(publishDocumentChanges(recordRows.map((section) => section._id)));
          }
        } else {
          // publish all children of the groupRow
          dispatch(publishDocumentChanges(record.children.map((section) => section._id)));
        }
      } else {
        // publish the row as it is the only section in document
        dispatch(publishDocumentChanges([record._id]));
      }
    }

    setChildRowChecked(false);
  };

  const rejectChange = (e, record) => {
    e.stopPropagation();
    // reject one or all document sections based on if it is a groupRow
    if (record.groupRow) {
      if (childRowChecked) {
        const recordRows = selectedRows.filter((row) => row.docId === record.docId);
        if (recordRows.length === 0) {
          message.error('Du har ikke valgt nogen sektioner i dette dokument');
          return;
        } else {
          dispatch(rejectDocumentChanges(recordRows.map((section) => section._id)));
        }
      } else {
        // reject all children of the groupRow
        dispatch(rejectDocumentChanges(record.children.map((section) => section._id)));
      }
    } else {
      // reject the row as it is the only section in document
      dispatch(rejectDocumentChanges([record._id]));
    }
  };

  const onCheckedFullRow = (e, record) => {
    e.stopPropagation();
    record.fullPublish = e.target.checked;
    setDisplayedRows(
      displayedRows?.map((row) => {
        // if the record matches a row id it is a group parent row and we set it to a full publish
        if (row._id === record._id) {
          row.fullPublish = e.target.checked;
          row.children = row.children.map((groupRowItem) => {
            return { ...groupRowItem, isDisabled: e.target.checked };
          });
        } else {
          // disable all of the other rows when one is checkmarked so we only work in that row
          row.isDisabled = e.target.checked;
          row.children = row?.children?.map((groupRowItem) => {
            return { ...groupRowItem, isDisabled: e.target.checked };
          });
        }
        return row;
      })
    );
  };

  const columns = [
    {
      dataIndex: 'docName',
      title: inlineHeader('Arbejdsinstruks'),
      sorter: (a, b) => {
        if (a.docName === b.docName) {
          return a.sectionName.localeCompare(b.sectionName);
        }
        return a.docName.localeCompare(b.docName);
      },
      width: '30%',
      ellipsis: true,
    },
    {
      dataIndex: 'sectionName',
      title: inlineHeader('Sektion'),
      sorter: (a, b) =>
        a.sectionName.localeCompare(b.sectionName, undefined, {
          numeric: true,
          sensitivity: 'base',
        }),
      defaultSortOrder: 'ascend',
      width: '20%',
      ellipsis: true,
    },
    {
      dataIndex: 'timestamp',
      title: inlineHeader('Ændret den'),
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.timestamp - b.timestamp,
      width: '15%',
      render: (_, record) => {
        const timestamp = dayjs(record.timestamp);
        return <>{`${timestamp.date()}/${timestamp.month() + 1}-${timestamp.year()} kl ${timestamp.format('LT')}`}</>;
      },
    },
    ChangeTable.SELECTION_COLUMN,
    {
      title: inlineHeader('Action'),
      width: '20%',
      render: (_, record) => {
        return (
          <>
            <Popconfirm
              title="Er du sikker på du vil afvise?"
              onConfirm={(e) => rejectChange(e, record)}
              onCancel={(e) => e.stopPropagation()}
              okText="Ja"
              cancelText="Nej"
            >
              <RejectButton type="secondary" disabled={record?.isDisabled} onClick={(e) => e.stopPropagation()}>
                {record.groupRow
                  ? record.fullPublish
                    ? 'Afvis hele'
                    : childRowChecked
                    ? 'Afvis valgte'
                    : 'Afvis alle'
                  : 'Afvis'}
              </RejectButton>
            </Popconfirm>

            <PublishButton type="primary" disabled={record?.isDisabled} onClick={(e) => publishChange(e, record)}>
              {record.groupRow
                ? record.fullPublish
                  ? 'Publisér hele'
                  : childRowChecked
                  ? 'Publisér valgte'
                  : 'Publisér alle'
                : 'Publisér'}
            </PublishButton>
          </>
        );
      },
    },
  ];

  const _onExpand = (expanded, record) => {
    setDisplayedRows(
      displayedRows.map((row) => ({
        ...row,
        expanded: row._id === record._id && record.groupRow ? expanded : row.expanded,
      }))
    );
  };

  return (
    <>
      <ChangeTable
        columns={columns}
        locale={{
          triggerDesc: 'Klik for at sortere faldende',
          triggerAsc: 'Klik for at sortere stigende',
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Ingen ændringer" />,
        }}
        sortDirections={['ascend', 'descend']}
        pagination={false}
        dataSource={displayedRows}
        scroll={{ y: 700 }}
        rowKey={'_id'}
        loading={loading}
        rowSelection={{
          rowSelectionIndex: 3,
          columnTitle: inlineHeader('Publicéring'),
          fixed: false,
          renderCell: (_, record, __, originNode) => {
            if (record.groupRow) {
              return (
                <>
                  {{
                    ...originNode,
                    props: {
                      ...originNode.props,
                      disabled: childRowChecked || record.isDisabled,
                      onClick: (e) => onCheckedFullRow(e, record),
                    },
                  }}
                  {`  Hele instruksen`}
                </>
              );
            }
            return { ...originNode, props: { ...originNode.props, disabled: record.isDisabled } };
          },
          onSelect: (record, __, selectedRows) => {
            if (!record.groupRow && selectedRows.length > 1) {
              setChildRowChecked(true);
            }
            if (selectedRows.length === 0) {
              setChildRowChecked(false);
            }
            setSelectedRows(selectedRows);
          },
        }}
        rowClassName={(record) => `${record.groupRow && record.expanded ? 'group-row-expanded' : ''}`}
        expandable={{
          rowExpandable: (record) => record.groupRow,
          expandRowByClick: (record) => record.groupRow,
          onExpand: (expanded, record) => _onExpand(expanded, record),
          indentSize: 0,
          expandIcon: ({ expanded, record }) => {
            if (record.groupRow) {
              return (
                <ExpandIconWrapper>
                  {expanded ? (
                    <DownOutlined onClick={(e) => _onExpand(record, e)} />
                  ) : (
                    <RightOutlined onClick={(e) => _onExpand(record, e)} />
                  )}
                </ExpandIconWrapper>
              );
            } else {
              return <RowIndent />;
            }
          },
        }}
      />
    </>
  );
};

export default RevisionTable;

const ChangeTable = styled(StyledTable)`
  .ant-table-tbody > tr {
    &.ant-table-row-level-0 td {
      background-color: #fff;
    }

    &.ant-table-row-level-1 td {
      background-color: rgba(0, 0, 0, 0.04);
    }

    &.group-row-expanded td {
      border-bottom: 1px solid rgba(0, 0, 0, 0.45);
    }
  }
`;

const ExpandIconWrapper = styled.span`
  padding-right: 8px;

  svg {
    font-size: 12px;
  }
`;

const RowIndent = styled.span`
  padding-left: 21px;
`;

const PublishButton = styled(Button)`
  background-color: #34495e;
  border-color: #34495e;
  border-radius: 0.5rem;
  &.ant-btn-round {
    border-radius: 10px;
  }
  &:hover,
  &:focus {
    background-color: #34495e;
    border-color: #34495e;
    span {
      text-decoration: underline;
    }
  }
`;

const RejectButton = styled(Button)`
  border-radius: 0.5rem;
  background-color: #ffffff;
  margin-right: 1rem;
  &.ant-btn-round {
    border-radius: 10px;
  }
  &:hover,
  &:focus {
    span {
      text-decoration: underline;
    }
  }
`;
