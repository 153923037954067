import { useState, useEffect } from 'react';
import { Row, Col, Typography, Tooltip, Button, Empty, Spin, Space } from 'antd';
import Cancel from '@material-ui/icons/Cancel';
import Fade from 'react-reveal/Fade';
import withReveal from 'react-reveal/withReveal';
import styled from 'styled-components';
import { maxBy, orderBy } from 'lodash';
import WorkInstructionTable from './WorkInstruction.table';
import { screen } from '../../sharedStyledComponents/Responsive.styles';
import { isEmpty } from '../../../utils/helpers';
import { LoadingOutlined, DownloadOutlined } from '@ant-design/icons';
import { exportExcel } from '../../../utils/export-data';

const COLORS = ['#219653', '#2F80ED', '#9B51E0', '#F2994A'];
// A margin for Bars with little to zero margin value (We need to subtract that from total height)
const ZeroMargin = 50;

const BarChartComponent = ({ chartData, period, fetching, moduleTitle, moduleSubtitle }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSelected, selectBar] = useState(false);
  const [csvData, setCSVData] = useState([]);

  const height = 500 + ZeroMargin;
  // const total = sum(chartData.map(category => sum(category.data.map(dataPoint => dataPoint.value)))) || 100;
  const highestNo =
    maxBy(chartData.map(category => (category.data.length > 0 ? maxBy(category.data, 'value').value : 0))) || 100;

  const highestHundred = Math.ceil(highestNo / 100) * 100;

  const axises = [...Array(highestHundred / 100)];

  const onToggleExpand = () => {
    setIsExpanded(isExpanded ? false : true);
  };

  const onSelectBar = barItem => event => {
    selectBar(isSelected.id === barItem.id ? false : barItem);
  };

  useEffect(() => {
    if (!isEmpty(chartData)) {
      let csv = [];
      chartData.forEach(category => {
        const data = category.data.map(dataPoint => ({ category: category.name, ...dataPoint }));
        csv.push(...data);
      });

      setCSVData(csv);
    }
  }, [chartData]);

  const onDownloadExcel = () => {
    exportExcel(csvData, `${period}-udtræk`);
  };

  return (
    <>
      <ModuleTitle level={2}>
        <span>
          <strong>{moduleTitle}</strong> {moduleSubtitle}
        </span>

        <CSVButton type='primary' icon={<DownloadOutlined />} disabled={isEmpty(csvData)} onClick={onDownloadExcel}>
          Download excel
        </CSVButton>
      </ModuleTitle>
      <Row>
        <Col flex={1}>
          {isEmpty(chartData) && (
            <Overlay>
              <NoData
                description={
                  fetching ? (
                    <Space>
                      <Typography.Text>Henter Data</Typography.Text>
                      <Spin indicator={<LoadingOutlined />} />
                    </Space>
                  ) : (
                      'Data er midlertidigt ikke tilgængeligt'
                    )
                }
              />
            </Overlay>
          )}
          <Chart $blur={isEmpty(chartData)}>
            <CategoryWrapper height={height}>
              {chartData.map((category, i) => {
                if (isExpanded && i > 0) return null;
                const sortedData = orderBy(category.data, 'value', 'desc');
                return (
                  <Category key={i} className={isExpanded && 'category-expanded'}>
                    {sortedData.map((item, ii) => {
                      return (
                        <BarWrapper key={ii}>
                          <Tooltip title={`${item.name} (${item.value})`}>
                            <Bar
                              $i={ii}
                              $expanded={isExpanded}
                              color={COLORS[i]}
                              value={item.value}
                              $item={item}
                              selected={isSelected}
                              $isSelected={isSelected.id === item.id}
                              height={height}
                              // total={total}
                              $highestNo={highestHundred}
                              onClick={onSelectBar(item)}
                            >
                              <Label>
                                <span>{item.name}</span>
                              </Label>
                            </Bar>
                          </Tooltip>
                        </BarWrapper>
                      );
                    })}
                    <CategoryLabel>
                      <CategoryLabelColor color={COLORS[i]} />
                      {category.name}
                    </CategoryLabel>
                    {!isExpanded && sortedData.length > 9 && (
                      <ExpandButton onClick={onToggleExpand} type='text'>
                        Vis mere
                      </ExpandButton>
                    )}
                    {isExpanded && (
                      <CollapseButton onClick={onToggleExpand} type='text' icon={<Cancel />}>
                        Tilbage til oversigt
                      </CollapseButton>
                    )}
                  </Category>
                );
              })}
              <ChartLines>
                {axises.map((axis, j) => {
                  const skip = Math.ceil(axises.length / 10);
                  if (j % skip === 0) return <ChartLine $legend={(axises.length - j) * 100} key={j} />;
                  return null;
                })}
                <ChartLine $legend={0} />
              </ChartLines>
            </CategoryWrapper>
          </Chart>
          {isSelected && <WorkInstructionTable period={period} workInstructionMeta={isSelected} />}
        </Col>
      </Row>
    </>
  );
};

const chartLineLabelDistance = 40;

const CSVButton = styled(Button)`
  background-color: #34495e;
  border-color: #34495e;
  border-radius: 0.5rem;

  &.ant-btn-round {
    border-radius: 10px;
  }
  &:hover,
  &:focus {
    background-color: #34495e !important;
    border-color: #34495e !important;
    span {
      text-decoration: underline;
    }
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

const NoData = styled(Empty)`
  padding: 2rem;
  box-shadow: 2px 2px 5px #aeaeae;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #aeaeae;
`;

const ExpandButton = styled(Button)`
  transform: rotate(-90deg) translate(30px, -30px);
  span {
    text-decoration: underline;
  }
`;

const CollapseButton = styled(Button)`
  position: fixed;
  top: 5px;
  left: 30px;
  display: flex;
  align-items: center;
  span {
    text-decoration: underline;
    margin: 0 5px;
  }
`;

const ModuleTitle = styled(Typography.Title)`
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
  &.ant-typography {
    font-weight: 400 !important;
    margin: 2rem 0;
  }
  strong {
    font-weight: 600;
  }
`;

const CategoryLabelColor = styled.span`
  background-color: ${({ color }) => color};
  border-radius: 50%;
  padding: 0.25rem;
  margin-right: 1rem;

  @media ${screen.XL} {
    padding: 0.75rem;
  }
`;

const ChartLine = styled.div`
  border-bottom: 1px solid #ebedf0;
  position: relative;

  &::after {
    content: '${props => props.$legend}';
    text-align: right;
    position: absolute;
    right: 0;
    bottom: 0;
    display: inline-block;
    width: ${chartLineLabelDistance}px;
  }
`;

const ChartLines = styled.div`
  display: grid;
  align-content: space-between;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
`;

const CategoryLabel = styled.div`
  position: absolute;
  top: calc(100% + 15px);
  padding: 0.75rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
`;

const CategoryWrapper = styled.div`
  z-index: 1;
  display: flex;
  align-items: flex-end;
  height: ${({ height }) => height}px;
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-right: ${chartLineLabelDistance}px;
  ::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar-thumb:horizontal {
    border-radius: 10px;
    background-color: #2f4960;
  }
`;

const Chart = styled.div`
  width: 100%;
  margin-bottom: 75px;
  filter: ${props => (props.$blur ? 'blur(.25rem)' : 'blur(0)')};
`;

const Category = styled.div`
  margin: auto;
  display: flex;
  align-items: flex-end;
  height: 100%;
  position: relative;
  z-index: 2;
  transition: 1s transform;
`;

const BarWrapper = styled.div`
  overflow: hidden;
`;
const Bar = withReveal(
  styled.div`
    background: ${props => (props.$isSelected ? '#34495E' : props.$i < 7 ? props.color : '#DEDEDE')};
    color: ${props => (props.$i < 7 ? '#F1f1f1' : '#333')};
    opacity: ${props => (props.$isSelected ? 1 : props.$i < 7 ? `calc(1 - 0.${props.$i})` : 1)} !important;
    display: flex;
    align-items: flex-start;
    padding: 7.5px;
    height: ${props => ZeroMargin + (props.value / props.$highestNo) * 500}px;
    transition: 0.25s opacity ease;
    display: ${props => (props.$i < 7 || props.$expanded ? 'block' : 'none')};

    &:hover {
      cursor: pointer;
      opacity: ${props => (props.$i < 7 ? `calc(1 - 0.${props.$i + 1})` : 0.75)};
      transition: 0.25s opacity ease;
    }
  `,
  <Fade bottom cascade />
);

const Label = styled.div`
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  height: 100%;
  display: inline-flex;
  justify-content: flex-end;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.6rem;

    @media ${screen.XL} {
      font-size: initial;
    }
  }
`;

export default BarChartComponent;
