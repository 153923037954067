import { Config } from '../config/config';
import axios from 'axios';

export const GET_TEAMS = 'revison/GET_TEAMS';
export const TEAMS_LOADING = 'revison/TEAMS_LOADING';
export const ERROR = 'revison/ERROR';

export const getTeams = (type = 'regular') => {
  return async (dispatch) => {
    dispatch({ type: TEAMS_LOADING, payload: { loading: true } });
    try {
      const res = await axios.get(`${Config.conf.backendUrl}/api/v1/admin/teams?type=${type}`);
      dispatch({ type: GET_TEAMS, payload: res.data });
    } catch (e) {
      dispatch({ type: ERROR, payload: { error: e } });
    }
  };
};

export const addTeam = (team) => {
  return async (dispatch) => {
    dispatch({ type: TEAMS_LOADING, payload: { loading: true } });
    try {
      await axios.post(`${Config.conf.backendUrl}/api/v1/admin/teams`, { name: team.name });
      dispatch(getTeams());
    } catch (e) {
      dispatch({ type: ERROR, payload: { error: e } });
    }
  };
};

export const deleteTeam = (teamId) => {
  return async (dispatch) => {
    dispatch({ type: TEAMS_LOADING, payload: { loading: true } });
    try {
      await axios.delete(`${Config.conf.backendUrl}/api/v1/admin/teams/${teamId}`);
      dispatch(getTeams());
    } catch (e) {
      dispatch({ type: ERROR, payload: { error: e } });
    }
  };
};

export const updateTeam = (teamData) => {
  return async (dispatch) => {
    dispatch({ type: TEAMS_LOADING, payload: { loading: true } });
    try {
      await axios.put(`${Config.conf.backendUrl}/api/v1/admin/teams/${teamData._id}`, { ...teamData });
      dispatch(getTeams());
    } catch (e) {
      dispatch({ type: ERROR, payload: { error: e } });
    }
  };
};
