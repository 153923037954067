import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Popconfirm, Form, Button, message, Space, Row, Col } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';

import {
  StyledAddButton,
  Fade,
  SettingsButton,
  StyledTable,
  TableSize,
  MinimizeButton,
  TableWrapper,
} from '../../sharedStyledComponents/dataGridStyles';
import { colorTheme } from '../../sharedStyledComponents/generalStyles';
import CustomModal from '../../GlobalComponents/custom-modal/custom-modal.component';
import { EditableCell } from './components/EditableCell';
import { useDispatch, useSelector } from 'react-redux';
import TeamConfig from './TeamConfig.component';
import { getAgents } from '../../../_actions/agents.actions';
import { addTeam, deleteTeam, updateTeam } from '../../../_actions/teams.actions';

const TeamsTable = (props) => {
  const { columns, loading, clickableRow, teams } = props;
  const { agents } = useSelector((stores) => stores.agents);
  const dispatch = useDispatch();

  const table = useRef(null);
  const cancelInputButton = useRef(null);
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [submitted, setSubmitted] = useState(true);
  const [displayTeamModal, setDisplayTeamModal] = useState(false);
  const [data, setData] = useState([]);
  const [teamModalData, setTeamModalData] = useState(null);

  useEffect(() => {
    document.addEventListener('click', onEditClickAway, false);
    document.addEventListener('keyup', onEditEscapeKey, false);
    return () => {
      document.removeEventListener('click', onEditClickAway, false);
      document.removeEventListener('keyup', onEditEscapeKey, false);
    };
  });

  useEffect(() => {
    setData(teams);
  }, [teams]);

  useEffect(() => {
    dispatch(getAgents());
  }, []);
  /**
   * Check if it is the currently currently edited row
   * @param {row} record
   */
  const isEditing = (record) => record.key === editingKey;

  /**
   * Column with actions (edit/save/delete) functions
   */
  const action = {
    title: 'Rediger',
    dataIndex: 'operation',
    render: (_, record) => {
      const editable = isEditing(record);
      return editable ? (
        <Space direction="vertical">
          <Button size="small" onClick={() => save(record.key)}>
            Gem
          </Button>

          <Popconfirm
            title={
              <>
                Er du sikker på at du vil slette dette team?
                <br /> (du kan ikke fortryde sletning)
              </>
            }
            onConfirm={onDelete}
          >
            <Button danger size="small">
              Slet
            </Button>
          </Popconfirm>

          {/* Hidden button for saving edit or regretting save */}
          <Popconfirm
            placement="leftBottom"
            title="Vil du gemme ændringerne?"
            cancelText="Nej"
            okText="Ja"
            onConfirm={() => save(record.key)}
            onCancel={cancel}
          >
            <Button
              ref={cancelInputButton}
              style={{ visibility: 'hidden', padding: 0, margin: 0, height: 0, width: 0 }}
              size="small"
            >
              Fortryd
            </Button>
          </Popconfirm>
        </Space>
      ) : (
        <EditButton
          icon={<CreateOutlinedIcon style={{ fontSize: '1.7rem' }} />}
          type="text"
          onClick={() => edit(record)}
        />
      );
    },
  };

  const deleteTempEntry = (key) => {
    setData(data.filter((entry) => entry.key !== key));
  };

  /**
   * Cancel edit
   * if it is a new entry with empty fields, remove it from redux
   */
  const cancel = () => {
    const formValues = form.getFieldValue(true);
    if (!formValues || Object.keys(formValues).length === 0) {
      deleteTempEntry('new');
    }
    message.warning('Ændringer ikke gemt');
    setEditingKey('');
    setSubmitted(true);
  };

  /**
   * Set selected row to edit state changing internal component to <Input/>
   * @param {row} record
   */
  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
    setSubmitted(false);
  };

  /**
   * Get the row key from form, and update the object in the main list
   * if it is a new key(does not have a key value) a submit fuction will be envoked instead
   * @param key Row key id
   */
  const save = async (key) => {
    // dont do a request if fields are untouched
    if (!form.isFieldsTouched()) {
      setEditingKey('');
      setSubmitted(true);
      return;
    }
    console.log(key);
    try {
      const row = await form.validateFields();
      const index = data.findIndex((item) => key === item.key);
      console.log('row', row);

      if (index > -1 && key !== 'new') {
        // UPDATE EXISTING KEYS
        console.log('update');
        const item = JSON.parse(JSON.stringify(data[index]));
        dispatch(updateTeam({ ...item, ...row }));
        setEditingKey('');
        setSubmitted(true);
      } else {
        //ADD NEW KEY
        setEditingKey('');
        setSubmitted(true);
        const item = JSON.parse(JSON.stringify(data[index]));
        dispatch(addTeam({ ...item, ...row }));
      }
      form.resetFields();
    } catch (errInfo) {
      console.log(errInfo);
      message.error('Validering fejlede, ændringerne blev ikke gemt.');
    }
    message.success('Ændringer er gemt');
  };

  /**
   * Handles delete of instruction
   */
  const onDelete = () => {
    dispatch(deleteTeam(editingKey));
    setEditingKey('');
    setSubmitted(true);
  };

  /**
   * Minimizing the table will scroll up to top of the table
   */
  const onMinimize = () => {
    setExpanded(!expanded);
    table.current.scrollIntoView(true, { behavior: 'smooth' });
  };

  /**
   * Set extra variables for column object, to be used in editing component
   */
  const mergedColumns = columns?.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.type,
        dataIndex: col.dataIndex,
        placeholder: col.placeholder,
        required: col.required,
        editing: isEditing(record),
      }),
    };
  });

  // Add action column
  mergedColumns?.push(action);

  const addTempEntry = (cleanEntry) => {
    setData([...data, cleanEntry]);
  };

  /**
   * On adding new instruction, an object with all column items
   * is created.
   */
  const onNewEntry = () => {
    if (!submitted) {
      message.warning(`Du skal først udfylde manglende felter.`);
      return;
    }
    // recreate the object with empty values
    const keys = columns.map((col) => col.dataIndex);
    // Bare minimum object
    const cleanEntry = { key: 'new' };
    keys.forEach((keyArray) => {
      if (keyArray) cleanEntry[keyArray[1]] = '';
    });
    addTempEntry(cleanEntry);
    form.resetFields();
    setEditingKey('new');
    setSubmitted(false);
  };

  /**
   * If user is editing and clicks on anything else but the input promt
   * if they want to cancel their input
   * @param {Event} e
   */
  const onEditClickAway = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // for click outside component (this triggers clicks in all components, thus check if 'current' exists)
    if (
      editingKey &&
      e.target.tagName !== 'INPUT' &&
      e.target.tagName !== 'SPAN' &&
      e.target.tagName !== 'TEXTAREA' &&
      e.target.tagName !== 'BUTTON' &&
      e.target.tagName !== 'PATH' &&
      e.target.tagName !== 'SVG' &&
      e.target.className !== 'ant-switch-handle' &&
      e.target.className !== 'ant-form-item-control-input'
    ) {
      if (!form.isFieldsTouched()) {
        cancel();
        return;
      }
      if (cancelInputButton.current) cancelInputButton.current.click();
    }
  };

  /**
   * Handles escape button press
   * @param {Event} e
   */
  const onEditEscapeKey = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (editingKey && e.key === 'Escape' && cancelInputButton) {
      if (!form.isFieldsTouched()) {
        cancel();
        return;
      }
      cancelInputButton.current.click();
    }
  };

  const onRowClick = (event, record) => {
    event.preventDefault();
    event.stopPropagation();
    if (
      editingKey === '' &&
      event.target.tagName !== 'SPAN' &&
      event.target.tagName !== 'svg' &&
      event.target.tagName !== 'path' &&
      event.target.className !== 'ant-select-selection-overflow' &&
      event.target.className !== 'ant-select-selection-item' &&
      event.target.className !== 'ant-select-selection-item-remove' &&
      event.target.className !== 'ant-tooltip-inner'
    ) {
      setTeamModalData(record);
      setDisplayTeamModal(true);
    }
  };

  const onCloseModal = () => {
    setTeamModalData(null);
    setDisplayTeamModal(false);
  };

  return (
    <>
      <CustomModal
        visible={displayTeamModal}
        title={<>Teams</>}
        width={'40vw'}
        summary={'Her kan du få overblik over hvilke rådgivere der er del af teamet.'}
        close={onCloseModal}
      >
        <TeamConfig data={teamModalData} agents={agents || []} />
      </CustomModal>
      <Container>
        <TableSize $expanded={expanded} $count={data?.length || 0}>
          <TableWrapper ref={table}>
            <Form form={form} component={false}>
              <StyledTable
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                sortDirections={['ascend', 'descend', 'ascend']}
                pagination={false}
                dataSource={data}
                columns={mergedColumns}
                loading={loading}
                clickableRow={clickableRow}
                rowClassName="editable-row"
                onRow={(record) => {
                  return {
                    onClick: (e) => onRowClick(e, record),
                  };
                }}
                footer={() => {
                  return (
                    data && (
                      <SettingsButton
                        icon={<PlusCircleOutlined style={{ fontSize: '1.4rem' }} />}
                        shape="round"
                        onClick={onNewEntry}
                      >
                        Tilføj nyt team
                      </SettingsButton>
                    )
                  );
                }}
              />
            </Form>
            <Fade $visible={data?.length > 8 && !expanded} />
          </TableWrapper>
        </TableSize>
        {data.length > 8 && (
          <StyledAddButton
            icon={<PlusCircleOutlined style={{ fontSize: '1.4rem' }} />}
            displaybutton={(data.length > 8 && !expanded).toString()}
            onClick={() => setExpanded(!expanded)}
          >
            Se alle teams
          </StyledAddButton>
        )}

        {data.length > 8 && expanded && (
          <MinimizeButton
            icon={<MinusCircleOutlined style={{ lineHeight: 0, fontSize: '1.2rem' }} />}
            onClick={onMinimize}
          >
            Vis færre
          </MinimizeButton>
        )}
      </Container>
    </>
  );
};

export default TeamsTable;

const Container = styled.div`
  flex-wrap: wrap;
  flex-direction: column;
  margin: 1rem 0rem;
  padding-bottom: 2rem;
`;

const EditButton = styled(Button)`
  background: none;
  color: ${colorTheme};
  &:hover {
    background: none !important;
    color: ${colorTheme};
    &:hover {
      background: none;
      border-color: none;
    }
    &:focus {
      background: none;
      border-color: none;
    }
  }
`;
