import { Cascader } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAgents } from '../../_actions/agents.actions';
import { getTeams } from '../../_actions/teams.actions';
const { SHOW_CHILD } = Cascader;

const AgentPickerTeams = ({ onChangeHandler, loadingAgents }) => {
  const dispatch = useDispatch();
  const [selectionList, setSelectionList] = useState(undefined);
  const { agents } = useSelector((store) => store.agents);
  const { teams, loading: loadingTeams } = useSelector((store) => store.teams);

  useEffect(() => {
    dispatch(getAgents());
    dispatch(getTeams());
  }, []);

  const onChange = (_, selectedOptions) => {
    const agents = [];
    selectedOptions.forEach((a) => {
      if (a.length === 1) {
        // if length is 1 then the entire team is selected;
        agents.push(...a[0].children.map((m) => m.value));
      } else if (a.length === 2) {
        // if length is 2 then there is only part of members selected from team
        agents.push(a[1].value);
      }
    });
    const uniqueAgents = [...new Set(agents)];
    onChangeHandler(uniqueAgents);
  };

  useEffect(() => {
    const agentsWithoutTeamsConfigs = agents.filter((a) => {
      return !teams.some((t) => t.members.find((member) => member.name === a._id));
    });
    const agentsWithoutTeamSelectionData = [
      {
        label: 'Rådgivere uden team',
        value: 'Rådgivere uden team',
        children: agentsWithoutTeamsConfigs.map((tm) => {
          return { label: tm._id, value: tm._id };
        }),
      },
    ];
    const agentInTeams = teams?.map((t) => {
      return {
        label: t.name,
        value: t.name,
        children: t.members.map((tm) => {
          return { label: tm.name, value: tm.name };
        }),
      };
    });
    const teamlessAgents =
      agentsWithoutTeamSelectionData[0]?.children?.length > 0 ? agentsWithoutTeamSelectionData : [];
    setSelectionList([...agentInTeams, ...teamlessAgents]);
  }, [teams]);

  return (
    <Cascader
      options={selectionList}
      showSearch
      onChange={onChange}
      loading={loadingAgents || loadingTeams}
      multiple
      maxTagCount="responsive"
      showcheckedstrategy={SHOW_CHILD}
    />
  );
};

export default AgentPickerTeams;
