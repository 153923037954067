import { LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS, CLEAR_TOKEN, LOGOUT_SHOW } from '../_actions/auth.actions';
import jwtDecode from 'jwt-decode';

const initialState = {
  username: sessionStorage.token ? jwtDecode(sessionStorage.token).username : '',
  loading: false,
  errorMsg: '',
  token: sessionStorage.token || '',
  showLogout: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return {
        ...state,
        username: action.username,
        loading: true,
        errorMsg: '',
        token: '',
      };
    }

    case LOGIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        token: action.token,
      };
    }

    case LOGIN_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMsg: action.errorMsg,
      };
    }
    case CLEAR_TOKEN: {
      return {
        ...state,
        loading: false,
        errorMsg: '',
        token: '',
        showLogout: false,
      };
    }
    case LOGOUT_SHOW: {
      return {
        ...state,
        showLogout: action.showState,
      };
    }
    default:
      return state;
  }
};

export default reducer;
