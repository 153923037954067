import React from 'react';
import { useRef } from 'react';
import { Input, InputNumber, Form, Select, Switch } from 'antd';
import { CloseOutlined, RobotOutlined } from '@ant-design/icons';

export const EditableCell = ({
  editing,
  dataIndex,
  placeholder,
  required,
  inputType,
  record,
  index,
  children,
  onChangeHandler,
  ...restProps
}) => {
  const ref = useRef(null);

  const onChange = (val) => {
    if (ref && ref.current) {
      ref.current.focus(); // set focus back on select field after adding a keyword
    }

    if (onChangeHandler) {
      onChangeHandler(val);
    }
  };

  let inputNode = undefined;
  switch (inputType) {
    case 'numeric':
      inputNode = <InputNumber />;
      break;
    case 'extrapolate':
      inputNode = (
        <Select
          ref={ref}
          mode="tags"
          maxTagCount="responsive"
          onChange={onChange}
          tokenSeparators={[',']}
          open={false}
          placeholder={placeholder}
          value={record && record.extrapolate ? record.extrapolate : []}
        >
          {record.extrapolate.map((entry, i) => (
            <Select.Option key={entry + i}>{entry}</Select.Option>
          ))}
        </Select>
      );
      break;
    case 'textarea':
      inputNode = <Input.TextArea autoSize={{ minRows: 1, maxRows: 5 }} placeholder={placeholder} />;
      break;
    case 'switch':
      inputNode = <Switch checkedChildren={<RobotOutlined />} unCheckedChildren={<CloseOutlined />} disabled={false} />;
      break;
    default:
      inputNode = <Input placeholder={placeholder} />;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={inputType === 'extrapolate' ? 'extrapolate' : dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: required,
              message: `Indtast ${placeholder}!`,
            },
          ]}
          // Handle switch as it has other value prop name
          {...(inputType === 'switch' && { valuePropName: 'checked' })}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
