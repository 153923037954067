import { Tooltip, Switch } from 'antd';
import { CloseOutlined, RobotOutlined } from '@ant-design/icons';
import { Title, inlineHeader, multiLineHeader } from '../../../sharedStyledComponents/dataGridStyles';

const getKeywordsCountFirstLevel = (rowData) => rowData.doc?.level1kw?.length;
const getKeywordsCountSecondLevel = (rowData) => rowData.doc?.level2kw?.length;

export const instructionColumns = [
  {
    dataIndex: ['doc', 'displayName'],
    title: inlineHeader('Display navn'),
    placeholder: 'Display navn',
    required: true,
    sorter: (cellParamsA, cellParamsB) =>
      (cellParamsA.doc?.displayName || 'zzz').localeCompare(cellParamsB.doc?.displayName || 'zzz', 'da'),
    defaultSortOrder: 'ascend',
    editable: true,
    width: '30%',
  },
  {
    dataIndex: ['doc', '_id'],
    title: inlineHeader('ID'),
    placeholder: 'Id',
    required: true,
    sorter: (cellParamsA, cellParamsB) =>
      (cellParamsA.doc?.displayName || 'zzz').localeCompare(cellParamsB.doc?.displayName || 'zzz', 'da'),
    defaultSortOrder: 'ascend',
    editable: false,
    ellipsis: {
      showTitle: false,
    },
    className: 'tooltip-td',
    width: '15%',
    render: (content) => {
      return (
        <Tooltip
          placement="topLeft"
          title={content}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
          }}
        >
          {content}
        </Tooltip>
      );
    },
  },
  {
    dataIndex: ['doc', 'fileName'],
    title: inlineHeader('URL'),
    placeholder: 'Filnavn',
    type: 'textarea',
    required: true,
    sorter: (cellParamsA, cellParamsB) =>
      (cellParamsA.doc?.fileName || 'zzz').localeCompare(cellParamsB.doc?.fileName || 'zzz', 'da'),
    editable: true,
    ellipsis: {
      showTitle: false,
    },
    width: '15%',
    className: 'tooltip-td',
    render: (content) => {
      return (
        <Tooltip
          placement="topLeft"
          title={content}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
          }}
        >
          {content}
        </Tooltip>
      );
    },
  },
  {
    dataIndex: ['doc', 'Process'],
    title: () => <Title>Process</Title>,
    placeholder: 'Process',
    sorting: false,
    editable: true,
    width: '10%',
  },
  {
    dataIndex: ['doc', 'Sagstype'],
    title: () => <Title>Sagstype</Title>,
    placeholder: 'Sagstype',
    sorting: false,
    editable: true,
    width: '10%',
  },
  {
    field: 'kwOneCount',
    sorting: false,
    title: () => multiLineHeader('Keywords', 'Niveau #1'),
    render: getKeywordsCountFirstLevel,
    type: 'numeric',
    editable: false,
    width: '10%',
  },
  {
    field: 'kwTwoCount',
    sorting: false,
    title: () => multiLineHeader('Keywords', 'Niveau #2'),
    render: getKeywordsCountSecondLevel,
    type: 'numeric',
    editable: false,
    width: '10%',
  },
];

export const transactionsColumns = [
  {
    dataIndex: ['doc', 'name'],
    title: inlineHeader('Display navn'),
    placeholder: 'Display navn',
    required: true,
    sorter: (cellParamsA, cellParamsB) =>
      (cellParamsA.doc?.name || 'zzz').localeCompare(cellParamsB.doc?.name || 'zzz', 'da'),
    defaultSortOrder: 'ascend',
    editable: true,
    width: '15%',
  },
  {
    dataIndex: ['doc', 'fileName'],
    title: inlineHeader('URL'),
    placeholder: 'Filnavn',
    required: true,
    sorter: (cellParamsA, cellParamsB) =>
      (cellParamsA.doc?.fileName || 'zzz').localeCompare(cellParamsB.doc?.fileName || 'zzz', 'da'),
    editable: true,
    ellipsis: {
      showTitle: false,
    },
    width: '50%',
    className: 'tooltip-td',
    render: (content) => {
      return (
        <Tooltip
          placement="topLeft"
          title={content}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
          }}
        >
          {content}
        </Tooltip>
      );
    },
  },
];

export const topfilesColumns = [
  {
    dataIndex: ['doc', 'name'],
    title: inlineHeader('Display navn'),
    placeholder: 'Display navn',
    required: true,
    editable: true,
    width: '20%',
  },
  {
    dataIndex: ['doc', 'aiRecommended'],
    title: inlineHeader('AI forslag'),
    placeholder: 'AI forslag',
    required: false,
    editable: true,
    type: 'switch',
    width: '10%',
    render: (aiRecommended) => {
      return (
        <Switch
          checkedChildren={<RobotOutlined />}
          unCheckedChildren={<CloseOutlined />}
          disabled={true}
          checked={aiRecommended}
        />
      );
    },
  },
  {
    dataIndex: ['doc', 'fileName'],
    title: inlineHeader('URL'),
    placeholder: 'Filnavn',
    required: true,
    editable: true,
    ellipsis: {
      showTitle: false,
    },
    width: '50%',
    className: 'tooltip-td',
    render: (content) => {
      return (
        <Tooltip
          placement="topLeft"
          title={content}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
          }}
        >
          {content}
        </Tooltip>
      );
    },
  },
  {
    field: 'kwOneCount',
    sorting: false,
    title: () => multiLineHeader('Keywords', 'Niveau #1'),
    render: getKeywordsCountFirstLevel,
    type: 'numeric',
    editable: false,
    width: '10%',
  },
  {
    field: 'kwTwoCount',
    sorting: false,
    title: () => multiLineHeader('Keywords', 'Niveau #2'),
    render: getKeywordsCountSecondLevel,
    type: 'numeric',
    editable: false,
    width: '10%',
  },
];

export const levelOneKeywordColumns = [
  {
    dataIndex: 'value',
    title: inlineHeader('Keywords ', '- Niveau 1'),
    defaultSortOrder: 'ascend',
    sorter: (cellParamsA, cellParamsB) => (cellParamsA.value || 'zzz').localeCompare(cellParamsB.value || 'zzz', 'da'),
    editable: true,
    placeholder: 'Nøgleord',
    required: true,
  },
];

export const levelTwoKeywordColumns = [
  {
    dataIndex: 'kw',
    title: inlineHeader('Keywords', '- Niveau 2'),
    defaultSortOrder: 'ascend',
    sorter: (cellParamsA, cellParamsB) => (cellParamsA.kw || 'zzz').localeCompare(cellParamsB.kw || 'zzz', 'da'),
    editable: true,
    required: true,
    placeholder: 'Nøgleord',
  },
  {
    render: (record) => <>{record?.extrapolate?.join(', ')}</>,
    title: inlineHeader('Henvisninger', '/ ekstrapoleringer'),
    sorting: false,
    type: 'extrapolate',
    placeholder: 'Ekstrapoleringer',
    editable: true,
  },
];
