import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { createLink } from '../../sharedStyledComponents/staticItemLink';
import { colorTheme } from '../../sharedStyledComponents/generalStyles';
import { dotIcon } from '../../sharedStyledComponents/icons';
import LinkDropdown from './components/topfiles.dropdown';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toggleDisplayChangesToInstructions } from '../../../_actions/dashboard.actions';
import { isEmpty } from '../../../utils/helpers';
import { setTopfileAsViewed } from '../../../_actions/agentConfig.actions';

export class Topfiles extends PureComponent {
    createLinks = (topFiles, onPress) => {
        return topFiles?.slice(0, 5).map((l, i) => {
            const isTopfileNew = !isEmpty(this.props.unseenTopfiles?.find((topfileId) => topfileId === l.id));
            return createLink(
                this.props.topFiles.length,
                i,
                dotIcon('8px', colorTheme),
                l.name,
                l.id,
                onPress,
                isTopfileNew,
                l?.confidence && l.confidence >= 10 ? l.confidence : undefined,
                this.props.expanded,
            );
        });
    };

    render() {
        //copy topfiles and add new entries
        const topFiles = [...this.props.topFiles];
        topFiles.push({
            id: 'changesToInstructions',
            name: 'Instruksændringer',
            type: 'changesToInstructions',
            updating: false,
            notificationNumber: this.props.unseenDocumentChanges?.length,
        });

        const onItemPress = (event) => {
            switch (event) {
                case 'changesToInstructions':
                    this.props.toggleDisplayChangesToInstructions();
                    break;
                default:
                    if (this.props.unseenTopfiles?.indexOf(event) > -1) {
                        this.props.setTopfileAsViewed({ topfileId: event });
                    }
                    this.props.addActiveDocument(event);
                    break;
            }
        };

        return (
            <Container $expanded={this.props.$expanded}>
                {this.createLinks(topFiles, onItemPress)}
                {topFiles.length > 5 && <LinkDropdown topFiles={topFiles.slice(5)} addActiveDocument={onItemPress} />}
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    unseenTopfiles: state.agentConfig.unseenTopfiles,
    unseenDocumentChanges: state.agentConfig.unseenDocumentChanges,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            toggleDisplayChangesToInstructions,
            setTopfileAsViewed,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Topfiles);

const Container = styled.div`
  display: grid;
  flex-wrap: wrap;
  margin: 1.2rem;
  gap:.3rem;
  grid-template-columns: ${props => props.$expanded ? '1fr 1fr 1fr' : ' 1fr 1fr'};
`;
