import { Progress } from 'antd';
import React from 'react';
import styled from 'styled-components';

import NotificationCircle from './NotificationCircle.component';
import { StyledLink, linkItemColor } from './generalStyles';

export const createLink = (linkCount, index, logo, docName, docId, onClickHandler, displayNotification, percent, expanded) => (
  <LinkWrapper key={docId} $linkCount={linkCount} index={index} onClick={() => onClickHandler(docId)} expanded={expanded}>
    <span>{logo}</span>
    <LinkText>{docName}</LinkText>
    <Icons>
      {percent && (
        <StyledProgress
          type="circle"
          percent={Math.round(percent)}
          gapPosition="top"
          trailColor={linkItemColor}
          strokeColor={'#4c853d'}
          strokeWidth={13}
          width={33}
        />
      )}
      {displayNotification && <NotificationCircle isNew={true} shouldPositionInlineRight={true} />}
    </Icons>
  </LinkWrapper>
);

const LinkWrapper = styled(StyledLink)`
  display: inline-flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  height: 2.5rem;
  list-style: none;
  text-decoration: none;
`;
const Icons = styled.div`
  display: flex;
  width: max-content;
`;
const LinkText = styled.span`
  display:inline-block;
  overflow:hidden;
  text-overflow:ellipsis;
  padding-left: 0.3rem;
`;

const StyledProgress = styled(Progress)`
    padding-left: .5rem;
`;