import {
  ADD_ACTIVE_DOCUMENT,
  ADD_PDF_PROPS,
  REMOVE_ACTIVE_DOCUMENT,
  MISSING_DOCUMENT,
  SET_CURRENT_DOCUMENT,
  LOADING_ACTIVE_DOCUMENT,
  REMOVE_ALL_ACTIVE_DOCUMENTS,
  CURRENT_SCALE,
  CURRENT_PAGE,
  MATCHES_COUNT,
} from '../_actions/multiDocument.actions';

import { CLEAR_TOKEN } from '../_actions/auth.actions';

import { TIMER_START } from '../_actions/dashboard.actions';

const initialState = {
  activeDocuments: [],
  currentDocumentIndex: null,
  missingDocument: false,
  loadingDocument: false,
  currentScale: 'auto',
  currentPage: 0,
  matchesCount: {
    current: 0,
    total: 0,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TIMER_START:
      return {
        ...state,
        activeDocuments: [],
        currentDocumentIndex: null,
      };
    case LOADING_ACTIVE_DOCUMENT:
      return {
        ...state,
        loadingDocument: true,
      };
    case ADD_ACTIVE_DOCUMENT:
      return {
        ...state,
        activeDocuments: [...state.activeDocuments, action.payload],
        missingDocument: false,
      };
    case ADD_PDF_PROPS:
      // find document with new props and add it
      const docs = state.activeDocuments.map((doc) => {
        if (doc.id === action.payload.document.id) {
          for (let prop in action.payload.props) {
            doc[prop] = action.payload.props[prop];
          }
          return { ...doc };
        }
        return doc;
      });

      return {
        ...state,
        loadingDocument: false,
        activeDocuments: docs,
      };
    case REMOVE_ACTIVE_DOCUMENT:
      return {
        ...state,
        activeDocuments: state.activeDocuments.filter((doc) => doc.id !== action.payload.id),
      };
    case SET_CURRENT_DOCUMENT:
      return {
        ...state,
        currentDocumentIndex: action.payload,
        loadingDocument: false,
      };
    case REMOVE_ALL_ACTIVE_DOCUMENTS:
      return {
        ...state,
        activeDocuments: [],
        currentDocumentIndex: null,
      };
    case MISSING_DOCUMENT: {
      alert('Dokumentet er ved at opdatere, prøv igen om et øjeblik.');
      return {
        ...state,
        missingDocument: true,
        loadingDocument: false,
      };
    }
    case CURRENT_SCALE: {
      return {
        ...state,
        currentScale: action.payload,
      };
    }
    case CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case MATCHES_COUNT: {
      return {
        ...state,
        matchesCount: action.payload,
      };
    }
    case CLEAR_TOKEN: {
      return {
        ...initialState,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
