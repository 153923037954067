import React, { useState, useRef, useEffect } from 'react';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import { Popconfirm, Form, Button, message, Space, Typography } from 'antd';
import { EditableCell } from './EditableCell';

import {
  SettingsButton,
  StyledTable,
  TableSize,
  Fade,
  StyledAddButton,
  MinimizeButton,
  TableWrapper,
} from '../../../sharedStyledComponents/dataGridStyles';
import { colorTheme, textTheme } from '../../../sharedStyledComponents/generalStyles';

const { Title } = Typography;

export const KeywordLevelOneTable = ({ data, columns, level, updateList, loading }) => {
  const cancelInputButton = useRef(null);
  const [form] = Form.useForm();
  const table = useRef(null);
  const [editingKey, setEditingKey] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [localData, setLocalData] = useState([]);

  useEffect(() => {
    document.addEventListener('click', onEditClickAway, false);
    document.addEventListener('keyup', onEditEscapeKey, false);
    return () => {
      document.removeEventListener('click', onEditClickAway, false);
      document.removeEventListener('keyup', onEditEscapeKey, false);
    };
  });

  useEffect(() => {
    setLocalData(data);
  }, [data]);

  /**
   * Check if it is the currently currently edited row
   * @param {row} record
   */
  const isEditing = (record) => record.key === editingKey;

  /**
   * Column with actions (edit/save/delete) functions
   */
  const action = {
    title: 'Action',
    dataIndex: 'operation',
    render: (_, record) => {
      const editable = isEditing(record);
      return editable ? (
        <Space direction='vertical'>
          <Button size='small' onClick={() => save(record.key)}>
            Gem
          </Button>

          <Popconfirm
            title={
              <>
                Er du sikker på at du vil slette dette keyword?
                <br /> (du kan ikke fortryde sletning)
              </>
            }
            onConfirm={onDelete}
          >
            <Button danger size='small'>
              Slet
            </Button>
          </Popconfirm>

          {/* Hidden button for saving edit or regretting save */}
          <Popconfirm
            placement='leftBottom'
            title='Vil du gemme ændringerne?'
            cancelText='Nej'
            okText='Ja'
            onConfirm={() => save(record.key)}
            onCancel={() => cancel(record.key)}
          >
            <Button ref={cancelInputButton} style={{ visibility: 'hidden', padding: 0, margin: 0, height: 0, width: 0 }} size='small'>
              Fortryd
            </Button>
          </Popconfirm>
        </Space>
      ) : (
        <EditButton icon={<CreateOutlinedIcon style={{ fontSize: '1.7rem' }} />} type='text' onClick={() => edit(record)} />
      );
    },
  };

  /**
   * Cancel edit
   * if it is a new entry with empty fields, remove it from redux
   */
  const cancel = (key) => {
    if (editingKey === 'new') {
      onDelete();
    }

    message.warning('Ændringer ikke gemt');
    setEditingKey('');
  };

  /**
   * Set selected row to edit state changing internal component to <Input/>
   * @param {row} record
   */
  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
  };

  /**
   * Get the row key from form, and update the object in the main list
   * if it is a new key(does not have a key value) a submit fuction will be envoked instead
   * @param key Row key id
   */
  const save = async (key) => {
    // dont do a request if fields are untouched
    if (!form.isFieldsTouched()) {
      setEditingKey('');
      return;
    }

    try {
      const row = await form.validateFields();
      const newData = [...localData];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        // UPDATE EXISTING KEYS
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setLocalData(newData); // TBD replace with redux action
        setEditingKey('');
        updateList(newData);
      } else {
        newData.push(row);
        setLocalData(newData);
        setEditingKey('');
      }
      form.resetFields();
    } catch (errInfo) {
      message.error('Validering fejlede. Prøv igen.');
    }
    message.success('Ændringer er gemt');
  };

  /**
   * Handles delete of instruction
   */
  const onDelete = () => {
    if (editingKey === 'new') {
      setLocalData(localData.filter((i) => i.key !== 'new'));
      setEditingKey('');
      return;
    }

    updateList(localData.filter((i) => i.key !== editingKey));
    setEditingKey('');
    return;
  };

  /**
   * Set extra variables for column object, to be used in editing component
   */
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.type === 'numeric' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        placeholder: col.placeholder,
        required: col.required,
        editing: isEditing(record),
      }),
    };
  });
  // Add action column
  mergedColumns.push(action);

  /**
   * On adding new instruction, an object with all column items
   * is created.
   */
  const onNewEntry = () => {
    if (localData.find((i) => i.key === 'new')) return message.info('Udfyld venligst først den tomme linje!');

    const newData = {
      key: 'new',
      value: '',
    };
    setLocalData([...localData, newData]);
    form.resetFields();
    setEditingKey('new');
  };

  /**
   * If user is editing and clicks on anything else but the input promt
   * if they want to cancel their input
   * @param {Event} e
   */
  const onEditClickAway = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // for click outside component (this triggers clicks in all components, thus check if 'current' exists)
    if (editingKey && e.target.tagName !== 'INPUT' && e.target.tagName !== 'SPAN') {
      if (!form.isFieldsTouched()) {
        cancel();
        return;
      }
      if (cancelInputButton.current) cancelInputButton.current.click();
    }
  };

  /**
   * Handles escape button press
   * @param {Event} e
   */
  const onEditEscapeKey = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (editingKey && e.key === 'Escape' && cancelInputButton) {
      if (!form.isFieldsTouched()) {
        cancel();
        return;
      }
      cancelInputButton.current.click();
    }
  };

  /**
   * Minimizing the table will scroll up to top of the table
   */
  const onMinimize = () => {
    setExpanded(!expanded);
    table.current.scrollIntoView(true, { behavior: 'smooth' });
  };
  return (
    <Container>
      <CategoryWrapper>
        <CategoryType>Keywords</CategoryType>
        <Title
          style={{
            marginBottom: 0,
            fontWeight: 400,
            color: colorTheme,
            userSelect: 'none',
          }}
          level={3}
        >
          {level}
        </Title>
      </CategoryWrapper>
      <TitleSubtext>Keywords på Niveau 1 bruges til at identificere den rigtige Arbejdsinstruks</TitleSubtext>
      <TableSize $expanded={expanded} $count={localData?.length}>
        <TableWrapper ref={table}>
          <Form form={form} component={false}>
            <StyledKeywordTable
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              sortDirections={['ascend', 'descend', 'ascend']}
              pagination={false}
              dataSource={localData}
              clickableRow={false}
              columns={mergedColumns}
              tableLayout='auto'
              loading={loading}
              footer={() => {
                return (
                  data && (
                    <SettingsButton icon={<PlusCircleOutlined style={{ fontSize: '1.4rem' }} />} shape='round' onClick={onNewEntry}>
                      Tilføj nyt keyword ({level})
                    </SettingsButton>
                  )
                );
              }}
            />
          </Form>
          <Fade $visible={localData?.length > 8 && !expanded} />
        </TableWrapper>
      </TableSize>
      {localData?.length > 8 && (
        <StyledAddButton
          icon={<PlusCircleOutlined style={{ fontSize: '1.4rem' }} />}
          displaybutton={(localData?.length > 8 && !expanded).toString()}
          onClick={() => setExpanded(!expanded)}
        >
          Se alle keywords
        </StyledAddButton>
      )}

      {localData?.length > 8 && expanded && (
        <MinimizeButton icon={<MinusCircleOutlined style={{ lineHeight: 0, fontSize: '1.2rem' }} />} onClick={onMinimize}>
          Vis færre
        </MinimizeButton>
      )}
    </Container>
  );
};

const StyledKeywordTable = styled(StyledTable)``;

const Container = styled.div`
  flex-wrap: wrap;
  flex-direction: column;
  margin: 1rem 0rem;
  padding-bottom: 2rem;
  width: fit-content;
`;

const CategoryWrapper = styled.div`
  font-size: 1.5rem;
  color: ${colorTheme};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0 0.5rem 0.1rem;
`;

const CategoryType = styled.div`
  font-weight: 700;
  margin-right: 0.6rem;
  user-select: none;
`;

const EditButton = styled(Button)`
  background: none;
  color: ${colorTheme};
  &:hover {
    background: none !important;
    border-color: none;
  }
  &:focus {
    background: none;
    border-color: none;
  }
`;

const TitleSubtext = styled.div`
  color: ${textTheme};
  margin-bottom: 1rem;
`;
