import { AGENTS_LOADING, GET_AGENTS, ERROR } from '../_actions/agents.actions';

const initialState = {
  agents: [],
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AGENTS: {
      return {
        ...state,
        loading: false,
        agents: action.payload.agents,
      };
    }

    case AGENTS_LOADING: {
      return {
        ...state,
        loading: action.payload.loading,
      };
    }

    case ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    }

    default:
      return state;
  }
};

export default reducer;
