// GET_USERS CONSTANTS
export const GET_USERS_START = 'STATISTICS/GET_USERS_START';
export const GET_USERS_SUCCESS = 'STATISTICS/GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'STATISTICS/GET_USERS_FAILURE';

// SET_FILTERS CONSTANTS
export const SET_FILTERS_START = 'STATISTICS/SET_FILTERS_START';
export const SET_FILTERS_SUCCESS = 'STATISTICS/SET_FILTERS_SUCCESS';
export const SET_FILTERS_FAILURE = 'STATISTICS/SET_FILTERS_FAILURE';

// GET_KPIS CONSTANTS
export const GET_KPIS_START = 'STATISTICS/GET_KPIS_START';
export const GET_KPIS_SUCCESS = 'STATISTICS/GET_KPIS_SUCCESS';
export const GET_KPIS_FAILURE = 'STATISTICS/GET_KPIS_FAILURE';

// GET_WORK_INSTRUCTIONS CONSTANTS
export const GET_WORK_INSTRUCTIONS_START = 'STATISTICS/GET_WORK_INSTRUCTIONS_START';
export const GET_WORK_INSTRUCTIONS_SUCCESS = 'STATISTICS/GET_WORK_INSTRUCTIONS_SUCCESS';
export const GET_WORK_INSTRUCTIONS_FAILURE = 'STATISTICS/GET_WORK_INSTRUCTIONS_FAILURE';

// GET_WORK_INSTRUCTION CONSTANTS
export const GET_WORK_INSTRUCTION_START = 'STATISTICS/GET_WORK_INSTRUCTION_START';
export const GET_WORK_INSTRUCTION_SUCCESS = 'STATISTICS/GET_WORK_INSTRUCTION_SUCCESS';
export const GET_WORK_INSTRUCTION_FAILURE = 'STATISTICS/GET_WORK_INSTRUCTION_FAILURE';

// GET AGENT ACTIVITIES CONSTANTS
export const GET_AGENT_ACTIVITIES_START = 'STATISTICS/GET_AGENT_ACTIVITIES_START';
export const GET_AGENT_ACTIVITIES_SUCCESS = 'STATISTICS/GET_AGENT_ACTIVITIES_SUCCESS';
export const GET_AGENT_ACTIVITIES_FAILURE = 'STATISTICS/GET_AGENT_ACTIVITIES_FAILURE';

export const GET_AGENT_FREQUENCY_START =  'STATISTICS/GET_AGENT_FREQUENCY_START';
export const GET_AGENT_FREQUENCY_SUCCESS =  'STATISTICS/GET_AGENT_FREQUENCY_SUCCESS';
export const GET_AGENT_FREQUENCY_FAILURE =  'STATISTICS/GET_AGENT_FREQUENCY_FAILURE';