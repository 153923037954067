import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import store, { history } from './store';

import * as serviceWorker from './serviceWorker';

import './index.css';
import './theme.css';

if (process.env.NODE_ENV !== 'production') {
  localStorage.setItem('debug', 'react-starter:*');
}

const target = document.querySelector('#root');
ReactDOM.render(
  <Provider store={store()}>
    <ConnectedRouter history={history}>
      <div>
        <App />
      </div>
    </ConnectedRouter>
  </Provider>,
  target
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
