import { orderBy } from 'lodash';

export const sortComplianceItems = (items) => orderBy(
  items,
  [
    item => (item.groupOrder ? parseInt(item.groupOrder) : 0),
    item => (item.group ? item.group : '!'), //lowest value in alphabetical sort
    item => (item.order ? parseInt(item.order) : 0),
    item => (item.resume ? item.resume : '~'), //highest value in alphabetical sort
  ],
  ['asc', 'asc', 'asc', 'asc']
);