import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FloatButton, Layout, message, Typography } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { getData, updateInstruction, updateTopfile } from '../../../_actions/instructionSettings.actions';
import { setCurrentInstruction, clearCurrentInstruction } from '../../../_actions/additionalSettings.actions';
import {
  colorTheme,
  grayedText,
  itemBackground,
  textTheme,
  TitleTextWithSecondaryTitle,
} from '../../sharedStyledComponents/generalStyles';
import { levelOneKeywordColumns, levelTwoKeywordColumns } from './tableConfiguration/cellSettings';
import { Loading } from '../../GlobalComponents/loading.component';
import { KeywordLevelOneTable } from './components/KeywordLevelOneTable.component';
import { KeywordLevelTwoTable } from './components/KeywordLevelTwoTable.component';

const { Header } = Layout;

export class AdditionalConfig extends Component {
  constructor(props) {
    super(props);

    this.state = {
      instructionId: undefined,
    };
  }

  componentDidMount() {
    const instructionId = new URLSearchParams(window.location.search).get('id');

    if (!instructionId) {
      message.error('Manglende instruks ID, du bliver dirigeret tilbage til instrukser', 2, () =>
        this.props.history.push('/admin/instructions')
      );

      return;
    }

    // if data is not present in redux store
    if (this.props.instructions.length === 0) {
      this.props.getData();
    }

    this.setState({ instructionId: instructionId });
  }

  componentDidUpdate() {
    // Set the current Instruction on update if id and list is present
    if (this.props.instructions.length > 0 && this.state.instructionId && !this.state.instruction) {
      this.props.setCurrentInstruction(this.state.instructionId);
    }
  }

  componentWillUnmount() {
    this.props.clearCurrentInstruction();
  }

  onKeywordLevelOneSave = (keywords) => {
    // hard copy object
    const updatedInstruction = JSON.parse(JSON.stringify(this.props.instruction));
    // replace keyword list with new list and only use the value (the keywords are objects for table usage)
    updatedInstruction.doc.level1kw = keywords.map((k) => k.value);

    // Check if document is a topfile (if it has aiRecommended field it is a topfile)
    if (updatedInstruction.doc.hasOwnProperty('aiRecommended')) {
      this.props.updateTopfile(updatedInstruction);
    } else {
      this.props.updateInstruction(updatedInstruction);
    }
  };

  onKeywordLevelTwoSave = (keywords) => {
    // hard copy object
    const updatedInstruction = JSON.parse(JSON.stringify(this.props.instruction));
    // replace keyword list with new list and only use the value (the keywords are objects for table usage)
    updatedInstruction.doc.level2kw = keywords.map((k) => {
      return { kw: k.kw, extrapolate: k.extrapolate };
    });
    // Check if document is a topfile (if it has aiRecommended field it is a topfile)
    if (updatedInstruction.doc.hasOwnProperty('aiRecommended')) {
      this.props.updateTopfile(updatedInstruction);
    } else {
      this.props.updateInstruction(updatedInstruction);
    }
  };

  render() {
    const { dataLoading, instruction, loading } = this.props;

    if (dataLoading) return <Loading />;
    return (<>
      <FloatButton.BackTop visibilityHeight={1000} />
      <StyledHeader>
        <TitleTextWithSecondaryTitle color={colorTheme} fontSize={'2.7rem'}>
          {instruction?.doc?.category || 'Kategori'}
          <Typography.Text>&nbsp;{this.props.instruction?.doc.displayName || 'Manglende navn'}</Typography.Text>
        </TitleTextWithSecondaryTitle>
        <TitleSubtext>
          <Link style={{ color: grayedText }} to={`/admin/instructions`}>
            {'<'} Tilbage til oversigten over arbejdsinstrukser
          </Link>
        </TitleSubtext>
      </StyledHeader>
      <KeywordLevelOneTable
        data={instruction?.doc.level1kw.map((k, i) => {
          return { value: k, key: k + i };
        })}
        columns={levelOneKeywordColumns}
        level={'Niveau 1'}
        loading={loading}
        updateList={this.onKeywordLevelOneSave}
      />
      <KeywordLevelTwoTable
        key={'table2'}
        data={instruction?.doc.level2kw.map((k, i) => {
          return { key: k.kw + '-' + i, ...k };
        })}
        columns={levelTwoKeywordColumns}
        level={'Niveau 2'}
        updateList={this.onKeywordLevelTwoSave}
      />
    </>);
  }
}

const mapStateToProps = (state) => ({
  instructions: state.instructionSettings.instructions,
  topfiles: state.instructionSettings.topfiles,
  loading: state.instructionSettings.loading,
  dataLoading: state.instructionSettings.loading,
  instruction: state.additionalSettings.instruction,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getData,
      setCurrentInstruction,
      clearCurrentInstruction,
      updateInstruction,
      updateTopfile,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalConfig);

const StyledHeader = styled(Header)`
  margin-top: 1rem;
  padding-left: 0;
  height: auto;
  background: ${itemBackground};
  line-height: normal;
`;

const TitleSubtext = styled.div`
  color: ${textTheme};
  margin-top: 0.5rem;
`;
