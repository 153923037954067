import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Content, colorTheme, textOnTheme } from '../../sharedStyledComponents/generalStyles';
import {
  searchRequest,
  clearSearch,
  suggestionsRequest,
  addFilterCategory,
  removeFilterCategory,
  openAndNavigate,
  searchWordChange,
} from '../../../_actions/search.actions';

import AutoCompleteSearch from './components/search.autocomplete';
import Categories from './components/search.categories';
import Suggestions from './components/search.suggestions';

export class SearchView extends Component {
  render() {
    return (
      <Container>
        <Header>Søg</Header>
        <Categories
          suggestionsRequest={this.props.suggestionsRequest}
          categories={this.props.categories}
          searchWord={this.props.searchWord}
          activeCategories={this.props.activeCategories}
          addFilterCategory={this.props.addFilterCategory}
          removeFilterCategory={this.props.removeFilterCategory}
        />
        <AutoCompleteSearch
          searchRequest={this.props.searchRequest}
          searchWordChange={this.props.searchWordChange}
          searchWord={this.props.searchWord}
          clearSearch={this.props.clearSearch}
          words={this.props.words}
          autoCompleteLoading={this.props.autoCompleteLoading}
          suggestionsRequest={this.props.suggestionsRequest}
          autoCompleteError={this.props.autoCompleteError}
        />
        <Suggestions
          suggestions={this.props.suggestions}
          words={this.props.words}
          searchWord={this.props.searchWord}
          openAndNavigate={this.props.openAndNavigate}
          suggestionError={this.props.suggestionError}
        />
      </Container>
    );
  }
}

SearchView.propTypes = {
  searchRequest: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  words: PropTypes.arrayOf(PropTypes.object),
  suggestions: PropTypes.arrayOf(PropTypes.object),
  openAndNavigate: PropTypes.func.isRequired,
  autoCompleteError: PropTypes.string,
  suggestionError: PropTypes.string,
};

const mapStateToProps = (state) => ({
  words: state.search.autoCompleteSuggestions,
  searchWord: state.search.searchWord,
  autoCompleteLoading: state.search.autoCompleteLoading,
  autoCompleteErrorText: state.search.autoCompleteErrorText,
  suggestions: state.search.suggestions,
  activeCategories: state.search.activeCategories,
  categories: state.dashboard.categories,
  suggestionError: state.search.suggestionError,
  autoCompleteError: state.search.autoCompleteError,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      searchRequest,
      clearSearch,
      suggestionsRequest,
      addFilterCategory,
      removeFilterCategory,
      openAndNavigate,
      searchWordChange,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SearchView);

const Container = styled(Content)`
  /* min-height: 6.5rem; */
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  border-radius: 0.2rem 0.2rem 0 0;
  padding: 0.5rem 1rem;
  background-color: ${colorTheme};
  color: ${textOnTheme};
  font-weight: 550;
  user-select: none;
`;
