import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import 'pdfjs-dist/web/pdf_viewer.css';
import { get, isEmpty, isNumber } from 'lodash';
import Utils from '../../../utils/Utils';
import { colorTheme, textOnTheme } from '../../sharedStyledComponents/generalStyles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setSectionAsViewed } from '../../../_actions/agentConfig.actions';
import ReactResizeDetector from 'react-resize-detector';
const { PDFLinkService, PDFFindController, PDFViewer, EventBus } = await import ("pdfjs-dist/web/pdf_viewer");

export class Viewer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      scale: 'auto',
      allSections: [],
      allText: [],
      pageHeight: 1500,
      pageWidth: 1000,
      totalPages: 0,
      viewContainerRef: React.createRef(null),
      notifiedFullRevision: false,
    };
  }

  componentDidMount() {
    // Get viewcontainer for the pdf viewer
    this.viewerContainer = ReactDOM.findDOMNode(this);

    this._eventBus = new EventBus();
    this._pdfLinkService = new PDFLinkService({
      eventBus: this._eventBus,
    });

    this._pdfFindController = new PDFFindController({
      linkService: this._pdfLinkService,
      eventBus: this._eventBus,
    });

    // Create PDFJS viewer instance with all the needed controllers
    this._pdfViewer = new PDFViewer({
      container: this.viewerContainer,
      removePageBorders: true, // this adds page borders and does not remove them as stated...
      linkService: this._pdfLinkService,
      findController: this._pdfFindController,
      eventBus: this._eventBus,
    });
    this._pdfLinkService.setViewer(this._pdfViewer);

    // Add eventlitener for changing current page in toolbar
    this._eventBus.on('pagechanging', () => this.props.setCurrentPage(this._pdfLinkService.page));

    // Every time the teaxt layer is updated, check for new section changes to display
    this._eventBus.on('textlayerrendered', () => this.updateChangedSectionsHighlight());

    this._eventBus.on('pagesloaded', () => {
      this.onPagesLoaded();
      this.loadAllText();
    });

    this._eventBus.on('updatefindcontrolstate', (e) => {
      const fakeEvent = new CustomEvent('updatefindcontrolstate', {
        detail: e,
      });
      window.dispatchEvent(fakeEvent);
    });

    this._eventBus.on('updatefindmatchescount', (e) => {
      const fakeEvent = new CustomEvent('updatefindmatchescount', {
        detail: e,
      });
      window.dispatchEvent(fakeEvent);
    });

    // initial setup of document settings
    this.setupDocument();
  }

  componentDidUpdate(prevProps, prevState) {
    // If a new document is opened
    if (prevProps.doc !== this.props.doc && prevProps.pdfProxy !== this.props.pdfProxy) {
      // Only add props if the previous document was loaded as a pdfProxy
      if (prevProps.pdfProxy) {
        // Save previous document properties
        this.props.addDocumentPdfProps(prevProps.doc, {
          zoom: prevState.scale,
          scrollTop: this.viewerContainer.scrollTop,
          scrollLeft: this.viewerContainer.scrollLeft,
        });
      }
      this.setupDocument();
    }
    // If the searchterm is deleted, reset search highlights (searching on nothing achieves this)
    if (prevProps?.searchTerm.length && this.props.searchTerm === '') {
      this._pdfViewer.eventBus.dispatch('find', { query: '' });
    }

    // Search functionality
    if (prevProps.searchTerm !== this.props.searchTerm) {
      this._pdfViewer.eventBus.dispatch('find', {
        query: this.props.searchTerm,
        caseSensitive: false,
        highlightAll: true,
        phraseSearch: true,
        findPrevious: false,
      });
    }

    // Navigation
    if (this.props.navigateTitle && this.props.navigateTitle !== '') {
      this.navigateToTitle();
    }
  }

  componentWillUnmount() {
    // Remove listener to avoid memory leaks
    this.viewerContainer.removeEventListener('scroll', this.handleScrollEvent);
  }

  /**
   * function for passing loaded document to pdf viewer and
   * triggering repositioning based on previous document browsing location and zoom.
   */
  async setupDocument() {
    this._pdfViewer.setDocument(this.props.pdfProxy);
    this._pdfLinkService.setDocument(this.props.pdfProxy);
    await this.rePosition();
    this.props.setCurrentPage(this._pdfLinkService.page);
    this.viewerContainer.addEventListener('scroll', this.handleScrollEvent);
  }

  handleScrollEvent = (event) => {
    // Save scroll settings in props whenever document gets scrolled in
    this.props.addDocumentPdfProps(this.props.doc, {
      zoom: this.state.scale,
      scrollTop: this.viewerContainer.scrollTop,
      scrollLeft: this.viewerContainer.scrollLeft,
    });
  }

  async rePosition() {
    const { zoom, scrollTop, scrollLeft } = this.props.doc;

    await this._pdfViewer.firstPagePromise;

    if (zoom > 0) {
      this.setScale(zoom);
    } else {
      this.setAutoScale(zoom);
    }

    if (typeof scrollTop !== 'undefined') {
      this.setScrollTop(scrollTop);
    }

    if (typeof scrollLeft !== 'undefined') {
      this.setScrollLeft(scrollLeft);
    }
  }

  /**
   * Used to scroll in the document to desired position from top
   * @param {number} scrollTop scroll position
   */
  setScrollTop = (scrollTop) => {
    if (this.viewerContainer) {
      this.viewerContainer.scrollTop = scrollTop;
    }
  };

  /**
   * Used to scroll in the document to desired position from left
   * @param {number} scrollLeft scroll position
   */
  setScrollLeft = (scrollLeft) => {
    if (this.viewerContainer) {
      this.viewerContainer.scrollLeft = scrollLeft;
    }
  };

  /**
   * Used to set current pdf scale / zoom
   * will not zoom out more than 10%
   * @param {number} scrollLeft scroll position
   */
  setScale = (scale) => {
    const nextScale = scale <= 10 ? 10 : scale >= 210 ? 210 : scale;

    this._pdfViewer.currentScaleValue = nextScale / 100;

    // Get the numeric value of the scale to "ignore" string auto scales
    const newScale = Utils.roundToNearest(this._pdfViewer.currentScale * 100, 10);
    this.setState(() => ({ scale: newScale }));
    this.props.setCurrentScale(newScale);
  };

  setAutoScale = (scale) => {
    // for string value scales as 'auto'
    this._pdfViewer.currentScaleValue = scale;
    // Get the numeric value of the scale to "ignore" string auto scales
    const newScale = Utils.roundToNearest(this._pdfViewer.currentScale * 100, 10);
    this.setState(() => ({ scale: newScale }));

    // Set the displayed value to the "textual representation" instead of number;
    this.props.setCurrentScale(scale);
  };

  /**
   * Find the right header, normalizing them to remove additional spaces sometimes present.
   * recursive function
   * @param list outline list
   */
  _testMatch = (list) => {
    for (let i = 0; i < list.length; i++) {
      const header = list[i];
      if (this._normalize(header.title) === this._normalize(this.props.navigateTitle)) {
        return header;
      }
      // if no match on first layer check second layer for match
      if (header.items && header.items.length > 0) {
        let subTest = this._testMatch(header.items);
        if (subTest != null) {
          return subTest;
        }
      }
    }
    return null;
  };

  /**
   * Util function to replace all spacing for comparison checks
   */
  _normalize = (text) => text.toLowerCase().replace(/\s/g, '');

  /**
   * Navigate in document when menu item is clicked
   */
  navigateToTitle() {
    const outline = get(this.props, 'doc.outline', []);
    let match = null;

    if (this.props.navigateTitle !== '') {
      // Check the first layer of outline if match return
      match = this._testMatch(outline);
    }

    // Navigate to the match, then reset the state.
    if (match) {
      this._pdfLinkService.goToDestination(match.dest);
      this.props.clearNavigation();
    }
  }

  /**
   * Go to next search match
   * @param next if true next match if false previous match
   */
  switchMatch = (next) => {
    // If we have multiple matches navigate to the next. (starts from top when end is met)
      this._pdfViewer.eventBus.dispatch('find', {
        type: 'again',
        query: this.props.searchTerm,
        caseSensitive: false,
        highlightAll: true,
        phraseSearch: true,
        findPrevious: next === 'previous',
      });
  };

  onPagesLoaded = () => {
    //first find all sections in document - title and depth
    const getAllSectionsDeepSearch = (outlineItems = this.props.doc.outline, depth = 0) => {
      let newItems = [];
      outlineItems?.forEach((item) => {
        if (!isEmpty(item.title)) {
          newItems.push({
            title: item.title,
            depth: depth,
          });
        }
        newItems = newItems.concat(getAllSectionsDeepSearch(item.items, depth + 1));
      });
      return newItems;
    };

    this.setState({
      ...this.state,
      allSections: getAllSectionsDeepSearch(),
    });
  };

  loadAllText = () => {
    // Load all text when document is initially loaded. Text is stored in the state
    // returns promise that resolves to the page text contents
    setTimeout(() => {
      const extractPageText = (pagenum) => {
        return this.props.pdfProxy.getPage(pagenum).then((page => {
          return page
            .getTextContent()
            .then((content) => {
              const strings = content.items.map(function (item) {
                return item.str;
              });
              page.cleanup();
              return strings;
            })
        }))
      }

      let allText = [];
      let pageCount = this._pdfViewer ? this._pdfViewer?._pages?.length : 0;
      for (let page = 1; page <= pageCount; page++) {
        allText.push(extractPageText(page));
      }

      // Set state after all pages have loaded (promises have settled)
      Promise.allSettled(allText).then((allText) => {
        allText = allText.map((page) => page.value).flat();
        this.setState({
          ...this.state,
          allText: allText,
        });

        // After all text is loaded, update to mark changed sections as red:
        this.updateChangedSectionsHighlight();
      })
    }, 500) // Timeout so pdf can load properly. If removed, the pdfviewer 
  }        // sometimes fails loading a page, and displays an all black page instead.

  updateChangedSectionsHighlight = () => {
    let allSections = [...this.state.allSections];
    let allText = this.state.allText;
    if (allText.length === 0) return; // Update will be called again after allText has been loaded.

    // only add highlight for last section as this is where Read entire document is marked
    if (
      this.props.unseenDocumentTotalRevisions?.find((totalRevision) => this.props.doc.id === totalRevision.documentId)
    ) {
      return;
    }

    // renderedTextLayers contains all pages that have been lazyloaded, not all pages in the document.
    const pages = this._pdfViewer._pages;
    let renderedTextLayers = pages.map((page) => page.textLayer?.textDivs).flat();

    //Remove the elements from renderedTextLayers, that are present before and on the Table of Content,
    //as these should never be highlighted.
    //The end of Table of Content is marked by the the location of the first hit from the last section in the Table of Content
    const lastSectionName = allSections[allSections.length - 1]?.title.replaceAll(' ', '') || '';
    let restoflastSectionName = lastSectionName;
    let indexOfEndOfTableOfContent = 0;

    // Find index of end of TOC in allText (it is not necessarily present in the lazyloaded textlayers).
    for (let index = 0; index < allText.length; index++) {
      const txt = allText[index];
      if (isEmpty(txt) || /^-?\d+$/.test(txt)) {
        continue;
      }
      let innerText = txt.replaceAll(' ', '')

      if (!restoflastSectionName.startsWith(innerText)) {
        restoflastSectionName = lastSectionName;
        continue;
      }

      restoflastSectionName = restoflastSectionName.slice(innerText.length);

      //if the checked text is valid, and there is no more text to check up against, set indexOfEndOfTableOfContent to current index + 1
      if (restoflastSectionName.length === 0) {
        indexOfEndOfTableOfContent = index + 1;
        break;
      }
    }

    // Remove any textlayers that appear before indexOfEndOfTableOfContent.
    let renderedText = renderedTextLayers.map((span) => span?.innerText).map((txt) => txt?.replaceAll(' ', ''))
    let nToRemove = 0;

    // Find the place in renderedText where TOC is (or would be), so sections before it are removed even if it is not loaded.
    // The matching section is recognised by finding 10 sections in a row that match. The number 10 is arbitrary, but 
    // if 30 adjacent textlayers are identical, then it is extremely likely that they are referring to the same place in the document.

    // This for-loop is very ineffecient. When we have time, we could optimise it by implementing a variation of binary search to determine the index 
    // of the TOC, rather than slowly iterating through every single text span of the textlayers. This would improve runtime from O(n^2) to O(n log n)
    // For now, using +5 for every iteration of the loop is a good enough performance increase.
    for (let i = 0; i < Math.min(renderedText.length - 10, indexOfEndOfTableOfContent); i += 5) {
      let span = renderedText.slice(i, i + 10); // Match sections by comparing 10 adjacent spans
      let indexInAllText = allText.findIndex((_, index, allText) => {
        let sectionsToCompare = allText.slice(index, index + 10).map((txt) => txt?.replaceAll(' ', ''));
        return (span.every((val, idx) => val === sectionsToCompare[idx]));
      })
      // End loop when in a section after TOC.
      if (indexInAllText > indexOfEndOfTableOfContent) break;
      nToRemove += 5;
    }

    renderedTextLayers = renderedTextLayers.slice(nToRemove);

    for (let index = 0; index < allSections.length; index++) {
      const sectionName = allSections[index].title.replaceAll(' ', '');
      let restOfSectionName = sectionName;

      //firstHitTopOfSectionTitle and firstHitSectionStartPage are used to point the start of a section title, rather than the end.
      //if the section title spands more than one line, this is necessary
      let firstHitTopOfSectionTitle;
      let firstHitSectionStartPage;
      renderedTextLayers.forEach((span) => {
        if (isEmpty(span?.innerText)) return;
        const spanText = span?.innerText.replaceAll(' ', '');
        if (!restOfSectionName.startsWith(spanText)) {
          restOfSectionName = sectionName;
          return;
        }

        //check if the current span is/could be the start of the section title - if so, save the values
        if (sectionName.startsWith(spanText)) {
          let scaleFactor = span?.parentNode?.parentNode?.parentNode?.parentNode?.style.getPropertyValue('--scale-factor')
          let spanTop = span.style.top.replace('px)', '').replace('calc(var(--scale-factor)*', '')
          let topOfSection = Number(spanTop * scaleFactor);
          firstHitTopOfSectionTitle = topOfSection;
          firstHitSectionStartPage = Number(span?.parentNode?.parentNode?.parentNode?.getAttribute('data-page-number'));
        }

        //remove part of string that is alreay used for checking
        restOfSectionName = restOfSectionName.slice(spanText.length);

        //if nore more string to check up against, the correct section title was found - use the above saved value for the start of the section name values
        if (restOfSectionName.length === 0) {
          allSections[index].topOfSectionTitle = firstHitTopOfSectionTitle;
          allSections[index].sectionStartPageNumber = firstHitSectionStartPage;
        }
      });
    }

    //link each section to the next section that marks the end of the current section
    for (let indexOfSectionsToLink = 0; indexOfSectionsToLink < allSections.length; indexOfSectionsToLink++) {
      allSections[indexOfSectionsToLink].linkedSectionIndex = indexOfSectionsToLink + 1;

      /*
       * This is removed, as the depth of each section is irrelevant when marking changes to sections

      let indexOfComparedSection = indexOfSectionsToLink + 1;

      //check if next section in array is of the same or higher depth, else keep looking
      for (indexOfComparedSection; indexOfComparedSection < allSections.length; indexOfComparedSection++) {

        if (allSections[indexOfSectionsToLink].depth < allSections[indexOfComparedSection].depth) continue;

        allSections[indexOfSectionsToLink].linkedSectionIndex = indexOfComparedSection;
        break;
      }
      */
    }

    this.setState({
      ...this.state,
      allSections: allSections.map((newSection, index) => {
        let sectionToReturn = newSection;
        if (
          this.state.allSections[index]?.scale === this.state.scale &&
          isNumber(this.state.allSections[index]?.topOfSectionTitle)
        ) {
          sectionToReturn = this.state.allSections[index];
        }

        return {
          ...sectionToReturn,
          scale: this.state.scale,
        };
      }),
      pageHeight: this._pdfViewer?._pages[0]?.viewport.height,
      pageWidth: this._pdfViewer?._pages[0]?.viewport.width,
      totalPages: this._pdfViewer?._pages?.length,
    });
  };

  calculateTopFromPdfStart = (section) => {
    //the 'top' value for the highlight box is calculated by adding the top on its own page, to the number of previous pages in pixels,
    return section?.topOfSectionTitle + (this.state.pageHeight + 9) * (section?.sectionStartPageNumber - 1);
  };

  onMarkSectionAsRead = (title) => () => {
    this.props.setSectionAsViewed({
      instructionId: this.props.doc?.id,
      sectionName: title,
      section: this.props.unseenDocumentChanges?.find((change) => change.sectionName === title),
    });
  };

  render() {
    const unseenSectionNamesOfCurrentInstruction = this.props.unseenDocumentChanges
      ?.filter((change) => change.docId === this.props.doc?.id)
      ?.map((change) => change.sectionName);
    const sectionsHighlightToDisplay = this.state.allSections?.filter(
      (section) =>
        section.topOfSectionTitle > 0 && unseenSectionNamesOfCurrentInstruction?.indexOf(section.title) >= 0
    );
    return (
      <ReactResizeDetector handleWidth>
        {({ width: containerWidth = 0 }) => (
          <ViewerContainer ref={this.state.viewContainerRef}>
            <div className="pdfViewer"></div>
            {sectionsHighlightToDisplay?.map((section, index) => {
              const topCurrentSection = this.calculateTopFromPdfStart(section);
              const topNextSection = this.calculateTopFromPdfStart(this.state.allSections[section.linkedSectionIndex]);
              const canHeightBeCalculated = this.state.allSections[section.linkedSectionIndex]?.topOfSectionTitle > 0;
              return (
                <SectionHighlight
                  style={{
                    top: `calc(${topCurrentSection}px + 2rem - 5px)`, //2rem is page top margin //10px to start a bit above section
                    height: canHeightBeCalculated // if there is a 'top' value for this sections linked section
                      ? `calc((${topNextSection}px + 2rem) - (${topCurrentSection}px + 2rem) - 5px)` //-1rem so marking stars a bit before section
                      : `${this.state.pageHeight -
                      section?.topOfSectionTitle +
                      this.state.pageHeight * (this.state.totalPages - section?.sectionStartPageNumber) -
                      5
                      }px`, //if linked section cannot be used, set height that is height enough, until linked section value can be set and rendered correctly
                    width: `calc(${this.state.pageWidth}px *  0.85)`, // * 0.85 as the width should not go to the edge of page
                    marginLeft:
                      (containerWidth - this.state.pageWidth) / 2 < 0
                        ? `${this.state.pageWidth * 0.07}px`
                        : `calc((calc(${containerWidth}px - calc(${this.state.pageWidth}px *  0.85)) / 2)`,

                  }}
                  key={index}
                >
                  <StyledButton onClick={this.onMarkSectionAsRead(section.title)}>
                    Set
                  </StyledButton>
                </SectionHighlight>
              );
            })}
          </ViewerContainer>
        )}
      </ReactResizeDetector>
    );
  }
}

Viewer.propTypes = {
  navigateTitle: PropTypes.string,
  addDocumentPdfProps: PropTypes.func.isRequired,
  doc: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  unseenDocumentChanges: state.agentConfig.unseenDocumentChanges,
  unseenDocumentTotalRevisions: state.agentConfig.unseenDocumentTotalRevisions,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSectionAsViewed,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Viewer);

const ViewerContainer = styled.div`
  padding-top: 2rem;
  background-color: #444;
  width: 75%;
  height: calc(100% - 4.5rem); //padding
  position: fixed;
  overflow: auto;
`;
/*
    left: 50%;
    transform: translateX(-50%);
*/
const SectionHighlight = styled.div`
  background: rgb(248, 127, 37, 0.3);
  position: absolute;
  border-radius: 0.2rem;

  &:hover {
    cursor: pointer;
  }

  &:after {
    content: '';
    background: #bf0015;
    position: absolute;
    left: -0.5rem;
    height: inherit;
    width: 0.2rem;
    border-radius: 0.2rem;
  }
`;

const StyledButton = styled.button`
  color: ${textOnTheme};
  background-color: ${colorTheme};
  border-color: ${colorTheme};
  padding: 10px 16px;
  font-weight: bold;
  position: sticky;
  top: 0;
  margin: 0 0 0 -4.5rem;
  z-index: 1000;

  &:hover{
    cursor: pointer;
  }
}
`;
