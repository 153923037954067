// import HeadsetMicOutlinedIcon from '@material-ui/icons/HeadsetMicOutlined';
import DonutLarge from '@material-ui/icons/DonutLarge';
// import EventNote from '@material-ui/icons/EventNote';
import SettingsIcon from '@material-ui/icons/Settings';
import { Typography } from 'antd';
import { FileOutlined, FileExclamationOutlined, FileDoneOutlined, UserOutlined } from '@ant-design/icons';

export const path = {
  index: '',
  instructions: '/instructions',
  compliance: '/compliance',
  instructionChanges: '/instructionChanges',
  revision: '/revision',
  additionalconfig: '/instructions/additionalconfig',
  general: '/general',
  teams: '/teams',
};

export const menuItems = [
  {
    icon: <DonutLarge style={{ fontSize: '1.5rem' }} />,
    navigateTo: path.index,
    title: 'Dashboard',
  },
  {
    icon: <FileOutlined style={{ fontSize: '1.5rem' }} />,
    title: 'Arbejdsinstrukser',
    navigateTo: path.instructions,
  },
  // {
  //   icon: <HeadsetMicOutlinedIcon style={{ fontSize: '1.5rem' }} />,
  //   title: 'Telefonpinde',
  //   navigateTo: path.compliance,
  // },
  {
    icon: <FileExclamationOutlined style={{ fontSize: '1.5rem' }} />,
    title: 'Instruksændringer',
    navigateTo: path.instructionChanges,
  },
  {
    icon: <FileDoneOutlined style={{ fontSize: '1.5rem' }} />,
    title: 'Revisionsstyring',
    navigateTo: path.revision,
    visibleForSuperAdmin: true,
  },
  {
    icon: <UserOutlined style={{ fontSize: '1.5rem' }} />,
    title: 'Teams',
    navigateTo: path.teams,
  },
  {
    icon: <SettingsIcon style={{ fontSize: '1.5rem' }} />,
    title: 'Generelt',
    navigateTo: path.general,
  },
];

export const headers = [
  {
    path: path.index,
    headerTitle: (
      <>
        Agent Assist&nbsp;<Typography.Text>Dashboard</Typography.Text>
      </>
    ),
    headerSubtext: (
      <>
        Her kan du se brugsdata og KPI'er fra agent assist
        <br />
        Nedenfor kan du vælge hvilken bruger og datointerval du vil have vist data for.
      </>
    ),
  },
  {
    path: path.instructions,
    headerTitle: <Typography.Text strong>Arbejdsinstrukser</Typography.Text>,
    headerSubtext: (
      <>
        Nedenfor kan du se samtlige arbejdsinstrukserfor medlemmer, virksomheder, kørsler, samt de faste elementer.
        <br />
        Du kan omarrangere, slette eller oprette et nyt dokument her fra oversigten, samt klikke ind på en specifik
        arbejdsinstruks og definere keywords.
      </>
    ),
  },
  {
    path: path.compliance,
    headerTitle: 'Telefonpinde',
    headerSubtext: (
      <>
        Nedenfor kan du se telefonpinde tilknyttet de respektive domæner.
        <br />I denne sektion kan du få overblik over de vejledende spørgsmål og kategorier, samt administrere
        indkøringsfaserne for de enkelte telefonpinde.
      </>
    ),
  },
  {
    path: path.instructionChanges,
    headerTitle: 'Instruksændringer',
    headerSubtext: (
      <>I oversigten vises arbejdsinstrukser, som er blevet ændret indenfor det definerede tidsinterval nedenfor.</>
    ),
  },
  {
    path: path.revision,
    headerTitle: 'Revisionsstyring',
    headerSubtext: (
      <>
        Nedenfor vises udkast til instruksændringer, som skal godkendes og publiceres inden rådgiverne ser dem. Når der
        trykkes ‘Publicér’ sendes en notifikation til rådgiverne, som gør dem opmærksom på ændringen i
        arbejdsinstruksen. Hvis der trykkes ‘Afvis’ nedenfor, så bliver der ikke sendt en notifikation til rådgiverne,
        men ikke ændringen kan stadig ses af rådgiverne i arbejdsinstruksen. Ændringer til arbejdsinstrukser logges
        natligt med deadline kl. 23.59, så hvis der er foretaget instruksændringer i dag er de ikke med i nedenstående
        oversigt til publicering, men vil først figurere imorgen.{' '}
      </>
    ),
  },
  {
    path: path.teams,
    headerTitle: 'Teams',
    headerSubtext: (
      <>
        Nedenfor vises de teams, som rådgiverne indgår i. Teams benyttes bland andet til dashboardet og overblik over
        instruksændringer.
      </>
    ),
  },
  {
    path: path.general,
    headerTitle: 'Generelt',
    headerSubtext: (
      <>
        Nedenfor kan du se generelle indstillinger.
        <br />I denne sektion kan du få overblik over generelle indstillinger, samt opsætning af disse.
      </>
    ),
  },
];
