import styled, { keyframes } from 'styled-components';
import { colorTheme, recommendationRGB } from "./generalStyles";

// Animates the bar changing values
const getBarAnimation = props => keyframes`
        from {
            width: ${props.$prevWidth}%;
        }
        to {
            width: ${props.width}%;
        }`;

/**
 * Should be placed within parent component that will be the full width of bar,
 * and should be provided with props for animation
 * @prevWidth what was the previod width
 * @width new width
 */
export const Bar = styled.div`
    position: relative;
    height: 1.5rem;
    float: right;
    text-align: center;
    transform: translate(0.6rem);
    color: ${colorTheme};
    font-size: .9rem;
    width: calc(${props => props.width}% + 0.6rem);
    animation: ${props => getBarAnimation(props)} 1s ease-in-out;
    background: linear-gradient(120deg, transparent 0.7rem, ${props =>
        `rgb(${recommendationRGB}, ${props.percent < 10 ? .1 : props.percent / 100 / 2 + 0.5}) calc(0.7rem + 1px))`}; //opacity should go from .5 to 1
    &:after{
        content:'';
        position:absolute;
        left: .1rem;
        bottom: -.1rem;
        width: 100%;
        height:.1rem;
        background: linear-gradient(120deg, transparent 0.1rem, ${props =>
        `rgb(0,0,0, ${props.percent < 10 ? .1 : props.percent / 100 / 2 + 0.5}) calc(0.7rem + 1px))`}; //opacity should go from .5 to 1
    }    
`;