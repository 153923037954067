import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';
import { colorTheme, textOnTheme } from '../../../sharedStyledComponents/generalStyles';
import DropdownTab from './workinginstructions.dropdowntab';

export default class Tabs extends PureComponent {

    componentDidUpdate() {
        if (!this.props.currentCategory) {
            this.props.setCurrentCategory(this.props.categories[0]?.value);
        }
    }

    render() {
        const { categories, currentCategory, setCurrentCategory } = this.props;
        const sortedCategories = categories.sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));
        return (

            <Container>
                {categories &&
                    <>
                        <Tab onClick={() => setCurrentCategory(sortedCategories[0].value)} $current={sortedCategories[0]?.value === currentCategory}>{sortedCategories[0]?.value}</Tab>
                        {sortedCategories.length > 1
                            &&
                            < DropdownTab
                                categories={sortedCategories}
                                setCurrentCategory={setCurrentCategory}
                                currentCategory={currentCategory}
                            />
                        }
                    </>
                }
            </Container>
        )
    }
}

Tabs.propTypes = {
    categories: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentCategory: PropTypes.string,
    setCurrentCategory: PropTypes.func.isRequired,
}

const Container = styled.div`
    display: inline-flex;
    flex-flow: wrap;
    border-radius: .2rem .2rem 0 0;
    overflow: hidden;
    width: 100%;
    border-bottom: .2rem solid ${colorTheme};
`;

const Tab = styled.div`
    background-color: ${props => props.$current ? colorTheme : textOnTheme};
    color: ${props => props.$current ? textOnTheme : colorTheme};
    user-select: none;
    border-radius: .2rem .2rem 0 0;
    cursor: pointer;
    padding: .5rem 1rem .3rem 1rem;
    font-weight: 550;
    user-select: none;
    flex: 1;
    box-shadow: -1px -1px 1px #888;
`;