import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import closeButton from '../../../GlobalComponents/close.component';

import { colorTheme, textOnTheme, linkItemColor } from '../../../sharedStyledComponents/generalStyles';

export const HeaderTab = React.memo(function multiDocumentHeaderTab(props) {
  const handleClose = (event) => {
    event.stopPropagation();
    props.removeActiveDocument(props.id);
  };
  return (
    <Container $isActive={props.isActive} onClick={() => props.setCurrentActiveDocument(props.id)}>
      <Title $isActive={props.isActive}>{props.title}</Title>
      {closeButton({ size: '.75rem', color: props.isActive ? textOnTheme : colorTheme, cb: handleClose })}
    </Container>
  );
});

HeaderTab.propTypes = {
  isActive: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  removeActiveDocument: PropTypes.func.isRequired,
};

const Container = styled.span`
  border-radius: 5px 5px 0 0;
  margin-right: 0.1rem;
  padding: 0.5rem;
  display: flex;
  cursor: pointer;
  align-items: center;
  background-color: ${(props) => (props.$isActive ? colorTheme : linkItemColor)};
  box-shadow: -1px -1px 1px #888;
  min-width: 0rem;
`;

const Title = styled.span`
  align-self: center;
  padding-right: 0.5rem;
  color: ${(props) => (props.$isActive ? textOnTheme : colorTheme)};
  font-weight: 550;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
