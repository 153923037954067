import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { StyledTable } from '../../sharedStyledComponents/dataGridStyles';
import dayjs from 'dayjs';
import 'dayjs/locale/da';
import { Empty } from 'antd';
import { RightOutlined, DownOutlined } from '@ant-design/icons';
import { inlineHeader } from '../../sharedStyledComponents/dataGridStyles';
import { AGENT_STATUS_TYPE } from './_constants/instructionChanges.contstants';

dayjs.locale('da');

const InstructionChangesTable = ({ documentChanges, loading, onChangeClicked }) => {
  const [displayedRows, setDisplayedRows] = useState([]);

  useEffect(() => {
    const instructionGroups = documentChanges.reduce((groups, change) => {
      if (groups[change.docId]) {
        groups[change.docId].changes.push(change);
      } else {
        groups[change.docId] = {
          docId: change.docId,
          docName: change.docName,
          changes: [change],
        };
      }

      return groups;
    }, {});

    const tableRows = Object.values(instructionGroups).reduce((rows, group) => {
      if (group.changes.length === 1) {
        rows.push({
          ...group.changes[0],
          groupRow: false,
        });
      } else {
        rows.push({
          _id: group.docId,
          docId: group.docId,
          docName: group.docName,
          published: group.changes.reduce((max, change) => {
            return Math.max(max, change.published);
          }, 0),
          sectionName: `${group.changes.length} sektioner`,
          agentStatus: group.changes.reduce((acc, change) => [...acc, ...change.agentStatus], []),
          timestamp: group.changes.reduce((max, change) => {
            return Math.max(max, change.timestamp);
          }, 0),
          lastSeen: group.changes.reduce((max, change) => {
            return Math.max(max, change.lastSeen);
          }, 0),
          groupRow: true,
          expanded: false,
          children: group.changes.map((change) => ({
            ...change,
            groupRow: false,
          })),
        });
      }

      return rows;
    }, []);

    setDisplayedRows(tableRows);
  }, [documentChanges]);

  const onRowClick = (record) => {
    if (!record.groupRow) {
      onChangeClicked(record);
    }
  };

  const renderAgentStatus = (record) => {
    const seenCount = record.agentStatus.filter((a) => a.status === AGENT_STATUS_TYPE.seen).length;

    if (record.groupRow) {
      return (
        <AgentStatus color={seenCount === record.agentStatus.length ? 'green' : seenCount === 0 ? 'red' : 'orange'}>
          {<>{seenCount === 0 ? 'Ikke set' : 'Set'}</>}
        </AgentStatus>
      );
    } else if (record.agentStatus.length === 1) {
      return (
        <AgentStatus
          color={
            record.agentStatus[0].status === AGENT_STATUS_TYPE.seen
              ? 'green'
              : record.agentStatus[0].status === AGENT_STATUS_TYPE.notSeen
              ? 'red'
              : 'grey'
          }
        >
          <>
            {record.agentStatus[0].status === AGENT_STATUS_TYPE.seen
              ? 'Set'
              : record.agentStatus[0].status === AGENT_STATUS_TYPE.notSeen
              ? 'Ikke set'
              : 'N/A'}
          </>
        </AgentStatus>
      );
    } else {
      return (
        <AgentStatus color={seenCount === record.agentStatus.length ? 'green' : seenCount === 0 ? 'red' : 'orange'}>
          <>Set {seenCount + '/' + record.agentStatus.length}</>
        </AgentStatus>
      );
    }
  };

  const columns = [
    {
      dataIndex: 'docName',
      title: inlineHeader('Arbejdsinstruks'),
      sorter: (a, b) => {
        if (a.docName === b.docName) {
          return a.sectionName.localeCompare(b.sectionName);
        }
        return a.docName.localeCompare(b.docName);
      },
      width: '25%',
      ellipsis: true,
    },
    {
      dataIndex: 'sectionName',
      title: inlineHeader('Sektion'),
      sorter: (a, b) =>
        a.sectionName.localeCompare(b.sectionName, undefined, {
          numeric: true,
          sensitivity: 'base',
        }),
      // defaultSortOrder: 'ascend',
      width: '30%',
      ellipsis: true,
    },
    {
      dataIndex: 'published',
      title: inlineHeader('Sidst publiceret'),
      sorter: (a, b) => a.published - b.published,
      render: (_, record) => {
        const published = dayjs(record.published);
        return <>{`${published.date()}/${published.month() + 1}-${published.year()} kl ${published.format('LT')}`}</>;
      },
      defaultSortOrder: 'descend',
    },
    {
      dataIndex: 'lastSeen',
      title: inlineHeader('Sidst set'),
      sorter: (a, b) => a.lastSeen - b.lastSeen,
      width: '20%',
      align: 'center',
      render: (_, record) => {
        if (record.lastSeen === 0) return '-';
        else {
          const lastSeen = dayjs(record.lastSeen);
          return <>{`${lastSeen.date()}/${lastSeen.month() + 1}-${lastSeen.year()} kl ${lastSeen.format('LT')}`}</>;
        }
      },
    },
    {
      dataIndex: 'agentStatus',
      title: inlineHeader('Status'),
      sorter: (a, b) =>
        a.agentStatus.filter((x) => x.status === AGENT_STATUS_TYPE.seen).length -
        b.agentStatus.filter((x) => x.status === AGENT_STATUS_TYPE.seen).length,
      width: '150px',
      render: (_, record) => {
        return <>{renderAgentStatus(record)}</>;
      },
    },
  ];

  const _onExpand = (expanded, record) => {
    setDisplayedRows(
      displayedRows.map((row) => ({
        ...row,
        expanded: row._id === record._id && record.groupRow ? expanded : row.expanded,
      }))
    );
  };

  return (
    <>
      <ChangeTable
        columns={columns}
        locale={{
          triggerDesc: 'Klik for at sortere faldende',
          triggerAsc: 'Klik for at sortere stigende',
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Ingen ændringer" />,
        }}
        clickableRow
        sortDirections={['ascend', 'descend']}
        pagination={false}
        dataSource={displayedRows}
        scroll={{ y: 700 }}
        rowKey={'_id'}
        loading={loading}
        rowClassName={(record, index) => `${record.groupRow && record.expanded ? 'group-row-expanded' : ''}`}
        onRow={(record) => {
          return {
            onClick: () => onRowClick(record),
          };
        }}
        expandable={{
          rowExpandable: (record) => record.groupRow,
          expandRowByClick: (record) => record.groupRow,
          onExpand: (expanded, record) => _onExpand(expanded, record),
          indentSize: 0,
          expandIcon: ({ expanded, record }) => {
            if (record.groupRow) {
              return (
                <ExpandIconWrapper>
                  {expanded ? (
                    <DownOutlined onClick={(e) => _onExpand(record, e)} />
                  ) : (
                    <RightOutlined onClick={(e) => _onExpand(record, e)} />
                  )}
                </ExpandIconWrapper>
              );
            } else {
              return <RowIndent />;
            }
          },
        }}
      />
    </>
  );
};

export default InstructionChangesTable;

const ChangeTable = styled(StyledTable)`
  .ant-table-tbody > tr {
    &.ant-table-row-level-0 td {
      background-color: #fff;
    }

    &.ant-table-row-level-1 td {
      background-color: rgba(0, 0, 0, 0.04);
    }

    &.group-row-expanded td {
      border-bottom: 1px solid rgba(0, 0, 0, 0.45);
    }
  }
`;

const ExpandIconWrapper = styled.span`
  padding-right: 8px;

  svg {
    font-size: 12px;
  }
`;

const RowIndent = styled.span`
  padding-left: 21px;
`;

const AgentStatus = styled.div`
  position: relative;
  padding-left: 1.2rem;

  &:before {
    position: absolute;
    content: '';
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
`;
