import React from 'react';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

export const Loading = ({ children }) => {
  return (
    <LoadingBox>
      <FlexCenter>
        <div>
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
        {children}
      </FlexCenter>
    </LoadingBox>
  )
}

// const Spinner = () => (
//   <StyledSpinner viewBox="0 0 50 50">
//     <circle
//       className="path"
//       cx="25"
//       cy="25"
//       r="20"
//       fill="none"
//       strokeWidth="8"
//     />
//   </StyledSpinner>
// );

const FlexCenter = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LoadingBox = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  left:0%;
  background: #fff;
  opacity: 0.8;
  z-index: 9999;
`;

// OLD SPINNER REPLACED FOR IE11 Support 
// const StyledSpinner = styled.svg`
//   z-index: 10000;
//   animation: rotate 2s linear infinite;
//   width: 50px;
//   height: 50px;

//   & .path {
//     stroke: #5652BF;
//     stroke-linecap: round;
//     animation: dash 1.5s ease-in-out infinite;
//   }

//   @keyframes rotate {
//     100% {
//       transform: rotate(360deg);
//     }
//   }
//   @keyframes dash {
//     0% {
//       stroke-dasharray: 1, 150;
//       stroke-dashoffset: 0;
//     }
//     50% {
//       stroke-dasharray: 90, 150;
//       stroke-dashoffset: -35;
//     }
//     100% {
//       stroke-dasharray: 90, 150;
//       stroke-dashoffset: -124;
//     }
//   }
// `;