import styled from 'styled-components';
import { Typography, Button } from 'antd';

// Constant color variables
export const colorTheme = '#2f4960';
export const colorThemeDarkend = '#223C53';
export const textTheme = '#666';
export const textOnTheme = '#e7e9ec';
export const generalBackground = '#f5f5f5';
export const itemBackground = '#ffffff';
export const borderColor = '#f0f0f0';
export const linkItemColor = '#e1ebed';
export const linkItemHover = '#cad3d5';
export const grayedText = '#b3b3b3';
export const popupBackground = '#E0F6FF';
export const popupBorder = '#81CAFF';
export const KPI_GREEN = '#006104';
export const KPI_RED = '#6D0000';
export const KPI_GREY = '#979797';
export const warning = '#BF0017';
export const success = '#0A5105';
export const recommendationRGB = '255, 165, 0';

export const StyledLink = styled.a`
  padding: 0.2rem 1rem;
  background-color: ${linkItemColor};
  color: ${colorTheme};
  text-decoration: none !important;
  list-style: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  &:hover {
    background-color: ${linkItemHover};
    color: initial;
  }
`;

export const CloseIcon = styled.button`
  position:absolute;
  top: 2rem;
  right: 2rem;
  background-color: transparent;
  border: none;
  width:20px;
  height:20px;
  &:hover{
    cursor:pointer;
  }
`;

export const TitleText = styled(Typography.Title)`
  &.ant-typography {
    color: ${(props) => (props.color ? props.color : textTheme)};
    font-size: ${(props) => props.fontSize || '1.2rem;'};
    font-weight: ${props => props.fontWeight || '600'};
    white-space: nowrap;
  }
`;

export const TitleTextWithSecondaryTitle = styled(TitleText)`
  & > span {
    font-size: 2.7rem !important;
    font-weight: 400;
    color: ${colorTheme};
  }
`;

export const Content = styled.div`
  margin: 0rem 1.2rem 1.5rem 1.2rem;
  background-color: ${itemBackground};
  border-radius: 0.2rem;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
`;

//Chosen
export const ChosenWrapper = styled.div`
  padding: 0.5rem 1.5rem;
  border-bottom: 0.1rem solid ${borderColor};
`;

export const TextUnderlineAnimated = styled.div`
  position: relative;
  text-decoration: none;
  display: inline-block;
  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #000;
    visibility: hidden;
    transform: width;
    transition: all 0.3s ease-out 0s;
  }
`;
/*
This must be added to the component that is the parent to the TextUnderlineAnimated,
this ensures that the parent component will trigger the underline effect.
  &:hover {
      ${TextUnderlineAnimated}:before {
          visibility: visible;
          width: 100%;
          background-color: ${textTheme};
          -webkit-transform: width;
          transform: width;
      }
  }
*/


export const StyledButton = styled(Button)`
  background-color: ${colorTheme};
  border-color: ${colorTheme};
  border-radius: 0.5rem;

  &.ant-btn-round {
    border-radius: 10px;
  }
  &:hover:not([disabled]),
  &:focus {
    background-color: ${colorTheme} !important;
    border-color: ${colorTheme};
    span {
      text-decoration: underline;
    }
  }
`;

export const StyledButtonInverse = styled(StyledButton)`
  background-color: transparent;
  border-color: ${colorTheme};
  color: ${colorTheme};

  &:hover,
  &:focus {
    background-color: transparent;
    border-color: ${colorTheme};
    color: ${colorTheme};
  }
`;