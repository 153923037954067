import { reopenDocuments, timerStart } from './dashboard.actions';
// Socket action
export const CALL_STATUS = 'socket-io.actions/CALL_STATUS';
export const N1_RECEIVED = 'socket-io.actions/N1_RECEIVED';
export const N2_RECEIVED = 'socket-io.actions/N2_RECEIVED';
export const COMPLIANCE_SCORES_RECEIVED = 'socket-io.actions/COMPLIANCE_SCORES_RECEIVED';
export const N1_CLICK = 'socket-io.actions/N1_CLICK';
export const N2_CLICK = 'socket-io.actions/N2_CLICK';
export const BACK_CLICK = 'socket-io.actions/BACK_CLICK';
export const RECONNECT = 'socket-io.actions/RECONNECT';
export const USER_INACTIVE = 'socket-io.actions/USER_INACTIVE';
export const TAB_COUNT = 'socket-io.actions/TAB_COUNT';

export const COMPLIANCE_ITEM_TOGGLE = 'socket-io.actions/COMPLIANCE_ITEM_CHECK_TOGGLE';

// An action to subscribe to a certain event type, and the reducer handle to catch the event from socket io
export function subscribeMessages() {
  return {
    event: 'n1',
    handle: N1_RECEIVED,
  };
}

// to unsubscribe, call this action with the event name and the leave parameter
export function unsubscribeMessages() {
  return {
    event: 'n1',
    leave: true,
  };
}

export function callStatus() {
  return {
    event: 'recording',
    handle: CALL_STATUS,
  };
}

export function unsubscribeCallStatus() {
  return {
    event: 'recording',
    leave: true,
  };
}

export function headersReceived() {
  return {
    event: 'n2',
    handle: N2_RECEIVED,
  };
}

export function unsubscribeHeadersReceived() {
  return {
    event: 'n2',
    leave: true,
  };
}

export function complianceScoresReceived() {
  return {
    event: 'compliance_scores',
    handle: COMPLIANCE_SCORES_RECEIVED,
  };
}

export function unsubscribecomplianceScoresReceived() {
  return {
    event: 'compliance_scores',
    leave: true,
  };
}

export function n1Click(instructionId, callStatus) {
  return {
    event: 'n1 clicked',
    emit: N1_CLICK,
    payload: instructionId,
    payloadName: 'n1',
    callStatus,
  };
}

export function unsubscribeN1Click() {
  return {
    event: 'n1 clicked',
    leave: true,
  };
}

export function n2Click(title, callStatus, id) {
  return {
    event: 'n2 clicked',
    emit: N2_CLICK,
    payload: {
      title,
      id,
    },
    payloadName: 'n2',
    callStatus,
  };
}

export function unsubscribeN2Click() {
  return {
    event: 'n2 clicked',
    leave: true,
  };
}

export function backClick(callStatus) {
  return {
    event: 'back clicked',
    emit: BACK_CLICK,
    callStatus,
  };
}

export function unsubscribeBackClick() {
  return {
    event: 'back clicked',
    leave: true,
  };
}

export function reconnect() {
  return async (dispatch) => {
    await dispatch({
      event: 'current state',
      handle: RECONNECT,
    });
    return (
      dispatch(timerStart()),
      dispatch(reopenDocuments()));
  };
}

export function tabCount() {
  return {
    event: 'tab count',
    handle: TAB_COUNT,
  };
}

export function unsubscribeReconnect() {
  return {
    event: 'current state',
    leave: true,
  };
}

export function complianceItemToggle(complianceItemId, value) {
  return {
    event: 'compliance_item_toggle',
    emit: COMPLIANCE_ITEM_TOGGLE,
    payload: { complianceItemId, value },
  };
}

export function unsubscribeComplianceItemToggle() {
  return {
    event: 'compliance_item_toggle',
    leave: true,
  };
}

export function userInactive(id) {
  return {
    event: 'user_inactive',
    emit: USER_INACTIVE,
    payload: id,
  };
}

export function unsubscribeUserInactive(id) {
  return {
    event: 'user_inactive',
    leave: true,
  };
}
