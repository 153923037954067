import styled from 'styled-components';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const CloseButton = (props) => <CloseBtn title='Luk' onClick={props.cb} type='text' color={props.color} icon={<CloseOutlined />} size={props.size ? props.size : 'small'} />;

const CloseBtn = styled(Button)`
  align-self: center;
  color: ${(props) => props.color};
  padding: 0.2rem;
  background-color: transparent;
  transition: transform 0.3s ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: transparent !important;
    color: ${(props) => props.color} !important;
    transform: scale(1.2);
  }
`;
export default CloseButton;
