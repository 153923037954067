import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { colorTheme, linkItemHover } from '../../../sharedStyledComponents/generalStyles';
import { addIcon, closeIcon } from '../../../sharedStyledComponents/icons';

export default class Categories extends PureComponent {
  componentDidUpdate(prevProps) {
    // Add all categories as active in the filter list
    if (prevProps.categories !== this.props.categories) {
      this.props.addFilterCategory({ name: 'Kørsler', id: 'Kørsler' });
      this.props.removeFilterCategory({ name: 'Kørsler', id: 'Kørsler' });

      this.props.categories.forEach((c) => {
        this.props.addFilterCategory({ ...c, name: c.value });
      });
    }
  }

  handleClick = (e, category) => {
    e.preventDefault();
    // If active and more than one category is chosen
    if (
      this.props.activeCategories[category].active &&
      Object.keys(this.props.activeCategories).filter((c) => this.props.activeCategories[c].active).length > 1
    ) {
      this.props.removeFilterCategory({ name: category });
    }

    if (!this.props.activeCategories[category].active) {
      this.props.addFilterCategory({ name: category });
    }

    /**
     * Do a suggestions search upon toggling a category if searchword is present
     * and if more that one category is active
     */
    if (
      this.props.searchWord.length > 0 &&
      Object.keys(this.props.activeCategories).filter((c) => this.props.activeCategories[c].active).length >= 1
    ) {
      this.props.suggestionsRequest();
    }
  };

  _createButtons = () => {
    // if list does not exist yet skip
    if (Object.keys(this.props.activeCategories).length > 0) {
      const transactions = 'Kørsler';

      // Create list of components for all except transactions as this should alwas be the last no matter the name
      const categoryItems = Object.keys(this.props.activeCategories)
        .sort()
        .filter((c) => c !== transactions)
        .map((c) => {
          return (
            <CategoryItem key={c} $active={this.props.activeCategories[c].active} onClick={(e) => this.handleClick(e, c)}>
              {c}
              {this.props.activeCategories[c].active ? <Icon>{closeIcon('12px', colorTheme)}</Icon> : <Icon>{addIcon('12px', linkItemHover)}</Icon>}
            </CategoryItem>
          );
        });
      // add the last transactions object to the end of the alphabeticly sorted array
      categoryItems.push(
        <CategoryItem key={transactions} $active={this.props.activeCategories[transactions].active} onClick={(e) => this.handleClick(e, transactions)}>
          {transactions}
          {this.props.activeCategories[transactions].active ? (
            <Icon>{closeIcon('12px', colorTheme)}</Icon>
          ) : (
              <Icon>{addIcon('12px', linkItemHover)}</Icon>
            )}
        </CategoryItem>
      );
      return categoryItems;
    }
    return null;
  };
  render() {
    return (
      <Container>
        {this.props.categories && this._createButtons()}
        {/* Static files not clickable or searchable but must figure in list */}
        {/* <CategoryItem>
                    Kørsler
                    <Icon>{addIcon('12px', linkItemHover)}</Icon>
                </CategoryItem> */}
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-items: center;
  margin: 0.6rem 0.6rem 0rem 0.6rem;
  border-radius: 0.2rem;
  overflow: hidden;
  
`;

const CategoryItem = styled.div`
  display: inline-flex;
  user-select: none;
  cursor: pointer;
  align-items: center;
  padding: 0.2rem 0.3rem;
  border-radius: 0.2rem;
  margin-right: 0.2rem;
  font-weight: 500;
  border: 1px solid ${linkItemHover};
  color: ${(props) => (props.$active ? colorTheme : linkItemHover)};
`;
const Icon = styled.span`
  margin-left: 0.2rem;
  display: inline-flex;
  align-items: center;
`;
