import React from 'react';
import { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types'


import { Content, TitleText, colorTheme, textTheme, textOnTheme, generalBackground } from '../sharedStyledComponents/generalStyles';
import { userIcon, lockIcon, warningIcon } from '../sharedStyledComponents/icons';

export class SignIn extends Component {
    constructor(props) {
        super(props)

        this.state = {
            username: '',
            password: '',
        }

        this.showAdminTag = this.props.fromPath?.includes('admin');
    }
    handleChange = (event) => this.setState({ [event.target.name]: event.target.value });

    render() {
        let formFilled = this.state.username !== '' && this.state.password !== '';
        let errorMsg = this.props.errorMsg;
        return (
            <Container>
                <Header>
                    <TitleText fontSize={'1.4rem'} fontWeight={this.showAdminTag ? '500' : ''}>
                        IP Supportbot
                        {this.showAdminTag ? <AdminTag> Admin site</AdminTag> : ''}
                    </TitleText>
                </Header>
                <SubHeaderContainer>
                    <TitleText>Login</TitleText>
                    <br />
                    <SubHeaderText>Du logger ind med dine standard IP oplysninger.</SubHeaderText>
                </SubHeaderContainer>
                {errorMsg === ''
                    ? ''
                    : <ErrorBox>
                        <ErrorIcon>{warningIcon('30px', colorTheme)}</ErrorIcon>
                        <ErrorText>
                            {errorMsg}
                        </ErrorText>
                    </ErrorBox>
                }
                <FormContainer>
                    <form onSubmit={this.props.onSubmit} >
                        <InputWrapper >
                            <Icon $padRight={'1rem'}>{userIcon('45px', colorTheme)}</Icon>
                            <Input tabIndex="1" onChange={this.handleChange} type="text" id="inputUsername" name="username" placeholder="Brugernavn" required autoFocus />
                        </InputWrapper>
                        <br />
                        <InputWrapper>
                            <Icon $padRight={'1.3rem'} >{lockIcon('35px', colorTheme)}</Icon>
                            <Input tabIndex="2" onChange={this.handleChange} type="password" id="inputPassword" name="password" placeholder="Adgangskode" required />
                        </InputWrapper>
                        <br />
                        {/* <ButtonWrapper>
                            <ForgotPasswordLink onClick={this.<doStuff>} href=''>Glemt adgangskode?</ForgotPasswordLink>
                        </ButtonWrapper> */}
                        <ButtonWrapper >
                            <Button disabled={!formFilled} type="submit">LOGIN</Button>
                        </ButtonWrapper>
                    </form>
                </FormContainer>
                <Footer>Powered by IBM Watson</Footer>
            </Container>)
    }
}

SignIn.propTypes = {
    fromPath: PropTypes.string,
    errorMsg: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,

}

const Container = styled(Content)`
    margin: 0;
    border-radius: 0;
    background-color: ${generalBackground};
    max-width: 26rem;
`;

const Header = styled(Content)`
    border-radius: 0;
    padding: 1rem 2rem;
    margin : 0;
`;


const AdminTag = styled.span`
  font-weight: 600;
`;

const SubHeaderContainer = styled.div`
    padding: 1rem 2rem;
`;

const SubHeaderText = styled.div`
    color: ${textTheme};
`;

const ErrorBox = styled.div`
    display: flex;
    color: red;
    border: 1px solid red;
    margin: .5rem 2rem;
    background-color: rgba(231, 76, 60,0.05);
`;

const ErrorText = styled.span`
    font-size: .8rem;
    padding: .2rem .3rem;
`;

const ErrorIcon = styled.span`
    padding: .3rem 1rem;
    max-height: 2.5rem;
`;

const FormContainer = styled.div`
    padding: 1rem 2rem;
    text-align: right;
`;

const InputWrapper = styled.div`
    display: flex;
    height: 2.5rem;
    max-height: 2.5rem;
    align-items: center;
    justify-content: center;
`;

const Icon = styled.span`
    margin-right: ${props => props.$padRight};
    text-align: left;
`;

const Input = styled.input`
    border-radius: 2rem;
    border: 2px solid #8c97a3;
    padding: 1rem 1rem 1em 1em;
    line-height: 1;
    color: #a0a0a0;
    width: 70%;
    opacity: 1; /* Firefox */
    font-style: italic;
    font-size: .9rem;

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #a0a0a0;
        opacity: 1; /* Firefox */
        font-style: italic;
        font-size: .9rem;
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #a0a0a0;
        font-style: italic;
        font-size: .9rem;
    }
    ::-ms-input-placeholder { /* Microsoft Edge */
        color: #a0a0a0;
        font-style: italic;
        font-size: .9rem;
    }
`;

// const ForgotPasswordLink = styled.a`
//     text-decoration: underline;
//     color: #b3b3b3;
//     :hover {
//         color: ${colorTheme};
//         text-decoration: none;
//     }
// `;

const ButtonWrapper = styled.div`
    margin: 2rem 3rem;
    text-align: center;
`;

const Button = styled.button`
    background-color: ${colorTheme};
    font-weight: 700;
    color: #f5f5f5;
    border-radius: .4rem;
    padding: .5rem 6rem;
    margin-left: 1.1rem;
    :disabled {
        background-color: #b3b3b3;
    }
`;

const Footer = styled.div`
  background-color : ${colorTheme};
  padding: .5rem 1rem;
  text-align: end;
  color: ${textOnTheme};
  font-weight: 700;
  font-size: .9rem;
`;