import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components';
import { orderBy } from 'lodash';

import { addActiveDocument } from '../../../_actions/multiDocument.actions';
import { setCurrentCategory } from '../../../_actions/dashboard.actions';

import { Content, colorTheme, borderColor, textTheme, TextUnderlineAnimated } from '../../sharedStyledComponents/generalStyles';
import Tabs from './components/workinstructions.tabs';
import { HeartFilled, HeartOutlined } from '@ant-design/icons';
import { toggleFavInstruction } from '../../../_actions/agentConfig.actions';
import { message } from 'antd';
import NotificationCircle from '../../sharedStyledComponents/NotificationCircle.component';

export class Workinstructions extends PureComponent {

    render() {
        const { currentCategory } = this.props;
        const workInstructionsWithFavAttribute = this.props.workInstructions.map(wi => {
            return {
                ...wi,
                isFav: this.props.favoriteInstructions?.indexOf(wi.id) >= 0
            }
        })

        const workInstructionsToDisplay = orderBy(workInstructionsWithFavAttribute.filter((wI) => wI.category === currentCategory), ['isFav', 'name'], ['desc', 'asc'])

        const onToggleFav = (workInstruction) => (event) => {
            //on click on toggle, document should not be opened, hence stopping
            event.stopPropagation();

            const amountOfInstructionsAsFav = workInstructionsToDisplay.filter(wi => wi.isFav)?.length;

            //there is only allowed 5 favs pr. instruction type
            if (amountOfInstructionsAsFav > 4 && !workInstructionsToDisplay.find(wi => wi.id === workInstruction.id)?.isFav) {
                message.warning('Maks 5 instrukser kan være tilføjet som favorit');
                return;
            }

            this.props.toggleFavInstruction(this.props.favoriteInstructions || [], workInstruction.id);
        }

        const Avatar = ({ workInstruction }) => {
            if (workInstruction.isFav) return <HeartFilled title='Fjern som favorit' onClick={onToggleFav(workInstruction)} />
            return <HeartOutlined title='Tilføj som favorit' onClick={onToggleFav(workInstruction)} />
        }

        const getAmountOfUnseenChangesInWI = (wIId) => {
            const changes = this.props.unseenDocumentChanges?.filter(change => change.docId === wIId)
            return changes?.length;
        }

        return (
            <Container>
                <Tabs
                    categories={this.props.categories}
                    currentCategory={currentCategory}
                    setCurrentCategory={this.props.setCurrentCategory}
                />
                <ListWrapper>
                    {workInstructionsToDisplay.map(wI => (
                        <ListItemLink key={wI.id} onClick={() => this.props.addActiveDocument(wI.id)}>
                            <Icon><Avatar workInstruction={wI} /></Icon>
                            <TextUnderlineAnimated>
                                {wI.name.charAt(0).toUpperCase() + wI.name.slice(1)}
                            </TextUnderlineAnimated>
                            <NotificationCircle number={getAmountOfUnseenChangesInWI(wI.id)} shouldPositionInlineRight={true} />
                        </ListItemLink>
                    ))}
                </ListWrapper>
            </Container>
        )
    }
}

Workinstructions.propTypes = {
    loading: PropTypes.bool,
    categories: PropTypes.arrayOf(PropTypes.object).isRequired,
    workInstructions: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        category: PropTypes.string
    })).isRequired,
    addActiveDocument: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    workInstructions: state.dashboard.workInstructions,
    categories: state.dashboard.categories,
    loading: state.dashboard.loading,
    currentCategory: state.dashboard.currentCategory,
    favoriteInstructions: state.agentConfig.favoriteInstructions,
    unseenDocumentChanges: state.agentConfig.unseenDocumentChanges,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    addActiveDocument,
    setCurrentCategory,
    toggleFavInstruction,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Workinstructions)

const Container = styled(Content)`
    ::-webkit-scrollbar {
    display: none;
    }
`;

const ListWrapper = styled.div`
    flex-grow: 1;
`;

const Icon = styled.div`
  margin-right: .5rem;
  display: flex;
  align-items: center;
  span > svg{
    fill: ${colorTheme};
  }
  :hover{
    span > svg{
        opacity: .5;
      }
  }
`;

const ListItemLink = styled.li`
    position:relative;
    margin: 0;
    padding: .35rem 1rem;
    border-bottom: .1rem solid ${borderColor};
    color : #666;
    cursor: pointer;
    list-style: none;
    display: inline-flex;
    width: 100%;
    &:hover {
        ${TextUnderlineAnimated}:before {
            visibility: visible;
            width: 100%;
            background-color: ${textTheme};
            transform: width;
        }
    }
`;