import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import {Buffer} from 'buffer';

import MultiDocumentHeader from './components/multiDocument.header';
import Toolbar from './components/document.toolbar';
import Viewer from './document.viewer';
import NoDocument from '../components/nodocument.component';

import {
  removeActiveDocument,
  setCurrentActiveDocument,
  addDocumentPdfProps,
  removeAllActiveDocuments,
  setCurrentScale,
  setCurrentTabToListview,
  setCurrentPage,
} from '../../../_actions/multiDocument.actions';
import { navigateTo, clearNavigation } from '../../../_actions/chosen.actions';

import { setDocumentTotalRevisionAsViewed } from '../../../_actions/agentConfig.actions';

import Utils from '../../../utils/Utils';
import { Alert, Tooltip } from 'antd';
import { StyledButton, textOnTheme } from '../../sharedStyledComponents/generalStyles';
import { version } from 'pdfjs-dist';
const PdfjsLib = await import ('pdfjs-dist/build/pdf');

export class MultiDocumentContainer extends Component {
  constructor(props) {
    super(props);

    PdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/pdf.worker.mjs`;

    this.worker = new PdfjsLib.PDFWorker('pdf-viewer');
    this.state = { searchTerm: '' };
    /** !TODO:  USE this to make UI unclickable until fired! This would help tackle the TextLayer Render Cancelled error
            !!Does not seem to be an issue while in a dev / prod build.!!
            // this.viewer.eventBus.on('textlayerrendered', () => alert('cancelled'))
       */
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.currentDocumentIndex !== prevProps.currentDocumentIndex &&
      this.props.currentDocumentIndex !== 'Listview'
    ) {
      const currentDoc = this.props.activeDocuments[this.props.currentDocumentIndex];
      // If document is already loaded just display it in the viewer
      if (!currentDoc?.pdfProxy) {
        try {
          this.loadPdfDocument(currentDoc);
        } catch (error) {}
      }
    }
  }

  /**
   * Loads in pdf document using pdf js worker
   * also adds pdf proxy (loaded document) and outline to pdf props in redux
   * @param document
   */
  loadPdfDocument(document) {
    // Create pdfjs promise loading the document

    const data = Buffer.from(document?.base64, 'base64')
    const loadingTask = PdfjsLib.getDocument({
      data: data,
      worker: this.worker,
    })
    // When document is ready pass it along to the viewer, with the outline.
    loadingTask.promise
      .then(async (pdfProxy) => {
        const outline = await pdfProxy.getOutline();
        // add props to document object
        this.props.addDocumentPdfProps(document, { pdfProxy, outline });

        // dispatch event when document is loaded for triggering scroll events in document
        window.dispatchEvent(new Event('pagesloaded'));
      })
      .catch((err) => console.error(`Error during document loading: ${err}`));
  }

  componentWillUnmount() {
    try {
      this.viewer.setState({ searchTerm: '' });
      this.worker.destroy();
    } catch (err) {}
  }

  zoomIn = () => {
    let { scale } = this.viewer.state;

    scale = Utils.roundToNearest(scale, 10);
    let newScale;

    if (scale >= 110 && scale < 990) {
      newScale = scale + 20;
    } else if (scale === 75 || scale === 125) {
      newScale = scale + 5;
    } else {
      newScale = scale + 10;
    }

    if (newScale > 1000) return;

    this.viewer.setScale(newScale);
  };

  zoomOut = () => {
    let { scale } = this.viewer.state;
    scale = scale === 125 ? scale : Utils.roundToNearest(scale, 10);
    let newScale;

    if (scale > 110 && scale !== 125) {
      newScale = scale - 20;
    } else if (scale === 75 || scale === 125) {
      newScale = scale - 5;
    } else {
      newScale = scale - 10;
    }

    if (newScale <= 0) return;
    this.viewer.setScale(newScale);
  };

  setCalculatedScale = (scale) => {
    this.viewer.setAutoScale(scale);
  };

  toTop() {
    this.viewer.setScrollTop(0);
  }

  handleSearch = (value) => {
    // Set the state searchTerm of the component
    this.setState({ searchTerm: value });
  };

  handleSwitchMatch = (next) => {
    if (this.state.searchTerm !== '') this.viewer.switchMatch(next);
  };

  onMarkDocumentAsRead = (e) => {
    e.stopPropagation();
    this.props.setDocumentTotalRevisionAsViewed({
      instructionId: this.props.activeDocuments[this.props.currentDocumentIndex].id,
    });
  };

  render() {
    const { activeDocuments, currentDocumentIndex } = this.props;
    const currentDoc =
      currentDocumentIndex !== 'Listview' ? activeDocuments[currentDocumentIndex] : currentDocumentIndex;

    const shouldSidebarBeWide = activeDocuments.length === 0 || currentDocumentIndex === 'Listview';
    const unseenSectionChanges = this.props.unseenDocumentChanges
      ?.filter((change) => change.docId === currentDoc?.id)
      ?.map((change) => change.sectionName);

    const documentHasTotalReview = this.props.unseenDocumentTotalRevisions?.find(
      (docRevision) => docRevision.documentId === currentDoc?.id
    );
    return (
      <Container style={{ width: shouldSidebarBeWide ? '50vw' : '75vw' }}>
        {' '}
        {/* Display document viewer when there are active documents */}
        <MultiDocumentHeader
          activeDocuments={this.props.activeDocuments}
          currentDocumentIndex={this.props.currentDocumentIndex}
          removeActiveDocument={this.props.removeActiveDocument}
          setCurrentActiveDocument={this.props.setCurrentActiveDocument}
          removeAllActiveDocuments={this.props.removeAllActiveDocuments}
          setCurrentTabToListview={this.props.setCurrentTabToListview}
        />
        {currentDoc && currentDoc !== 'Listview' && (
          <>
            <Toolbar
              currentDoc={currentDoc}
              onZoomIn={this.zoomIn}
              onZoomOut={this.zoomOut}
              onCalculatedScale={this.setCalculatedScale}
              onSearch={this.handleSearch}
              scale={this.props.currentScale}
              onSwitchMatch={this.handleSwitchMatch}
              navigateTo={this.props.navigateTo}
              currentPage={this.props.currentPage}
              totalPages={currentDoc.pdfProxy ? currentDoc.pdfProxy._pdfInfo.numPages : 0}
              searchWord={this.props.searchWord}
              unseenSectionChanges={documentHasTotalReview ? [] : unseenSectionChanges}
            />
            {documentHasTotalReview && (
              <StyledAlert
                message="Der er instruksændringer til hele denne arbejdsinstruks. Når du har set dem alle, så kan du klikke her:"
                type="info"
                action={
                  <Tooltip title="Du kan først klikke her når du har læst hele instruksen">
                    <ViewedAllButton
                      size="small"
                      onClick={this.onMarkDocumentAsRead}
                      disabled={this.props.currentPage !== currentDoc?.pdfProxy?._pdfInfo?.numPages}
                    >
                      Set alle
                    </ViewedAllButton>
                  </Tooltip>
                }
              />
            )}
            <Viewer
              ref={(ref) => (this.viewer = ref)}
              doc={currentDoc}
              pdfProxy={currentDoc.pdfProxy}
              searchTerm={this.state.searchTerm}
              loading={this.props.loadingDocument}
              addDocumentPdfProps={this.props.addDocumentPdfProps}
              clearNavigation={this.props.clearNavigation}
              navigateTitle={this.props.navigateTitle}
              setCurrentScale={this.props.setCurrentScale}
              setCurrentPage={this.props.setCurrentPage}
            />
          </>
        )}
        {currentDoc === 'Listview' && <NoDocument props={activeDocuments} />}
      </Container>
    );
  }
}

MultiDocumentContainer.propTypes = {
  loadingDocument: PropTypes.bool.isRequired,
  setCurrentActiveDocument: PropTypes.func.isRequired,
  removeActiveDocument: PropTypes.func.isRequired,
  addDocumentPdfProps: PropTypes.func.isRequired,
  currentDocumentIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  clearNavigation: PropTypes.func.isRequired,
  navigateTo: PropTypes.func.isRequired,
  navigateTitle: PropTypes.string,
  removeAllActiveDocuments: PropTypes.func.isRequired,
  setCurrentScale: PropTypes.func.isRequired,
  currentScale: PropTypes.any.isRequired,
  setCurrentTabToListview: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  activeDocuments: state.documents.activeDocuments,
  currentDocumentIndex: state.documents.currentDocumentIndex,
  missingDocument: state.documents.missingDocument,
  loadingDocument: state.documents.loadingDocument,
  navigateTitle: state.chosen.navigateTitle,
  currentScale: state.documents.currentScale,
  currentPage: state.documents.currentPage,
  searchWord: state.search.searchWord,
  unseenDocumentChanges: state.agentConfig.unseenDocumentChanges,
  unseenDocumentTotalRevisions: state.agentConfig.unseenDocumentTotalRevisions,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      removeActiveDocument,
      setCurrentActiveDocument,
      addDocumentPdfProps,
      navigateTo,
      clearNavigation,
      removeAllActiveDocuments,
      setCurrentScale,
      setCurrentTabToListview,
      setCurrentPage,
      setDocumentTotalRevisionAsViewed,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MultiDocumentContainer);

const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 75vw;
  max-width: 75vw;
  height: 100vh;
  transition: width 0.1s;
`;

const ViewedAllButton = styled(StyledButton)`
  color: ${textOnTheme};
  position: relative;
  margin-left: 8px;
`;

const StyledAlert = styled(Alert)`
  display: flex;
  justify-content: center;
  .ant-alert-content {
    flex: none;
  }
`;
