import { Config } from '../config/config';
import { addActiveDocument, setCurrentActiveDocument } from './multiDocument.actions';
import { navigateTo } from './chosen.actions';
import { wait } from '../utils/helpers';
export const SEARCH_AUTOCOMPLETE_REQUEST = 'search/SEARCH_AUTOCOMPLETE_REQUEST';
export const SEARCH_AUTOCOMPLETE_SUCCESS = 'search/SEARCH_AUTOCOMPLETE_SUCCESS';
export const SEARCH_AUTOCOMPLETE_FAILED = 'search/SEARCH_AUTOCOMPLETE_FAILED';
export const CLEAR_SEARCH = 'search/CLEAR_SEARCH';
export const SUGGESTION_REQUEST = 'search/SUGGESTION_REQUEST';
export const SEARCH_WORD_CHANGES = 'search/SEARCH_WORD_CHANGES';
export const SUGGESTION_SUCCESS = 'search/SUGGESTION_SUCCESS';
export const SUGGESTION_FAILED = 'search/SUGGESTION_FAILED';
export const ADD_FILTER_CATEGORY = 'search/ADD_FILTER_CATEGORY';
export const REMOVE_FILTER_CATEGORY = 'search/REMOVE_FILTER_CATEGORY';

export const searchRequest = () => {
  return (dispatch, getState) => {
    const { activeCategories, searchWord } = getState().search;
    const { categories } = getState().dashboard;

    if (searchWord.length === 0) return;
    dispatch({ type: SEARCH_AUTOCOMPLETE_REQUEST });

    // Get the categories from the map / object
    const requestCategories = Object.keys(activeCategories)
      .map((c) => activeCategories[c])
      .filter((c) => c.active)
      .map((c) => {
        if (c.name === 'Kørsler') return c.name;
        return categories.find((cat) => cat.value === c.name)?.id;
      });

    fetch(Config.conf.searchApi, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Basic aXBzZDp3YXRzb24yMDE5`,
      },
      body: JSON.stringify(_requestBody(searchWord?.toLowerCase(), 5, requestCategories)),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.completeList.length === 0) {
          dispatch({ type: SEARCH_AUTOCOMPLETE_FAILED, payload: 'Ingen forslag' });
        } else {
          dispatch({ type: SEARCH_AUTOCOMPLETE_SUCCESS, payload: data.completeList });
        }
      })
      .catch((e) => {
        dispatch({ type: SEARCH_AUTOCOMPLETE_FAILED, payload: 'Der skete desværre en fejl, prøv igen.' });
      });
  };

  // {
  //     "word": STRING,  		# det ord som skal autocompletes
  //     "noWords": INT,	 		# optional: max på hvor mange forslag du får tilbage
  //     "categoryList": LIST, 	# optional: liste med categorier som der skal søges i
  // }
  function _requestBody(searchWord, maxResponseCount, categories) {
    return {
      word: _hotfixParsing(searchWord),
      maxResponseCount: maxResponseCount ? maxResponseCount : null,
      categoryList: categories ? categories : null,
    };
  }
};

function _hotfixParsing(search) {
  let res = search;
  const paragraphSignIndex = res.indexOf('§');
  if (paragraphSignIndex !== -1) {
    // Check if string has a space char between paragraph
    if (/§\S/.test(res)) {
      // Add space after paragraph
      res = res.slice(0, paragraphSignIndex + 1) + ' ' + res.slice(paragraphSignIndex + 1);
    }
  }

  // remove dot charater always
  res = res.replace(/\./g, '');
  return res;
}
/**
 * clear all search parameters
 */
export const clearSearch = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_SEARCH });
  };
};

/**
 * chosen autocomplete word request to get the list of suggestions for instructions + headers in them
 * @param {string} word searchword to be processed by service
 */
export const suggestionsRequest = () => {
  return (dispatch, getState) => {
    dispatch({ type: SUGGESTION_REQUEST });
    const { activeCategories, searchWord } = getState().search;
    const { categories } = getState().dashboard;

    // create required object
    const wordWithScore = { word: _hotfixParsing(searchWord), score: 0 };

    // TODO: A hotfix is applied here
    const wordString = wordWithScore.word;

    const requestCategories = Object.keys(activeCategories)
      .map((c) => activeCategories[c])
      .filter((c) => c.active)
      .map((c) => {
        if (c.name === 'Kørsler') return c.name;
        return categories.find((cat) => cat.value === c.name).id;
      });

    const body = {
      completeDict: {
        completeList: [wordWithScore],
      },
      categoryList: requestCategories,
      totN: 50, // Include up to 50 search results
    };
    fetch(Config.conf.suggestionsApi, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Basic aXBzZDp3YXRzb24yMDE5`,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        // handle empty result (no suggestions for word)
        if (!data[wordString])
          return dispatch({ type: SUGGESTION_FAILED, payload: 'Ingen forslag matcher søgningen.' });
        dispatch({ type: SUGGESTION_SUCCESS, payload: _formatData(data[wordString]) });
      })
      .catch((e) => {
        dispatch({ type: SUGGESTION_FAILED, payload: 'Der skete desværre en fejl, prøv igen.' });
      });

    /**
     * The data does not contain proper names or categories, these are added in this method
     * @param {any} data data from endpoint
     */
    function _formatData(data) {
      const { workInstructions, transactions } = getState().dashboard;
      const instructions = [...workInstructions, ...transactions];
      const suggestions = [];
      data.forEach((entry) => {
        const instr = instructions.find((i) => i.id === entry.document);
        if (instr) {
          suggestions.push({
            documentName: instr.name,
            id: instr.id,
            category: instr.category || 'Kørsler',
            title: entry.heading,
          });
        }
      });
      return suggestions;
    }
  };
};

/**
 * add item to be active (used in search)
 * @param {object} category { name: {string}, active: {boolean}}
 */
export const addFilterCategory = (category) => {
  return (dispatch) => {
    dispatch({ type: ADD_FILTER_CATEGORY, payload: category });
  };
};
/**
 * remove item (not used in search / used to filter suggestions)
 * @param {string} category name of category
 */
export const removeFilterCategory = (category) => {
  return (dispatch) => {
    dispatch({ type: REMOVE_FILTER_CATEGORY, payload: category });
  };
};

export const openAndNavigate = (id, title, searchWord) => {
  return async (dispatch, getState) => {
    if (getState().documents.activeDocuments.filter((doc) => doc.id === id).length > 0) {
      dispatch(setCurrentActiveDocument(id));
      dispatch(navigateTo(title, id));
    } else {
      dispatch(addActiveDocument(id));
    }
    // Add and remove the eventlistener
    const handleNavEvent = () => {
      dispatch(navigateTo(title, id));
      window.removeEventListener('pagesloaded', handleNavEvent);
    };
    window.addEventListener('pagesloaded', handleNavEvent);
    await wait(2000);
    const fakeEvent = new CustomEvent('openandnavigate', {
      detail: searchWord,
    });
    window.dispatchEvent(fakeEvent);
  };
};

export const searchWordChange = (searchWord) => {
  return (dispatch) => {
    dispatch({ type: SEARCH_WORD_CHANGES, payload: searchWord });
  };
};
