import { GET_TEAMS, TEAMS_LOADING, ERROR } from '../_actions/teams.actions';

const initialState = {
  teams: [],
  adminTeam: [],
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TEAMS: {
      return {
        ...state,
        loading: false,
        teams: action.payload.filter((t) => t.type !== 'excepted'),
        adminTeam: action.payload.find((t) => t.type !== 'regular'),
      };
    }

    case TEAMS_LOADING: {
      return {
        ...state,
        loading: action.payload.loading,
      };
    }

    case ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    }

    default:
      return state;
  }
};

export default reducer;
