import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { itemBackground, colorTheme, generalBackground } from '../../../sharedStyledComponents/generalStyles';
import { searchIcon } from '../../../sharedStyledComponents/icons';

export default class AutoCompleteSearch extends PureComponent {
  constructor(props) {
    super(props);
    this.searchTimeout = undefined;
    this.clearTimeout = undefined;
    this.refs = [];
    this.suggestionIndex = -3;
  }

  componentDidMount() {
    window.addEventListener('click', this.handleHideList);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleHideList);
  }
  /**
   * eventlistener to hide list when a search is clicked away from or chosen
   */
  handleHideList = (e) => {
    const element = document.getElementById('suggestionList');
    if (element) {
      element.style.display = 'none';
    }
    this.suggestionIndex = -3;
  };

  /**
   * handles an item clicked instead of pressed enter upon
   */
  handleListItemClick = (e, w) => {
    e.preventDefault();
    e.stopPropagation();
    this.handleSearchChosen(w);
  };

  onSearch = (e) => {
    e.preventDefault();
    const element = document.getElementById('suggestionList');
    if (element) {
      element.style.display = 'block';
    }
    let searchWord = e.target.value;
    // clean up autocomplete suggestions when empty
    if (searchWord.length === 0) {
      clearTimeout(this.clearTimeout);
      this.clearTimeout = setTimeout(() => {
        this.props.clearSearch();
        this.suggestionIndex = -3;
      }, 200);
    }
    this.props.searchWordChange(searchWord);
    clearTimeout(this.searchTimeout);
    if (searchWord.length) {
      this.searchTimeout = setTimeout(() => this.props.searchRequest(searchWord), 300);
    }
  };

  /**
   * Handle arrowkeys and enter when navigating autocomplete suggestions
   */
  handleKeyUps = (e) => {
    e.preventDefault();
    // On enter
    if (e.keyCode === 13) {
      // get first child as it is the full word in a hidden fields
      if (e.target.firstChild) {
        this.handleSearchChosen(e.target.firstChild.value);
      } else {
        this.handleSearchChosen(this.props.searchWord);
      }
    }
    // only do actions if actually there is suggestions
    if (this.props.words.length > 0 && document.getElementById('suggestionList')) {
      // on up arrow key
      if (e.keyCode === 38) {
        this.suggestionIndex--;
        if (this.suggestionIndex < 0) this.suggestionIndex = this.props.words.length - 1;
        this.refs[this.suggestionIndex].current.focus();
      }
      // on down arrow key
      if (e.keyCode === 40) {
        this.suggestionIndex++;

        if (this.suggestionIndex < 0 || this.suggestionIndex === this.props.words.length) {
          this.suggestionIndex = 0;
        }
        this.refs[this.suggestionIndex].current.focus();
      }
    }
  };

  /**
   * Create list of suggested words
   */
  createList = () => {
    // create a list of refs for each item focusing and choosing
    const list = [];
    this.props.words.forEach((_, i) => {
      const ref = React.createRef();
      list.push(ref);
    });

    this.refs = list;
    return this.props.words.map((w, index) => {
      const indexOfMatch = w.word.indexOf(this.props.searchWord);
      return (
        <ListItem
          style={{ borderBottom: this.props.words.length === index + 1 ? 'none' : '0.1rem solid #f0f0f0' }}
          tabIndex='-1'
          ref={this.refs[index]}
          key={w.word}
          onKeyDown={this.handleKeyUps}
          onClickCapture={(e) => this.handleListItemClick(e, w.word)}
        >
          {/* Add word as hidden value because we need full word to init search */}
          <input type='hidden' value={w.word} />
          {w.word.substring(0, indexOfMatch)}
          <Marking onClick={(e) => this.handleListItemClick(e, w.word)}>{w.word.substring(indexOfMatch, this.props.searchWord.length)}</Marking>
          {w.word.substring(this.props.searchWord.length)}
        </ListItem>
      );
    });
  };

  handleSearchChosen = (word) => {
    this.props.searchWordChange(word);
    this.props.suggestionsRequest();
    this.handleHideList();
  };

  render() {
    return (
      <Container>
        <SearchAndSuggestContainer>
          <SearchWrapper>
            <SearchIcon>{searchIcon('15px', 'gray')}</SearchIcon>
            <Search
              type='search'
              placeholder='Søg i arbejdsinstrukser'
              onChange={this.onSearch}
              onKeyUp={this.handleKeyUps}
              value={this.props.searchWord}
            />
          </SearchWrapper>
          {this.props.words.length > 0 && <SuggestionList id='suggestionList'>{this.createList()}</SuggestionList>}
          {this.props.autoCompleteError && (
            <SuggestionList>
              <ErrorItem>{this.props.autoCompleteError}</ErrorItem>
            </SuggestionList>
          )}
        </SearchAndSuggestContainer>
      </Container>
    );
  }
}

AutoCompleteSearch.propTypes = {
  words: PropTypes.arrayOf(PropTypes.object),
  searchRequest: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.6rem 0.6rem 0.6rem 0.6rem;
  position: relative;
  width: 100%;
`;

const SearchAndSuggestContainer = styled.div`
  border: 1px solid ${colorTheme};
  margin: auto;
  border-radius: 0.2rem 0.2rem;
  z-index: 99;
  width: 100%;
`;
const Marking = styled.mark`
  padding: 0;
  border-radius: 0.1rem;
  background-color: yellow;
`;

const SearchWrapper = styled.div`
  display: flex;
  position: relative;
`;
const SearchIcon = styled.div`
  background: ${itemBackground};
  margin-left: 0.3rem;
`;

const Search = styled.input`
  background: ${itemBackground};
  border: 1px solid hsla(216, 12%, 92%, 0);
  text-indent: 0.4rem;
  border-radius: 2px;
  margin-right: 0.3rem;
  border: 1px solid transparent;
  width: 100%;
  &:focus {
    outline: none;
  }
`;

const SuggestionList = styled.ul`
  list-style-type: none;
  margin: 1px;
  padding: 0;
  background-color: ${itemBackground};
`;

const ListItem = styled.li`
  padding: 0.2rem 0 0.2rem 1.3rem;
  list-style: none;
  border-bottom: 0.1rem solid #f0f0f0;
  user-select: none;
  cursor: pointer;
  &:focus {
    background-color: ${generalBackground};
    outline: none;
  }
`;

const ErrorItem = styled.li`
  padding: 0.2rem 0 0.2rem 1.3rem;
  list-style: none;
  border-bottom: 0.1rem solid #f0f0f0;
  user-select: none;
`;
