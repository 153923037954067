import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import FlipMove from 'react-flip-move';
import { ConfidenceItem } from './confidence-item.component';
import { Loading } from '../../GlobalComponents/loading.component';
import { Offline } from '../../GlobalComponents/offline.component';
export class Confidence extends Component {

  shouldComponentUpdate(nextProps) {
    // Check if the instructions have been altered else dont update
    return (JSON.parse(JSON.stringify(nextProps.instructions)) !== JSON.parse(JSON.stringify(this.props.instructions)))
  }

  mockDataForLoading = [{ name: "SKAT", confidence: 0, id: 'a' }, { name: "Død", confidence: 0, id: 'b' }, { name: "Genkøb", confidence: 0, id: 'c' }]

  renderBar(instruction, index) {
    const percent = Math.round(instruction.confidence * 100);
    const prevPercent = Math.round(instruction.prevConfidence * 100 || 0);

    return (
      <ConfidenceItem
        index={index}
        key={instruction.name}
        id={instruction.id}
        addActiveDocument={this.props.addActiveDocument}
        text={instruction.name}
        percent={percent}
        prevPercent={prevPercent}
      />
    )
  }

  render() {
    let instructions = this.mockDataForLoading;
    if (!this.props.loading) {
      instructions = this.props.instructions
        .sort((a, b) => a.name.localeCompare(b.name))
        .sort((a, b) => a.confidence > b.confidence ? -1 : 1)
        .slice(0, 3);
    }

    return (
      <Container>
        {this.props.loading && <Loading />}
        {this.props.offline && <Offline />}
        <List>
          <FlipMove>
            {instructions.map((instruction, index) => this.renderBar(instruction, index))}
          </FlipMove>
        </List>
      </Container>
    )
  }
}

Confidence.propTypes = {
  loading: PropTypes.bool.isRequired,
  instructions: PropTypes.array,
  addActiveDocument: PropTypes.func.isRequired,
  offline: PropTypes.bool.isRequired,
}

Confidence.defaultProps = {

}

const List = styled.ul`
  padding: 0;
  margin: 0;
`

const Container = styled.div`
    overflow: hidden;
    position: relative;
`;
