import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { textTheme, borderColor, TextUnderlineAnimated } from '../../sharedStyledComponents/generalStyles';
import { Bar } from '../../sharedStyledComponents/Bar'

export class ConfidenceItem extends Component {

    INITIAL_WIDTH = 25;
    handleClick(e, id) {
        e.preventDefault();
        this.props.addActiveDocument(id);
    }
    render() {
        let barWidth = this.INITIAL_WIDTH + this.props.percent;
        if (barWidth > 100) barWidth = 100; // reduce bar size to 100% so it does not exceed the container.
        const prevWidth = this.INITIAL_WIDTH + this.props.prevPercent;
        const percentText = this.props.percent === 0 ? 'N/A' : this.props.percent + '%';

        return (
            <Item key={`${this.props.id}-item`} onClick={e => this.handleClick(e, this.props.id)}>
                <TextContainer>
                    <BarText>{this.props.text}</BarText>
                </TextContainer>
                <BarContainer>
                    <Bar percent={this.props.percent} width={barWidth} $prevWidth={prevWidth} index={this.props.index}>{percentText}</Bar>
                </BarContainer>
            </Item>
        )
    }
}

ConfidenceItem.propTypes = {
    addActiveDocument: PropTypes.func.isRequired,
    percent: PropTypes.number,
    text: PropTypes.string,
    id: PropTypes.string.isRequired,
    index: PropTypes.number,
}

const BarText = styled(TextUnderlineAnimated)`
    font-weight: 300;
    font-size: .9rem;
    padding: .1rem 0rem;
    color: ${textTheme};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
`;

const TextContainer = styled.div`
    max-width:55%;
    transition: all 0.3s ease;
`;

const BarContainer = styled.div`
    width: 45%;
`;

const Item = styled.li`
    padding: .5rem 0 .5rem .6rem;
    border-bottom: .1rem solid ${borderColor};
    display: flex;
    height: 2.5rem;
    justify-content: space-between;
    text-decoration:none !important;
    cursor: pointer;
    &:hover {
        ${BarText} {
            display: inline-block;
            opacity: .95;
            padding-right: .2rem;
            border-top-right-radius: .2rem;
            border-bottom-right-radius: .2rem;
            background-color: #fff;
        }
        ${BarText}:before {
            visibility: visible;
            width: 100%;
            background-color: ${textTheme};
            transform: width;
        }
        ${TextContainer} {
            text-overflow: initial;
            overflow: initial;
            margin-bottom: .2rem;
        }
    } 
`;


