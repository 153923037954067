import { useState, useEffect } from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import { Menu, Layout, ConfigProvider, Affix, Popconfirm } from 'antd';
import daDK from 'antd/lib/locale/da_DK';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { menuItems, headers, path } from './MenuItems';
import { clearToken } from '../../_actions/auth.actions';

import LogoImg from '../../assets/logo.png';
import {
  itemBackground,
  colorTheme,
  textOnTheme,
  textTheme,
  TitleTextWithSecondaryTitle,
} from '../sharedStyledComponents/generalStyles';
import AdditionalSettings from './InstructionSettings/AdditionalConfig.container';
import InstructionsSettings from './InstructionSettings/InstructionSettings.container';
import Statistics from './Statistics/Statistics.container';
import { screen } from '../sharedStyledComponents/Responsive.styles';

import { logoutIcon } from '../sharedStyledComponents/icons';

// import Compliance from './Compliance/Compliance.container';
import InstructionChanges from './InstructionChanges/InstructionChanges.container';
import Revision from './Revision/Revision.container';
import GeneralContainer from './General/General.container';
import TeamsContainer from './Teams/Teams.container';
import utils from '../../utils/Utils';

const { Header, Content, Sider } = Layout;

const AdminDashboard = ({ match, location, logout }) => {
  const [currentHeader, setCurrentHeader] = useState(null);

  useEffect(() => {
    const header = headers.find((i) => {
      return `${match.url}${i.path}` === location.pathname;
    });
    if (header) {
      setCurrentHeader(header);
      return;
    }
    setCurrentHeader(null);
  }, [match.url, location.pathname]);

  const userIsSuperAdmin = utils.isSuperAdmin();
  return (
    <ConfigProvider locale={daDK}>
      <Layout>
        <StyledSider width="16rem">
          <Affix style={{ position: 'absolute', top: 0, left: 0 }}>
            <div>
              <LogoContainer>
                <LogoText>
                  IPSB <strong>Admin site</strong>
                </LogoText>
              </LogoContainer>
              <StyledMenu
                defaultSelectedKeys={[
                  location.pathname === `${match.path}${path.additionalconfig}`
                    ? `${match.path}${path.instructions}`
                    : location.pathname,
                ]}
                selectedKeys={[
                  location.pathname === `${match.path}${path.additionalconfig}`
                    ? `${match.path}${path.instructions}`
                    : location.pathname,
                ]}
                mode="inline"
                // onClick={(e) => {
                //   const index = headers.findIndex((i) => `${ match.url } ${ i.path } ` === e.key);
                //   setCurrentHeader(headers[index]);
                // }}
              >
                {userIsSuperAdmin
                  ? menuItems.map((item) => (
                      <Menu.Item
                        style={{ display: 'inline-flex', alignItems: 'center' }}
                        key={`${match.url}${item.navigateTo}`}
                        icon={item.icon}
                      >
                        <Link to={`${match.url}${item.navigateTo}`}>{item.title}</Link>
                      </Menu.Item>
                    ))
                  : menuItems
                      .filter((item) => !item.visibleForSuperAdmin)
                      .map((item) => (
                        <Menu.Item
                          style={{ display: 'inline-flex', alignItems: 'center' }}
                          key={`${match.url}${item.navigateTo}`}
                          icon={item.icon}
                        >
                          <Link to={`${match.url}${item.navigateTo}`}>{item.title}</Link>
                        </Menu.Item>
                      ))}
              </StyledMenu>
            </div>
          </Affix>
          <StyledMenu mode="inline" style={{ position: 'fixed', bottom: 0, width: '16rem' }}>
            <Menu.Item
              key="logOut"
              style={{ display: 'inline-flex', alignItems: 'center', borderTop: '1px solid #aeaeae' }}
              icon={logoutIcon('20px', 'white')}
            >
              <Popconfirm
                placement="topLeft"
                title={'Er du sikker på at du vil logge ud?'}
                onConfirm={logout}
                okText="Log ud"
                cancelText="Fortryd"
              >
                <Link to="#">Log ud</Link>
              </Popconfirm>
            </Menu.Item>
          </StyledMenu>
        </StyledSider>
        <Layout style={{ backgroundColor: itemBackground, height: '100%', minHeight: '100vh' }}>
          {currentHeader && (
            <StyledHeader>
              <TitleTextWithSecondaryTitle color={colorTheme} fontSize={'2.7rem'}>
                {currentHeader.headerTitle}
              </TitleTextWithSecondaryTitle>

              <TitleSubtext>{currentHeader.headerSubtext}</TitleSubtext>
              {/* <span style={{position: 'absolute', top: '30px', right: '30px'}}>Test</span> */}
            </StyledHeader>
          )}
          <StyledContent>
            <Switch>
              <Route path={`${match.path}`} exact component={Statistics} />
              <Route path={`${match.path}/instructions`} exact component={InstructionsSettings} />
              <Route path={`${match.path}/instructions/additionalconfig`} component={AdditionalSettings} />
              {/* <Route path={`${match.path}/compliance`} exact component={Compliance} /> */}
              <Route path={`${match.path}/instructionChanges`} exact component={InstructionChanges} />
              {userIsSuperAdmin && <Route path={`${match.path}/revision`} exact component={Revision} />}
              <Route path={`${match.path}${path.teams}`} exact component={TeamsContainer} />
              <Route path={`${match.path}/general`} exact component={GeneralContainer} />
            </Switch>
          </StyledContent>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout: clearToken,
    },
    dispatch
  );

AdminDashboard.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);

const StyledContent = styled(Content)`
  height: 100%;
  min-height: 90vh;
  padding: 0 0.5rem;
  @media ${screen.MD} {
    padding: 0 calc(1rem + 50px);
  }
`;

const StyledSider = styled(Sider)`
  overflow: hidden;
  background: ${colorTheme} !important;
  color: ${textOnTheme};
  width: ${(props) => props.siderWidth};
  overflow-y: 'auto';
  left: 0;
`;

const StyledMenu = styled(Menu)`
  background-color: ${colorTheme};
  color: ${itemBackground};

  &.ant-menu-inline {
    border-right: 0 !important;
  }

  .ant-menu-item {
    color: ${textOnTheme} !important;
    background-color: ${colorTheme} !important;
    border-bottom: 1px solid #aeaeae;
    margin: 0 !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    height: auto;
    width: 100%;
    border-radius: 0;

    &:hover  {
      color: ${itemBackground} !important;
    }

    span:nth-child(2) > a {
      color: ${textOnTheme} !important;
      &:hover  {
        color: ${itemBackground} !important;
      }
    }
  }
    .ant-menu-item::after {
      width: 0;
      height: 0;
      margin-top: 10px;
      padding-right: -1px;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
      border-right: 20px solid ${itemBackground} !important;
      position: absolute;
      top: 50%;
      transform: translateY(-20%);
    }

  .ant-menu-item-active {
    font-weight: 700;
    color: ${itemBackground};

    span:nth-child(2) > a {
      color: ${itemBackground};
    }
  }

  .ant-menu-item-selected {
    background-color: transparent !important;
    font-weight: 700;
    color: ${itemBackground};

    span:nth-child(2) > a {
      color: ${itemBackground};
    }
  }
`;

const StyledHeader = styled(Header)`
  margin: 1rem;
  height: auto;
  background: ${itemBackground};
  line-height: normal;
`;

const TitleSubtext = styled.div`
  color: ${textTheme};
  margin-top: 0.5rem;
`;

const LogoContainer = styled.div`
  background-image: url(${LogoImg});
  background-position: center center;
  background-size: 75% auto;
  background-repeat: no-repeat;
  border-bottom: solid 0.1rem ${itemBackground};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:after {
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #aeaeae;
    position: absolute;
    opacity: 0.15;
    z-index: 4;
  }
`;

const LogoText = styled.h1`
  color: ${itemBackground};
  font-size: 1.5rem;
  width: 100%;
  text-align: center;
  padding: 2rem 1.5rem 1rem;
  margin: 0;
  z-index: 5;
`;
