import React, { useEffect } from 'react'
import styled from 'styled-components';
import { itemBackground, colorTheme, grayedText } from '../../sharedStyledComponents/generalStyles';
import { robotIcon } from '../../sharedStyledComponents/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getGreetingMessage } from '../../../_actions/additionalSettings.actions';
import InstructionsChangesNotification from './instructionsChangesNotification.component';

const NoDocument = (props) => {
    const greetingMessage = useSelector(state => state.additionalSettings.greetingMessage);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getGreetingMessage());
    }, [dispatch]);

    return (
        <Container>
            {props.activeDocuments && <ToolbarPlaceholder></ToolbarPlaceholder>}
            <InstructionsChangesNotification />
            <FlexCenter>
                <IconWrapper>
                    {robotIcon('150', grayedText)}
                </IconWrapper>
                {greetingMessage}
            </FlexCenter>
        </Container>
    )
}

export default NoDocument;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${itemBackground};
    height: 100%;
    max-width: 75vw;
`;

const FlexCenter = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: #b3b3b3;
  white-space: pre-wrap;
  text-align: center;
  background-color: ${itemBackground};
`;

const IconWrapper = styled.div`
    height: 8rem;
    
`;

const ToolbarPlaceholder = styled.div`
  background-color: ${colorTheme}; 
  height: 2.2rem;
`;