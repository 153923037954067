import localForage from 'localforage';

export class DB {
    constructor(databaseName) {
        this.db = localForage.createInstance({ name: databaseName });
    }

    async addItem(key, item) {
        const output = await this.db.setItem(key, item);
        return output;
    }
    async addDocument(document) {
        const output = await this.db.setItem(document.id, document);
        return output;
    }

    async findDocument(key) {
        const document = await this.db.getItem(key);
        return document;
    }

    async removeDocument(key) {
        const output = await this.db.removeItem(key);
        return output;
    }
    async getAllDocuments() {
        const document = await this.db.keys();
        return document;
    }

}