import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colorTheme, textTheme } from '../../sharedStyledComponents/generalStyles';
import { Modal } from 'antd';
import CloseButton from '../../GlobalComponents/close.component';
import './custom-modal.css';

const CustomModal = ({ icon, title, summary, subtitle, visible, close, children, width }) => {
  return (
    (<Modal centered open={visible} width={width} footer={null} closable={false} wrapClassName={'custom-modal-class'}>
      <ModalHeader>
        {icon && <Icon>{icon}</Icon>}

        {title && <Title>{title}</Title>}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}

        <CloseButtonWrapper>
          <CloseButton cb={() => close()} color={colorTheme} size="large" />
        </CloseButtonWrapper>
      </ModalHeader>
      {summary && <TitleSubtext>{summary}</TitleSubtext>}
      <ModalBody>{children}</ModalBody>
    </Modal>)
  );
};

export default CustomModal;

CustomModal.propTypes = {
  title: PropTypes.element.isRequired,
  icon: PropTypes.element,
  subtitle: PropTypes.element,
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

const ModalHeader = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  color: ${colorTheme};
`;

const ModalBody = styled.div``;

const Icon = styled.i`
  font-size: 1.2rem;
  margin-right: 0.5rem;
`;

const Title = styled.span`
  font-size: 1.2rem;
  font-weight: 650;
  margin-right: 0.5rem;
`;

const Subtitle = styled.span`
  font-size: 1.2rem;
`;

const CloseButtonWrapper = styled.i`
  margin-left: auto;
`;

const TitleSubtext = styled.div`
  color: ${textTheme};
  margin-bottom: 1rem;
`;
