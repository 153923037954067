import { Config } from '../config/config';
import { DB } from '../utils/DB';
import { SINGLE_DOCUMENT_UPDATING, SINGLE_DOCUMENT_UPDATED } from './dashboard.actions';
import asyncFilter from '../utils/asyncFilter';
import { clearN2, setComplianceList } from './chosen.actions';
import { documentTypes } from '../config/enums';
import { n1Click } from './socket-io.actions';
import { backClick } from './socket-io.actions';
import { message } from 'antd';

export const LOADING_ACTIVE_DOCUMENT = 'document/LOADING_DOCUMENT';
export const ADD_ACTIVE_DOCUMENT = 'document/ADD_ACTIVE_DOCUMENT';
export const ADD_PDF_PROPS = 'document/ADD_PDF_PROPS';
export const REMOVE_ACTIVE_DOCUMENT = 'document/REMOVE_ACTIVE_DOCUMENT';
export const REMOVE_ALL_ACTIVE_DOCUMENTS = 'document/REMOVE_ALL_ACTIVE_DOCUMENTS';
export const SET_CURRENT_DOCUMENT = 'document/SET_CURRENT_DOCUMENT';
export const MISSING_DOCUMENT = 'document/MISSING_DOCUMENT';
export const CURRENT_SCALE = 'document/CURRENT_SCALE';
export const CURRENT_PAGE = 'document/CURRENT_PAGE';
export const MATCHES_COUNT = 'document/MATCHES_COUNT';

const db = new DB('Documents');

/**
 * Add a document to active document list by id
 * If a document already is active it will set the document as the currently active document
 * @param {string} id ID of document to add to active list
 */
export const addActiveDocument = (id) => {
  return async (dispatch, getState) => {
    // Clear previous relevant headers
    dispatch(clearN2());

    let result = undefined;
    dispatch({ type: LOADING_ACTIVE_DOCUMENT });

    const { activeDocuments } = await getState().documents;
    const { topFiles, workInstructions } = await getState().dashboard;

    const alreadyActive = activeDocuments.find((doc) => doc.id === id);
    if (alreadyActive) {
      await dispatch(setCurrentActiveDocument(alreadyActive.id));
    } else {
      result = await db.findDocument(id);

      // document exists in indexedDB
      if (result) {
        const storedDoc = [...topFiles, ...workInstructions].find((doc) => doc.id === id);

        // check if document is updating to newer version
        if (!storedDoc.updating) {
          await dispatch(_addActiveDocument(result));
          await dispatch(setCurrentActiveDocument(result.id));
        } else {
          // Add 100ms interval to check if the new version of the doc is downloaded
          const removeInterval = window.setInterval(async () => {
            const { topFiles, workInstructions } = getState().dashboard;
            const storedDoc = [...topFiles, ...workInstructions].find((doc) => doc.id === id);

            // if the doc is done updating fetch the new one from indexedDB and display it
            if (!storedDoc.updating) {
              const result = await db.findDocument(id);
              await dispatch(_addActiveDocument(result));
              await dispatch(setCurrentActiveDocument(result.id));
              clearInterval(removeInterval);
            }
          }, 100);
        }

        // Handle documents not saved in DB
      } else if (!result && getState().dashboard.transactions.find((t) => t.id === id)) {
        result = await _fetchDocument(
          getState().dashboard.transactions.find((t) => t.id === id),
          dispatch
        );
        await dispatch(_addActiveDocument(result));
        await dispatch(setCurrentActiveDocument(result.id));
      } else {
        dispatch({ type: MISSING_DOCUMENT });
      }
    }
  };
  function _addActiveDocument(payload) {
    // Add a pdfProxy property for pdf viewer
    payload['pdfProxy'] = undefined;
    payload['outline'] = undefined;
    payload['zoom'] = 'auto';
    payload['scrollLeft'] = undefined;
    payload['scrollTop'] = undefined;
    return {
      type: ADD_ACTIVE_DOCUMENT,
      payload,
    };
  }
};

function _handleN1Clicks(document, dispatch, callStatus) {
  if (document.type === documentTypes.workInstructions) {
    dispatch(n1Click(document.id, callStatus));
    return;
  }

  // handle topfile clicks
  if (document.aiRecommended && document.type === documentTypes.topFiles) {
    dispatch(n1Click(document.id, callStatus));
    return;
  }

  // in all other cases
  dispatch(backClick(callStatus));
}

export const addDocumentPdfProps = (document, props) => {
  return {
    type: ADD_PDF_PROPS,
    payload: { document, props },
  };
};

/**
 * Removes document from current list, if it is the currently opened one it
 * will switch to the next availible tab, if there is no next, it will switch to
 * the previous tab, if none available (last tab), it will set the index to null
 * @param {string} id Name of document to add to remove from list
 * @param {boolean} [documentName] Optional used on browser back or N2 back to change current tab to Listview upon click
 */
export const removeActiveDocument = (id, backButton) => {
  return async (dispatch, getState) => {
    const { activeDocuments, currentDocumentIndex } = getState().documents;
    const docIndex = activeDocuments.findIndex((doc) => doc.id === id);

    let currentDocumentId = null;
    // Handle different for n1 tab
    if (currentDocumentIndex !== 'Listview') {
      currentDocumentId = activeDocuments[currentDocumentIndex].id;
    } else {
      // if the current tab is listview we set the document id to Listview so it remains the active tab
      currentDocumentId = currentDocumentIndex;
    }

    // Handle if the Listview tab is active
    if (currentDocumentIndex === 'Listview') {
      if (activeDocuments.length > 1) {
        dispatch(_removeActiveDocument(id));
        return;
      } else {
        dispatch(removeAllActiveDocuments());
        return;
      }
    }

    // If the closed document is the current document
    if (docIndex === currentDocumentIndex) {
      // get next and previous docs surrounding the closed one
      const nextDoc = activeDocuments[currentDocumentIndex + 1];
      const prevDoc = activeDocuments[currentDocumentIndex - 1];

      // remove doc from the active list
      dispatch(_removeActiveDocument(id));

      if (backButton) {
        dispatch(setCurrentTabToListview());
        return;
      } else if (nextDoc) {
        dispatch(setCurrentActiveDocument(nextDoc.id));
        return;
      } else if (prevDoc) {
        dispatch(setCurrentActiveDocument(prevDoc.id));
        return;
      } else {
        // if it was the only opened document
        dispatch(setCurrentActiveDocument(null));
      }

      // If it is not the currently opened tab just close it
    } else {
      dispatch(_removeActiveDocument(id));
      dispatch(setCurrentActiveDocument(currentDocumentId));
    }
  };
  function _removeActiveDocument(id) {
    return { type: REMOVE_ACTIVE_DOCUMENT, payload: { id } };
  }
};

/**
 * Method sets the current index that should be displayed from the activeDocuments list,
 * if it is the last document it will set the current index to null.
 * @param {string} id ID of document to set to current
 */
export const setCurrentActiveDocument = (id) => {
  return async (dispatch, getState) => {
    const { activeDocuments } = getState().documents;
    const { callStatus, topFiles, workInstructions, transactions } = getState().dashboard;
    // Find the index of the requested doc and set it to be the current
    let index = activeDocuments.findIndex((doc) => doc.id === id);
    // if the doc does not exist or the last tab is closed
    if (index < 0) {
      index = null;
      dispatch(backClick(callStatus));
    }

    if (index !== null) {
      const doc = [...topFiles, ...workInstructions, ...transactions].find((doc) => doc.id === id);
      _handleN1Clicks(doc, dispatch, callStatus);
      dispatch(setComplianceList(id));
    }
    dispatch(_setCurrentIndex(index));
  };
  function _setCurrentIndex(payload) {
    return {
      type: SET_CURRENT_DOCUMENT,
      payload,
    };
  }
};

/**
 * Activate special tab for going to Listview while having open documents
 */
export const setCurrentTabToListview = () => {
  return async (dispatch, getState) => {
    dispatch(backClick(getState().dashboard.callStatus));
    dispatch({ type: SET_CURRENT_DOCUMENT, payload: 'Listview' });
  };
};

/**
 * Set current page in toolbar
 */
export const setCurrentPage = (currentPage) => {
  return async (dispatch) => {
    dispatch({ type: CURRENT_PAGE, payload: currentPage });
  };
};

/**
 * Set current page in toolbar
 */
export const setMatchesCount = (matchesCount) => {
  return async (dispatch) => {
    dispatch({ type: MATCHES_COUNT, payload: matchesCount });
  };
};

/**
 * Removes all active documents
 */
export const removeAllActiveDocuments = () => {
  return (dispatch, getState) => {
    dispatch(backClick(getState().dashboard.callStatus));
    dispatch({ type: REMOVE_ALL_ACTIVE_DOCUMENTS });
  };
};

/**
 * Sets current document scale for usage in toolbar
 */
export const setCurrentScale = (scale) => {
  return (dispatch) => {
    dispatch({ type: CURRENT_SCALE, payload: scale });
  };
};

export const getAndUpdateDocuments = (documentList) => {
  return async (dispatch, getState) => {
    if (documentList !== null) {
      // Check what to update or fetch
      const documentsToUpdate = await asyncFilter(documentList, async (document) => await _doUpdate(document));

      // Fetch / Refetch missing documents
      if (documentsToUpdate.length > 0) {
        documentsToUpdate.forEach((document) => {
          _fetchDocument(document, dispatch);
        });
      }

      // Clean up stored documents
      const { workInstructions, topFiles } = getState().dashboard;
      const combinedList = [...workInstructions, ...topFiles];
      _cleanStoredDocuments(combinedList);
    }
  };

  async function _doUpdate(newDocument) {
    const savedDocument = await db.findDocument(newDocument.id);

    // If new document add to list to fetch
    if (savedDocument === null) {
      return true;
    }
    // If document exists but has been altered add to fetch list
    if (savedDocument.documentHash !== newDocument.documentHash) {
      return true;
    }

    // Document checks out and does not need updates
    return false;
  }
};
async function _fetchDocument(document, dispatch) {
  // Set document status to updating
  dispatch(_singleDocumentUpdate(document.id));

  const docType = document.type === documentTypes.workInstructions ? '' : document.type + '/';
  try {
    const res = await fetch(`${Config.conf.backendUrl}/api/v1/document/${docType}${document.id}`, {
      method: 'GET',
      headers: {
        Authorization: `bearer ${sessionStorage.token}`,
      },
    })
      .then((res) => res.text())
      .then((res) => {
        // Save item to localForge db / Indexdb
        const instr = {
          id: document.id,
          name: document.name,
          type: document.type,
          category: document.category,
          documentHash: document.documentHash,
          base64: res,
          ...(document.aiRecommended && { aiRecommended: document.aiRecommended }),
        };
        /* This is just a check to register that it is not an weird error message, 
        and is a base64 encoded file (the length is always thousands of chars)*/
        if (instr.base64.length > 200) {
          if (document.type !== documentTypes.transactions) {
            db.addDocument(instr)
              // Set document status to not updating
              .then(dispatch(_singleDocumentUpdateDone(instr.id)));
          } else {
            dispatch(_singleDocumentUpdateDone(instr.id));
            return instr;
          }
        } else {
          throw new Error('Networking error, document not downloaded, try again');
        }
      });
    return res;
  } catch (e) {
    message.error('Dokumentet kunne ikke hentes. Prøv igen.');
    // TODO: Add handler for error
  }
  function _singleDocumentUpdate(id) {
    return { type: SINGLE_DOCUMENT_UPDATING, payload: { id } };
  }
  function _singleDocumentUpdateDone(id) {
    return { type: SINGLE_DOCUMENT_UPDATED, payload: { id } };
  }
}

/**
 * Cleans up unused documents fo
 * @param {any} documentList
 */
async function _cleanStoredDocuments(documentList) {
  // Get all current keys/Document id's from indexeddb
  const storedDocuments = await db.getAllDocuments();
  // Check if some does not appear in the newDocuments
  storedDocuments.forEach((instructionId) => {
    const isStored = documentList.find((doc) => instructionId === doc.id);
    // if not present in new list remove Document from db
    if (isStored === undefined) db.removeDocument(instructionId);
  });
}
