import { Config } from '../config/config';
import axios from 'axios';

export const GET_DOCUMENT_CHANGES = 'documentChanges/GET_DOCUMENT_CHANGES';
export const DOCUMENT_CHANGES_LOADING = 'documentChanges/DOCUMENT_CHANGES_LOADING';
export const ERROR = 'documentChanges/ERROR';

export const getDocumentChanges = (timestamp) => {
  return async (dispatch) => {
    dispatch({ type: DOCUMENT_CHANGES_LOADING, payload: { loading: true } });
    try {
      const res = await axios.get(`${Config.conf.backendUrl}/api/v1/revision?since=${timestamp}`);

      dispatch({ type: GET_DOCUMENT_CHANGES, payload: { documentChanges: res.data } });
    } catch (e) {
      dispatch({ type: ERROR, payload: { error: e } });
    }
  };
};

export const getAdminDocumentChanges = (timestamp) => {
  return async (dispatch) => {
    dispatch({ type: DOCUMENT_CHANGES_LOADING, payload: { loading: true } });
    try {
      const res = await axios.get(`${Config.conf.backendUrl}/api/v1/admin/revision?since=${timestamp}`);

      dispatch({ type: GET_DOCUMENT_CHANGES, payload: { documentChanges: res.data } });
    } catch (e) {
      dispatch({ type: ERROR, payload: { error: e } });
    }
  };
};
