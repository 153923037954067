import {
  SET_AGENT_CONFIG,
  SET_FAV_INSTRUCTIONS,
  SET_SECION_CHANGE_VIEWED,
  SET_TOPFILE_AS_VIEWED,
  SET_TOTAL_REVIEW_VIEWED,
} from '../_actions/agentConfig.actions';

const initialState = {
  _id: '',
  favoriteInstructions: [],
  unseenDocumentChanges: [],
  unseenDocumentTotalRevisions: [],
  unseenTopfiles: [],
  previousLoginTimestamp: new Date().getTime(),
  createdTimestamp: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AGENT_CONFIG: {
      return {
        ...action.payload,
      };
    }
    case SET_FAV_INSTRUCTIONS: {
      return {
        ...state,
        favoriteInstructions: action.payload.favInstructions,
      };
    }
    case SET_SECION_CHANGE_VIEWED: {
      const instructionId = action.payload.instructionId;
      const sectionName = action.payload.sectionName;
      return {
        ...state,
        unseenDocumentChanges: state.unseenDocumentChanges.filter(
          (change) => change.docId !== instructionId || change.sectionName !== sectionName
        ),
      };
    }
    case SET_TOTAL_REVIEW_VIEWED: {
      const instructionId = action.payload.instructionId;
      return {
        ...state,
        unseenDocumentTotalRevisions: state.unseenDocumentTotalRevisions.filter(
          (totalRevision) => totalRevision.documentId !== instructionId
        ),
      };
    }
    case SET_TOPFILE_AS_VIEWED: {
      return {
        ...state,
        unseenTopfiles: state.unseenTopfiles.filter((topfileId) => topfileId !== action.payload.topfileId),
      };
    }
    default:
      return state;
  }
};

export default reducer;
