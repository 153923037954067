import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { listIcon } from '../../../sharedStyledComponents/icons';
import {
  colorTheme,
  borderColor,
  linkItemColor,
  generalBackground,
  textOnTheme,
  Content,
  itemBackground,
  StyledLink,
  linkItemHover,
} from '../../../sharedStyledComponents/generalStyles';
import { connect } from 'react-redux';
import NotificationCircle from '../../../sharedStyledComponents/NotificationCircle.component';
import { isEmpty } from '../../../../utils/helpers';

export class LinkDropdown extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };
    this.ref = React.createRef();
  }

  handleExpand = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dropdownTop = this.ref.current.offsetTop + this.ref.current.offsetHeight;
    this.setState({ expanded: !this.state.expanded });
  };

  _createDropdown = () => {
    return (
      <Dropdown onMouseLeave={this.handleExpand} top={this.dropdownTop}>
        {this.props.topFiles.map((f, i) => {
          const isTopfileNew = !isEmpty(this.props.unseenTopfiles?.find(topfileId => topfileId === f.id));
          return (
            <DropdownItem key={f.name + i} onClick={() => this.props.addActiveDocument(f.id)}>
              {f.name}
              <NotificationCircle number={f.notificationNumber} isNew={f.notificationNumber > 0 ? false : isTopfileNew} shouldPositionInlineRight={true} />
            </DropdownItem>
          )
        })}
      </Dropdown>
    );
  };

  render() {
    const changesInDropdownTopfiles = this.props.topFiles?.filter(topfile => this.props.unseenTopfiles?.indexOf(topfile.id) > -1)?.length;
    return (
      <>
        <LinkWrapper ref={this.ref} onClick={this.handleExpand} $expanded={this.state.expanded}>
          <Expand >
            {listIcon("18px", colorTheme)}
            <NotificationCircle isNew={changesInDropdownTopfiles} />
          </Expand>
          <LinkText>Vis mere</LinkText>
        </LinkWrapper>
        {this.state.expanded && this._createDropdown()}
      </>
    );
  }
}

LinkDropdown.propTypes = {
  topFiles: PropTypes.array.isRequired,
  addActiveDocument: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  unseenTopfiles: state.agentConfig.unseenTopfiles,
});

export default connect(mapStateToProps)(LinkDropdown);

const LinkWrapper = styled(StyledLink)`
  position: relative;
  flex: 1;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0.1rem 0.1rem;
  border-right: 0.1rem solid ${borderColor};
  background-color: ${props => props.$expanded ? linkItemHover : linkItemColor};
  align-items: center;

`;

const Expand = styled.div`
  position: relative;
  border-radius: 2px;
  padding: 0.4rem 0.5rem;
  margin-left: .5rem;
  align-items: center;
  display: inline-flex;
  cursor: pointer;
  color: ${textOnTheme};
  user-select: none;
  border: 1px transparent;
  box-shadow: 0 1px 0 hsla(0, 0%, 100%, 0.05) inset, 0 0 1px hsla(0, 0%, 100%, 0.15) inset, 0 0 1px hsla(0, 0%, 0%, 0.05);
`;

const LinkText = styled.div`
  padding-left: .5rem;
`;

const Dropdown = styled(Content)`
  top: ${(props) => props.top + 1 + 'px'};
  margin: 0;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  position: absolute;
  right: 1.2rem;
  z-index: 999999;
  background-color: ${itemBackground};
`;

const DropdownItem = styled.div`
  display: flex;
  padding: 0.3rem 0.5rem 0.3rem 1rem;
  border-bottom: 0.1rem solid #f0f0f0;
  user-select: none;
  cursor: pointer;
  &:hover {
    background-color: ${generalBackground};
    outline: none;
  }
`;