import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { bindActionCreators } from 'redux';

import LoginContainer from './components/LoginComp/login.container';
import PrivateRoute from './components/private-route.component';
import Dashboard from './components/Dashboard/dashboard.container';
import ErrorComp from './components/GlobalComponents/error.component';
import { clearToken, redirectDashboard, loginSuccess, loginRequest, loginFailure } from './_actions/auth.actions';
import { Config } from './config/config';
import { Loading } from './components/GlobalComponents/loading.component';
import AdminDashboard from './components/AdminDashboard/AdminDashboard.container';
import AxiosInterceptor from './middleware/axios.interceptor';
import utils from './utils/Utils';

export class App extends Component {
  async componentDidMount() {
    // Get url path (this should be a token on initial entry)
    if (this.props.query && this.props.query.includes('?token')) {
      const urlPath = this.props.query;
      const urlToken = urlPath.split('=')[1]; // split query string so we get token value
      // Decode Url token if not valid an error is thrown and redirect to login page
      try {
        await this.props.loginRequest('Validating token');
        const decodedURLToken = jwtDecode(urlToken);
        //Handle different token cases
        await this.handleTokenLogin(urlToken, decodedURLToken);
      } catch (error) {
        this.props.loginFailure('Token var ikke valid, prøv igen eller login her');
        return this.props.redirectDashboard();
      }
    }

    if (this.props.pathname === '/') {
      this.props.redirectDashboard();
    }

    AxiosInterceptor();
  }

  async checkTokenValidityOnServer(token) {
    const result = await fetch(`${Config.conf.backendUrl}/api/v1/testAuth`, {
      method: 'GET',
      headers: {
        Authorization: `bearer ${token}`,
      },
    });

    // if the token is valid the HTTP header will be 200 else 401
    return result.status === 200;
  }
  async handleHttpErrors(res) {
    if (!res.ok) {
      throw Error(res.statusText);
    }
    return res.json();
  }
  async exchangeToAccessToken(urlToken, decodedURLToken) {
    const result = await fetch(`${Config.conf.backendUrl}/api/v1/tokenlogin`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        token: urlToken,
        adid: decodedURLToken.username,
      }),
    }).then(this.handleHttpErrors);

    // If the token is valid return the token else return null
    return result.token;
  }

  async handleTokenLogin(urlAccessToken, decodedURLAccessToken) {
    // Fetch existing token from sessionStorage
    const storedToken = sessionStorage.getItem('token');

    try {
      // Exchange the access token to "real" token
      const urlToken = await this.exchangeToAccessToken(urlAccessToken, decodedURLAccessToken);
      const decodedURLToken = jwtDecode(urlToken);

      // If a stored token is present, not expired and Valid
      if (storedToken && this.isNotExpired(storedToken) && (await this.checkTokenValidityOnServer(storedToken))) {
        const decodedStoredToken = jwtDecode(storedToken);

        // Check if URL token is newer than stored token
        if (decodedURLToken.exp > decodedStoredToken.exp) {
          // Check if URL token is valid on the server, else throw error and redirect
          if (await this.checkTokenValidityOnServer(urlToken)) {
            sessionStorage.setItem('token', urlToken);
            this.props.loginSuccess(urlToken);
            this.props.redirectDashboard();
          } else {
            throw Error('The URL provided token is not valid!');
          }
        } else {
          //If existing token is newer than the URL token
          this.props.loginSuccess(storedToken);
          this.props.redirectDashboard();
        }
      } else {
        // if there is no stored token, it is expired or it is invalid we remove it and try the URL token
        sessionStorage.removeItem('token');

        // Check if the URL token is valid
        if (await this.checkTokenValidityOnServer(urlToken)) {
          sessionStorage.setItem('token', urlToken);
          this.props.loginSuccess(urlToken);
          this.props.redirectDashboard();
        } else {
          throw Error('Server invalidated URL token!');
        }
      }
    } catch (error) {
      console.error('INVALID TOKEN :', error);
      this.props.loginFailure('Der skete en fejl med token login. Prøv venligst igen, eller login her');
      this.props.redirectDashboard();
    }
  }

  isNotExpired(token) {
    try {
      // Use argument token or token from storage
      const tokenToValidate = token || sessionStorage.getItem('token');

      if (!tokenToValidate) {
        return false;
      }
      // if there is no token then an error is thrown and a redirect to loginpage
      const decodedToken = jwtDecode(tokenToValidate);
      const now = Date.now() / 1000;
      // true if token is still valid, else remove token and redirect to login
      if (now < decodedToken.exp) {
        return true;
      } else {
        sessionStorage.removeItem('token');
        this.props.clearToken();
        return false;
      }
    } catch (error) {
      sessionStorage.removeItem('token');
      return false;
    }
  }

  render() {
    if (this.props.loading) return <Loading />;
    const isValid = this.isNotExpired();
    const isAdmin = isValid && (utils.isSuperAdmin() || utils.isAdmin());

    return (
      <Switch>
        <Route exact path="/login" component={LoginContainer} />
        <PrivateRoute exact isLoggedIn={isValid} path="/dashboard" component={Dashboard} />
        <PrivateRoute isLoggedIn={isAdmin} path="/admin" component={AdminDashboard} />
        <Route
          render={() => <ErrorComp redirect={this.props.redirectDashboard} text={'404 Denne side eksisterer ikke'} />}
        />
      </Switch>
    );
  }
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearToken,
      redirectDashboard,
      loginSuccess,
      loginRequest,
      loginFailure,
    },
    dispatch
  );

const mapStatetoProps = (state) => ({
  token: state.auth.token,
  query: state.router.location.search,
  pathname: state.router.location.pathname,
  loading: state.auth.loading,
});

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(App));
