import {
  GET_COMPLIANCE_DATA,
  GET_COMPLIANCE_DATA_SUCCESS,
  GET_COMPLIANCE_DATA_FAILED,
  UPDATE_COMPLIANCE_IMPLEMENTATION,
  UPDATE_COMPLIANCE_IMPLEMENTATION_SUCCESS,
  UPDATE_COMPLIANCE_IMPLEMENTATION_FAILED,
  SET_SELECTED_COMPLIANCE_ITEM,
  SET_DISPLAY_SETUP_MODAL,
  SET_DISPLAY_STATUS_MODAL,
  SET_DISPLAY_STOP_MODAL,
} from '../_actions/compliance.actions';

const initialState = {
  complianceItems: [],
  agentIds: [],
  loading: false,
  selectedComplianceItem: undefined,
  displaySetupModal: false,
  displayStatusModal: false,
  displayStopModal: false,
  error: undefined,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPLIANCE_DATA:
    case UPDATE_COMPLIANCE_IMPLEMENTATION:
      return {
        ...state,
        loading: true,
      };

    case GET_COMPLIANCE_DATA_SUCCESS:
      return {
        ...state,
        complianceItems: action.payload.complianceItems,
        agentIds: action.payload.agentIds,
        loading: false,
      };

    case UPDATE_COMPLIANCE_IMPLEMENTATION_SUCCESS:
      const { complianceItem } = action.payload;
      return {
        ...state,
        loading: false,
        selectedComplianceItem: complianceItem,
        complianceItems: state.complianceItems.map(item => {
          return item._id === complianceItem._id ? complianceItem : item;
        }),
      };

    case SET_SELECTED_COMPLIANCE_ITEM:
      return {
        ...state,
        selectedComplianceItem: state.complianceItems.find(item => item._id === action.payload.complianceId),
      };

    case SET_DISPLAY_SETUP_MODAL:
      return {
        ...state,
        displaySetupModal: action.payload.displayValue,
        displayStatusModal: !action.payload.displayValue,
      };

    case SET_DISPLAY_STATUS_MODAL:
      return {
        ...state,
        displayStatusModal: action.payload.displayValue,
      };

    case SET_DISPLAY_STOP_MODAL:
      return {
        ...state,
        displayStopModal: action.payload.displayValue,
        displayStatusModal: !action.payload.displayValue,
      };

    case GET_COMPLIANCE_DATA_FAILED:
    case UPDATE_COMPLIANCE_IMPLEMENTATION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default reducer;
