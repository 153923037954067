import {
  DATA_REQUEST,
  DATA_REQUEST_FAILED, // TBD
  DATA_REQUEST_SUCCESS,
  INSTRUCTION_REQUEST,
  TRANSACTION_REQUEST,
  TOPFILE_REQUEST,
  GROUP_REQUEST,
  INSTRUCTION_REQUEST_FAILED,
  TRANSACTION_REQUEST_FAILED,
  TOPFILE_REQUEST_FAILED,
  GROUP_REQUEST_FAILED,
  UPDATE_INSTRUCTION_SUCCESS,
  UPDATE_TRANSACTION_SUCCESS,
  UPDATE_TOPFILE_SUCCESS,
  UPDATE_GROUP_SUCCESS,
  NEW_ROW_SUBMITTED,
  NEW_INSTRUCTION,
  NEW_INSTRUCTION_SUCCESS,
  NEW_TOPFILE,
  NEW_TRANSACTION,
  NEW_TRANSACTION_SUCCESS,
  NEW_TOPFILE_SUCCESS,
  NEW_GROUP_SUCCESS,
  DELETE_INSTRUCTION_SUCCESS,
  DELETE_TRANSACTION_SUCCESS,
  DELETE_TOPFILE_SUCCESS,
  SET_DOCUMENT_CHANGES,
  SET_DOCUMENT_CHANGES_LOADING,
} from '../_actions/instructionSettings.actions';

const initialState = {
  groups: [],
  instructions: [],
  topfiles: [],
  transactions: [],
  error: null,
  loading: false,
  instructionsLoading: false,
  transactionsLoading: false,
  topfilesLoading: false,
  groupsLoading: false,
  submitted: true,
  documentChanges: [],
  isDocumentChangesLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DATA_REQUEST_SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        loading: false,
        error: null,
      };
    case DATA_REQUEST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case INSTRUCTION_REQUEST: {
      return {
        ...state,
        instructionsLoading: true,
      };
    }
    case INSTRUCTION_REQUEST_FAILED: {
      return {
        ...state,
        instructionsLoading: false,
      };
    }
    case TRANSACTION_REQUEST: {
      return {
        ...state,
        transactionsLoading: true,
      };
    }
    case TRANSACTION_REQUEST_FAILED: {
      return {
        ...state,
        transactionsLoading: false,
      };
    }
    case TOPFILE_REQUEST: {
      return {
        ...state,
        topfilesLoading: true,
      };
    }
    case TOPFILE_REQUEST_FAILED: {
      return {
        ...state,
        topfilesLoading: false,
      };
    }
    case GROUP_REQUEST: {
      return {
        ...state,
        groupsLoading: true,
      };
    }
    case GROUP_REQUEST_FAILED: {
      return {
        ...state,
        groupsLoading: false,
      };
    }
    case UPDATE_INSTRUCTION_SUCCESS: {
      const newInstructions = JSON.parse(JSON.stringify(state.instructions));
      return {
        ...state,
        instructionsLoading: false,
        instructions: newInstructions.map((i) => {
          if (i.doc._id === action.payload._id) return { ...i, doc: action.payload };
          return { ...i };
        }),
      };
    }

    case UPDATE_TRANSACTION_SUCCESS: {
      const newTransactions = JSON.parse(JSON.stringify(state.transactions));
      return {
        ...state,
        transactionsLoading: false,
        transactions: newTransactions.map((i) => {
          if (i.doc._id === action.payload._id) return { ...i, doc: action.payload };
          return { ...i };
        }),
      };
    }

    case UPDATE_TOPFILE_SUCCESS: {
      const newTopfiles = JSON.parse(JSON.stringify(state.topfiles));
      return {
        ...state,
        topfilesLoading: false,
        topfiles: newTopfiles.map((i) => {
          if (i.doc._id === action.payload._id) return { ...i, doc: action.payload };
          return { ...i };
        }),
      };
    }
    case UPDATE_GROUP_SUCCESS: {
      const newGroups = JSON.parse(JSON.stringify(state.groups));
      return {
        ...state,
        groupsLoading: false,
        groups: newGroups.map((group) => {
          if (group.id === action.payload.id) return action.payload;
          return { ...group };
        }),
      };
    }
    case NEW_ROW_SUBMITTED: {
      return {
        ...state,
        submitted: action.payload,
      };
    }
    case NEW_INSTRUCTION: {
      const newInstructions = JSON.parse(JSON.stringify(state.instructions));
      newInstructions.push(action.payload);
      return {
        ...state,
        instructions: newInstructions,
      };
    }
    case NEW_TRANSACTION: {
      const newTransactions = JSON.parse(JSON.stringify(state.transactions));
      newTransactions.push(action.payload);
      return {
        ...state,
        transactions: newTransactions,
      };
    }
    case NEW_TOPFILE: {
      const newTopfiles = JSON.parse(JSON.stringify(state.topfiles));
      newTopfiles.push(action.payload);
      return {
        ...state,
        topfiles: newTopfiles,
      };
    }
    case NEW_INSTRUCTION_SUCCESS: {
      const newInstructions = JSON.parse(JSON.stringify(state.instructions)).map((instruction) => {
        if (instruction.key === 'new') {
          return {
            id: action.payload._id,
            key: action.payload._id,
            doc: { ...action.payload, key: action.payload._id },
          };
        }
        return instruction;
      });
      return {
        ...state,
        instructionsLoading: false,
        submitted: true,
        instructions: newInstructions,
      };
    }
    case NEW_TRANSACTION_SUCCESS: {
      const newTransactions = JSON.parse(JSON.stringify(state.transactions)).map((instruction) => {
        if (instruction.key === 'new') {
          return {
            id: action.payload._id,
            key: action.payload._id,
            doc: { ...action.payload, key: action.payload._id },
          };
        }
        return instruction;
      });
      return {
        ...state,
        transactionsLoading: false,
        submitted: true,
        transactions: newTransactions,
      };
    }
    case NEW_TOPFILE_SUCCESS: {
      const newTopfiles = JSON.parse(JSON.stringify(state.topfiles)).map((instruction) => {
        if (instruction.key === 'new') {
          return {
            id: action.payload._id,
            key: action.payload._id,
            doc: { ...action.payload, key: action.payload._id },
          };
        }
        return instruction;
      });
      return {
        ...state,
        topfilesLoading: false,
        submitted: true,
        topfiles: newTopfiles,
      };
    }

    case NEW_GROUP_SUCCESS: {
      const newGroups = JSON.parse(JSON.stringify(state.groups));
      newGroups.push({ id: action.payload._id, key: action.payload.name, value: action.payload.name });
      return {
        ...state,
        groupsLoading: false,
        groups: newGroups,
      };
    }
    case DELETE_INSTRUCTION_SUCCESS: {
      const newInstructions = JSON.parse(JSON.stringify(state.instructions)).filter(
        (instruction) => instruction.key !== action.payload.id
      );
      return {
        ...state,
        instructionsLoading: false,
        submitted: true,
        instructions: newInstructions,
      };
    }
    case DELETE_TRANSACTION_SUCCESS: {
      const newTransactions = JSON.parse(JSON.stringify(state.transactions)).filter(
        (instruction) => instruction.key !== action.payload.id
      );
      return {
        ...state,
        transactionsLoading: false,
        submitted: true,
        transactions: newTransactions,
      };
    }

    case DELETE_TOPFILE_SUCCESS: {
      const newTopfiles = JSON.parse(JSON.stringify(state.topfiles)).filter(
        (instruction) => instruction.key !== action.payload.id
      );
      return {
        ...state,
        topfilesLoading: false,
        submitted: true,
        topfiles: newTopfiles,
      };
    }

    case SET_DOCUMENT_CHANGES: {
      return {
        ...state,
        isDocumentChangesLoading: false,
        documentChanges: action.payload.map(change => {
          return {
            ...change,
            key: change._id,
          }
        }),
      }
    }

    case SET_DOCUMENT_CHANGES_LOADING: {
      return {
        ...state,
        isDocumentChangesLoading: action.payload.isLoading,
      }
    }

    default:
      return state;
  }
};

export default reducer;
