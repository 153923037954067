import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';


import { Confidence } from '../ConfidenceComp/confidence.component';
import { Content } from '../../sharedStyledComponents/generalStyles'

export class Listening extends Component {
    render() {
        return (
            <Container>
                <Content>
                    <Confidence
                        loading={this.props.loading}
                        instructions={this.props.workInstructions}
                        addActiveDocument={this.props.addActiveDocument}
                        offline={this.props.offline}
                    />
                </Content>
            </Container>
        )
    }
}
Listening.propTypes = {
    loading: PropTypes.bool.isRequired,
    addActiveDocument: PropTypes.func.isRequired,
    workInstructions: PropTypes.array,
    offline: PropTypes.bool.isRequired,
}

const Container = styled.div`
`;
