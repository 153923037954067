import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, FloatButton, Layout, Row } from 'antd';
import { itemBackground } from '../../sharedStyledComponents/generalStyles';

import RevisionTable from './RevisionTable.component';
import { getSuperAdminDocumentChanges } from '../../../_actions/revision.actions';

const Revision = () => {
  const { documentChangesRevisions, loading, error } = useSelector((store) => store.revision);
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(getSuperAdminDocumentChanges(0));
  }, [dispatch]);

  return (<>
    <Layout style={{ background: itemBackground, marginTop: '1rem' }}>
      <FloatButton.BackTop visibilityHeight={700} />
      {error && (
        <Alert message="Der skete desværre en fejl" description={error.message} type="error" showIcon closable />
      )}

      <>
        <Row>
          <RevisionTable documentChanges={documentChangesRevisions} loading={loading} />
        </Row>
      </>
    </Layout>
  </>);
};

// const FilterContainer = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 0.5rem;
//   margin-bottom: 1.5rem;
// `;

// const DaysPicker = styled(Select)`
//   max-width: 60px;
//   width: 60px;
// `;

export default Revision;
