import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { Alert, FloatButton, Layout, Row, Select, Space, Typography } from 'antd';
import { itemBackground } from '../../sharedStyledComponents/generalStyles';

import InstructionChangesTable from './InstructionChangesTable.component';
import { getAdminDocumentChanges } from '../../../_actions/documentChanges.actions';
import InstructionChangeModal from './InstructionChangeModal.component';
import { getAgents } from '../../../_actions/agents.actions';
import { AGENT_STATUS_TYPE } from '../InstructionChanges/_constants/instructionChanges.contstants';
import AgentPickerTeams from '../../GlobalComponents/agentPickerTeams.component';

const InstructionChanges = () => {
  const { documentChanges, loading: loadingDocs, error } = useSelector((store) => store.documentChanges);
  const { agents, loading: loadingAgents } = useSelector((store) => store.agents);

  const [displayedChanges, setDisplayedChanges] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [selectedDays, setSelectedDays] = useState(30);
  const [selectedChange, setSelectedChange] = useState(null);
  const [displayModal, setDisplayModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAgents());
  }, [dispatch]);

  useEffect(() => {
    const timestamp = selectedDays > 90 ? 0 : dayjs().subtract(selectedDays, 'days').toDate().getTime();
    dispatch(getAdminDocumentChanges(timestamp));
  }, [selectedDays, dispatch]);

  useEffect(() => {
    const displayedAgents = selectedAgents.length > 0 ? selectedAgents : agents;
    const mappedChanges = documentChanges.filter((element) => {
      return element.visibleAgent;
    }).map((change) => {
      const mappedStatus = displayedAgents.map((agent) => ({
        agent: agent._id,
        status:
          (agent.createdTimestamp ?? 0) > change.timestamp
            ? AGENT_STATUS_TYPE.notAvailable
            : change.agentStatus[agent._id]
              ? AGENT_STATUS_TYPE.seen
              : AGENT_STATUS_TYPE.notSeen,
        timestamp: change.agentStatus[agent._id]?.timestamp || 0,
      }));

      const lastSeen = mappedStatus.reduce((lastSeen, agentStatus) => {
        return Math.max(lastSeen, agentStatus.timestamp);
      }, 0);

      return { ...change, lastSeen: lastSeen, agentStatus: mappedStatus };
    });

    setDisplayedChanges(mappedChanges);
  }, [documentChanges, selectedAgents, agents]);

  const onChangeSelectedAgents = (agentIds) => {
    setSelectedAgents(agents.filter((a) => agentIds.find((selectedAgent) => selectedAgent === a._id)));
  };

  const onChangeDays = (days) => {
    setSelectedDays(days);
  };

  const onChangeSelected = (change) => {
    setSelectedChange(change);
    setDisplayModal(true);
  };

  const onCloseModal = () => {
    setDisplayModal(false);
  };

  return (<>
    <Layout style={{ background: itemBackground, marginTop: '1rem' }}>
      <FloatButton.BackTop visibilityHeight={700} />
      {error && (
        <Alert message="Der skete desværre en fejl" description={error.message} type="error" showIcon closable />
      )}

      <>
        <Row>
          <FilterContainer>
            <Typography.Text>Vælg rådgiver(e)</Typography.Text>
            <AgentPickerTeams loading={loadingAgents} onChangeHandler={onChangeSelectedAgents} />
            <Space>
              <Typography.Text>og vis ændringer for de sidste</Typography.Text>
              <DaysPicker
                maxTagCount="responsive"
                style={{ width: 250 }}
                defaultValue={30}
                placeholder="Vælg dage"
                onChange={onChangeDays}
              >
                <Select.Option value="30">30</Select.Option>
                <Select.Option value="60">60</Select.Option>
                <Select.Option value="90">90</Select.Option>
                <Select.Option value="91">90+</Select.Option>
              </DaysPicker>
              <Typography.Text>dage.</Typography.Text>
            </Space>
          </FilterContainer>
        </Row>
        <Row>
          <InstructionChangesTable
            documentChanges={displayedChanges}
            agents={selectedAgents}
            loading={loadingDocs}
            onChangeClicked={onChangeSelected}
          />
        </Row>
      </>
      <InstructionChangeModal
        change={selectedChange}
        visible={selectedChange !== null && displayModal}
        onClose={onCloseModal}
      />
    </Layout>
  </>);
};

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
`;

const DaysPicker = styled(Select)`
  max-width: 60px;
  width: 60px;
`;

export default InstructionChanges;
