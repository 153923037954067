import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types'

import { Loading } from '../GlobalComponents/loading.component';

export const Login = ({ loading, children }) => {
    return (
        <Container>
            {loading ? <Loading /> : ''}
            {children}
        </Container>
    )
}

Login.propTypes = {
    loading: PropTypes.bool.isRequired,
    children: PropTypes.object,
}
const Container = styled.div`
    height: 100vh;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
`;