export const IMPLEMENTATION_STATUS = {
  setup: 'setup',
  active: 'active',
  paused: 'paused',
  completed: 'completed',
};

export const COLORS = {
  blue: '#2f4960',
  lightBlue: '#2f496016',
  green: '#006106',
  lightGreen: '#00610616',
  red: '#6D0100',
  lightRed: '#6D010016',
  orange: '#FBA66C',
  lightOrange: '#FBA66C16',
  rowGrey: '#F1F1F1',
  rowLightGrey: '#78787880',
  tabBlue: '#E3EBED',
  footerBlue: '#D4DAE2',
};