import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';
import FlipMove from 'react-flip-move';
import { ChosenWrapper, colorTheme, textTheme, TextUnderlineAnimated } from '../../../sharedStyledComponents/generalStyles';
import { markerIcon } from '../../../sharedStyledComponents/icons';

class RelevantDocuments extends PureComponent {

    createLink = (logo, title, percentage, index) => {
        const regex = /[a-zA-Z\u00c0-\u017e]+/g;
        const displayTitle = title.match(regex) ? title.match(regex).join(' ') : title;
        const displayPercentage = Math.round(percentage * 100);
        const docId = this.props.activeDocument.id
        return (<LinkWrapper title={title} key={title + index} onClick={() => this.props.navigateTo(title, docId)}>
            <TextWrapper>
                <IconWrapper>{logo}</IconWrapper>
                <LinkText>{displayTitle}</LinkText>
            </TextWrapper>
            <PercentWrapper>{displayPercentage}%</PercentWrapper>
        </LinkWrapper>
        )
    }

    createList = () => {
        let res = []
        if (this.props.relevantHeaders) {
            // Remove headers without text (all with text are above 6 in length)
            let cleanedHeaders = this.props.relevantHeaders.filter(h => h.name.length > 6);
            res = cleanedHeaders
                .sort((a, b) => a.name.localeCompare(b.name))
                .sort((a, b) => a.score > b.score ? -1 : 1)
                .slice(0, 5)
            return res.map((header, i) => this.createLink(markerIcon('16px', colorTheme), header.name, header.score, i))
        }
        return res;
    }

    render() {

        return (
            <Wrapper>
                <Header>Foreslåede emner</Header>
                {!this.props.relevantHeaders && <HeaderSubtext>Ingen forslag</HeaderSubtext>}
                <LinkList>
                    <FlipMove>
                        {this.props.relevantHeaders && this.createList()}
                    </FlipMove>
                </LinkList>
            </Wrapper >
        )
    }
}

RelevantDocuments.propTypes = {
    relevantHeaders: PropTypes.array.isRequired,
    navigateTo: PropTypes.func.isRequired,
}

export default RelevantDocuments

const Wrapper = styled(ChosenWrapper)`
    flex-direction: column; 
`;

const Header = styled.div`
    font-weight: 700;
    color: ${colorTheme};
    display: flex;
    user-select: none;
`;
const HeaderSubtext = styled.div`
    color: #b3b3b3;
    user-select: none;
`;
const TextWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    max-height: 1.5rem;
`;
const PercentWrapper = styled.div`
    margin-left:auto;
    float: right;
`;

const IconWrapper = styled.span`
    height: 1.5rem;
    max-height: 1.5rem;
`;
const LinkText = styled(TextUnderlineAnimated)`
    margin-left: .4rem;
    color: ${colorTheme};
    white-space:nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    max-width: 14rem;
    width: 13.5vw;
    user-select: none;
`
const LinkWrapper = styled.a`
    display: flex;
    justify-items: space-between;
    align-items: center;
    padding: .2rem 0rem;
    cursor: pointer;
    &:hover {
        max-height: initial;
      ${LinkText}:before {
          visibility: visible;
          width: 100%;
          background-color: ${textTheme};
          transform: width;
      }
  }
`;

const LinkList = styled.ul`
    list-style: none;
    padding: 0;
    padding-left: .5rem;
    margin: 0;
    /* max-width: 18rem; */
`