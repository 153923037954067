import React from 'react'
import styled from 'styled-components';
import { colorTheme, textOnTheme, linkItemColor } from '../../../sharedStyledComponents/generalStyles';

export const N1Tab = React.memo(function multiDocumentHeaderTab(props) {
    return (
        <Container $isActive={props.isActive} onClick={() => props.setCurrentTabToListview()}>
            <Title $isActive={props.isActive}>
                {props.title}
            </Title>
        </Container>
    )
})

const Container = styled.span`
    border-radius: 5px 5px 0 0;
    margin-right: .1rem;
    padding: 0.5rem;
    display:flex;
    cursor: pointer;
    align-content: center;
    background-color: ${props => props.$isActive ? colorTheme : linkItemColor};
    box-shadow: -1px -1px 1px #888;
`

const Title = styled.span`
    align-self: center;
    padding-right: .5rem;
    color: ${props => props.$isActive ? textOnTheme : colorTheme};
    font-weight: 550;
`

export const CloseButton = styled.a`
  align-self: center;
  font-size: 1rem;
  padding: .2rem;
  cursor: pointer;
  background-color : transparent;
  max-height: inherit;
  transition: transform .3s ease-in-out;
  &:hover {
    background-color : transparent;
    transform: scale(1.2);
  }
`