import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { HeaderTab } from './multiDocument.header.tab';
import { N1Tab } from './multiDocument.header.n1Tab';
import CloseButton from '../../../GlobalComponents/close.component';

import { generalBackground, colorTheme } from '../../../sharedStyledComponents/generalStyles';

export default class MultiDocumentHeader extends PureComponent {
  createTabs = () =>
    this.props.activeDocuments.map((doc, i) => {
      return (
        <HeaderTab
          key={doc.id}
          id={doc.id}
          title={doc.name}
          isActive={i === this.props.currentDocumentIndex}
          removeActiveDocument={this.props.removeActiveDocument}
          setCurrentActiveDocument={this.props.setCurrentActiveDocument}
        />
      );
    });

  render() {
    return (
      <Container>
        <N1Tab
          isActive={this.props.currentDocumentIndex === 'Listview'}
          key={'Listview'}
          title={'Listevisning'}
          setCurrentTabToListview={this.props.setCurrentTabToListview}
        />
        {this.createTabs()}
        <CloseButtonContainer>
          <CloseButton color={colorTheme} cb={this.props.removeAllActiveDocuments} />
        </CloseButtonContainer>
      </Container>
    );
  }
}

MultiDocumentHeader.propTypes = {
  activeDocuments: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentDocumentIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  removeActiveDocument: PropTypes.func.isRequired,
  setCurrentActiveDocument: PropTypes.func.isRequired,
  removeAllActiveDocuments: PropTypes.func.isRequired,
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.2rem 0.2rem 0 0.2rem;
  width: 100%;
  max-width: 75vw;
  height: 2.3rem;
  background-color: ${generalBackground};
`;

const CloseButtonContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
