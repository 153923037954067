import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, Col, FloatButton, Layout, Row } from 'antd';
import { itemBackground } from '../../sharedStyledComponents/generalStyles';

import TeamsTable from './TeamsTable.component';
import { inlineHeader, Title } from '../../sharedStyledComponents/dataGridStyles';
import AdminTeamTable from './AdminTeamTable.component';
import { getTeams } from '../../../_actions/teams.actions';

dayjs.locale('da');
const getTeamMemberCount = (rowData) => `${rowData.members?.length || 0} rådgivere`;

export const teamsColumns = [
  {
    dataIndex: ['name'],
    title: inlineHeader('Teams'),
    placeholder: 'Team navn',
    required: true,
    sorter: (cellParamsA, cellParamsB) => (cellParamsA.name || 'zzz').localeCompare(cellParamsB.name || 'zzz', 'da'),
    defaultSortOrder: 'ascend',
    editable: true,
    width: '30%',
  },
  {
    field: 'memberCount',
    title: () => <Title>Rådgivere</Title>,
    placeholder: 'Rådgiver',
    sorting: false,
    editable: false,
    render: getTeamMemberCount,
    width: '30%',
  },
  {
    dataIndex: ['lastEdit'],
    title: () => <Title>Sidst opdateret</Title>,
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.lastEdit - b.lastEdit,
    width: '30%',
    render: (_, record) => {
      const lastEdited = dayjs(record.lastEdit);
      return <>{`${lastEdited.date()}/${lastEdited.month() + 1}-${lastEdited.year()} kl ${lastEdited.format('LT')}`}</>;
    },
  },
];

export const adminTeamColumns = [
  {
    dataIndex: ['name'],
    title: inlineHeader('Rådgiver'),
    placeholder: 'Rådgiver initialer',
    required: true,
    sorter: (cellParamsA, cellParamsB) => (cellParamsA.name || 'zzz').localeCompare(cellParamsB.name || 'zzz', 'da'),
    defaultSortOrder: 'ascend',
    editable: true,
    width: '30%',
  },
  {
    dataIndex: ['lastEdited'],
    title: () => <Title>Tilføjet</Title>,
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.lastEdited - b.lastEdited,
    width: '30%',
    render: (_, record) => {
      const lastEdited = dayjs(record.lastEdited);
      return <>{`${lastEdited.date()}/${lastEdited.month() + 1}-${lastEdited.year()} kl ${lastEdited.format('LT')}`}</>;
    },
  },
];

const Teams = () => {
  const { loading, error, teams, adminTeam } = useSelector((store) => store.teams);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTeams());
  }, []);

  return (
    (<Layout style={{ background: itemBackground, marginTop: '1rem' }}>
      <FloatButton.BackTop visibilityHeight={700} />
      {error && (
        <Alert message="Der skete desværre en fejl" description={error.message} type="error" showIcon closable />
      )}
      <Row>
        <Col span={24}>
          <TeamsTable
            columns={teamsColumns}
            clickableRow={true}
            teams={teams.map((t) => {
              return { ...t, key: t._id };
            })}
            loading={loading}
          />
        </Col>
      </Row>
      <Row>
        <Col span={18}>
          <AdminTeamTable columns={adminTeamColumns} clickableRow={true} data={adminTeam || []} loading={loading} />
        </Col>
      </Row>
    </Layout>)
  );
};

export default Teams;
