import { Row, Col, Typography, Card, Divider, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { screen } from '../../sharedStyledComponents/Responsive.styles';
import { KPI_GREEN, KPI_RED, itemBackground, colorTheme} from '../../sharedStyledComponents/generalStyles';
import PlayArrow from '@material-ui/icons/PlayArrow';

/**
 * Renders a Card Module with the given data input
 * @param {Object} props contains data field to iterate through and render content
 * @returns {CardModule}
 */
const CardModule = (props) => {
  return (
    <>
      <ModuleTitle level={2}>
        <Typography.Text strong>{props.moduleTitle}</Typography.Text>&nbsp;
        {props.moduleSubtitle}
      </ModuleTitle>
      <Row gutter={20} type='flex'>
        {props.data &&
          props.data.map((item, i) => (
            <Col key={i} lg={Math.min(12, 24 / props.data.length)} xs={24} md={12}>
              <CardComponent loading={props.isLoading}>
                <CardTitle>
                  {item.title}
                  {item.tooltip && (
                    <CardTooltip>
                      <Tooltip title={item.tooltip} placement="topLeft">
                        <ExclamationCircleOutlined />
                      </Tooltip>
                    </CardTooltip>
                  )}
                </CardTitle>
                <CardValue>{item.value}</CardValue>
                <Description>
                  {item.text &&
                    item.text.map((txt, ii) => (
                      <Typography.Text strong={txt.isBold} key={ii}>
                        {txt.value}
                        {item.text.length - 1 !== ii && ' '}
                      </Typography.Text>
                    ))}
                </Description>
                <FlexProvider>
                  <Divider />
                  <SPLY>
                    {item.differentialPercentage > 0 ? <ArrowUpIcon /> : <ArrowDownIcon />}{' '}
                    {item.differentialPercentage ? `${item.differentialPercentage}%` : 'N/A'}
                  </SPLY>
                </FlexProvider>
              </CardComponent>
            </Col>
          ))}
      </Row>
    </>
  );
}; // CardModule

const ArrowUpIcon = styled(PlayArrow)`
  transform: rotate(-90deg) translateX(-30%);
  color: ${KPI_GREEN};
`;

const ArrowDownIcon = styled(PlayArrow)`
  transform: rotate(90deg) translateX(30%);
  color: ${KPI_RED};
`;

CardModule.defaultProps = {
  moduleTitle: 'Generisk titel',
};

const FlexProvider = styled.div`
  justify-self: flex-end;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
`;

const CardTitle = styled(Typography.Text)`
  font-family: 'IBM Plex Sans', sans-serif;
  background-color: ${colorTheme};
  border-radius: 10px 10px 0 0;
  color: ${itemBackground};
  font-size: 1rem;
  font-weight: 400;
  padding: calc(0.25rem + 8px);
  // In order to support equal height on all cards, we must have just one child of the <Row>, and due to the colored header it makes sense to do a negative margin to make it look native
  margin: -0.5rem -0.5rem 0 -0.5rem !important;

  @media ${screen.XL} {
    padding: calc(0.5rem + 8px);
    font-size: 1.5rem;
    margin: -1rem -1rem 0 -1rem !important;
  }
`;

const CardTooltip = styled.i`
  margin-left: 0.5rem;

  svg {
    font-size: 1.3rem;
  }
`;

const CardValue = styled(Typography.Text)`
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 2rem;
  color: ${colorTheme};
  text-align: center;
  font-weight: 800;

  @media ${screen.LG} {
    font-size: 2.25rem;
  }

  @media ${screen.XL} {
    font-size: 3rem;
  }
`;

const ModuleTitle = styled(Typography.Title)`
  &.ant-typography {
    font-weight: 400 !important;
    margin: 2rem 0;
  }
  strong {
    font-size: 30px;
    font-weight: 600;
  }
`;

const CardComponent = styled(Card)`
  box-shadow: 1px 3px 3px #d1d1d1;
  border-radius: 10px;
  height: 100%;

  * {
    font-family: 'IBM Plex Sans', sans-serif;
  }

  .ant-card-body {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    @media ${screen.XL} {
      padding: 1rem;
    }

    .ant-divider {
      margin: 0 0 12px 0;
    }
  }
`;

const Description = styled(Typography.Paragraph)`
  text-align: center;
  font-size: 16px;
`;

const SPLY = styled(Typography.Paragraph)`
  text-align: center;
`;

export default CardModule;
