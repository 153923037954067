import * as SC from '../_constants/statistics.constants';
import dayjs from 'dayjs';
import { uuid, secondsToMinutes } from '../utils/helpers';

const initialState = {
  users: [],
  kpis: [],
  workInstructions: [],
  agentActivityCards: [],
  agentActivityTable: [],
  agentFrequency: [],
  workInstruction: {
    id: '',
    name: '',
    data: [],
  },
  minFromDate: '',
  filters: {
    startTime: dayjs().subtract(7, 'days'),
    endTime: dayjs().subtract(1, 'days'),
    users: [],
    type: '',
  },
  fetching: {
    users: false,
    filters: false,
    kpis: false,
    workInstructions: false,
    agentActivities: false,
    agentFrequency: false,
  },
  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SC.GET_USERS_START:
      return {
        ...state,
        fetching: { ...state.fetching, users: true },
      };
    case SC.GET_USERS_SUCCESS:
      return {
        ...state,
        fetching: { ...state.fetching, users: false },
        users: action.payload.users,
        minFromDate: action.payload.minFromDate,
      };

    case SC.GET_USERS_FAILURE:
      return {
        ...state,
        fetching: { ...state.fetching, users: false },
        error: action.payload,
      };
    case SC.SET_FILTERS_START:
      return {
        ...state,
        fetching: { ...state.fetching, filters: true },
      };
    case SC.SET_FILTERS_SUCCESS:
      return {
        ...state,
        fetching: { ...state.fetching, filters: false },
        filters: { ...state.filters, ...action.payload },
      };
    case SC.SET_FILTERS_FAILURE:
      return {
        ...state,
        fetching: { ...state.fetching, filters: false },
        error: action.payload,
      };
    case SC.GET_KPIS_START:
      return {
        ...state,
        fetching: { ...state.fetching, kpis: true },
      };
    case SC.GET_KPIS_SUCCESS:
      return {
        ...state,
        fetching: { ...state.fetching, kpis: false },
        kpis: action.payload,
      };
    case SC.GET_KPIS_FAILURE:
      return {
        ...state,
        fetching: { ...state.fetching, kpis: false },
        error: action.payload,
      };
    case SC.GET_WORK_INSTRUCTIONS_START:
      return {
        ...state,
        workInstructions: [],
        fetching: { ...state.fetching, workInstructions: true },
      };
    case SC.GET_WORK_INSTRUCTIONS_SUCCESS:
      return {
        ...state,
        fetching: { ...state.fetching, workInstructions: false },
        workInstructions: action.payload.categories,
      };
    case SC.GET_WORK_INSTRUCTIONS_FAILURE:
      return {
        ...state,
        fetching: { ...state.fetching, workInstructions: false },
        error: action.payload,
      };
    case SC.GET_WORK_INSTRUCTION_START:
      return {
        ...state,
        workInstruction: initialState.workInstruction,
        fetching: { ...state.fetching, workInstruction: true },
      };
    case SC.GET_WORK_INSTRUCTION_SUCCESS:
      const formattedWorkInstruction = action.payload.data.map(inst => {
        const duration = (new Date(inst.endTimestamp) - new Date(inst.startTimestamp)) / 1000;
        const minutes = secondsToMinutes(duration);

        return {
          ...inst,
          formattedStartDate: new Date(inst.startTimestamp).toLocaleString(),
          durationMin: minutes,
          key: uuid(),
        };
      });
      return {
        ...state,
        fetching: { ...state.fetching, workInstruction: false },
        workInstruction: {
          ...action.payload,
          data: formattedWorkInstruction,
        },
      };
    case SC.GET_WORK_INSTRUCTION_FAILURE:
      return {
        ...state,
        fetching: { ...state.fetching, workInstruction: false },
        error: action.payload,
      };
    case SC.GET_AGENT_ACTIVITIES_START:
      return {
        ...state,
        fetching: { ...state.fetching, agentActivities: true },
        agentActivityCards: initialState.agentActivityCards,
        agentActivityTable: initialState.agentActivityTable,
      };
    case SC.GET_AGENT_ACTIVITIES_SUCCESS:
      return {
        ...state,
        fetching: { ...state.fetching, agentActivities: false },
        agentActivityCards: action.payload.cardData,
        agentActivityTable: action.payload.tableData,
      };
    case SC.GET_AGENT_ACTIVITIES_FAILURE:
      return {
        ...state,
        fetching: { ...state.fetching, agentActivities: false },
        error: action.payload,
      };
    case SC.GET_AGENT_FREQUENCY_START:
      return {
        ...state,
        fetching: { ...state.fetching, agentFrequency: true },
        agentFrequency: initialState.agentFrequency,
      };
    case SC.GET_AGENT_FREQUENCY_SUCCESS:
      return {
        ...state,
        fetching: { ...state.fetching, agentFrequency: false },
        agentFrequency: action.payload.categories,
      };
    case SC.GET_AGENT_FREQUENCY_FAILURE:
      return {
        ...state,
        fetching: { ...state.fetching, agentFrequency: false },
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
