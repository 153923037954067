import React, { memo } from 'react'
import styled from 'styled-components';
import PropTypes from 'prop-types'
import FlipMove from 'react-flip-move';

function createTextLine(text, i) {
    return <TextLine key={text + i} toggleColor={i}>{text}</TextLine>;
};

const TextTranscribing = memo(function TextTranscribing(props) {
    const list = JSON.parse(JSON.stringify(props.fullText));
    return (
        <Container >
            <FlipMove>
                {list.map((t, i) => createTextLine(t.join(' '), i))}
            </FlipMove>
        </Container>
    )
})

TextTranscribing.propTypes = {
    fullText: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
}

export default TextTranscribing;

const Container = styled.div`
    position: fixed;
    z-index: 999;
    background: #4c4c4c;
    -webkit-box-shadow: inset 0px -0.5px 2px 1px rgba(0,0,0,0.75);
    -moz-box-shadow: inset 0px -0.5px 2px 1px rgba(0,0,0,0.75);
    box-shadow: inset 0px -0.5px 2px 1px rgba(0,0,0,0.75);
    border-radius: .3rem;
    bottom: .5vh;
    left: 10vw;
    padding: .2rem 0 .2rem .2rem;
    width: 55vw;
    color: whitesmoke;
    max-height: 10vh;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    overflow-x: hidden;
    transition: height 0.5s;
    opacity: .9;
    &:hover {
        opacity: 1;
        max-height: 20vh;
    }
`;

const TextLine = styled.span`
    display: block;
    background-color: ${props => (props.toggleColor % 2) === 0 ? '' : 'rgba(108, 122, 137, .9)'};
    margin-right: .1rem;
    overflow: hidden;
`;