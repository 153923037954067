import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { textOnTheme, colorTheme, colorThemeDarkend } from '../../../sharedStyledComponents/generalStyles';
import { listIcon, chevronLeftIcon, chevronRightIcon } from '../../../sharedStyledComponents/icons';
import Utils from '../../../../utils/Utils';

import TableOfContent from './document.toc';
import NotificationCircle from '../../../sharedStyledComponents/NotificationCircle.component';

export default class Toolbar extends PureComponent {
  constructor(props) {
    super(props);
    this.buttonRef = React.createRef();
    this.state = {
      showToC: false,
      search: undefined,
      totalMatches: 0,
      currentMatch: 0,
      noMatch: null,
    };

    this.top = undefined;

    window.addEventListener('updatefindmatchescount', this.handleSearchTotalCountUpdate);
    window.addEventListener('updatefindcontrolstate', this.handleSearchCurrentMatchUpdate);
  }

  handleSearchTotalCountUpdate = (e) => {
    this.setState({
      totalMatches: e.detail.matchesCount.total,
      currentMatch: e.detail.matchesCount.current,
      noMatch: null,
    });
  };

  handleSearchCurrentMatchUpdate = (e) => {
    if (e.detail.state === 0 || e.detail.state === 2) {
      this.setState({ currentMatch: e.detail.matchesCount.current, noMatch: null });
    }
    if (e.detail.state === 1) {
      this.setState({ totalMatches: 0, currentMatch: 0, noMatch: 'Ingen match' });
    }
    return;
  };

  componentDidMount = () => {
    if (this.props.searchWord) {
      this.setState({ search: this.props.searchWord });
      this.props.onSearch(this.props.searchWord);
    }
  };
  componentWillUnmount() {
    window.removeEventListener('updatefindmatchescount', this.handleSearchTotalCountUpdate);
    window.removeEventListener('updatefindcontrolstate', this.handleSearchCurrentMatchUpdate);
  }
  handleEnter = (e) => {
    e.preventDefault();
    if (e.keyCode === 13) {
      this.props.onSwitchMatch(e);
    }
  };

  handleSearch = (e) => {
    this.setState({ search: e.target.value });
    this.props.onSearch(e.target.value);
  };

  handleTitleNavigation = (title, id) => (e) => {
    e.preventDefault();
    this.props.navigateTo(title, id);
  };

  /**
   * Toggles state of Table of Contents
   */
  toggleToC = (e) => {
    e?.preventDefault();
    this.top = this.buttonRef.current.getBoundingClientRect().bottom + 3;
    this.setState((prevState) => ({ showToC: !prevState.showToC }));
  };

  render() {
    const { showToC, currentMatch, totalMatches, search, noMatch } = this.state;

    const scale = Utils.isString(this.props.scale) ? this.props.scale : `${this.props.scale}%`;

    return (
      <ToolbarContainer>
        <FirstButtonWrapper>
          <ToggleToCButton ref={this.buttonRef} title="Indholdsfortegnelse" onClick={this.toggleToC} $active={showToC}>
            {listIcon('18px', textOnTheme)}
          </ToggleToCButton>
          <NotificationCircle
            number={this.props.unseenSectionChanges?.length}
            shouldPositionInTopRight={true}
            onClick={this.toggleToC}
          />
        </FirstButtonWrapper>
        {showToC && this.props.currentDoc.outline && (
          <TableOfContent
            unseenSectionChanges={this.props.unseenSectionChanges}
            top={this.top}
            docId={this.props.currentDoc.id}
            handleClick={this.handleTitleNavigation}
            outline={this.props.currentDoc.outline}
          />
        )}
        <SearchWrapper>
          <SearchField
            type="search"
            id="searchField"
            placeholder="Søg i dokumentet"
            onKeyUp={this.handleEnter}
            onChange={this.handleSearch}
            defaultValue={this.props.searchWord}
          />
          {search && (
            <>
              <NextButton value="previous" onClick={() => this.props.onSwitchMatch('previous')}>
                {chevronLeftIcon('11px', textOnTheme)}
              </NextButton>
              <NextButton value="next" onClick={() => this.props.onSwitchMatch('next')}>
                {chevronRightIcon('11px', textOnTheme)}
              </NextButton>
              <MatchesIndicationContainer>
                {totalMatches > 0 && `${currentMatch} af ${totalMatches}`}
                {noMatch}
              </MatchesIndicationContainer>
            </>
          )}
        </SearchWrapper>
        <PageIndicationContainer>
          {this.props.currentPage} / {this.props.totalPages}
        </PageIndicationContainer>
        <ZoomContainer>
          <ZoomAntButton icon={<MinusOutlined />} size="small" onClick={this.props.onZoomOut} />
          <ZoomAntButton icon={<PlusOutlined />} size="small" onClick={this.props.onZoomIn} />
          <ZoomScale>{scale}</ZoomScale>
        </ZoomContainer>
      </ToolbarContainer>
    );
  }
}

Toolbar.propTypes = {
  onZoomIn: PropTypes.func.isRequired,
  onZoomOut: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSwitchMatch: PropTypes.func.isRequired,
};

const ToolbarContainer = styled.div`
  height: 2.2rem;
  max-height: 2.2rem;
  top: 0;
  display: flex;
  align-items: center;
  max-width: 75vw;
  padding: 0.2rem 1rem 0.2rem 1rem;
  background-color: ${colorTheme};
`;

const FirstButtonWrapper = styled.span`
  position: relative;
  display: inline-flex;
  align-self: center;
  margin-right: 0.5rem;
`;
const ToolbarButton = styled.button`
  border-radius: 2px;
  align-self: center;
  cursor: pointer;
  color: ${textOnTheme};
  background-color: ${colorTheme};
  border: 1px solid white;
  user-select: none;
  &:hover {
    background-color: ${colorThemeDarkend};
    border: 1px solid hsla(360, 100%, 100%, 0.35);
    box-shadow: 0 1px 0 hsla(0, 0%, 100%, 0.05) inset, 0 0 1px hsla(0, 0%, 100%, 0.15) inset,
      0 0 1px hsla(0, 0%, 0%, 0.05);
  }
  :focus {
    outline: none;
    border: 1px solid hsla(216, 12%, 92%, 0.45);
    box-shadow: 0 1px 0 hsla(0, 0%, 100%, 0.05) inset, 0 0 1px hsla(0, 0%, 100%, 0.15) inset,
      0 0 1px hsla(0, 0%, 0%, 0.05);
  }
`;

const ToggleToCButton = styled.div`
  align-self: center;
  padding: 0.2rem 0.3rem;
  display: inline-flex;
  user-select: none;
  cursor: pointer;
  border-radius: 2px;
  background-color: ${(props) => (props.$active ? colorThemeDarkend : 'transparent')};
  border: ${(props) => (props.$active ? '1px solid hsla(216, 12%, 92%,.35)' : '1px solid transparent')};
  &:hover {
    background-color: ${colorThemeDarkend};
    border: 1px solid hsla(216, 12%, 92%, 0.35);
    box-shadow: 0 1px 0 hsla(0, 0%, 100%, 0.05) inset, 0 0 1px hsla(0, 0%, 100%, 0.15) inset,
      0 0 1px hsla(0, 0%, 0%, 0.05);
  }
`;

const SearchWrapper = styled.div`
  display: inline-flex;
  flex-basis: 45.5%;
`;

const SearchField = styled.input`
  background: ${textOnTheme};
  border: 1px solid hsla(216, 12%, 92%, 0);
  margin: 0.1rem 0.2rem 0.1rem 0;
  text-indent: 0.4rem;
  border-radius: 2px;
  border: 1px solid transparent;
  line-height: inherit;
  &:hover {
    border: 1px solid hsla(216, 12%, 92%, 0.35);
    box-shadow: 0 1px 0 hsla(0, 0%, 100%, 0.05) inset, 0 0 1px hsla(0, 0%, 100%, 0.15) inset,
      0 0 1px hsla(0, 0%, 0%, 0.05);
  }
  &:focus {
    outline: none;
    background: whitesmoke;
    border: 1px solid hsla(216, 12%, 92%, 0.45);
    box-shadow: 0 1px 0 hsla(0, 0%, 100%, 0.05) inset, 0 0 1px hsla(0, 0%, 100%, 0.15) inset,
      0 0 1px hsla(0, 0%, 0%, 0.05);
  }
`;

const NextButton = styled(ToolbarButton)`
  margin-left: 0.2rem;
  padding: 0rem 0.2rem;
  font-weight: 600;
  font-size: 0.8rem;
  color: ${textOnTheme};
`;

const ZoomAntButton = styled(Button)`
  background: transparent !important;
  color: ${textOnTheme} !important;
  border-color: ${textOnTheme} !important;
  margin-right: 0.5rem;

  &:hover,
  &:focus,
  &:active {
    background: transparent;
    color: #fff;
    border-color: ${textOnTheme};
  }
`;

const ZoomContainer = styled.div`
  display: flex;
  height: 2.2rem;
  align-self: center;
  align-items: center;
  justify-self: flex-end;
  margin-left: auto;
  margin-right: 1rem;
`;

const ZoomScale = styled.span`
  align-self: center;
  color: ${textOnTheme};
  font-size: 0.8rem;
  padding-left: 0.2rem;
  font-variant-numeric: tabular-nums;
`;

const PageIndicationContainer = styled.div`
  display: flex;
  justify-self: auto;
  color: ${textOnTheme};
  font-weight: 500;
  font-variant-numeric: tabular-nums;
  user-select: none;
  align-items: center;
`;

const MatchesIndicationContainer = styled.div`
  display: flex;
  justify-self: auto;
  color: ${textOnTheme};
  font-weight: 500;
  font-size: 0.9rem;
  font-variant-numeric: tabular-nums;
  user-select: none;
  align-items: center;
  margin-left: 0.3rem;
`;
