import { Config } from '../config/config';

export const DATA_REQUEST = 'instructionSettings/DATA_REQUEST';
export const DATA_REQUEST_FAILED = 'instructionSettings/DATA_REQUEST_FAILED';
export const DATA_REQUEST_SUCCESS = 'instructionSettings/DATA_REQUEST_SUCCESS';
export const INSTRUCTION_REQUEST = 'instructionSettings/INSTRUCTION_REQUEST';
export const TRANSACTION_REQUEST = 'instructionSettings/TRANSACTION_REQUEST';
export const TOPFILE_REQUEST = 'instructionSettings/TOPFILE_REQUEST';
export const GROUP_REQUEST = 'instructionSettings/GROUP_REQUEST';
export const INSTRUCTION_REQUEST_FAILED = 'instructionSettings/INSTRUCTION_REQUEST_FAILED';
export const TRANSACTION_REQUEST_FAILED = 'instructionSettings/TRANSACTION_REQUEST_FAILED';
export const TOPFILE_REQUEST_FAILED = 'instructionSettings/TOPFILE_REQUEST_FAILED';
export const GROUP_REQUEST_FAILED = 'instructionSettings/GROUP_REQUEST_FAILED';
export const UPDATE_INSTRUCTION_SUCCESS = 'instructionSettings/UPDATE_INSTRUCTION_SUCCESS';
export const UPDATE_TRANSACTION_SUCCESS = 'instructionSettings/UPDATE_TRANSACTION_SUCCESS';
export const UPDATE_TOPFILE_SUCCESS = 'instructionSettings/UPDATE_TOPFILE_SUCCESS';
export const UPDATE_GROUP_SUCCESS = 'instructionSettings/UPDATE_GROUP_SUCCESS';
export const NEW_ROW_SUBMITTED = 'instructionSettings/NEW_ROW_SUBMITTED';
export const NEW_INSTRUCTION = 'instructionSettings/NEW_INSTRUCTION';
export const NEW_TRANSACTION = 'instructionSettings/NEW_TRANSACTION';
export const NEW_TOPFILE = 'instructionSettings/NEW_TOPFILE';
export const NEW_INSTRUCTION_SUCCESS = 'instructionSettings/NEW_INSTRUCTION_SUCCESS';
export const NEW_TRANSACTION_SUCCESS = 'instructionSettings/NEW_TRANSACTION_SUCCESS';
export const NEW_TOPFILE_SUCCESS = 'instructionSettings/NEW_TOPFILE_SUCCESS';
export const NEW_GROUP_SUCCESS = 'instructionSettings/NEW_GROUP_SUCCESS';
export const DELETE_INSTRUCTION_SUCCESS = 'instructionSettings/DELETE_INSTRUCTION_SUCCESS';
export const DELETE_TRANSACTION_SUCCESS = 'instructionSettings/DELETE_TRANSACTION_SUCCESS';
export const DELETE_TOPFILE_SUCCESS = 'instructionSettings/DELETE_TOPFILE_SUCCESS';
export const SET_DOCUMENT_CHANGES = 'instructionSettings/SET_DOCUMENT_CHANGES';
export const SET_DOCUMENT_CHANGES_LOADING = 'instructionSettings/SET_DOCUMENT_CHANGES_LOADING';

export const getData = () => {
  return async (dispatch) => {
    dispatch({ type: DATA_REQUEST, payload: { loading: true } });
    try {
      const res = await fetch(Config.conf.backendUrl + '/api/v1/admin/document/adminconfig', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${sessionStorage.token}`,
        },
      });
      if (!res.ok) {
        dispatch({
          type: DATA_REQUEST_FAILED,
          payload: { loading: false, error: 'Der skete desværre en server fejl, prøv igen' },
        });
        return;
      }

      const data = await res.json();
      dispatch({ type: DATA_REQUEST_SUCCESS, payload: { data: data } });
      return;
    } catch (err) {
      return dispatch({
        type: DATA_REQUEST_FAILED,
        payload: {
          loading: false,
          error: 'Der skete desværre en server fejl, prøv igen',
        },
      });
    }
  };
};

export const updateInstruction = ({ doc }) => {
  return async (dispatch) => {
    dispatch({ type: INSTRUCTION_REQUEST });
    if (doc) {
      try {
        const res = await fetch(`${Config.conf.backendUrl}/api/v1/admin/document/${doc._id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `bearer ${sessionStorage.token}`,
          },
          body: JSON.stringify(doc),
        });
        if (!res.ok) {
          console.error(`error: request failed`);
          dispatch({ type: INSTRUCTION_REQUEST_FAILED });
          return;
        }
        dispatch({ type: UPDATE_INSTRUCTION_SUCCESS, payload: doc });
        return;
      } catch (e) {
        dispatch({ type: INSTRUCTION_REQUEST_FAILED, error: e.message });
      }
    }
  };
};

export const updateTransaction = ({ doc }) => {
  return async (dispatch) => {
    dispatch({ type: TRANSACTION_REQUEST });
    if (doc) {
      try {
        const res = await fetch(`${Config.conf.backendUrl}/api/v1/admin/document/transactions/${doc._id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `bearer ${sessionStorage.token}`,
          },
          body: JSON.stringify(doc),
        });
        if (!res.ok) {
          console.error(`error: request failed`);
          dispatch({ type: TRANSACTION_REQUEST_FAILED });
          return;
        }
        dispatch({ type: UPDATE_TRANSACTION_SUCCESS, payload: doc });
        return;
      } catch (e) {
        dispatch({ type: TRANSACTION_REQUEST_FAILED, error: e.message });
      }
    }
  };
};

export const updateTopfile = ({ doc }) => {
  return async (dispatch) => {
    dispatch({ type: TOPFILE_REQUEST });
    if (doc) {
      try {
        const res = await fetch(`${Config.conf.backendUrl}/api/v1/admin/document/topfiles/${doc._id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `bearer ${sessionStorage.token}`,
          },
          body: JSON.stringify(doc),
        });
        if (!res.ok) {
          console.error(`error: request failed`);
          dispatch({ type: TOPFILE_REQUEST_FAILED });
          return;
        }
        dispatch({ type: UPDATE_TOPFILE_SUCCESS, payload: doc });
        return;
      } catch (e) {
        dispatch({ type: TOPFILE_REQUEST_FAILED, error: e.message });
      }
    }
  };
};

/**
 * Updates group in database
 * @param {string} id group id
 * @param {string} groupName new group name
 */
export const updateGroup = (id, groupName) => {
  return async (dispatch) => {
    dispatch({ type: GROUP_REQUEST });

    try {
      const res = await fetch(`${Config.conf.backendUrl}/api/v1/admin/group/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${sessionStorage.token}`,
        },
        body: JSON.stringify({ name: groupName }),
      });
      if (!res.ok) {
        console.error(`error: request failed`);
        dispatch({ type: GROUP_REQUEST_FAILED });
        return;
      }

      dispatch({ type: UPDATE_GROUP_SUCCESS, payload: { id: id, key: groupName, value: groupName } });
      return;
    } catch (e) {
      dispatch({ type: GROUP_REQUEST_FAILED, error: e.message });
    }
  };
};

/**
 * Set state of newly added item to not submitted until its is saved
 * to be able to promt user
 * @param {boolean} state
 */
const setNewRowStatus = (state) => {
  return async (dispatch) => {
    dispatch({ type: NEW_ROW_SUBMITTED, payload: state });
  };
};

/**
 * Add instruction to list of data with empty fields (not to database)
 * @param {object} newInstruction new instruction object with empty fields
 */
export const addNewInstruction = (newInstruction) => {
  return async (dispatch) => {
    dispatch(setNewRowStatus(false));
    dispatch({ type: NEW_INSTRUCTION, payload: newInstruction });
  };
};

/**
 * Add transaction to list of data with empty fields (not to database)
 * @param {object} newTransaction new transaction object with empty fields
 */
export const addNewTransaction = (newTransaction) => {
  return async (dispatch) => {
    dispatch(setNewRowStatus(false));
    dispatch({ type: NEW_TRANSACTION, payload: newTransaction });
  };
};

/**
 * Add topfile to list of data with empty fields (not to database)
 * @param {object} newTopfile new topfile object with empty fields
 */
export const addNewTopfile = (newTopfile) => {
  return async (dispatch) => {
    dispatch(setNewRowStatus(false));
    dispatch({ type: NEW_TOPFILE, payload: newTopfile });
  };
};

/**
 * Find the document group and return its id value
 * @param {object} doc document to submit
 * @param {array} groups list of groups
 */
const getCategoryId = (doc, groups) => groups.filter((g) => g.value === doc.category)[0].id;

/**
 * Submits new document to database adding missing required fields
 * @param {object} param0 document object
 */
export const submitNewInstruction = ({ doc }) => {
  return async (dispatch, getState) => {
    dispatch({ type: INSTRUCTION_REQUEST });
    // ADD all missing required fields for an instruction schema
    doc.type = 'doc';
    doc.categoryId = getCategoryId(doc, getState().instructionSettings.groups);
    doc.process = !doc.process ? '' : doc.process;
    doc.sagstype = !doc.sagstype ? '' : doc.sagstype;
    doc.compliance = [];
    doc.level1kw = doc?.level1kw?.length > 0 ? doc.level1kw : [];
    doc.level2kw = doc?.level2kw?.length > 0 ? doc.level2kw : [];

    if (doc) {
      try {
        const res = await fetch(`${Config.conf.backendUrl}/api/v1/admin/document`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `bearer ${sessionStorage.token}`,
          },
          body: JSON.stringify(doc),
        });
        if (!res.ok) {
          console.error(`error: request failed`);
          dispatch({ type: INSTRUCTION_REQUEST_FAILED });
          return;
        }
        const body = await res.json();
        dispatch({ type: NEW_INSTRUCTION_SUCCESS, payload: body });
        return;
      } catch (e) {
        dispatch({ type: INSTRUCTION_REQUEST_FAILED, error: e.message });
      }
    }
  };
};

/**
 * Submits new document to database adding missing required fields
 * @param {object} param0 document object
 */
export const submitNewTransaction = ({ doc }) => {
  return async (dispatch) => {
    dispatch({ type: TRANSACTION_REQUEST });
    // ADD all missing required fields for an instruction schema
    if (doc) {
      try {
        const res = await fetch(`${Config.conf.backendUrl}/api/v1/admin/document/transactions`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `bearer ${sessionStorage.token}`,
          },
          body: JSON.stringify(doc),
        });
        if (!res.ok) {
          console.error(`error: request failed`);
          dispatch({ type: TRANSACTION_REQUEST_FAILED });
          return;
        }
        const body = await res.json();
        dispatch({ type: NEW_TRANSACTION_SUCCESS, payload: body });
        return;
      } catch (e) {
        dispatch({ type: TRANSACTION_REQUEST_FAILED, error: e.message });
      }
    }
  };
};

/**
 * Submits new document to database adding missing required fields
 * @param {object} param0 document object
 */
export const submitNewTopfile = ({ doc }) => {
  return async (dispatch, getState) => {
    dispatch({ type: TOPFILE_REQUEST });
    // ADD all missing required fields for an instruction schema
    
    if (doc) {
      doc.order = 0; // TBD s
      doc.aiRecommended = doc.aiRecommended || false;
      doc.level1kw = doc.level1kw?.length > 0 ? doc.level1kw : [];
      doc.level2kw = doc.level2kw?.length > 0 ? doc.level2kw : [];
      
      try {
        const res = await fetch(`${Config.conf.backendUrl}/api/v1/admin/document/topfiles`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `bearer ${sessionStorage.token}`,
          },
          body: JSON.stringify(doc),
        });
        if (!res.ok) {
          console.error(`error: request failed`);
          dispatch({ type: TOPFILE_REQUEST_FAILED });
          return;
        }
        const body = await res.json();
        dispatch({ type: NEW_TOPFILE_SUCCESS, payload: body });
        return;
      } catch (e) {
        dispatch({ type: TOPFILE_REQUEST_FAILED, error: e.message });
      }
    }
  };
};

/**
 * Creates new group in database
 * @param {string} group group object {name: <group name>}
 */
export const addNewGroup = (group) => {
  return async (dispatch) => {
    dispatch({ type: GROUP_REQUEST });

    try {
      const res = await fetch(`${Config.conf.backendUrl}/api/v1/admin/group`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${sessionStorage.token}`,
        },
        body: JSON.stringify(group),
      });
      if (!res.ok) {
        console.error(`error: request failed`);
        dispatch({ type: GROUP_REQUEST_FAILED });
        return;
      }
      const body = await res.json();
      dispatch({ type: NEW_GROUP_SUCCESS, payload: body });
      return;
    } catch (e) {
      dispatch({ type: GROUP_REQUEST_FAILED, error: e.message });
    }
  };
};

/**
 * Delete instruction form db, or redux if not submitted to db yet.
 * @param {string} id id of instruction (if not submitted it is 'new')
 */
export const deleteInstruction = (id) => {
  return async (dispatch) => {
    dispatch({ type: INSTRUCTION_REQUEST });
    // IF the instruction is not submitted yet just delete it from redux
    if (id === 'new') {
      dispatch({ type: DELETE_INSTRUCTION_SUCCESS, payload: { id } });
      return;
    }

    try {
      const res = await fetch(`${Config.conf.backendUrl}/api/v1/admin/document/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${sessionStorage.token}`,
        },
      });
      if (!res.ok) {
        console.error(`Error: request failed`);
        dispatch({ type: INSTRUCTION_REQUEST_FAILED });
        return;
      }

      dispatch({ type: DELETE_INSTRUCTION_SUCCESS, payload: { id } });
      return;
    } catch (e) {
      dispatch({ type: INSTRUCTION_REQUEST_FAILED, error: e.message });
    }
  };
};

/**
 * Delete transaction doc form db, or redux if not submitted to db yet.
 * @param {string} id of transaction doc (if not submitted it is 'new')
 */
export const deleteTransaction = (id) => {
  return async (dispatch) => {
    dispatch({ type: TRANSACTION_REQUEST });
    // IF the TRANSACTION is not submitted yet just delete it from redux
    if (id === 'new') {
      dispatch({ type: DELETE_TRANSACTION_SUCCESS, payload: { id } });
      return;
    }

    try {
      const res = await fetch(`${Config.conf.backendUrl}/api/v1/admin/document/transactions/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${sessionStorage.token}`,
        },
      });
      if (!res.ok) {
        console.error(`Error: request failed`);
        dispatch({ type: TRANSACTION_REQUEST_FAILED });
        return;
      }

      dispatch({ type: DELETE_TRANSACTION_SUCCESS, payload: { id } });
      return;
    } catch (e) {
      dispatch({ type: TRANSACTION_REQUEST_FAILED, error: e.message });
    }
  };
};

/**
 * Delete topfile doc form db, or redux if not submitted to db yet.
 * @param {string} id of topfile doc (if not submitted it is 'new')
 */
export const deleteTopfile = (id) => {
  return async (dispatch) => {
    dispatch({ type: TOPFILE_REQUEST });
    // IF the TOPFILE is not submitted yet just delete it from redux
    if (id === 'new') {
      dispatch({ type: DELETE_TOPFILE_SUCCESS, payload: { id } });
      return;
    }

    try {
      const res = await fetch(`${Config.conf.backendUrl}/api/v1/admin/document/topfiles/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${sessionStorage.token}`,
        },
      });
      if (!res.ok) {
        console.error(`Error: request failed`);
        dispatch({ type: TOPFILE_REQUEST_FAILED });
        return;
      }

      dispatch({ type: DELETE_TOPFILE_SUCCESS, payload: { id } });
      return;
    } catch (e) {
      dispatch({ type: TOPFILE_REQUEST_FAILED, error: e.message });
    }
  };
};

