import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components';
import { downIcon } from '../../../sharedStyledComponents/icons';
import { colorTheme, generalBackground, textOnTheme, Content, itemBackground } from '../../../sharedStyledComponents/generalStyles';

export default class DropdownTab extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            expanded: false,
        }
        this.ref = React.createRef();
    }

    handleExpand = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.dropdownTop = this.ref.current.offsetTop + this.ref.current.offsetHeight;
        this.dropdownWidth = this.ref.current.offsetWidth;
        this.setState({ expanded: !this.state.expanded })
    }

    _createDropdown = () => {
        return <Dropdown onMouseLeave={this.handleExpand} top={this.dropdownTop} width={this.dropdownWidth} >
            {this.props.categories.slice(1).filter(c => (c.value !== this.props.currentCategory && c.value !== this._handleTabName())).map((c, i) =>
                <DropdownItem
                    key={c.value + i}
                    onClick={() => this.props.setCurrentCategory(c.value)}

                >
                    {c.value}
                </DropdownItem>)}
        </Dropdown>
    }

    _handleTabName = () => {
        if (this.props.currentCategory !== this.props.categories[1]?.value && this.props.currentCategory !== this.props.categories[0]?.value) {
            return this.props.currentCategory;
        }
        else {
            return this.props.categories[1].value;
        }
    }

    render() {
        const combiButtonDocName = this._handleTabName();
        const current = this.props.currentCategory === combiButtonDocName;

        return (
            <>
                <Tab $current={current} onClick={() => this.props.setCurrentCategory(combiButtonDocName)} ref={this.ref}>
                    <LinkText>{combiButtonDocName}</LinkText>
                </Tab>

                {this.props.categories.length > 2
                    &&
                    <Expand $expanded={this.state.expanded} $current={current} onClick={this.handleExpand}>{downIcon('15px', colorTheme)}</Expand>
                }

                {this.props.categories.length > 2
                    &&
                    this.state.expanded
                    &&
                    this._createDropdown()
                }
            </>
        )
    }
}

DropdownTab.propTypes = {
    categories: PropTypes.array.isRequired,
    setCurrentCategory: PropTypes.func.isRequired,
    currentCategory: PropTypes.string,
}

const Tab = styled.div`
    display:inline-flex;
    background-color: ${props => props.$current ? colorTheme : textOnTheme};
    color: ${props => props.$current ? textOnTheme : colorTheme};
    user-select: none;
    border-radius: .2rem .2rem 0 0;
    cursor: pointer;
    padding: .5rem .3rem .3rem 1rem;
    font-weight: 550;
    user-select: none;
    flex: 1;
    text-align: center;
    box-shadow: -1px -1px 1px #888;
`

const LinkText = styled.span`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: .3rem;
    border-radius: 2px;
`

const Expand = styled.div`
    margin-left: auto;
    border-radius: 2px;
    padding: .3rem .5rem;
    cursor: pointer;
    color: ${textOnTheme}; 
    user-select: none;
    border: 1px transparent;
    border-left: 1px solid ${generalBackground};
    background-color: ${props => props.$expanded || props.$current ? generalBackground : textOnTheme};
    box-shadow: 0 1px 0 hsla(0,0%,100%,.05) inset,
                    0 0 1px hsla(0,0%,100%,.15) inset,
                    0 0 1px hsla(0,0%,0%,.05);
    &:hover {
        background-color: ${generalBackground};
    }
`;

const Dropdown = styled(Content)`
    margin: 0;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    position: absolute;
    right: 1rem;
    z-index: 999999;
    background-color: ${itemBackground};
    top: ${props => (props.top) + 'px'};
    width: ${props => (props.width) + 'px'};
`;

const DropdownItem = styled.div`
    padding: .3rem .5rem .3rem 1rem;
    border-bottom: .1rem solid #f0f0f0;
    user-select: none;
    cursor: pointer;
    :hover {
      background-color: ${generalBackground};
      outline: none;
    }  
`;
