import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// import topImg from './ip-top-button.png';

import { clearToken } from '../../_actions/auth.actions';
import { getDashboardConfig, openNotification } from '../../_actions/dashboard.actions';

import { getAndUpdateDocuments, addActiveDocument, removeActiveDocument } from '../../_actions/multiDocument.actions';

import {
  subscribeMessages,
  unsubscribeMessages,
  n1Click,
  unsubscribeCallStatus,
  headersReceived,
  unsubscribeHeadersReceived,
  complianceScoresReceived,
  unsubscribecomplianceScoresReceived,
  reconnect,
  unsubscribeReconnect,
  unsubscribeN1Click,
  unsubscribeN2Click,
  unsubscribeBackClick,
  userInactive,
  unsubscribeUserInactive,
  callStatus,
  unsubscribeComplianceItemToggle,
  tabCount,
} from '../../_actions/socket-io.actions';

import { addOutline, clearNavigation } from '../../_actions/chosen.actions';
import { getAgentConfig } from '../../_actions/agentConfig.actions';

import ContentHeader from './ContentHeader/contentHeader.component';
import Topfiles from './TopFilesView/topfiles.container';
import { Listening } from './Listening/listening.component';
import Chosen from './ChosenView/chosen.container';
import NoDocument from './components/nodocument.component';
import Workinstructions from './WorkInstructionsView/workinstructions.container';
import MultiDocumentContainer from './DocumentView/multiDocument.container';
import TextTranscribing from './DebugView/TextTranscribing';
import SearchView from './SearchView/search.container';
import Transactions from './TransactionsView/transactions.container';

import { colorTheme, generalBackground, borderColor, textOnTheme } from '../sharedStyledComponents/generalStyles';

import { documentTypes } from '../../config/enums';
import { Loading } from '../GlobalComponents/loading.component';
import ChangesToInstructionsModal from './TopFilesView/changesToInstructionsModal.component';

export class Dashboard extends Component {
  componentDidMount() {
    this.props.reconnect();
    this.props.subscribe();
    this.props.callStatusCheck();
    this.props.headersReceived();
    this.props.tabCount();
    this.props.complianceScoresReceived();

    // Get config then fetch/update all docs
    this.props.getDashboardConfig().then(() => {
      // Fetch all instructions and topfiles
      this.props.getAndUpdateDocuments(this.props.workInstructions.concat(this.props.topFiles));
      this.props.getAgentConfig();
    });

    this.userInactiveTimeout = null;

    /*
    document.addEventListener('mousemove', (e) => {
      clearTimeout(this.userInactiveTimeout);
      //send event to backend if user has been inactive for 15 minutes (to close any existing offline session)
      this.userInactiveTimeout = setTimeout(() => this.props.userInactive(), 900000);
    });
    */

    // This is used to remove the active instruction when browser "back" button is clicked
    this.unblock = this.props.history.block((location) => {
      const LOGOUT_MSG = 'Er du sikker på du vil logge ud?';
      const CLOSE_DOC_MSG = 'Er du sikker på at du vil lukke dokumentet?';
      // If current tab is the Listview tab or In case of logout or pressing back when ever no documents are active
      if (
        (this.props.currentDocumentIndex === 'Listview' || this.props.activeDocuments.length === 0) &&
        location.pathname !== '/dashboard'
      ) {
        if (window.confirm(LOGOUT_MSG)) {
          this.props.clearToken();
          this.unblock();
          this.props.history.replace('/login');
          return;
        } else {
          return false;
        }
      }

      // If there are currently opened documents and the tab is not Listview
      if (this.props.activeDocuments.length > 0 && location.pathname !== '/dashboard') {
        if (window.confirm(CLOSE_DOC_MSG)) {
          this.props.removeActiveDocument(this.props.activeDocuments[this.props.currentDocumentIndex].id, true);
          return false;
        }
        return false;
      }
    });
  }

  componentDidUpdate(prevProps) { 
    if (prevProps.currentTabCount === 0 && this.props.currentTabCount !== 0) {
      openNotification(this.props.currentTabCount)
    }
  }

  componentWillUnmount() {
    this.props.unsubscribe();
    this.props.unsubscribeReconnect();
    this.props.unsubscribeCallStatus();
    this.props.unsubscribeHeadersReceived();
    this.props.unsubscribecomplianceScoresReceived();
    this.props.unsubscribeBackClick();
    this.props.unsubscribeN1Click();
    this.props.unsubscribeN2Click();
    this.props.unsubscribeUserInactive();
    this.props.unsubscribeComplianceItemToggle();
    this.unblock();
    // Ensures that the WebSocket is closed and set to null
    window.dispatchEvent(new Event('onbeforeunload'));
  }

  render() {
    const {
      activeDocuments,
      currentDocumentIndex,
      loadingDocument,
      fullText,
      history,
      topFiles,
      addActiveDocument,
      loadingConfig,
      workInstructions,
      offline,
      suggestions,
    } = this.props;

    let currentDocPDFData = undefined;
    let currentDocMetaData = undefined;
    if (typeof currentDocumentIndex === 'number' && activeDocuments.length > 0) {
      currentDocPDFData = activeDocuments[currentDocumentIndex];
      // find current active PDF metadata
      currentDocMetaData =
        workInstructions.find((doc) => doc.id === currentDocPDFData.id) ||
        topFiles.find((doc) => doc.id === currentDocPDFData.id);
    }

    const shouldSidebarBeWide = activeDocuments.length === 0 || currentDocumentIndex === 'Listview';
    // Search component is only displayed in non workinstruction docs and docs which are not aiRecommended among the topfiles
    const shouldDisplaySearch =
      !currentDocPDFData ||
      (currentDocMetaData?.type !== documentTypes.workInstructions && !currentDocMetaData?.aiRecommended);

    // Chosen / N2 component should be displayed only if we are in an AI recommended document
    const shouldDisplayChosen =
      activeDocuments.length > 0 &&
      currentDocumentIndex !== 'Listview' &&
      (currentDocMetaData?.type === documentTypes.workInstructions ||
        (currentDocMetaData?.type === documentTypes.topFiles && currentDocMetaData?.aiRecommended));

    return (
      <MainContainer>
        {loadingDocument && <Loading />}
        <MultiDocumentContainer />
        {fullText.length > 0 && <TextTranscribing fullText={fullText} />}
        <NoDocumentContainer
          style={{
            visibility: activeDocuments.length === 0 ? 'visible' : 'hidden',
            width: activeDocuments.length === 0 ? '50vw' : '75vw',
          }}
        >
          <NoDocument props={activeDocuments} />
        </NoDocumentContainer>

        <Content style={{ width: shouldSidebarBeWide ? '50vw' : '25vw' }}>
          <FixedContent>
            <ContentHeader history={history} />

            <Topfiles
              topFiles={topFiles}
              addActiveDocument={addActiveDocument}
              $expanded={activeDocuments.length === 0}
            />

            <Listening
              loading={loadingConfig}
              workInstructions={workInstructions}
              addActiveDocument={addActiveDocument}
              offline={offline}
            />

            {
              // The search component is static and not scrollable until a search is performed
              shouldDisplaySearch && suggestions.length === 0 && <SearchView />
            }
          </FixedContent>

          <ScrollableContent>
            {
              // Upon search the component becomes scrollable so as we can still see the rest of the components
              shouldDisplaySearch && suggestions.length > 0 && <SearchView />
            }

            {shouldDisplayChosen ? (
              <Chosen activeDocument={currentDocPDFData} outline={currentDocPDFData?.outline} />
            ) : (
              <>
                <Workinstructions />
                <Transactions />
              </>
            )}

            <Footer style={{ width: shouldSidebarBeWide ? '50vw' : '25vw' }}>
              {process.env.REACT_APP_STAGE === 'dev' ? '**DEVELOPMENT**  ' : ''}Powered by IBM Watson
            </Footer>
          </ScrollableContent>
        </Content>

        <ChangesToInstructionsModal />

        {/*
        // Demo purpose container 
        <Temp src={topImg} /> 
        */}
      </MainContainer>
    );
  }
}

Dashboard.propTypes = {
  currentDocumentIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  activeDocuments: PropTypes.arrayOf(PropTypes.object),
  workInstructions: PropTypes.arrayOf(PropTypes.object),
  topFiles: PropTypes.arrayOf(PropTypes.object),
  getAndUpdateDocuments: PropTypes.func.isRequired,
  loadingConfig: PropTypes.bool.isRequired,
  fullText: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  missingDocument: PropTypes.bool,
  offline: PropTypes.bool.isRequired,
  n1Click: PropTypes.func.isRequired,
  unsubscribeCallStatus: PropTypes.func.isRequired,
  unsubscribeReconnect: PropTypes.func.isRequired,
  unsubscribeHeadersReceived: PropTypes.func.isRequired,
  unsubscribecomplianceScoresReceived: PropTypes.func.isRequired,
  unsubscribeBackClick: PropTypes.func.isRequired,
  unsubscribeN1Click: PropTypes.func.isRequired,
  unsubscribeN2Click: PropTypes.func.isRequired,
  userInactive: PropTypes.func.isRequired,
  unsubscribeUserInactive: PropTypes.func.isRequired,
  clearToken: PropTypes.func.isRequired,
  unsubscribeComplianceItemToggle: PropTypes.func.isRequired,
  tabCount: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentDocumentIndex: state.documents.currentDocumentIndex,
  activeDocuments: state.documents.activeDocuments,
  workInstructions: state.dashboard.workInstructions,
  topFiles: state.dashboard.topFiles,
  loadingConfig: state.dashboard.loadingConfig,
  fullText: state.dashboard.fullText,
  missingDocument: state.documents.missingDocument,
  offline: state.dashboard.offline,
  loadingDocument: state.documents.loadingDocument,
  suggestions: state.search.suggestions,
  favoriteInstructions: state.agentConfig.favoriteInstructions,
  username: state.auth.username,
  unseenDocumentChanges: state.agentConfig.unseenDocumentChanges,
  currentTabCount: state.dashboard.tabCount,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDashboardConfig,
      addActiveDocument,
      removeActiveDocument,
      getAndUpdateDocuments,
      subscribe: subscribeMessages,
      unsubscribe: unsubscribeMessages,
      callStatusCheck: callStatus,
      addOutline,
      clearNavigation,
      n1Click,
      headersReceived,
      complianceScoresReceived,
      reconnect,
      unsubscribeCallStatus,
      unsubscribeReconnect,
      unsubscribeHeadersReceived,
      unsubscribecomplianceScoresReceived,
      unsubscribeBackClick,
      unsubscribeN1Click,
      unsubscribeN2Click,
      userInactive,
      unsubscribeUserInactive,
      clearToken,
      unsubscribeComplianceItemToggle,
      getAgentConfig: getAgentConfig,
      tabCount,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

const MainContainer = styled.div`
  height: 100%;
  overflow-y: scroll;
`;
const NoDocumentContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 75vw;
  max-width: 75vw;
  height: 100vh;
  transition: width 0.1s;
`;

const Content = styled.div`
  position: fixed;
  width: 25vw;
  top: 0;
  right: 0;
  bottom: 2rem;
  background-color: ${generalBackground};
  border-left: 0.1rem solid ${borderColor};
  display: flex;
  flex-direction: column;
`;

const FixedContent = styled.div``;

const ScrollableContent = styled.div`
  overflow: hidden;
  &:hover {
    overflow-y: auto;
  }
`;

const Footer = styled.div`
  background-color: ${colorTheme};
  position: fixed;
  width: 25%;
  bottom: 0;
  height: 2rem;
  padding: 0.3rem 1rem;
  text-align: end;
  color: ${textOnTheme};
  font-weight: 700;
  font-size: 0.9rem;
  white-space: nowrap;
`;

// Used for showing buttons for demo videos
// const Temp = styled.img`
//   position: fixed;
//   top: -1%;
//   left: 39%;
//   height: 5rem;
//   width: 9.9rem;
// `;
