import axios from 'axios';
import { Config } from '../config/config';
import { backClick } from './socket-io.actions';
import { documentTypes } from '../config/enums';
import { addActiveDocument } from './multiDocument.actions';
import { notification } from 'antd';
import call_icon from '../assets/call_icon.ico';
import ipsb_icon from '../assets/ipsb.ico';

export const GET_INSTRUCTIONS_CONFIG_REQUEST = 'dashboard/GET_INSTRUCTIONS_CONFIG_REQUEST';
export const GET_INSTRUCTIONS_CONFIG_SUCCESS = 'dashboard/GET_INSTRUCTIONS_CONFIG_SUCCESS';
export const GET_INSTRUCTIONS_CONFIG_FAILURE = 'dashboard/GET_INSTRUCTIONS_CONFIG_FAILURE';
export const OFFLINE_CONFIG = 'dashboard/OFFLINE_CONFIG';
export const SINGLE_DOCUMENT_UPDATING = 'dashboard/SINGLE_DOCUMENT_UPDATING';
export const SINGLE_DOCUMENT_UPDATED = 'dashboard/SINGLE_DOCUMENT_UPDATED';
export const TIMER_START = 'dashboard/TIMER_START';
export const TIMER_TICK = 'dashboard/TIMER_TICK';
export const TIMER_STOP = 'dashboard/TIMER_STOP';
export const MODAL_OPEN = 'dashboard/MODAL_OPEN';
export const MODAL_CLOSED = 'dashboard/MODAL_CLOSED';
export const SET_CURRENT_CATEGORY = 'dashboard/SET_CURRENT_CATEGORY';
export const TOGGLE_DISPLAY_CHANGES_TO_INSTRUCTIONS_MODAL = 'dashboard/TOGGLE_DISPLAY_CHANGES_TO_INSTRUCTIONS_MODAL';

export const getDashboardConfig = () => {
  return async (dispatch) => {
    dispatch(request());
    try {
      const [documentConfigResponse, complianceResponse] = await Promise.all([
        axios.get(`${Config.conf.backendUrl}/api/v1/document/config`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.token}`,
          },
        }),
        axios.get(`${Config.conf.backendUrl}/api/v1/compliance`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.token}`,
          },
        }),
      ]);

      dispatch(success(documentConfigResponse.data, complianceResponse.data));
    } catch (e) {
      console.error('error:' + e);
      // This ensures the app works offline as well
      const categories = localStorage.getItem('categories');
      const workInstr = localStorage.getItem('workInstructions');
      const topFiles = localStorage.getItem('topFiles');
      const transactions = localStorage.getItem('transactions');
      const complianceItems = localStorage.getItem('complianceItems');

      dispatch(
        offlineConfig(
          JSON.parse(categories),
          JSON.parse(workInstr),
          JSON.parse(topFiles),
          JSON.parse(transactions),
          JSON.parse(complianceItems)
        )
      );
    }
  };

  function request() {
    return { type: GET_INSTRUCTIONS_CONFIG_REQUEST };
  }

  function success(documentConfig, complianceItems) {
    const categories = documentConfig.groups;

    const workInstr = documentConfig.instructions.map((i) => {
      return {
        id: i.id,
        name: i.value.displayName,
        documentName: i.value.fileName,
        category: i.value.category,
        categoryId: categories.find((cat) => cat.name === i.value.category),
        type: documentTypes.workInstructions,
        documentHash: i.value.documentHash,
        confidence: 0,
        updating: false,
        // compliance:
        //   !i.value.compliance || i.value.compliance.length === 0
        //     ? null
        //     : i.value.compliance.map((group) => {
        //         return {
        //           ...group,
        //           complianceItems: group.complianceItems.map((item) => {
        //             return { ...item, manualChecked: false, aiChecked: false, previousConfidence: 0, confidence: 0 };
        //           }),
        //         };
        //       }),
      };
    });

    const topFiles = documentConfig.topfiles.map((i) => {
      return {
        id: i.id,
        name: i.value.name,
        type: documentTypes.topFiles,
        documentHash: i.value.documentHash,
        aiRecommended: i.value.aiRecommended,
        updating: false,
      };
    });

    const transactions = documentConfig.transactions.map((t) => {
      return {
        id: t.id,
        name: t.value.name,
        type: documentTypes.transactions,
        documentHash: t.value.documentHash,
        updating: false,
      };
    });

    // OFFLINE CONFIG STORAGE
    localStorage.setItem('categories', JSON.stringify(categories));
    localStorage.setItem('workInstructions', JSON.stringify(workInstr));
    localStorage.setItem('topFiles', JSON.stringify(topFiles));
    localStorage.setItem('transactions', JSON.stringify(transactions));
    localStorage.setItem('complianceItems', JSON.stringify(complianceItems));

    return {
      type: GET_INSTRUCTIONS_CONFIG_SUCCESS,
      payload: { categories, workInstructions: workInstr, topFiles, transactions, complianceItems },
    };
  }

  // TODO: handle this properly
  // function error(err) {
  //   return { type: GET_INSTRUCTIONS_CONFIG_FAILURE, err: err }
  // }

  function offlineConfig(categories, workInstructions, topFiles, transactions, complianceItems) {
    return { type: OFFLINE_CONFIG, payload: { categories, workInstructions, topFiles, transactions, complianceItems } };
  }
};

export const setFavicon = (status) => {
  const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'icon';
  if (status) {
    link.href = call_icon
    document.title = 'Live kald | ' + document.title;
  } else {
    link.href = ipsb_icon
    document.title = document.title.replace('Live kald | ', '');
  }
  document.getElementsByTagName('head')[0].appendChild(link);
}

let timer = null;
export const timerStart = () => {
  return async (dispatch, getState) => {
    // When reconnection if a call is ongoing we start the timer
    const { callStatus, topic } = await getState().dashboard;

    if (callStatus) {
      setFavicon(callStatus)
    }

    // if user is in n2 on backend and n1 is on n1 we send backclick
    if (callStatus && topic) {
      dispatch(backClick(callStatus));
    }

    if (callStatus) {
      clearInterval(timer);
      timer = setInterval(() => dispatch(tick()), 1000);
      dispatch({ type: TIMER_START, callStatusText: '(AI medlyt)' });
      dispatch(tick());
    }
  };
  function tick() {
    return { type: TIMER_TICK };
  }
};

export const timerStop = () => {
  return (dispatch) => {
    setFavicon(false);
    clearInterval(timer);
    dispatch(stop());
  };
  function stop() {
    return { type: TIMER_STOP };
  }
};

export const reopenDocuments = () => {
  return async (dispatch, getState) => {
    const { callStatus, topic } = await getState().dashboard;

    if (callStatus && topic) {
      dispatch(addActiveDocument(topic));
    }
  };
};

export const openNotification = (count) => {
  const args = {
    message: `Du har ${count} faner åbne`,
    description:
      'Husk at lukke dem du ikke længere benytter.',
    duration: 0,
  };
  notification.open(args);
};

export const setCurrentCategory = (payload) => {
  return (dispatch) => {
    dispatch({ type: SET_CURRENT_CATEGORY, payload });
  };
};

export const toggleDisplayChangesToInstructions = () => {
  return dispatch => {
    dispatch({ type: TOGGLE_DISPLAY_CHANGES_TO_INSTRUCTIONS_MODAL });
  }
}