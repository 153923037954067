import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components';
import { itemBackground, colorTheme } from '../../../sharedStyledComponents/generalStyles';
import { callIcon, callEndIcon } from '../../../sharedStyledComponents/icons';


export default class CallStatus extends Component {

  format(time) {
    const pad = (time, length) => {
      while (time.length < length) {
        time = '0' + time;
      }
      return time;
    }

    time = new Date(time * 1000);
    let m = pad(time.getMinutes().toString(), 2);
    let s = pad(time.getSeconds().toString(), 2);

    return `${m}:${s}`;
  }

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.callStatusIndicator !== this.props.callStatusIndicator ||
      nextProps.time !== this.props.time ||
      nextProps.username !== this.props.username ||
      nextProps.offline !== this.props.offline
    ) {
      return true;
    }
    return false;
  }
  componentDidUpdate(prevProps) {
    if (!prevProps.callStatusIndicator && this.props.callStatusIndicator) {
      // start counter.
      this.props.timerStart();
      this.props.reopenDocuments();
    }
    if (!this.props.callStatusIndicator && prevProps.callStatusIndicator) this.props.timerStop();
  }

  componentWillUnmount() {
    this.props.timerStop();
  }

  render() {
    const callstatus = this.props.callStatusIndicator;

    return (
      <Container>
        <IndicatorWrapper>
          <Indicator > {callstatus ?
            callIcon('20px', colorTheme) :
            callEndIcon('20px', 'red')}</Indicator>
          {!this.props.offline && this.props.time !== 0 && <Timer key={'timer'}>{this.format(this.props.time)}</Timer>}
        </IndicatorWrapper>
        {!this.props.offline ?
          <StatusText $status={callstatus}>{this.props.callStatusText}</StatusText>
          :
          <StatusText $status={callstatus}>OFFLINE</StatusText>}
      </Container>
    )
  }
}

CallStatus.propTypes = {
  callStatusIndicator: PropTypes.bool.isRequired,
  callStatusText: PropTypes.string,
  timerStart: PropTypes.func.isRequired,
  timerStop: PropTypes.func.isRequired,
  reopenDocuments: PropTypes.func.isRequired,
  offline: PropTypes.bool.isRequired,
  time: PropTypes.number,
}

CallStatus.defaultProps = {
  callStatusText: 'AI medlyt stoppet'
}

const Container = styled.div`
  padding: 0 1.5rem .5rem 1.5rem;
  background-color : ${itemBackground};
  display: flex;
  align-items: center;
`;

const StatusText = styled.span`
  color: ${colorTheme};
  user-select: none;
`;

const IndicatorWrapper = styled.div`
  display: flex;
  align-items: center;
  animation: ${props => props.$status ? fadeIn : fadeOutIn} 1s ease-in-out;
`;

const Timer = styled.div`
  font-variant-numeric: tabular-nums;
  display: flex;
  user-select: none;
  padding-right: .3rem;
`;

const fadeIn = keyframes`
  from {
    transform: scale(.25);
    opacity: .2;
  }
  80% {
    transform: scale(1.3);
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const fadeOutIn = keyframes`
  from {
    transform: scale(1);
    opacity: .2;
  }
  50% {
    transform: scale(.25);
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const Indicator = styled.div`
  margin-right: .5rem;
  display: flex;
  opacity: .9;
  animation: ${props => props.$status ? fadeIn : fadeOutIn} 1s ease-in-out;
`;

// const Username = styled(TitleText)`
//   text-overflow: ellipsis;
//   overflow: hidden;
//   max-width: 52%;
//   /* Ensure that the Status text is present if the username is short but 
//     is removed for a long username.
//    */
//   ${props => (props.length > 20)
//     ? `&:hover {
//       + ${StatusText} {
//         color:  transparent;
//       }
//       position: absolute;
//       overflow: initial;
//       text-overflow: initial;
//       background-color: #fff;
//       max-width: 100%;
//       /* IE11 indentifier, because it handles hover diffrently and realigns the textline */
//       @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
//         margin-top: -.6rem;
//       }
//       } `
//     :
//     ''
//   }
// `;