import styled from 'styled-components';
import { colorTheme, textTheme } from '../../sharedStyledComponents/generalStyles';
import { StyledTable } from '../../sharedStyledComponents/dataGridStyles';
import dayjs from 'dayjs';
import 'dayjs/locale/da';
import { Empty, Progress, Divider } from 'antd';
import { inlineHeader } from '../../sharedStyledComponents/dataGridStyles';
import CustomModal from '../../GlobalComponents/custom-modal/custom-modal.component';
import { AGENT_STATUS_TYPE } from './_constants/instructionChanges.contstants';

dayjs.locale('da');

const InstructionChangeModal = ({ change, visible, onClose }) => {
  const columns = [
    {
      dataIndex: 'agent',
      title: inlineHeader('Rådgiver'),
      sorter: (a, b) => a.agent.localeCompare(b.agent),
    },
    {
      dataIndex: '',
      title: inlineHeader('Sidst opdateret'),
      sorter: (a, b) => change?.timestamp,
      render: (_, agent) => {
        const timestamp = dayjs(change.timestamp);
        return <>{`${timestamp.date()}/${timestamp.month() + 1}-${timestamp.year()} kl ${timestamp.format('LT')}`}</>;
      },
    },
    {
      dataIndex: 'timestamp',
      title: inlineHeader('Sidst set'),
      sorter: (a, b) => a.timestamp - b.timestamp,
      width: '20%',
      align: 'center',
      render: (_, agent) => {
        if (agent.timestamp === 0) return '-';
        else {
          const timestamp = dayjs(agent.timestamp);
          return <>{`${timestamp.date()}/${timestamp.month() + 1}-${timestamp.year()} kl ${timestamp.format('LT')}`}</>;
        }
      },
    },
    {
      dataIndex: 'seen',
      title: inlineHeader('Status'),
      sorter: (a, b) => a.status - b.status,
      width: '20%',
      render: (_, agent) => {
        return (
          <>
            {
              <AgentStatus
                color={
                  agent.status === AGENT_STATUS_TYPE.seen
                    ? 'green'
                    : agent.status === AGENT_STATUS_TYPE.notSeen
                    ? 'red'
                    : 'grey'
                }
              >
                {agent.status === AGENT_STATUS_TYPE.seen
                  ? 'Set'
                  : agent.status === AGENT_STATUS_TYPE.notSeen
                  ? 'Ikke set'
                  : 'N/A'}
              </AgentStatus>
            }
          </>
        );
      },
    },
  ];

  const seenCount = change?.agentStatus.filter((a) => a.status === AGENT_STATUS_TYPE.seen).length;
  const percentage = Math.round((seenCount / change?.agentStatus.length) * 100);

  return (
    <CustomModal
      title={<PrimaryTitle>Instruksændring</PrimaryTitle>}
      subtitle={<SecondaryTitle>{change?.docName}</SecondaryTitle>}
      width={1000}
      visible={visible}
      close={() => onClose()}
    >
      <ModalBody>
        <Description>
          Her kan du få overblik over hvilke rådgivere, der har set nedenstående arbejdsinstruks.
        </Description>
        <InfoWrapper>
          <ProgressWrapper>
            <PercentageWrapper>
              <Percentage>{percentage}%</Percentage>
              <span>set</span>
            </PercentageWrapper>
            <ProgressBar>
              <StyledProgress
                percent={percentage}
                strokeLinecap="square"
                size={"default"}
                strokeColor={colorTheme}
                trailColor={'rgba(0,0,0,0.15)'}
                showInfo={false}
              />
            </ProgressBar>
          </ProgressWrapper>
          <Divider type="vertical" height={100} style={{ height: '45px', borderColor: 'rgba(0,0,0,0.15)' }} />
          <ListWrapper>
            <ListItem>
              <Label>Arbejdsinstruks:</Label> {change?.docName}
            </ListItem>
            <ListItem>
              <Label>Sektion:</Label> {change?.sectionName}
            </ListItem>
            <ListItem>
              <Label>Status:</Label> {percentage}% af rådgiverne har set instruksændringen ({seenCount}/
              {change?.agentStatus.length})
            </ListItem>
          </ListWrapper>
        </InfoWrapper>
        <AgentStatusTable
          locale={{
            triggerDesc: 'Klik for at sortere faldende',
            triggerAsc: 'Klik for at sortere stigende',
            emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Ingen ændringer" />,
          }}
          clickableRow
          sortDirections={['ascend', 'descend']}
          pagination={false}
          dataSource={change?.agentStatus}
          sticky={true}
          columns={columns}
          rowKey={'agent'}
        />
      </ModalBody>
    </CustomModal>
  );
};

export default InstructionChangeModal;

const AgentStatusTable = styled(StyledTable)`
  border-radius: 0;

  .ant-table-tbody > tr:hover > td {
    cursor: default;
  }
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const PrimaryTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
`;

const SecondaryTitle = styled.div`
  font-size: 24px;
`;

const Description = styled.div`
  color: ${textTheme};
  margin-bottom: 0.5rem;
`;

const InfoWrapper = styled.div`
  display: flex;
  // justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

const ProgressWrapper = styled.div`
  display: flex;
  width: 125spx;
  flex-direction: column;
  padding: 0 5px;
  line-height: 24px;
`;

const PercentageWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: 18px;

  > span {
    position: relative;
    top: 4px;
    color: rgba(0, 0, 0, 0.3);
  }
`;

const Percentage = styled.div`
  font-weight: 700;
  font-size: 30px;
  color: ${colorTheme};
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 16px;
`;

const StyledProgress = styled(Progress)`
  .ant-progress-inner {
    border-radius: 0;
  }
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 5px;
`;

const ListItem = styled.div`
  display: flex;
  gap: 0.2rem;
  color: ${textTheme};
  line-height: 16px;
`;

const Label = styled.div`
  font-weight: 600;
`;

const AgentStatus = styled.div`
  position: relative;
  padding-left: 1.2rem;

  &:before {
    position: absolute;
    content: '';
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
`;
