import { message } from 'antd';
import { history } from '../store';
import axios from 'axios';
import { Config } from '../config/config';

export const CURRENT_INSTRUCTION = 'additionalSettings/CURRENT_INSTRUCTION';
export const ERROR = 'additionalSettings/ERROR';
export const SET_GREETING_MESSAGE = 'additionalSettings/SET_GREETING_MESSAGE';

export const setCurrentInstruction = (id) => {
  return (dispatch, getState) => {
    const instructions = getState().instructionSettings.instructions;
    const currentInstruction = instructions.find((i) => i.id === id);
    const currentTopfile = getState().instructionSettings.topfiles.find((i) => i.id === id);

    // if no matches on id go back to overview
    if (!currentInstruction && !currentTopfile) {
      message.error('Forkert instruks ID, du bliver dirigeret tilbage til instrukser', 2, () =>
        history.push('/admin/instructions')
      );
    }
    dispatch({ type: CURRENT_INSTRUCTION, payload: currentInstruction || currentTopfile });
  };
};

export const clearCurrentInstruction = () => {
  return (dispatch) => {
    dispatch({ type: CURRENT_INSTRUCTION, payload: undefined });
  };
};

export const getGreetingMessage = () => async (dispatch) => {
  try {
    const res = await axios.get(`${Config.conf.backendUrl}/api/v1/appconfig/greeting`);
    dispatch({ type: SET_GREETING_MESSAGE, payload: res.data?.text });
  } catch (error) {
    console.log(error?.message);
  }
};

export const updateGreetingMessage = (newMessage) => async (dispatch) => {
  const messageCouldNotBeUpdated = () => {
    message.error('Baggrundstekst kunne ikke opdateres, prøv igen');
  };

  try {
    const res = await axios.put(`${Config.conf.backendUrl}/api/v1/appconfig/greeting`, { text: newMessage });
    if (!res.data?.ok) {
      messageCouldNotBeUpdated();
      return;
    }
    dispatch({ type: SET_GREETING_MESSAGE, payload: newMessage });
  } catch (Error) {
    messageCouldNotBeUpdated();
  }
};
